import type {Day, DayRange} from "@hassanmojab/react-modern-calendar-datepicker";

import {monthNamesShort} from './FilterCalendar.constants';

export const isEqualDate = (dayOne: Day, dayTwo: Day): boolean => {
    return dayOne.day === dayTwo.day && dayOne.month === dayTwo.month && dayOne.year === dayTwo.year;
};

export const isEqualDateRange = (dayRangeOne: DayRange, dayRangeTwo: DayRange): boolean => {
    if(!dayRangeOne.from || !dayRangeOne.to || !dayRangeTwo.from || !dayRangeTwo.to) {
        return false;
    }

    return isEqualDate(dayRangeOne.from, dayRangeTwo.from) && isEqualDate(dayRangeOne.to, dayRangeTwo.to);
};

export const dayObjToString = (day: Day): string => {
    return `${monthNamesShort[day.month - 1]} ${('00' + day.day).slice(-2)}, ${day.year}`;
}