import {EMPLOYEE, EMPLOYEES} from "../../constants";

export const setFieldAPI = async (id: string, data: string, addEmployee = false) => {
    try {
        const response = await fetch(`${addEmployee ? `${EMPLOYEES}/employee` : EMPLOYEE}/${id}/${data}`, {
            method: 'PUT',
        });

        if(!response.ok || response.status !== 200) {
            const error = await response.json();
            throw new Error(error[0].message);
        }

        let result;
        try {
            result = await response.json();
        } catch (e) {
            result = response;
        }

        return result;
    } catch(error: any) {
        // @ts-ignore
        return {
            error: String(error.message)
        };
        // @ts-ignore
        throw new Error(error);
    }
};