export const sampleEmployees = [
  {
    id: 1,
    date: "10.04.24",
    amount: "0",
    changedBy: "Auto",
    balance: 10,
    comment: "Added according to time off policy",
  },
  {
    id: 2,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 2,
    comment: "Max limit reached by time off policy",
  },
  {
    id: 3,
    date: "10.04.24",
    amount: "+111",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 1,
    comment: "Max limit reached by time off policy",
  },
  {
    id: 4,
    date: "10.04.24",
    amount: "+1.5",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 0,
    comment: "Added according to time off policy",
  },
  {
    id: 5,
    date: "10.04.24",
    amount: "-1.5",
    changedBy: "Auto",
    balance: 2,
    comment: "A long comment with truncateA long comment with truncate",
  },
  {
    id: 6,
    date: "10.04.24",
    amount: "-11",
    changedBy: "Auto",
    balance: 10,
    comment: "Subtracted due to time off request",
  },
  {
    id: 7,
    date: "10.04.24",
    amount: "+5",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 22,
    comment: "Added according to time off policy",
  },
  {
    id: 8,
    date: "10.04.24",
    amount: "+6",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 15,
    comment: "Added according to time off policy",
  },
  {
    id: 9,
    date: "10.04.24",
    amount: "-8",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 5,
    comment: "Added according to time off policy",
  },
  {
    id: 10,
    date: "10.04.24",
    amount: "-2.5",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 3,
    comment: "Added according to time off policy",
  },
  {
    id: 11,
    date: "10.04.24",
    amount: "+1.5",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 4,
    comment: "Added according to time off policy",
  },
  {
    id: 12,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 0,
    comment: "Added according to time off policy",
  },
  {
    id: 13,
    date: "10.04.24",
    amount: "-5",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 1,
    comment: "Added according to time off policy",
  },
  {
    id: 14,
    date: "10.04.24",
    amount: "+3",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 2,
    comment: "Added according to time off policy",
  },
  {
    id: 15,
    date: "10.04.24",
    amount: "-3",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 0,
    comment: "Added according to time off policy",
  },
  {
    id: 16,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 15,
    comment: "Added according to time off policy",
  },
  {
    id: 17,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 5,
    comment: "Added according to time off policy",
  },
  {
    id: 18,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 10,
    comment: "Added according to time off policy",
  },
  {
    id: 19,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 6,
    comment: "Added according to time off policy",
  },
  {
    id: 20,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 1,
    comment: "Added according to time off policy",
  },
  {
    id: 21,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 0,
    comment: "Added according to time off policy",
  },
  {
    id: 22,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 10,
    comment: "Added according to time off policy",
  },
  {
    id: 23,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 20,
    comment: "Added according to time off policy",
  },
  {
    id: 24,
    date: "10.04.24",
    amount: "0",
    changedBy: {
      name: "Jane Rotanson",
      nickname: "Nikname",
      avatar: "",
      requesterId: "1",
    },
    balance: 1,
    comment: "Added according to time off policy",
  },
];
