import {EMPLOYEES} from "../../constants";
import type {DirectManagerProps} from "./directManager.types";

export const fetchDirectManagerAPI = async (idProfile: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/employee/${idProfile}/direct-manager`, {
            method: 'GET',
            headers: {'Accept': 'application/json'}
        });

        return await response.json() as DirectManagerProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};