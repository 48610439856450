import { IAnnouncementsModalProps } from "./types";

export enum AnnouncementModalTypeEnum {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const announcementModalDefaultState: IAnnouncementsModalProps = {
  isOpen: false,
  type: AnnouncementModalTypeEnum.CREATE,
  payload: null,
};
