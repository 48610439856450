import React, {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import * as yup from "yup";

import style from './ForgotPassword.module.scss';
import styleAuth from "../Auth.module.scss";
import {AuthLayout} from '../AuthLayout';
import { ErrorMessage } from '@atlaskit/form';
import {Input} from "../../UI/Input";
import {LoadingButton} from "@atlaskit/button";
import {Button} from "../../UI/Button";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";

import {AUTH_SIGN_IN} from '../../../Router/routes';

import {SuccessForgotPassword} from './SuccessForgotPassword';
import {yupResolver} from "@hookform/resolvers/yup";

import {useSendLetterLink} from '../../../API/auth/useSendLetterLink';

interface ForgotPasswordFormFields {
    email: string;
}

const forgotPasswordSchema = yup.object().shape({
    email: yup
        .string()
        .required('Email don\'t match')
        .email('Email is invalid')
}).required();

export const ForgotPassword: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    const toLogin = () => {
        navigate(AUTH_SIGN_IN, { replace: true });
    };

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<ForgotPasswordFormFields>({
        mode: 'onTouched',
        defaultValues: {
            email: email || ''
        },
        resolver: yupResolver(forgotPasswordSchema)
    });

    const {isLoading, isSuccess, isError, sendLetterLink, resetStatuses} = useSendLetterLink();

    const onSubmit: SubmitHandler<ForgotPasswordFormFields> = (values) => {
        resetStatuses();
        sendLetterLink(values.email);
    };

    if(isSuccess) {
        return(<SuccessForgotPassword email={getValues('email')} />);
    }

    return(
        <AuthLayout>
            <h2 className={style.title}>Forgot your Password?</h2>
            <p className={style.paragraph}>Enter your email address and we will send you a reset email</p>

            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className={styleAuth.inputContainer}>
                    <Input
                        {...register('email')}
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        isInvalid={!!errors.email?.message}
                    />
                    {errors.email?.message && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
                    {isError && <ErrorMessage>Something wrong...</ErrorMessage>}
                </div>

                <div className={styleAuth.sendWrapper}>
                    {isLoading ? (
                        <LoadingButton appearance="primary" isLoading
                                       className={styleAuth.sendBtn}>Loading...</LoadingButton>
                    ) : (
                        <Button appearance="primary" type="submit" className={styleAuth.sendBtn}>Send me a reset
                            link</Button>
                    )}
                </div>

                <div className={style.returnWrap}>
                    <Button
                        appearance="link"
                        spacing="none"
                        iconBefore={<ArrowLeftIcon label="Return to login"/>}
                        className={styleAuth.linkBtn}
                        onClick={toLogin}
                    >
                        Return to login
                    </Button>
                </div>
            </form>
        </AuthLayout>
    );
}