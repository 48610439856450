import { useState } from "react";
import { adminStatusAPI } from "API/common/status";

interface DeleteStatusProps {
  id: string;
  onSuccess: () => void;
}

interface ErrorDetails {
  message: string;
  statusCode: number | null;
}

const useDeleteStatus = () => {
  const [error, setError] = useState<ErrorDetails | null>(null);

  let statusCode: number | null = null;
  const deleteStatus = async ({ id, onSuccess }: DeleteStatusProps) => {
    try {
      const deleteStatusResponse = await adminStatusAPI.deleteStatus(id);
      if (!deleteStatusResponse.ok) {
        statusCode = deleteStatusResponse.status;
        let errorMessage = `HTTP error! status: ${statusCode}`;

        if (statusCode === 422) {
          const errorData = await deleteStatusResponse.json();
          errorMessage = errorData?.message || "Unprocessable Entity";
        }

        throw new Error(errorMessage);
      }
      onSuccess();
    } catch (error) {
      setError({
        message: (error as Error).message,
        statusCode: statusCode || null,
      });
    }
  };

  return { deleteStatus, error };
};

export default useDeleteStatus;
