import {useState} from 'react';
import {useEvent} from '../../customHooks/useEvent';
import {changeUserPassword} from './changeUserPassword';

export const useChangePassword = (resetCode: string) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);

    const changePassword = useEvent((newPassword: string, confirmedPassword: string) => {
        setLoading(true);
        changeUserPassword(resetCode, newPassword, confirmedPassword)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return {
        isLoading,
        isSuccess,
        isError,
        changePassword
    };
};