import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootSlice";

const store = configureStore({
  reducer: {
    rootReducer: rootReducer,
  },
});

export default store;



export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']