export const createHead = (withWidth: boolean) => {
  return {
    cells: [
      {
        key: 'Employee',
        content: 'Employee',
        isSortable: true,
        width: withWidth ? 19 : undefined,
      },
      {
        key: 'Creation Date',
        content: 'Creation Date',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 10 : undefined,
      },
      {
        key: 'Policy',
        content: 'Policy',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 6 : undefined,
      },
      {
        key: 'Time off period',
        content: 'Time off period',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 25 : undefined,
      },
      {
        key: 'Substitute',
        content: 'Substitute',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 19 : undefined,
      },
      {
        key: 'Status',
        content: 'Status',
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 11 : undefined,
      },
      {
        key: 'Action',
        content: 'Action',
        shouldTruncate: true,
        width: withWidth ? 12 : undefined,
      },
    ],
  }
}

export const tableHeader = createHead(true)
