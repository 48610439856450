import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const StyledHeader = styled.header<{ $navIsOpen: boolean }>`
  position: fixed;
  width: 100%;
  max-width: 2560px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${token("color.border")};
  background: ${token("color.border.inverse")};
  padding-right: ${token("space.400")};
  z-index: 4;

  @media (min-width: 1920px) {
    padding-right: ${token("space.800")};
  }

  @media (max-width: 1024px) {
    background: ${token("color.background.accent.blue.subtlest")};
    border-bottom: 0;
    padding: 0 ${token("space.200")};
    transition: transform 0.25s ease-out;
    ${(props) =>
      props.$navIsOpen &&
      `
      overflow: hidden;
      transform: translate(240px);
      `}
  }

  .logo {
    width: 190px;
    height: 44px;
    display: block;
    margin: 0 ${token("space.300")};
    cursor: pointer;

    @media (max-width: 1024px) {
      display: none;
    }

    &--mob {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: none;

      @media (max-width: 1024px) {
        display: block;
        margin: 0 auto;
      }
    }
  }
`;

export const StyledHeaderSearch = styled.div<{ $searchIsOpen: boolean }>`
  position: relative;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;

    ${(props) =>
      props.$searchIsOpen &&
      `
          width: 100%;
          position: absolute;
          inset: 0 0 0 0;
          right: ${token("space.200")};
          z-index: 2;
          `}
  }

  // open\close button
  & > button {
    display: none;

    @media (max-width: 1024px) {
      display: inline-flex;

      ${(props) =>
        props.$searchIsOpen &&
        `
          position: absolute;
          inset: 0 56px 0 auto;
          margin: auto;
          z-index: 3;
          `}
    }
  }
`;

export const StyledHeaderMenuBtn = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const StyledHeaderTime = styled.div`
  margin-right: ${token("space.300")};

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const StyledHeaderUser = styled.div`
  border-left: 1px solid ${token("color.border")};
  padding-left: ${token("space.300")};

  @media (max-width: 1024px) {
    display: none;
  }
`;
