import React, { FC } from "react";
import Select, { ValueType } from "@atlaskit/select";
import { Field, ErrorMessage } from "@atlaskit/form";
import { StyledIconItem, StyledIconSelect } from "./IconSelectStyles";
import { TypeIconsCollection } from "../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import { typeNames, nameFromValue } from "../../../../shared/TimeOffPolicy/constants/IconsName";
import {IconOption} from "../../../../interfaces/timeOffPolicy/Select/iconOption.interface";
import {IconSelectProps} from "../../../../interfaces/timeOffPolicy/iconSelectProps.interface";

const IconSelect: FC<IconSelectProps> = ({ icon, setIcon, setPolicyIconError }) => {
    const iconKeys = Object.keys(TypeIconsCollection).filter(
        (key) => key !== "birthday" && key !== "anniversary" && key !== "holiday"
    );

    const options: IconOption[] = iconKeys.map((key) => ({
        label: (
            <StyledIconItem>
                {TypeIconsCollection[key as keyof typeof TypeIconsCollection](
                    "large-icon", "withouthover"
                )}
            </StyledIconItem>
        ),
        value: key,
    }));

    const handleChange = (option: ValueType<IconOption>) => {
        setPolicyIconError('');
        if (option) {
            setIcon((option as IconOption).value);
        }
    };

    const selectedValue = options.find((option) => option.value === icon) || null;

    return (
        <StyledIconSelect>
            <Field<ValueType<IconOption>> name="icon" label="Icon">
                {({ fieldProps: { id, value: _value, onChange: _onChange, ...rest }, error }) => (
                    <>
                        <Select<IconOption>
                            inputId={id}
                            options={options}
                            placeholder="Choose an icon"
                            className="modal-icon-select"
                            onChange={handleChange}
                            value={selectedValue}
                            isSearchable
                            maxMenuHeight={204}
                            {...rest}
                        />
                        {error && <ErrorMessage>Please, choose the policy</ErrorMessage>}
                    </>
                )}
            </Field>
        </StyledIconSelect>
    );
};

export default IconSelect;