import React, { useState, useEffect } from "react";
import "./JobTitles.scss";
import {
  JOB_TITLES,
  JOB_TITLES_CATEGORIES,
} from "../../../../utils/constants/api";
import Button, { Size, Type } from "../../../Component/Button/Button";
import CreateCategory from "./Modal/Category/CreateCategory";
import DeleteCategory from "./Modal/Category/DeleteCategory";
import EditCategory from "./Modal/Category/EditCategory";
import CreateJobTitle from "./Modal/JobTitle/CreateJobTitle";
import DeleteJobTitle from "./Modal/JobTitle/DeleteJobTitle";
import EditJobTitle from "./Modal/JobTitle/EditJobTitle";
import { JobTitleFromApi } from "../../../../shared/JobTitle/data/JobTitleFromApi";
import { JobTitlesCategoryFromApi } from "../../../../shared/JobTitle/data/JobTitlesCategoryFromApi";

export const JobTitles = () => {
  const [createJobTitleModal, setCreateJobTitleModal] =
    useState<boolean>(false);
  const [editJobTitleModal, setEditJobTitleModal] = useState<boolean>(false);
  const [deleteJobTitleModal, setDeleteJobTitleModal] =
    useState<boolean>(false);

  const [createCategoryModal, setCreateCategoryModal] =
    useState<boolean>(false);
  const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false);
  const [deleteCategoryModal, setDeleteCategoryModal] =
    useState<boolean>(false);

  const [jobTitles, setJobTitles] = useState<JobTitleFromApi[]>([]);
  const [jobTitleToChange, setJobTitleToChange] =
    useState<JobTitleFromApi | null>(null);
  const [jobTitleToDelete, deleteJobTitle] = useState<JobTitleFromApi | null>(
    null
  );

  const [jobTitlesCategories, setJobTitlesCategories] = useState<
    JobTitlesCategoryFromApi[]
  >([]);
  const [categoryToChange, setCategoryToChange] =
    useState<JobTitlesCategoryFromApi | null>(null);
  const [categoryToDelete, deleteCategory] =
    useState<JobTitlesCategoryFromApi | null>(null);

  const [jobTitleButtonVisibility, setJobTitleButtonVisibility] =
    useState<boolean>(false);

  useEffect(getJobTitles, []);
  useEffect(getJobTitlesCategories, []);
  useEffect(checkJobTitleButtonVisibility, [jobTitlesCategories]);

  function getJobTitles() {
    fetch(JOB_TITLES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((jobTitles: JobTitleFromApi[]) => setJobTitles(jobTitles));
  }

  function getJobTitlesCategories() {
    fetch(JOB_TITLES_CATEGORIES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((jobTitlesCategories: JobTitlesCategoryFromApi[]) =>
        setJobTitlesCategories(jobTitlesCategories)
      );
  }

  function editJobTitleModalVisibility(jobTitle: JobTitleFromApi): void {
    setJobTitleToChange(jobTitle);
    setEditJobTitleModal(true);
  }

  function confirmDeleteJobTitleModalVisibility(
    jobTitle: JobTitleFromApi
  ): void {
    deleteJobTitle(jobTitle);
    setDeleteJobTitleModal(true);
  }

  function editCategoryModalVisibility(
    category: JobTitlesCategoryFromApi
  ): void {
    setCategoryToChange(category);
    setEditCategoryModal(true);
  }

  function confirmDeleteCategoryModalVisibility(
    category: JobTitlesCategoryFromApi
  ): void {
    deleteCategory(category);
    setDeleteCategoryModal(true);
  }

  function checkJobTitleButtonVisibility(): void {
    if (jobTitlesCategories.length > 0) {
      setJobTitleButtonVisibility(true);
    } else {
      setJobTitleButtonVisibility(false);
    }
  }

  function getCategoryNameById(id: string): string {
    return (
      jobTitlesCategories.find((category) => category.id === id)?.name || ""
    );
  }

  return (
    <div className={"admin-panel-board"}>
      <div className="sub-content-block d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Job titles</h3>
          </div>
          <div className="add-button-block">
            <div
              style={{ display: jobTitleButtonVisibility ? "flex" : "none" }}
            >
              <Button
                text="<i className='material-icons add-item'>add</i> Add Job Title"
                size={Size.Medium}
                type={Type.Accent}
                onClick={() => setCreateJobTitleModal(true)}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Button
                text="<i className='material-icons add-item'>add</i> Add Category"
                size={Size.Medium}
                type={jobTitleButtonVisibility ? Type.White : Type.Accent}
                onClick={() => setCreateCategoryModal(true)}
              />
            </div>
          </div>
        </div>

        <div className="sub-content-main">
          <table className="job-titles-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobTitlesCategories.map((category) => {
                return (
                  <tr key={category.id}>
                    <td title={category.name}>{category.name}</td>
                    <td>
                      <i
                        className="material-icons"
                        onClick={() => editCategoryModalVisibility(category)}
                      >
                        edit
                      </i>
                      <i
                        className="material-icons"
                        onClick={() =>
                          confirmDeleteCategoryModalVisibility(category)
                        }
                      >
                        delete
                      </i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table className="job-titles-table">
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Category</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobTitles.map((jobTitle) => {
                return (
                  <tr key={jobTitle.id}>
                    <td title={jobTitle.name}>{jobTitle.name}</td>
                    <td title={getCategoryNameById(jobTitle.categoryId)}>
                      {getCategoryNameById(jobTitle.categoryId)}
                    </td>
                    <td>
                      <i
                        className="material-icons"
                        onClick={() => editJobTitleModalVisibility(jobTitle)}
                      >
                        edit
                      </i>
                      <i
                        className="material-icons"
                        onClick={() =>
                          confirmDeleteJobTitleModalVisibility(jobTitle)
                        }
                      >
                        delete
                      </i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <CreateJobTitle
        opened={createJobTitleModal}
        onOk={() => {
          getJobTitles();
          setCreateJobTitleModal(false);
        }}
        onClose={() => setCreateJobTitleModal(false)}
        categories={jobTitlesCategories}
      />
      {jobTitleToDelete ? (
        <DeleteJobTitle
          opened={deleteJobTitleModal}
          onOk={() => {
            getJobTitles();
            setDeleteJobTitleModal(false);
            deleteJobTitle(null);
          }}
          onClose={() => {
            setDeleteJobTitleModal(false);
            deleteJobTitle(null);
          }}
          jobTitle={jobTitleToDelete}
        />
      ) : null}
      {jobTitleToChange ? (
        <EditJobTitle
          opened={editJobTitleModal}
          onOk={() => {
            getJobTitles();
            setEditJobTitleModal(false);
            setJobTitleToChange(null);
          }}
          onClose={() => {
            setEditCategoryModal(false);
            setJobTitleToChange(null);
          }}
          jobTitle={jobTitleToChange}
          categories={jobTitlesCategories}
        />
      ) : null}

      <CreateCategory
        opened={createCategoryModal}
        onOk={() => {
          getJobTitlesCategories();
          setCreateCategoryModal(false);
        }}
        onClose={() => setCreateCategoryModal(false)}
        categories={jobTitlesCategories}
      />
      {categoryToDelete ? (
        <DeleteCategory
          opened={deleteCategoryModal}
          onOk={() => {
            getJobTitlesCategories();
            setDeleteCategoryModal(false);
            deleteCategory(null);
          }}
          onClose={() => {
            setDeleteCategoryModal(false);
            deleteCategory(null);
          }}
          category={categoryToDelete}
        />
      ) : null}
      {categoryToChange ? (
        <EditCategory
          opened={editCategoryModal}
          onOk={() => {
            getJobTitlesCategories();
            setEditCategoryModal(false);
            setCategoryToChange(null);
          }}
          onClose={() => {
            setEditCategoryModal(false);
            setCategoryToChange(null);
          }}
          category={categoryToChange}
          categories={jobTitlesCategories}
        />
      ) : null}
    </div>
  );
};
