import type {CountryOptionsProps, CountryResponseProps} from "./country.types";
import {COUNTRIES} from "../../../../constants";

const transformToADSSelectOptions = (options: Array<CountryResponseProps>): Array<CountryOptionsProps> => {
    return options.map((option) => {
        return {
            ...option,
            label: option.name,
            value: option.code
        }
    });
};

export const fetchCountryAPI = async () => {
    try {
        const response = await fetch(COUNTRIES, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        const data = await response.json() as Array<CountryResponseProps>;
        return transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};