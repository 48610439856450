import React, { useState } from "react";
import { Radio } from "@atlaskit/radio";
import Textfield from "@atlaskit/textfield";
import { Box } from "@atlaskit/primitives";
import {
  StyledWrapper,
  StyledLeftSideBig,
  StyledRightSide,
  StyledTextField,
  StyledTextInput,
  StyledSettingsPreview,
  StyledList,
  StyledMessageError, StyledErrorDialog, StyledWarningIcon,
} from "../StylesCreatePolicy";
import { TypeIconsCollection } from "../../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import { ApprovalsAndTimeframesStepProps } from "../../../../../interfaces/timeOffPolicy/Steps/approvalsAndTimeframesStepProps";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { token } from "@atlaskit/tokens";
import InlineDialog from "@atlaskit/inline-dialog";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import {monthsOptions} from "../../../../../shared/TimeOffPolicy/constants/AccrualSchedule";

const StepApprovals: React.FC<ApprovalsAndTimeframesStepProps> = ({
  policyName,
  policyDescription,
  policyIcon,
  companyPaidRegardlessOfWorkday,
  accrualSchedule,
  requiresApproval,
  setRequiresApproval,
  requiresReplacement,
  setRequiresReplacement,
  minDaysPerRequest,
  setMinDaysPerRequest,
  maxDaysPerRequest,
  setMaxDaysPerRequest,
  minTimeframe,
  setMinTimeframe,
  isMinDaysPerRequest,
  setIsMinDaysPerRequest,
  isMaxDaysPerRequest,
  setIsMaxDaysPerRequest,
  isMinTimeframe,
  setIsMinTimeframe,
  minDaysPerRequestError,
  setMinDaysPerRequestError,
  maxDaysPerRequestError,
  setMaxDaysPerRequestError,
  minTimeframeError,
  setMinTimeframeError,
  requiresApprovalError,
  setRequiresApprovalError,
  requiresReplacementError,
  setRequiresReplacementError,
  isMinDaysPerRequestError,
  setIsMinDaysPerRequestError,
  isMaxDaysPerRequestError,
  setIsMaxDaysPerRequestError,
  isMinTimeframeError,
  setIsMinTimeframeError,
  policyDesignatedDayType,
  burnout,
  burnoutAccrualRule,
  activeBalance,
  balanceLimit,
  proratedAtHireStart,
  proratedAtHireEnd,
  accrualRules,
  statuses
}) => {
  return (
    <StyledWrapper>
      <StyledLeftSideBig>
        <Box>
          <h4>Does this policy require approval from a manager?</h4>
          <Radio
            label="No"
            name="requiresApproval"
            isChecked={requiresApproval === false}
            onChange={() => {
              setRequiresApproval(false);
              setRequiresApprovalError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <Radio
            label="Yes"
            name="requiresApproval"
            isChecked={requiresApproval === true}
            onChange={() => {
              setRequiresApproval(true);
              setRequiresApprovalError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          {requiresApprovalError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {requiresApprovalError}
            </StyledMessageError>
          )}
        </Box>

        <Box>
          <h4>Is a replacement person required?</h4>
          <Radio
            label="No"
            name="requiresReplacement"
            isChecked={requiresReplacement === false}
            onChange={() => {
              setRequiresReplacement(false);
              setRequiresReplacementError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <Radio
            label="Yes"
            name="requiresReplacement"
            isChecked={requiresReplacement === true}
            onChange={() => {
              setRequiresReplacement(true);
              setRequiresReplacementError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          {requiresReplacementError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {requiresReplacementError}
            </StyledMessageError>
          )}
        </Box>

        <Box>
          <h4>
            Is there a minimum number of days that can be taken per request?
          </h4>
          <Radio
            label="No"
            name="minDaysPerRequest"
            isChecked={isMinDaysPerRequest === false}
            onChange={() => {
              setIsMinDaysPerRequest(false);
              setIsMinDaysPerRequestError(null);
              setMinDaysPerRequest(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <Radio
            label="Yes"
            name="minDaysPerRequest"
            isChecked={isMinDaysPerRequest === true}
            onChange={() => {
              setIsMinDaysPerRequest(true);
              setIsMinDaysPerRequestError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          {isMinDaysPerRequestError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {isMinDaysPerRequestError}
            </StyledMessageError>
          )}
          {isMinDaysPerRequest && (
            <StyledTextField>
              <p>Minimum</p>
              <StyledTextInput>
                <Textfield
                  name="minDaysPerRequestValue"
                  value={minDaysPerRequest ? minDaysPerRequest.toString() : ""}
                  placeholder=""
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;

                    if (parseInt(newValue) <= 99 || newValue === '') {
                      setMinDaysPerRequest(
                          newValue && newValue !== ""
                              ? parseInt(newValue, 10)
                              : null
                      );
                      setMinDaysPerRequestError(false);
                    }
                  }}
                />
                {minDaysPerRequestError && (
                    <StyledErrorDialog>
                      <InlineDialog
                          content={'Please enter minimal days per request'}
                          isOpen={minDaysPerRequestError}
                          placement={'right-end'}
                      >
                        <StyledWarningIcon>
                          <WarningIcon
                              label=""
                              primaryColor={token("color.icon.warning")}
                          />
                        </StyledWarningIcon>
                      </InlineDialog>
                    </StyledErrorDialog>
                )}
              </StyledTextInput>
              <span>business days per request</span>
            </StyledTextField>
          )}
        </Box>

        <Box>
          <h4>
            Is there a maximum number of days that can be taken per request?
          </h4>
          <Radio
            label="No"
            name="maxDaysPerRequest"
            isChecked={isMaxDaysPerRequest === false}
            onChange={() => {
              setIsMaxDaysPerRequest(false);
              setIsMaxDaysPerRequestError(null);
              setMaxDaysPerRequest(null);

            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <Radio
            label="Yes"
            name="maxDaysPerRequest"
            isChecked={isMaxDaysPerRequest === true}
            onChange={() => {
              setIsMaxDaysPerRequest(true);
              setIsMaxDaysPerRequestError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          {isMaxDaysPerRequestError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {isMaxDaysPerRequestError}
            </StyledMessageError>
          )}
          {isMaxDaysPerRequest && (
            <StyledTextField>
              <p>Maximum</p>
              <StyledTextInput>
                <Textfield
                  name="maxDaysPerRequestValue"
                  placeholder=""
                  value={maxDaysPerRequest ? maxDaysPerRequest.toString() : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;

                    if (parseInt(newValue) <= 99 || newValue === '') {
                      setMaxDaysPerRequest(
                          newValue && newValue !== ""
                              ? parseInt(newValue, 10)
                              : null
                      );
                      setMaxDaysPerRequestError(false);
                    }
                  }}
                />
                {maxDaysPerRequestError && (
                    <StyledErrorDialog>
                      <InlineDialog
                          content={'Please enter maximum days per request'}
                          isOpen={maxDaysPerRequestError}
                          placement={'right-end'}
                      >
                        <StyledWarningIcon>
                          <WarningIcon
                              label=""
                              primaryColor={token("color.icon.warning")}
                          />
                        </StyledWarningIcon>
                      </InlineDialog>
                    </StyledErrorDialog>
                )}
              </StyledTextInput>
              <span>business days per request</span>
            </StyledTextField>
          )}
        </Box>

        <Box>
          <h4>Is there a minimum timeframe for making a request?</h4>
          <Radio
            label="No"
            name="minTimeframe"
            isChecked={isMinTimeframe === false}
            onChange={() => {
              setIsMinTimeframe(false);
              setIsMinTimeframeError(null);
              setMinTimeframe(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <Radio
            label="Yes"
            name="minTimeframe"
            isChecked={isMinTimeframe === true}
            onChange={() => {
              setIsMinTimeframe(true);
              setIsMinTimeframeError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          {isMinTimeframeError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {isMinTimeframeError}
            </StyledMessageError>
          )}
          {isMinTimeframe && (
            <StyledTextField>
              <p>Require minimum of</p>
              <StyledTextInput>
                <Textfield
                  name="minTimeframeValue"
                  placeholder=""
                  value={minTimeframe ? minTimeframe.toString() : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;

                    if (parseInt(newValue) <= 99 || newValue === '') {
                      setMinTimeframe(
                          newValue && newValue !== ""
                              ? parseInt(newValue, 10)
                              : null
                      );
                      setMinTimeframeError(false);
                    }
                  }}
                />
                {minTimeframeError && (
                    <StyledErrorDialog>
                      <InlineDialog
                          content={'Please enter minimal timeframe'}
                          isOpen={minTimeframeError}
                          placement={'right-end'}
                      >
                        <StyledWarningIcon>
                          <WarningIcon
                              label=""
                              primaryColor={token("color.icon.warning")}
                          />
                        </StyledWarningIcon>
                      </InlineDialog>
                    </StyledErrorDialog>
                )}
              </StyledTextInput>
              <span>business days before start date</span>
            </StyledTextField>
          )}
        </Box>
      </StyledLeftSideBig>

      <StyledRightSide>
        <h3>Summary</h3>

        <StyledSettingsPreview>
          <h4>Name</h4>
          <span>{policyName}</span>
        </StyledSettingsPreview>
        <StyledSettingsPreview>
          <h4>Icon</h4>
          {TypeIconsCollection[policyIcon as keyof typeof TypeIconsCollection](
            "small-icon"
          )}{" "}
        </StyledSettingsPreview>
        <StyledSettingsPreview>
          <h4>Description</h4>
          <p>{policyDescription}</p>
        </StyledSettingsPreview>
        <StyledList>
          <h4>Accruals & carryovers</h4>
          <ul>
            {companyPaidRegardlessOfWorkday != null &&
                <li>
                  This policy is{" "}
                  {companyPaidRegardlessOfWorkday ? "paid" : "unpaid"} by company
                </li>
            }
            {policyDesignatedDayType != null &&
                <li>
                  {policyDesignatedDayType === 'workDay' ? "Workday type" : "Unpaid day off type"} policy
                </li>
            }
            {accrualSchedule != null &&
                <li>
                  This is {accrualSchedule ? "an accrual" : "a non-accrual"} policy
                </li>
            }
            {accrualRules.length > 0 && (
                <div className={'rules'}>
                  <h4>Rules:</h4>
                  <ul>
                    {accrualRules.map((rule, index) => {
                      let text = 'Employees will accrue ';

                      if (rule.intervalType === 'daily') {
                        text += `${rule.allocationDays ? rule.allocationDays : 0} business day(s) per each ${rule.intervalCount ? rule.intervalCount : 0} day(s)`;
                      } else if (rule.intervalType === 'monthly') {
                        text += `${rule.allocationDays ? rule.allocationDays : 0} business day(s) every ${rule.intervalCount ? rule.intervalCount : 0} month(s) on ${rule.allocationDay !== 0
                            ? `${rule.allocationDay}${rule.allocationDay === 1 ? 'st' : 'th'} day`
                            : 'last day of month'
                        }`;
                      } else if (rule.intervalType === 'yearly') {
                        if (rule.yearlyAllocationDateType === 'hire') {
                          text += `${rule.allocationDays ? rule.allocationDays : 0} business day(s) per each year on hire date`;
                        } else if (rule.yearlyAllocationDateType === 'birthday') {
                          text += `${rule.allocationDays ? rule.allocationDays : 0} business day(s) per each year on birth date`;
                        } else if (rule.yearlyAllocationDateType === 'status') {
                          const statusLabel = statuses.find(
                              (option) => option.value === rule.allocationStatusId
                          )?.label || '';
                          text += `${rule.allocationDays ? rule.allocationDays : 0} business day(s) per each year on status starts date, namely ${statusLabel}`;
                        } else if (rule.yearlyAllocationDateType === 'custom') {
                          const monthLabel = monthsOptions.find(
                              (option) => parseInt(option.value) === rule.allocationMonth
                          )?.label || '';
                          text += `${rule.allocationDays ? rule.allocationDays : 0} business day(s) per each year on custom date, namely ${monthLabel} on ${rule.allocationDay !== 0
                              ? `${rule.allocationDay}${rule.allocationDay === 1 ? 'st' : 'th'} day`
                              : 'last day of month'
                          }`;
                        }
                      }

                      return (
                          <li style={{ color: 'var(--ds-text-subtlest)' }} key={index}>
                            {text}
                          </li>
                      );
                    })}
                  </ul>
                </div>
            )}

            {burnout && burnoutAccrualRule != null && accrualRules[burnoutAccrualRule] &&
                <li>
                  Accrued days burn off on date by the Rule №{burnoutAccrualRule + 1} (
                  {accrualRules[burnoutAccrualRule].intervalType === 'daily' &&
                      <>per each {accrualRules[burnoutAccrualRule].intervalCount} days</>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'monthly' &&
                      <>every {accrualRules[burnoutAccrualRule].intervalCount} month(s) on {accrualRules[burnoutAccrualRule].allocationDay !== 0
                          ? accrualRules[burnoutAccrualRule].allocationDay + ' day'
                          : 'last day of month'}</>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'hire' &&
                      <>per each year on hire date</>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'birthday' &&
                      <>per each year on birth date</>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'status' &&
                      <>per each year on status starts date, namely {statuses.find(
                          (option) => option.value === accrualRules[burnoutAccrualRule].allocationStatusId
                      )?.label || ''}</>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'custom' &&
                      <>per each year on custom date, namely {
                          monthsOptions.find(
                              (option) => parseInt(option.value) === accrualRules[burnoutAccrualRule].allocationMonth
                          )?.label || ''
                      } on {accrualRules[burnoutAccrualRule].allocationDay !== 0
                          ? accrualRules[burnoutAccrualRule].allocationDay + ' day'
                          : 'last day of month'}
                      </>}
                  )
                </li>
            }
            {burnout === false &&
                <li>Accrued days carry over from previous period</li>
            }
            {activeBalance != null && <>
              {!activeBalance &&
                  <li>
                    Accruals keep going regardless of the number of days on the balance
                  </li>
              }
              {balanceLimit != null &&
                  <li>
                    Accruals should be stopped when the balance reaches {balanceLimit ? balanceLimit : 0} business days
                  </li>
              }
            </>
            }
            {proratedAtHireStart != null &&
                <li>
                  The first accrual will be {!proratedAtHireStart ? "for the full amount" : "prorated"}
                </li>
            }
            {proratedAtHireEnd != null &&
                <li>
                  {!proratedAtHireEnd
                      ? 'The balance of this policy remains unchanged when an employee becomes inactive'
                      : 'The balance of this policy will be prorated when an employee becomes inactive'
                  }
                </li>
            }
          </ul>
        </StyledList>
        <StyledList>
          <h4>Approvals & timeframes</h4>
          <ul>
            {requiresApproval != null && <li>
              {requiresApproval
                  ? 'Require approval from a manager'
                  : 'Doesn’t require approval from a manager'
              }
            </li>
            }
            {requiresReplacement != null && <li>
              {requiresReplacement
                  ? 'An employee needs a replacement person'
                  : 'A replacement person isn’t required'
              }
            </li>
            }
            {isMinDaysPerRequest === true && minDaysPerRequest && <li>Employees can request a minimum of {minDaysPerRequest} business day(s)</li>}
            {isMinDaysPerRequest === false && <li>There is no minimum days that can be taken per request</li>}

            {isMaxDaysPerRequest === true && maxDaysPerRequest && <li>Employees can request a maximum of {maxDaysPerRequest} business day(s)</li>}
            {isMaxDaysPerRequest === false && <li>There is no maximum days that can be taken per request</li>}

            {isMinTimeframe === true && minTimeframe && <li>Request should be made minimum {minTimeframe} business days before start</li>}
            {isMinTimeframe === false && <li>There is no timeframe before start for this type of request</li>}

          </ul>
        </StyledList>
      </StyledRightSide>
    </StyledWrapper>
  );
};

export default StepApprovals;
