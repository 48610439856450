import { FC, useState } from "react";
import styleBTN from "../../UI/Button/Button.module.scss";
import style from "./MoreExport.module.scss";
import { IconButton } from "@atlaskit/button/new";
import Popup from "@atlaskit/popup";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";

interface MoreExportProps {
  exportPDF: () => void;
  exportCSV: () => void;
  isDisabled: boolean;
}

export const MoreExport: FC<MoreExportProps> = ({
  exportPDF,
  exportCSV,
  isDisabled,
}) => {
  return (
    <DropdownMenu<HTMLButtonElement>
      placement="bottom-start"
      trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
        <span className={styleBTN.button}>
          <IconButton
            isDisabled={isDisabled}
            {...providedProps}
            isSelected={isSelected}
            ref={triggerRef}
            icon={ChevronDownIcon}
            label="More"
          />
        </span>
      )}
    >
      <DropdownItemGroup>
        <DropdownItem onClick={exportPDF}>PDF File</DropdownItem>
        <DropdownItem onClick={exportCSV}>CSV File</DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  );
};
