import { FC, Fragment, useEffect } from "react";
import { useEmergencyContact } from "../../../../API/profile/emergencyContact/useEmergencyContact";
import { TabContainer } from "../TabContainer";
import {
  BlockInfo,
  BlockInfoItem,
  BlockInfoSkeletonItem,
} from "../../BlockInfo";
import { useProfile } from "../../context";
import { Name } from "../../Fields/emergencyContact/Name";
import { Relationship } from "../../Fields/emergencyContact/Relationship";
import { PhoneNumber } from "../../Fields/emergencyContact/PhoneNumber";
import styleParent from "../../Profile.module.scss";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import { Button } from "../../../UI/Button";

export const EmergencyContactTab: FC = () => {
  const { idOpenProfile, emergencyContact, setEmergencyContact, isAccessEdit } =
    useProfile();
  const {
    fetchCurrentEmergencyContact,
    isLoading,
    removeEmergencyContact,
    reFetchCurrentEmergencyContact,
    addEmergencyContact,
    addPhoneEmergencyContact,
    editPhoneEmergencyContact,
    removePhoneEmergencyContact,
    editEmergencyContact,
  } = useEmergencyContact(idOpenProfile);

  useEffect(() => {
    fetchCurrentEmergencyContact().then((response) =>
      setEmergencyContact(response)
    );
  }, []);

  const removeContactPerson = (id: string) => {
    removeEmergencyContact(id).then(() => {
      reFetchCurrentEmergencyContact().then((response) =>
        setEmergencyContact(response)
      );
    });
  };

  const addContactPerson = () => {
    addEmergencyContact().then(() => {
      reFetchCurrentEmergencyContact().then((response) =>
        setEmergencyContact(response)
      );
    });
  };

  const editContactPerson = (id: string, name: string, person: string) => {
    editEmergencyContact(id, name, person).then(() => {
      reFetchCurrentEmergencyContact().then((response) =>
        setEmergencyContact(response)
      );
    });
  };

  const addPhoneAPI = async (id: string, data: string) => {
    // addPhoneEmergencyContact(id, data).then(() => {
    //     reFetchCurrentEmergencyContact().then((response) => setEmergencyContact(response));
    // });
    try {
      const res = await addPhoneEmergencyContact(id, data).then(() => {
        reFetchCurrentEmergencyContact().then((response) =>
          setEmergencyContact(response)
        );
      });

      return res;
    } catch (error: any) {
      throw new Error(String(error.message));
    }
  };

  const editPhoneAPI = async (idContact: string, id: string, data: string) => {
    try {
      const res = await editPhoneEmergencyContact(idContact, id, data).then(
        () => {
          reFetchCurrentEmergencyContact().then((response) =>
            setEmergencyContact(response)
          );
        }
      );

      return res;
    } catch (error: any) {
      throw new Error(String(error.message));
    }
  };

  const removePhoneAPI = (idContact: string, id: string) => {
    removePhoneEmergencyContact(idContact, id).then(() => {
      reFetchCurrentEmergencyContact().then((response) =>
        setEmergencyContact(response)
      );
    });
  };

  return (
    <TabContainer>
      <BlockInfo title="Emergency Contact Information">
        {/* <tr>
                    <td colSpan={2} className={style.bannerCell}>
                        <div className={style.banner}>
                            <div className={style.bannerIcon}><InfoIcon label="Info" primaryColor="#1D7AFC" /></div>
                            <div className={style.bannerText}>Add info of someone who should be contacted if you have an emergency.</div>
                        </div>
                    </td>
                </tr> */}
        {isLoading ? (
          <Fragment>
            <BlockInfoSkeletonItem />
            <BlockInfoSkeletonItem />
            <BlockInfoSkeletonItem />
          </Fragment>
        ) : null}

        {emergencyContact.length
          ? emergencyContact.map(({ id, name, person, phones, employeeId }) => {
              return (
                <Fragment key={id}>
                  <Name
                    editContactPerson={editContactPerson}
                    id={id}
                    person={name}
                    name={person}
                    employeeId={employeeId}
                    removeContactPerson={removeContactPerson}
                  />
                  <Relationship
                    editContactPerson={editContactPerson}
                    id={id}
                    person={name}
                    name={person}
                    employeeId={employeeId}
                  />
                  <PhoneNumber
                    addPhoneAPI={addPhoneAPI}
                    editPhoneAPI={editPhoneAPI}
                    removePhoneAPI={removePhoneAPI}
                    id={id}
                    employeeId={employeeId}
                    phones={phones}
                  />
                </Fragment>
              );
            })
          : null}

        {isAccessEdit && !isLoading && emergencyContact.length < 3 && (
          <BlockInfoItem title="Add additional contact">
            <Button
              className={styleParent.btnAdd}
              iconBefore={
                <EditorAddIcon
                  primaryColor="#758195"
                  label="Add Phone number"
                />
              }
              appearance="subtle"
              spacing="compact"
              onClick={() => {
                addContactPerson();
              }}
            >
              Add contact person
            </Button>
          </BlockInfoItem>
        )}
      </BlockInfo>
    </TabContainer>
  );
};
