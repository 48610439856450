import React from 'react';
import BaseModal  from './BaseModal';
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi';
import { ORG_CHART } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';

type EditOrgUnit = {
    opened: boolean,
    orgUnits: OrgUnitFromApi[],
    onOk: () => void,
    onClose: () => any,
    orgUnit: OrgUnitFromApi
};

function EditOrgUnit(props: EditOrgUnit) {
    const parentOrgUnit = props.orgUnit.parentId && props.orgUnit.parentName ?
        { label: props.orgUnit.parentName, value: props.orgUnit.parentId } : null;

    const leaderOrgUnit = props.orgUnit.leaderId && props.orgUnit.leaderName ?
        { label: <span>{props.orgUnit.leaderName}</span>, value: props.orgUnit.leaderId } : null;

    function edit(
        name: string,
        parentId: string | null,
        leaderId: string | null,
        isCompany: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(ORG_CHART + '/' + props.orgUnit.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                parentId: parentId,
                leaderId: leaderId,
                isCompany: isCompany,
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Org Unit'
            orgUnits={ props.orgUnits }
            orgUnitValue={ { id: props.orgUnit.id, name: props.orgUnit.name, isCompany: props.orgUnit.isCompany } }
            parentOrgUnit={ parentOrgUnit }
            leaderOrgUnit={ leaderOrgUnit }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditOrgUnit;