import {FC, useCallback, useEffect, useRef, useState} from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button/new";
import { ProgressTracker, type Stages } from "@atlaskit/progress-tracker";
import {
  StyledHeaderWrap,
  StyledBodyWrap,
  StyledFooterWrap,
  StyledProgresTracker,
} from "./StylesCreatePolicy";
import StepAccruals from "./Steps/StepAccruals";
import StepApprovals from "./Steps/StepApprovals";
import StepDetails from "./Steps/StepDetails";
import ModalCancelCreation from "../CancelCreation/ModalCancelCreation";
import { TIME_OFF_POLICIES } from "../../../../API/constants";
import { VALIDATION_ERROR } from "../../../../utils/constants/errors";
import { ValidationError } from "../../../../shared/Common/Error/ValidationError";
import { AccrualRule } from "../../../../interfaces/timeOffPolicy/accrualRule.interface";
import { CreatePolicyModalProps } from "../../../../interfaces/timeOffPolicy/createPolicyModal.interface";
import { AccrualRulesError } from "../../../../interfaces/timeOffPolicy/Steps/accrualRulesProps.interface";
import {Option} from "../../../../interfaces/timeOffPolicy/Select/option.interface";
import { adminStatusAPI } from "API/common/status";
import {Status as StatusType, statusEmptyStateID} from "interfaces/common/status.interface";
type Status = "current" | "unvisited" | "visited" | "disabled";

const initialItems: Stages = [
  {
    id: "details",
    label: "Details",
    percentageComplete: 0,
    status: "current" as Status,
    href: "#",
  },
  {
    id: "accruals",
    label: "Accruals & carryovers",
    percentageComplete: 0,
    status: "unvisited" as Status,
    href: "#",
  },
  {
    id: "approvals",
    label: "Approvals & timeframes",
    percentageComplete: 0,
    status: "unvisited" as Status,
    href: "#",
  },
];

const CreatePolicy: FC<CreatePolicyModalProps> = ({ isOpen, onCloseSuccess, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [items, setItems] = useState<Stages>(initialItems);
  const [companyPaidRegardlessOfWorkday, setCompanyPaidRegardlessOfWorkday] =
    useState<boolean | null>(null);
  const [accrualSchedule, setAccrualSchedule] = useState<boolean | null>(null);
  const [policyDesignatedDayType, setPolicyDesignatedDayType] = useState<
    string | null
  >(null);
  const [requiresApproval, setRequiresApproval] = useState<boolean | null>(
    null
  );
  const [requiresReplacement, setRequiresReplacement] = useState<
    boolean | null
  >(null);
  const [minDaysPerRequest, setMinDaysPerRequest] = useState<number | null>(
    null
  );
  const [maxDaysPerRequest, setMaxDaysPerRequest] = useState<number | null>(
    null
  );
  const [minTimeframe, setMinTimeframe] = useState<number | null>(null);
  const [policyName, setPolicyName] = useState<string>("");
  const [policyDescription, setPolicyDescription] = useState<string>("");
  const [policyNameError, setPolicyNameError] = useState<string>("");
  const [policyIconError, setPolicyIconError] = useState<string>("");
  const [policyDescriptionError, setPolicyDescriptionError] =
    useState<string>("");
  const [selectedIcon, setSelectedIcon] = useState<string>("");
  const [balanceLimit, setBalanceLimit] = useState<number | null>(null);
  const [proratedAtHireStart, setProratedAtHireStart] = useState<
    boolean | null
  >(null);
  const [proratedAtHireEnd, setProratedAtHireEnd] = useState<boolean | null>(
    null
  );
  const [accrualRules, setAccrualRules] = useState<AccrualRule[]>([]);
  const [accrualRulesError, setAccrualRulesError] = useState<
    AccrualRulesError[]
  >([]);
  const [burnoutAccrualRule, setBurnoutAccrualRule] = useState<number | null>(
    null
  );
  const [activeBalance, setActiveBalance] = useState<boolean | null>(null);
  const [burnout, setBurnout] = useState<boolean | null>(null);
  const [isMinDaysPerRequest, setIsMinDaysPerRequest] = useState<
    boolean | null
  >(null);
  const [isMaxDaysPerRequest, setIsMaxDaysPerRequest] = useState<
    boolean | null
  >(null);
  const [isMinTimeframe, setIsMinTimeframe] = useState<boolean | null>(null);
  const [ruleError, setRuleError] = useState(false);
  const [burnoutError, setBurnoutError] = useState(false);
  const [balanceError, setBalanceError] = useState(false);
  const [minDaysPerRequestError, setMinDaysPerRequestError] = useState(false);
  const [maxDaysPerRequestError, setMaxDaysPerRequestError] = useState(false);
  const [minTimeframeError, setMinTimeframeError] = useState(false);
  const [companyPaidError, setCompanyPaidError] = useState<string | null>(null);
  const [dayTypeError, setDayTypeError] = useState<string | null>(null);
  const [accrualScheduleError, setAccrualScheduleError] = useState<
    string | null
  >(null);
  const [burnoutRadioError, setBurnoutRadioError] = useState<string | null>(
    null
  );
  const [balanceRadioError, setBalanceRadioError] = useState<string | null>(
    null
  );
  const [accrualStartError, setAccrualStartError] = useState<string | null>(
    null
  );
  const [accrualEndError, setAccrualEndError] = useState<string | null>(null);
  const [requiresApprovalError, setRequiresApprovalError] = useState<
    null | string
  >(null);
  const [requiresReplacementError, setRequiresReplacementError] = useState<
    null | string
  >(null);
  const [isMinDaysPerRequestError, setIsMinDaysPerRequestError] = useState<
    null | string
  >(null);
  const [isMaxDaysPerRequestError, setIsMaxDaysPerRequestError] = useState<
    null | string
  >(null);
  const [isMinTimeframeError, setIsMinTimeframeError] = useState<null | string>(
    null
  );
  const [statuses, setStatuses] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [proratedRadio, setProratedRadio] = useState<boolean>(false);

  let validateAccrualRules = () => true;

  const handleSubmit = async () => {
    const ruleOption = {
      accrualRule: burnoutAccrualRule,
      balanceLimit: balanceLimit ? balanceLimit : null,
      proratedAtHireStart: proratedAtHireStart,
      proratedAtHireEnd: proratedAtHireEnd,
    };

    const policyData = {
      name: policyName,
      typeIcon: selectedIcon,
      description: policyDescription,
      isVisible: true,
      accrualSchedule: accrualSchedule,
      companyPaidRegardlessOfWorkday: companyPaidRegardlessOfWorkday,
      policyDesignatedDayType: policyDesignatedDayType,
      neededApprovalFromManager: requiresApproval,
      isReplacementPersonMandatory: requiresReplacement,
      minimumDaysRequirement: minDaysPerRequest ? minDaysPerRequest : null,
      maximumDaysRequirement: maxDaysPerRequest ? maxDaysPerRequest : null,
      minimumLeadTimeRequirement: minTimeframe ? minTimeframe : null,
      accrualRules: accrualSchedule ? accrualRules : [],
      ruleOption: accrualSchedule ? ruleOption : [],
    };

    try {
      const result = await sendPolicyData(policyData);
      if (result) {
        setLoading(false);
        onCloseSuccess();
        clear();
      }
    } catch (error) {
      console.error("Failed to send policy data:", error);
    }
  };

  const clear = () => {
    setCurrentStep(0);
    setShowCancelConfirm(false);
    setItems(initialItems);
    setCompanyPaidRegardlessOfWorkday(null);
    setAccrualSchedule(null);
    setPolicyDesignatedDayType(null);
    setRequiresApproval(null);
    setRequiresReplacement(null);
    setMinDaysPerRequest(null);
    setMaxDaysPerRequest(null);
    setMinTimeframe(null);
    setPolicyName("");
    setPolicyDescription("");
    setPolicyNameError("");
    setPolicyIconError("");
    setPolicyDescriptionError("");
    setSelectedIcon("");
    setBalanceLimit(null);
    setProratedAtHireStart(null);
    setProratedAtHireEnd(null);
    setAccrualRules([]);
    setBurnoutAccrualRule(null);
    setActiveBalance(null);
    setBurnout(null);
    setIsMinDaysPerRequest(null);
    setIsMaxDaysPerRequest(null);
    setIsMinTimeframe(null);
    setRuleError(false);
    setBurnoutError(false);
    setBalanceError(false);
    setMinDaysPerRequestError(false);
    setMaxDaysPerRequestError(false);
    setMinTimeframeError(false);
    setCompanyPaidError(null);
    setDayTypeError(null);
    setAccrualScheduleError(null);
    setBurnoutRadioError(null);
    setBalanceRadioError(null);
    setAccrualStartError(null);
    setAccrualEndError(null);
    setRequiresApprovalError(null);
    setRequiresReplacementError(null);
    setIsMinDaysPerRequestError(null);
    setIsMaxDaysPerRequestError(null);
    setIsMinTimeframeError(null);
  };

  const nameValidate = async () => {
    try {
      await validatePolicy({
        name: policyName,
      });
      setPolicyNameError('');
    } catch (error) {
      setPolicyNameError('A policy with that name already exists');
    }
  };

  const handleNextStep = async () => {
    let policyData = {};
    let hasError = false;

    if (currentStep === 0) {
      policyData = {
        name: policyName,
        typeIcon: selectedIcon,
        description: policyDescription,
      };

      if (!policyName) {
        setPolicyNameError("Please enter the name of the policy");
        hasError = true;
      }

      if (!selectedIcon) {
        setPolicyIconError("Please select an icon.");
        hasError = true;
      }
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    if (currentStep === 1) {
      policyData = {
        accrualSchedule: accrualSchedule,
        accrualRules: accrualRules,
        description: policyDescription,
        ruleOption: {
          accrualRule: burnoutAccrualRule,
          balanceLimit: balanceLimit ? balanceLimit : null,
          proratedAtHireStart: proratedAtHireStart,
          proratedAtHireEnd: proratedAtHireEnd,
        },
      };

      if (burnout && burnoutAccrualRule === null) {
        setBurnoutError(true);
        hasError = true;
      }

      if (activeBalance && !balanceLimit) {
        setBalanceError(true);
        hasError = true;
      }

      if (accrualSchedule && accrualRules.length === 0) {
        setRuleError(true);
        hasError = true;
      }

      if (!validateAccrualRules()) {
        hasError = true;
      }

      if (!validateAccrualRadioSelections()) {
        hasError = true;
      }
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    if (currentStep === 2) {
      if (isMinDaysPerRequest && !minDaysPerRequest) {
        setMinDaysPerRequestError(true);
        hasError = true;
      }

      if (isMaxDaysPerRequest && !maxDaysPerRequest) {
        setMaxDaysPerRequestError(true);
        hasError = true;
      }

      if (isMinTimeframe && !minTimeframe) {
        setMinTimeframeError(true);
        hasError = true;
      }

      if (!validateApprovalRadioSelections()) {
        hasError = true;
      }
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      await validatePolicy(policyData);
    } catch (error) {
      setLoading(false);
      return;
    }

    if (currentStep < items.length - 1) {
      const newItems: Stages = items.map((item, index) => {
        if (index === currentStep) {
          return {
            ...item,
            percentageComplete: 100,
            status: "visited" as Status,
          };
        }
        if (index === currentStep + 1) {
          return {
            ...item,
            percentageComplete: 0,
            status: "current" as Status,
          };
        }
        return item;
      });
      setItems(newItems);
      setLoading(false);
      setCurrentStep(currentStep + 1);
    } else {
      await handleSubmit();
      setLoading(false);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      const newItems: Stages = items.map((item, index) => {
        if (index === currentStep - 1) {
          return {
            ...item,
            percentageComplete: 0,
            status: "current" as Status,
          };
        }
        if (index === currentStep) {
          return {
            ...item,
            percentageComplete: 0,
            status: "unvisited" as Status,
          };
        }
        if (index < currentStep - 1) {
          return {
            ...item,
            status: "visited" as Status,
            percentageComplete: 100,
          };
        }
        return item;
      });
      setItems(newItems);
      setCurrentStep(currentStep - 1);
    }
  };

  const validateAccrualRadioSelections = () => {
    let isValid = true;
    let errorText = "Please, choose the answer";
    if (companyPaidRegardlessOfWorkday === null) {
      setCompanyPaidError(errorText);
      isValid = false;
    } else {
      setCompanyPaidError(null);
    }

    if (accrualSchedule === true && companyPaidRegardlessOfWorkday === false && policyDesignatedDayType === null) {
      setDayTypeError(errorText);
      isValid = false;
    } else {
      setDayTypeError(null);
    }

    if (accrualSchedule === null) {
      setAccrualScheduleError(errorText);
      isValid = false;
    } else {
      setAccrualScheduleError(null);
    }

    if (accrualSchedule === true && burnout === null) {
      setBurnoutRadioError(errorText);
      isValid = false;
    } else {
      setBurnoutRadioError(null);
    }

    if (accrualSchedule === true && burnout === false && activeBalance === null) {
      setBalanceRadioError(errorText);
      isValid = false;
    } else {
      setBalanceRadioError(null);
    }

    if (accrualSchedule === true && proratedRadio && proratedAtHireStart === null) {
      setAccrualStartError(errorText);
      isValid = false;
    } else {
      setAccrualStartError(null);
    }

    if (accrualSchedule === true && proratedRadio && proratedAtHireEnd === null) {
      setAccrualEndError(errorText);
      isValid = false;
    } else {
      setAccrualEndError(null);
    }

    return isValid;
  };

  const fetchStatuses = useCallback(async () => {
    setLoading(true);
    try {
      const response = await adminStatusAPI.getStatuses();
      const statusesData: StatusType[] = await response.json();
      const options: Option[] = statusesData
          .filter((status) => statusEmptyStateID !== status.id)
          .map((status) => ({
            label: status.name,
            value: status.id,
          }));
      setStatuses(options);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  const validateApprovalRadioSelections = () => {
    let isValid = true;

    if (requiresApproval === null) {
      setRequiresApprovalError(
        "Please, select if manager approval is required"
      );
      isValid = false;
    } else {
      setRequiresApprovalError(null);
    }

    if (requiresReplacement === null) {
      setRequiresReplacementError(
        "Please, select if a replacement is required"
      );
      isValid = false;
    } else {
      setRequiresReplacementError(null);
    }

    if (isMinDaysPerRequest === null) {
      setIsMinDaysPerRequestError(
        "Please, select if there's a minimum days per request"
      );
      isValid = false;
    } else {
      setIsMinDaysPerRequestError(null);
    }

    if (isMaxDaysPerRequest === null) {
      setIsMaxDaysPerRequestError(
        "Please, select if there's a maximum days per request"
      );
      isValid = false;
    } else {
      setIsMaxDaysPerRequestError(null);
    }

    if (isMinTimeframe === null) {
      setIsMinTimeframeError("Please, select if there's a minimum timeframe");
      isValid = false;
    } else {
      setIsMinTimeframeError(null);
    }

    if (isMinDaysPerRequest === true && minDaysPerRequest === null) {
      setMinDaysPerRequestError(true);
      isValid = false;
    } else {
      setMinDaysPerRequestError(false);
    }

    if (isMaxDaysPerRequest === true && maxDaysPerRequest === null) {
      setMaxDaysPerRequestError(true);
      isValid = false;
    } else {
      setMaxDaysPerRequestError(false);
    }

    if (isMinTimeframe === true && minTimeframe === null) {
      setMinTimeframeError(true);
      isValid = false;
    } else {
      setMinTimeframeError(false);
    }

    return isValid;
  };

  const handleCancel = () => {
    setShowCancelConfirm(true);
  };

  const handleConfirmCancel = () => {
    setShowCancelConfirm(false);
    onClose();
    clear();
  };

  const handleCloseConfirmCancel = () => {
    setShowCancelConfirm(false);
  };

  const sendPolicyData = async (policyData: any) => {
    try {
      const response = await fetch(TIME_OFF_POLICIES, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(policyData),
      });

      return true;
    } catch (error) {
      console.error("Failed to create policy:", error);
      return false;
    }
  };

  const validatePolicy = async (policyData: any) => {
    try {
      const response = await fetch(TIME_OFF_POLICIES + "/validate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(policyData),
      });

      if (response.status === VALIDATION_ERROR) {
        const errors: ValidationError[] = await response.json();
        validate(errors);
        throw new Error("Validation errors occurred");
      }
    } catch (e) {
      throw new Error("Validation errors occurred");
    }
  };

  function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
      if (error.property === "timeOffPolicy.name") {
        setPolicyNameError(error.message);
      }
      if (error.property === "timeOffPolicy.typeIcon") {
        setPolicyIconError(error.message);
      }
      if (error.property === "timeOffPolicy.description") {
        setPolicyDescriptionError(error.message);
      }
    });
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleCancel} width="" shouldScrollInViewport>
          <StyledHeaderWrap>
            <ModalHeader>
              <ModalTitle>New policy</ModalTitle>
            </ModalHeader>
          </StyledHeaderWrap>

          <StyledBodyWrap>
            <ModalBody>
              <StyledProgresTracker>
                <ProgressTracker items={items} />
              </StyledProgresTracker>

              {currentStep === 0 && (
                <StepDetails
                  policyName={policyName}
                  setPolicyName={setPolicyName}
                  policyNameError={policyNameError}
                  setPolicyNameError={setPolicyNameError}
                  policyDescription={policyDescription}
                  setPolicyDescription={setPolicyDescription}
                  selectedIcon={selectedIcon}
                  setSelectedIcon={setSelectedIcon}
                  policyIconError={policyIconError}
                  setPolicyIconError={setPolicyIconError}
                  policyDescriptionError={policyDescriptionError}
                  setPolicyDescriptionError={setPolicyDescriptionError}
                  nameValidate={nameValidate}
                />
              )}

              {currentStep === 1 && (
                <StepAccruals
                  policyName={policyName}
                  policyDescription={policyDescription}
                  policyIcon={selectedIcon}
                  companyPaidRegardlessOfWorkday={
                    companyPaidRegardlessOfWorkday
                  }
                  accrualSchedule={accrualSchedule}
                  setCompanyPaidRegardlessOfWorkday={
                    setCompanyPaidRegardlessOfWorkday
                  }
                  setAccrualSchedule={setAccrualSchedule}
                  policyDesignatedDayType={policyDesignatedDayType}
                  setPolicyDesignatedDayType={setPolicyDesignatedDayType}
                  balanceLimit={balanceLimit}
                  setBalanceLimit={setBalanceLimit}
                  proratedAtHireStart={proratedAtHireStart}
                  setProratedAtHireStart={setProratedAtHireStart}
                  proratedAtHireEnd={proratedAtHireEnd}
                  setProratedAtHireEnd={setProratedAtHireEnd}
                  accrualRules={accrualRules}
                  setAccrualRules={setAccrualRules}
                  burnoutAccrualRule={burnoutAccrualRule}
                  setBurnoutAccrualRule={setBurnoutAccrualRule}
                  activeBalance={activeBalance}
                  setActiveBalance={setActiveBalance}
                  burnout={burnout}
                  setBurnout={setBurnout}
                  ruleError={ruleError}
                  setRuleError={setRuleError}
                  balanceError={balanceError}
                  setBalanceError={setBalanceError}
                  burnoutError={burnoutError}
                  setBurnoutError={setBurnoutError}
                  accrualRulesError={accrualRulesError}
                  setAccrualRulesError={setAccrualRulesError}
                  onValidate={(validateFn) => {
                    validateAccrualRules = validateFn;
                  }}
                  companyPaidError={companyPaidError}
                  setCompanyPaidError={setCompanyPaidError}
                  dayTypeError={dayTypeError}
                  setDayTypeError={setDayTypeError}
                  accrualScheduleError={accrualScheduleError}
                  setAccrualScheduleError={setAccrualScheduleError}
                  burnoutRadioError={burnoutRadioError}
                  setBurnoutRadioError={setBurnoutRadioError}
                  balanceRadioError={balanceRadioError}
                  setBalanceRadioError={setBalanceRadioError}
                  accrualStartError={accrualStartError}
                  setAccrualStartError={setAccrualStartError}
                  accrualEndError={accrualEndError}
                  setAccrualEndError={setAccrualEndError}
                  statuses={statuses}
                  proratedRadio={proratedRadio}
                  setProratedRadio={setProratedRadio}
                />
              )}
              {currentStep === 2 && (
                <StepApprovals
                  policyName={policyName}
                  policyDescription={policyDescription}
                  policyIcon={selectedIcon}
                  companyPaidRegardlessOfWorkday={
                    companyPaidRegardlessOfWorkday
                  }
                  accrualSchedule={accrualSchedule}
                  requiresApproval={requiresApproval}
                  setRequiresApproval={setRequiresApproval}
                  requiresReplacement={requiresReplacement}
                  setRequiresReplacement={setRequiresReplacement}
                  minDaysPerRequest={minDaysPerRequest}
                  setMinDaysPerRequest={setMinDaysPerRequest}
                  maxDaysPerRequest={maxDaysPerRequest}
                  setMaxDaysPerRequest={setMaxDaysPerRequest}
                  minTimeframe={minTimeframe}
                  setMinTimeframe={setMinTimeframe}
                  isMinDaysPerRequest={isMinDaysPerRequest}
                  setIsMinDaysPerRequest={setIsMinDaysPerRequest}
                  isMaxDaysPerRequest={isMaxDaysPerRequest}
                  setIsMaxDaysPerRequest={setIsMaxDaysPerRequest}
                  isMinTimeframe={isMinTimeframe}
                  setIsMinTimeframe={setIsMinTimeframe}
                  minDaysPerRequestError={minDaysPerRequestError}
                  setMinDaysPerRequestError={setMinDaysPerRequestError}
                  maxDaysPerRequestError={maxDaysPerRequestError}
                  setMaxDaysPerRequestError={setMaxDaysPerRequestError}
                  minTimeframeError={minTimeframeError}
                  setMinTimeframeError={setMinTimeframeError}
                  requiresApprovalError={requiresApprovalError}
                  setRequiresApprovalError={setRequiresApprovalError}
                  requiresReplacementError={requiresReplacementError}
                  setRequiresReplacementError={setRequiresReplacementError}
                  isMinDaysPerRequestError={isMinDaysPerRequestError}
                  setIsMinDaysPerRequestError={setIsMinDaysPerRequestError}
                  isMaxDaysPerRequestError={isMaxDaysPerRequestError}
                  setIsMaxDaysPerRequestError={setIsMaxDaysPerRequestError}
                  isMinTimeframeError={isMinTimeframeError}
                  setIsMinTimeframeError={setIsMinTimeframeError}
                  policyDesignatedDayType={policyDesignatedDayType}
                  burnout={burnout}
                  burnoutAccrualRule={burnoutAccrualRule}
                  activeBalance={activeBalance}
                  balanceLimit={balanceLimit}
                  proratedAtHireStart={proratedAtHireStart}
                  proratedAtHireEnd={proratedAtHireEnd}
                  accrualRules={accrualRules}
                  statuses={statuses}
                />
              )}
            </ModalBody>
          </StyledBodyWrap>

          <StyledFooterWrap>
            <ModalFooter>
              <Button appearance="subtle" onClick={handleCancel}>
                Cancel
              </Button>
              {currentStep > 0 && (
                <Button appearance="default" onClick={handlePreviousStep}>
                  Previous step
                </Button>
              )}
              <Button isLoading={loading} appearance="primary" onClick={() => {
                setLoading(true);
                handleNextStep();
              }}>
                {currentStep === items.length - 1 ? "Create" : "Next step"}
              </Button>
            </ModalFooter>
          </StyledFooterWrap>
        </Modal>
      )}
      {showCancelConfirm && (
        <ModalCancelCreation
          isOpen={showCancelConfirm}
          onClose={handleCloseConfirmCancel}
          onConfirm={handleConfirmCancel}
        />
      )}
    </ModalTransition>
  );
};

export default CreatePolicy;
