import {useQuery} from "../../useQuery";
import {
    addEmergencyContactAPI,
    getCurrentEmergencyContact,
    removeEmergencyContactAPI,
    addPhoneEmergencyContactAPI,
    editPhoneEmergencyContactAPI,
    removePhoneEmergencyContactAPI,
    editEmergencyContactAPI
} from "./getCurrentEmergencyContact";
import {useState} from "react";
import type {EmergencyContactProps} from "./emergencyContact.types";
import {useEvent} from "../../../customHooks/useEvent";

export const useEmergencyContact = (idProfile: string) => {
    const [fetchCurrentEmergencyContactAPI, isLoading, isSuccess, isError] = useQuery(getCurrentEmergencyContact);
    const [emergencyContact, setEmergencyContact] = useState<EmergencyContactProps>();

    const fetchCurrentEmergencyContact = useEvent(() => {
        return fetchCurrentEmergencyContactAPI(idProfile).then((response) => {
            setEmergencyContact(response);
            return response;
        });
    });

    const reFetchCurrentEmergencyContact = useEvent(() => {
        return getCurrentEmergencyContact(idProfile).then((response) => {
            setEmergencyContact(response);
            return response;
        });
    });

    const addEmergencyContact = useEvent(() => {
        return addEmergencyContactAPI(idProfile);
    });

    const editEmergencyContact = useEvent((id: string, name: string, person: string) => {
        return editEmergencyContactAPI(idProfile, id, name, person);
    });

    const removeEmergencyContact = useEvent((id: string) => {
        return removeEmergencyContactAPI(idProfile, id);
    });

    const addPhoneEmergencyContact = useEvent( async (id: string, data: string) => {
        try {
            const res = await addPhoneEmergencyContactAPI(idProfile, id, data);
            //@ts-ignore
            if(res && res.error) {
                //@ts-ignore
                throw new Error(String(res.error));
            }
            return res;
        } catch (error: any) {
            throw new Error(String(error.message));
        }
    });

    const editPhoneEmergencyContact = useEvent(async (idContact: string, id: string, data: string) => {
        try {
            const res = await editPhoneEmergencyContactAPI(idProfile, idContact, id, data);
            //@ts-ignore
            if(res && res.error) {
                //@ts-ignore
                throw new Error(String(res.error));
            }
            return res;
        } catch (error: any) {
            throw new Error(String(error.message));
        }
    });

    const removePhoneEmergencyContact = useEvent((idContact: string, id: string) => {
        return removePhoneEmergencyContactAPI(idProfile, idContact, id);
    });

    return {
        fetchCurrentEmergencyContact: fetchCurrentEmergencyContact,
        emergencyContact: emergencyContact,
        reFetchCurrentEmergencyContact: reFetchCurrentEmergencyContact,
        addEmergencyContact: addEmergencyContact,
        removeEmergencyContact: removeEmergencyContact,
        addPhoneEmergencyContact: addPhoneEmergencyContact,
        editPhoneEmergencyContact: editPhoneEmergencyContact,
        removePhoneEmergencyContact: removePhoneEmergencyContact,
        editEmergencyContact: editEmergencyContact,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError
    }
};