import {FC, useState} from "react";
import {useProfile} from "../../../context";
import {BlockInfoItem} from "../../../BlockInfo";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Input} from "../../../../UI/Input";
import {InputText} from "../../../ViewingModes/InputText";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const Nickname: FC = () => {
    const {isAccessEditRole, profile, idOpenProfile, setNickname, showErrorNotify} = useProfile();
    const [value, setValue] = useState<string>(profile.nickname || '');

    const {setField} = useSetField(idOpenProfile, true);

    const [mainErrorStateServ, setMainErrorStateServ] = useState<{
        clearError?: () => void | undefined;
        error?: string | undefined;
        valueBeforeError: string;
    }>({
        clearError: undefined,
        error: undefined,
        valueBeforeError: ''
    });

    return(
        <BlockInfoItem title="Nickname">
            <div
                className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    {...(mainErrorStateServ.error && {isEditing: true})}
                    defaultValue={mainErrorStateServ.valueBeforeError ? mainErrorStateServ.valueBeforeError : value}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Input
                            {...fieldProps}
                            value={value}
                            onChange={(event) => {
                                const isValid = /^([-'a-zA-Z_\s]+)?$/.test(event.currentTarget.value);
                                if(!isValid) {
                                    return;
                                }
                                onChange(event);

                                const value = event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1);
                                setValue(value);
                            }}
                            autoFocus={!mainErrorStateServ.error}
                            isInvalid={!!errorMessage || !!mainErrorStateServ.error}
                            autoComplete="off"
                            placeholder=""
                            className={style.profileInput}
                        />
                    )}
                    readView={() => (<InputText value={value}/>)}
                    onConfirm={(value) => {
                        mainErrorStateServ.clearError && mainErrorStateServ.clearError();
                        setMainErrorStateServ({
                            error: undefined,
                            valueBeforeError: '',
                            clearError: undefined
                        });
                        profile.nickname !== value && setField(['nickname', value || 'null'].join('/')).then(() => {
                            setNickname(value);
                            setValue(value);
                        }).catch((error) => {
                            setMainErrorStateServ({
                                clearError: showErrorNotify(String(error.message)),
                                error: String(error.message),
                                valueBeforeError: value,
                            });
                        });
                    }}
                />
            </div>
        </BlockInfoItem>
    );
};