import React, { useState, useEffect, useRef, FC } from "react";
import {
  ADMIN_RANDOM_PHRASES,
  ADMIN_LINKS,
  ADMIN_GENDER_PRONOUNS,
  ADMIN_LEVELS,
  ADMIN_JOB_TITLES,
  ADMIN_LOCATIONS,
  ADMIN_ORG_CHART,
  ADMIN_PUSH_NOTIFICATIONS,
  ADMIN_EMPLOYEES,
  ADMIN_HOLIDAYS,
} from "../../../../utils/constants/routes";
import { Link, useLocation } from "react-router-dom";
import {
  ROLE_ADMIN,
  ROLE_CONTENT_MANAGER,
  ROLE_HR,
  ROLE_OPERATIONAL_MANAGER,
} from "../../../../shared/Security/constants/AccessLevels";
import {
  useEmployee,
  assertEmployee,
} from "../../../../contexts/EmployeeContext";
import { ADMIN_TIME_OFF_POLICIES, STATUSES } from "../../../../Router/routes";
import { StyledSubMenu } from "./SubMenuStyles";

export const SubMenu: FC = () => {
  const { employee } = useEmployee();
  assertEmployee(employee);
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState("portalSettings");
  const [activePortalSetting, setActivePortalSetting] =
    useState<boolean>(false);
  const [activeCorporateSetting, setActiveCorporateSetting] =
    useState<boolean>(false);
  const [activeHrSetting, setActiveHrSetting] = useState<boolean>(false);

  function toggleActivePortalSettings(open: boolean) {
    if (activePortalSetting === open) {
      setActivePortalSetting(false);
    } else {
      setActivePortalSetting(true);
    }
  }

  function toggleActiveCorporateSetting(open: boolean) {
    if (activeCorporateSetting === open) {
      setActiveCorporateSetting(false);
    } else {
      setActiveCorporateSetting(true);
    }
  }

  function toggleActiveHrSetting(open: boolean) {
    if (activeHrSetting === open) {
      setActiveHrSetting(false);
    } else {
      setActiveHrSetting(true);
    }
  }

  useEffect(() => {
    const activePortalSetting = window.localStorage.getItem(
      "activePortalSetting"
    );
    const activeCorporateSetting = window.localStorage.getItem(
      "activeCorporateSetting"
    );
    const activeHrSetting = window.localStorage.getItem("activeHrSetting");

    toggleActivePortalSettings(true);
    if (activePortalSetting != null) {
      toggleActivePortalSettings(activePortalSetting === "true");
    }
    if (activeCorporateSetting != null) {
      toggleActiveCorporateSetting(activeCorporateSetting === "true");
    }
    if (activeHrSetting != null) {
      toggleActiveHrSetting(activeHrSetting === "true");
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "activePortalSetting",
      JSON.stringify(activePortalSetting)
    );
    window.localStorage.setItem(
      "activeCorporateSetting",
      JSON.stringify(activeCorporateSetting)
    );
    window.localStorage.setItem(
      "activeHrSetting",
      JSON.stringify(activeHrSetting)
    );
    const storedScrollTop = localStorage.getItem("scroll-top");
    if (storedScrollTop !== null) {
      if (scroll.current) {
        scroll.current.scrollTop = parseInt(storedScrollTop);
      }
    }
  }, [activePortalSetting, activeCorporateSetting, activeHrSetting]);

  const [scrollTop, setScrollTop] = useState(0);
  const scroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scroll.current) {
        setScrollTop(scroll.current.scrollTop);
      }
    };

    const div = scroll.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scroll]);

  useEffect(() => {
    if (scrollTop != 0) {
      localStorage.setItem("scroll-top", scrollTop.toString());
    }
  }, [scrollTop]);

  type RolePermissions = Record<string, string[]>;

  // Access levels
  const rolePermissions: RolePermissions = {
    [ROLE_ADMIN]: [
      "view_hr",
      "view_locations",
      "view_org_chart",
      "view_random_phrases",
      "view_links",
      "view_employees",
    ],
    [ROLE_HR]: [
      "view_hr",
      "view_locations",
      "view_org_chart",
      "view_random_phrases",
      "view_links",
      "view_employees",
    ],
    [ROLE_OPERATIONAL_MANAGER]: ["view_locations", "view_org_chart"],
    [ROLE_CONTENT_MANAGER]: ["view_random_phrases", "view_links"],
  };

  function hasPermission(userRoles: string[], permission: string) {
    return userRoles.some(
      (role) =>
        rolePermissions[role] && rolePermissions[role].includes(permission)
    );
  }

  const handleToggleMenu = (menuKey: any) => {
    setSelectedMenuItem((prevSelectedMenuItem) =>
      prevSelectedMenuItem === menuKey ? null : menuKey
    );
  };

  const isMenuItemActive = (menuKey: string) =>
    location.pathname === menuKey ? "inner-sub-menu-active" : "";

  return (
    <StyledSubMenu ref={scroll}>
      <ul>
        {hasPermission(employee.roles, "view_employees") && (
          <li className={isMenuItemActive(ADMIN_EMPLOYEES)}>
            <Link to={ADMIN_EMPLOYEES}>Employee list</Link>
          </li>
        )}
        {hasPermission(employee.roles, "view_hr") && (
          <li>
            <div onClick={() => handleToggleMenu("hrSettings")}>
              <span>Employee profile settings</span>
              <i
                className={`material-icons ${
                  selectedMenuItem === "hrSettings"
                    ? "material-icons-arrow-right"
                    : ""
                }`}
              >
                arrow_drop_down
              </i>
            </div>
            <ul className={selectedMenuItem === "hrSettings" ? "" : "isHidden"}>
              <li className={isMenuItemActive(STATUSES)}>
                <Link target={"_blank"} to={STATUSES}>
                  Work statuses
                </Link>
              </li>
              <li className={isMenuItemActive(ADMIN_JOB_TITLES)}>
                <Link to={ADMIN_JOB_TITLES}>Job titles</Link>
              </li>
              <li className={isMenuItemActive(ADMIN_LEVELS)}>
                <Link to={ADMIN_LEVELS}>Job levels</Link>
              </li>
              <li className={isMenuItemActive(ADMIN_GENDER_PRONOUNS)}>
                <Link to={ADMIN_GENDER_PRONOUNS}>Gender pronouns</Link>
              </li>
            </ul>
          </li>
        )}
        {hasPermission(employee.roles, "view_org_chart") && (
          <li className={isMenuItemActive(ADMIN_ORG_CHART)}>
            <Link to={ADMIN_ORG_CHART}>Org chart settings</Link>
          </li>
        )}
        <li className="sub-menu-active">
          <div onClick={() => handleToggleMenu("corporateSettings")}>
            <span>Work and time management</span>
            <i
              className={`material-icons ${
                selectedMenuItem === "corporateSettings"
                  ? "material-icons-arrow-right"
                  : ""
              }`}
            >
              arrow_drop_down
            </i>
          </div>
          <ul
            className={
              selectedMenuItem === "corporateSettings" ? "" : "isHidden"
            }
          >
            {hasPermission(employee.roles, "view_locations") && (
              <li className={isMenuItemActive(ADMIN_LOCATIONS)}>
                <Link to={ADMIN_LOCATIONS}>Work locations</Link>
              </li>
            )}
            <li className={isMenuItemActive(ADMIN_TIME_OFF_POLICIES)}>
              <Link to={ADMIN_TIME_OFF_POLICIES}>
                Time off & special status policies
              </Link>
            </li>
            <li className={isMenuItemActive(ADMIN_HOLIDAYS)}>
              <Link to={ADMIN_HOLIDAYS + "?year=" + new Date().getFullYear()}>
                Holidays & events settings
              </Link>
            </li>
          </ul>
        </li>
        <li className="sub-menu-active">
          <div onClick={() => handleToggleMenu("portalSettings")}>
            <span>Main page settings</span>
            <i
              className={`material-icons ${
                selectedMenuItem === "portalSettings"
                  ? "material-icons-arrow-right"
                  : ""
              }`}
            >
              arrow_drop_down
            </i>
          </div>
          <ul
            className={selectedMenuItem === "portalSettings" ? "" : "isHidden"}
          >
            <li className={isMenuItemActive(ADMIN_PUSH_NOTIFICATIONS)}>
              <Link to={ADMIN_PUSH_NOTIFICATIONS}>Announcement</Link>
            </li>
            {hasPermission(employee.roles, "view_links") && (
              <li className={isMenuItemActive(ADMIN_LINKS)}>
                <Link to={ADMIN_LINKS}>Useful links</Link>
              </li>
            )}
            {hasPermission(employee.roles, "view_random_phrases") && (
              <li className={isMenuItemActive(ADMIN_RANDOM_PHRASES)}>
                <Link to={ADMIN_RANDOM_PHRASES}>Random Phrases</Link>
              </li>
            )}
          </ul>
        </li>
      </ul>
    </StyledSubMenu>
  );
};
