export const sendLetterLink = async (email: string) => {
    try {
        const response = await fetch('/api/employee/reset-password/send-link', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                email: email,
            })
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch (error) {
        console.error(error)
        throw new Error();
    }
};