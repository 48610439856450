import {useState} from 'react';
import {useEvent} from '../../customHooks/useEvent';
import {signOut as fetchSignOut} from './signOut';
import {useEmployee} from "../../contexts/EmployeeContext";

export const useSignOut = () => {
    const {signOut: signOutContext} = useEmployee();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);

    const resetStatuses = useEvent(() => {
        setLoading(false);
        setSuccess(false);
        setError(false);
    });

    const signOut = useEvent(() => {
        setLoading(true);
        return fetchSignOut()
            .then((signOutUrl) => {
                window.location.href = signOutUrl;
                setSuccess(true);
                signOutContext();
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return {
        isLoading,
        isSuccess,
        isError,
        signOut,
        resetStatuses
    };
};