import {FC, useEffect, useState} from "react";
import {useProfile} from "../../../context";
import {useWorkLocation, WorkLocationProps} from "../../../../../API/profile/fields/jobInfo/workLocation";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {type OnChangeValue, Select} from "../../../../UI/Select";
import {InputText} from "../../../ViewingModes/InputText";
import {BlockInfoItem} from "../../../BlockInfo";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const WorkLocation: FC = () => {
    const {isAccessEditRole, jobInfo, idOpenProfile} = useProfile();
    const {fetchWorkLocations, workLocations, isLoading} = useWorkLocation();

    const [picked, setPicked] = useState<WorkLocationProps>();

    const {setField} = useSetField(idOpenProfile);

    useEffect(() => {
        fetchWorkLocations().then((response) => {
            if(jobInfo?.currentJobInfoValue.workLocationId) {
                const workLocation = response.find(
                    workLocation => workLocation.value === jobInfo.currentJobInfoValue.workLocationId
                );

                setPicked(workLocation);
            }
        });
    }, []);

    return(
        <BlockInfoItem title="Work location">
            <div className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    hideActionButtons
                    defaultValue={picked}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Select
                            {...fieldProps}
                            appearance="subtle"
                            spacing="compact"
                            inputId="single-select-example-clearable1"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(newValue: OnChangeValue<WorkLocationProps, false>) => {
                                onChange(newValue as any);
                                setField(['current-job-info', 'work_location_id', newValue?.id || 'null'].join('/'));
                            }}
                            className={style.profileSelect}
                            autoFocus
                            openMenuOnFocus
                            isLoading={isLoading}
                            options={workLocations}
                            placeholder="Choose a Work Location"
                        />
                    )}
                    readView={() => (<InputText value={picked && picked.label} />)}
                    onConfirm={(value) => setPicked(value)}
                />
            </div>
        </BlockInfoItem>
    );
};