import { Label, Field } from "@atlaskit/form";
import "./SetStatusModal.scss";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Select, { OptionType } from "@atlaskit/select";
import { useEffect, useRef, useState } from "react";
import { DatePicker as AtlaskitDatePicker } from "@atlaskit/datetime-picker";
import SectionMessage from "@atlaskit/section-message";
import { GroupedOptionType } from "utils/helpers/EmployeeProfile/formatAllowedStatusesTransitions";
import Button from "@atlaskit/button/new";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { PropsValue } from "react-select";
import { useSetStatusModal } from "./useSetStatusModal";
import { parse, format, addDays } from "date-fns";
import {
  ProfileModalChangeDateWithPolicyAccrual
} from "../../../../../../../interfaces/timeOffPolicy/profileModalChangeDateWithPolicyAccrual.interface";

export type SetStatusModalProps = {
  options: GroupedOptionType[];
  dateFrom: string;
  isOpen: boolean;
  onStatusUpdate: () => void;
  closeModal: () => void;
  employeeId: string;
  defaultDate?: string;
  isSelectDisabled?: boolean;
  defaultStatus?: string;
  currentStatusIsActive?: boolean;
  currentStatusArchived?: boolean;
  currentStatusId?: string;
  currentOriginalStatusId?: string;
  mode: "edit" | "add";
  lastStatusId?: string;
  lastOriginalStatusId?: string;
  setPolicies?: (policies: ProfileModalChangeDateWithPolicyAccrual[]) => void;
};

export const SetStatusModal = (props: SetStatusModalProps) => {
  const {
    showSubmitMessage,
    submitMessages,
    showToActiveWarning,
    showToInactiveMessage,
    selectedStatus,
    selectedDate,
    setSelectedDate,
    handleSubmit,
    closeModal,
    handleChange,
    handleStatusChange,
    submitResult,
    setPolicies,
  } = useSetStatusModal(props);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (props.isOpen && props.mode === "edit") {
      setSelectedDate(props.defaultDate);
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen) {
      if (props.mode === "add" && (!selectedStatus || !selectedDate)) {
        setIsSubmitDisabled(true);
        return;
      }
      if (props.mode === "edit" && !selectedDate) {
        setIsSubmitDisabled(true);
        return;
      }
      setIsSubmitDisabled(false);
    }
  }, [selectedStatus, selectedDate, props.isOpen, props.mode]);

  const parseInputValue = (date: string, dateFormat: string): Date => {
    const parsedDate = parse(date, "dd.MM.yyyy", new Date());
    return parsedDate;
  };

  return (
    <>
      {showSubmitMessage && submitResult && (
        <div className='section-message-modal'>
          <SectionMessage
            appearance={submitMessages[props.mode][submitResult].appearance}
            title={submitMessages[props.mode][submitResult].title}
          >
            <p>{submitMessages[props.mode][submitResult].text}</p>
          </SectionMessage>
        </div>
      )}

      <ModalTransition>
        {props.isOpen && (
          <Modal
            autoFocus={false}
            onClose={props.closeModal}
            width={368}
            shouldScrollInViewport
          >
            <form onSubmit={handleSubmit}>
              <div className='header-modal'>
                <ModalHeader>
                  <div className='modal-title-info'>
                    <ModalTitle
                      appearance={showToActiveWarning ? "warning" : undefined}
                    >
                      {props.mode === "edit" ? "Edit status" : "Set status"}
                    </ModalTitle>
                    {showToActiveWarning && (
                      <p className='modal-title-notification'>
                        When switching from inactive to active status, all
                        previous job information will be moved to the archive.
                      </p>
                    )}
                  </div>
                </ModalHeader>
              </div>
              <div className='body-modal'>
                <ModalBody>
                  <Label htmlFor='single-select-example'>Status</Label>
                  <Select
                    inputId='single-select-example'
                    className='set-status-select'
                    classNamePrefix='react-select'
                    options={props.options}
                    placeholder='Choose a status'
                    onChange={handleStatusChange}
                    defaultValue={
                      props.defaultStatus
                        ? ({
                            label: props.defaultStatus,
                            value: props.defaultStatus,
                          } as PropsValue<OptionType>)
                        : undefined
                    }
                    isDisabled={isDisabled}
                  />
                  {showToInactiveMessage && (
                    <div className='section-message-info'>
                      <EditorPanelIcon size='small' label='inactive status' />
                      <p>
                        It is inactive status, the employee's profile will be
                        hidden, but the profile remains available in the admin
                        panel on inactive employees section and search.
                      </p>
                    </div>
                  )}{" "}
                  <div className='date-input-container'>
                    <Field label='Start Date' name='date-picker'>
                      {() => (
                        <AtlaskitDatePicker
                          label='Date'
                          placeholder='Date'
                          parseInputValue={parseInputValue}
                          defaultValue={
                            props.mode === "add" ? undefined : props.defaultDate
                          }
                          defaultIsOpen={false}
                          minDate={
                            props.dateFrom && props.mode === "add"
                              ? format(
                                  addDays(
                                    parse(
                                      props.dateFrom,
                                      "yyyy-mm-dd",
                                      new Date()
                                    ),
                                    1
                                  ),
                                  "yyyy-mm-dd"
                                )
                              : props.dateFrom
                          }
                          onChange={handleChange}
                          dateFormat='DD.MM.YYYY'
                          autoFocus={false}
                          weekStartDay={1}
                        />
                      )}
                    </Field>
                  </div>
                </ModalBody>
              </div>
              <div className='footer-modal'>
                <ModalFooter>
                  <Button appearance='subtle' onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    appearance='primary'
                    type='submit'
                    isDisabled={isSubmitDisabled}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </div>
            </form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
