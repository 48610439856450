import React, { useState } from 'react';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './TextEditor.scss';

type TextEditor = {
    label: string,
    required: boolean,
    content: string,
    error: string | null,
    onChange: (content: string) => void
}

function TextEditor(props: TextEditor) {

    const customConfig = {
        // custom configuration options go here
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList']
    };
    const [clicked, setClicked] = useState<boolean>(false);

    return (
        <div className={'input'}>
            <label>{ props.label }{ props.required ? <span>*</span> : '' }</label>
            <input className={'editor-field-input'} type="text"/>
            <div className={'input-container'}>
                <CKEditor
                    editor={ClassicEditor}
                    config={customConfig}
                    data={props.content}
                    onChange={(event, editor) => {
                        const newContent = editor.getData();
                        props.onChange(newContent);
                        setClicked(true);
                    }}
                />
                {(() => {
                    if (props.error) {
                        return (
                            <div className='error'>
                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z" fill="#E05D44"/></svg>
                                <span>{ props.error }</span>
                            </div>
                        );
                    }
                })()}
            </div>
        </div>
    );
}

export default TextEditor;