import {useState} from "react";
import {CityOptionsProps} from "./city.types";
import {useQuery} from "../../../../useQuery";
import {fetchCityAPI} from "./city";
import {useEvent} from "../../../../../customHooks/useEvent";

export const useCity = (country: string) => {
    const [city, setCity] = useState<Array<CityOptionsProps>>([]);
    const [getAllCity, isLoading, isSuccess, isError] = useQuery(fetchCityAPI);

    const fetchCity = useEvent(async () => {
        return getAllCity(country).then((response) => {
            setCity(response);
            return response;
        });
    });

    return {
        city: city,
        fetchCity: fetchCity,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
    };
};