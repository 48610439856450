import {FC} from "react";
import TooltipADS, {TooltipProps, TooltipPrimitive, TooltipPrimitiveProps } from "@atlaskit/tooltip";
import styled from '@emotion/styled';

const InlineDialog = styled(TooltipPrimitive)`
  background-color: #44546f;
  padding: 2px 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  border-radius: 3px;
`;

export const Tooltip: FC<TooltipProps> = ({...rest}) => {
  return(
    <TooltipADS component={InlineDialog as React.ComponentType<TooltipPrimitiveProps>} {...rest} />
  );
}
