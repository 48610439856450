import { FC, useState, useRef, useEffect, useCallback } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Toggle from "@atlaskit/toggle";
import Select, { DropdownIndicatorProps, OptionType } from "@atlaskit/select";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import { IconButton } from "@atlaskit/button/new";
import {
  StatusWithTransitions,
  statusEmptyStateID,
} from "interfaces/common/status.interface";
import useStatuses, { switchedToStatuses } from "./useStatuses";
import useFetchStatusesWithTransitions, {
  findRemoveTransitionID,
  findToTransition,
} from "hooks/adminStatuses/useFetchStatusesWithTransitions";
import useUpdateStatus from "hooks/adminStatuses/useUpdateStatus";
import useAddStatus from "hooks/adminStatuses/useAddStatus";
import useDeleteStatus from "hooks/adminStatuses/useDeleteStatus";
import SectionMessage from "@atlaskit/section-message";
import useDeleteStatusTransition from "hooks/adminStatuses/useDeleteStatusTransition";
import useShowErrorMessages from "hooks/adminStatuses/useErrorShowMessage";
import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import { nanoid } from "nanoid";
import Tooltip from "@atlaskit/tooltip";
import StatusesPopup from "./StatusesPopup";
import {
  StyledDynamicTable,
  StyledStatusRow,
  StyledSelectRow,
  StyledToogleWrapper,
  StyledIconRemove,
  StyledArrowIcon,
  StyledStatusesPage,
  StyledAddStatusBtn,
  StyledEditableStatus,
} from "./StatusesStyles";

interface EditingStatus {
  id: string;
  name: string;
  isActive: boolean;
}

export const Statuses: FC = () => {
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const { addStatusTransition } = useStatuses(setForceUpdate);
  const hasMounted = useRef(false);
  const [sortOrder, setSortOrder] = useState<{ key: string; order: string }>({
    key: "",
    order: "ASC",
  });
  const [editingStatusId, setEditingStatusId] = useState<string | null>(null);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [editingStatus, setEditingStatus] = useState<EditingStatus | null>(
    null
  );

  const { addStatus, error: addError } = useAddStatus();
  const handleAddStatus = useCallback(
    (name: string) => {
      addStatus({
        name,
        onSuccess: () => {
          setForceUpdate((prev) => prev + 1);
        },
      });
    },
    [addStatus]
  );

  const {
    statusWithTransitions,
    setStatusWithTransitions,
    loading: loadingStatuses,
    error: errorStatuses,
    fetchStatuses,
    addStatusID,
  } = useFetchStatusesWithTransitions({
    withExtraLine: true,
    onExtralineConfirmed: handleAddStatus,
  });
  const { updateStatus, error: updateError } = useUpdateStatus();
  const { deleteStatus, error: deleteError } = useDeleteStatus();
  const { deleteStatusTransition, error: deleteStatusTransitionError } =
    useDeleteStatusTransition();
  const { showErrorMessages } = useShowErrorMessages(deleteError);

  useEffect(() => {
    if (hasMounted.current) {
      fetchStatuses();
    } else {
      hasMounted.current = true;
    }
  }, [forceUpdate, fetchStatuses]);

  useEffect(() => {
    const handleClick = (event: Event) => {
      let target = event.target as HTMLElement;
      while (target && target.tagName !== "BUTTON") {
        target = target.parentElement as HTMLElement;
      }
      if (target && (target as HTMLButtonElement).type === "submit") {
        if (editingStatusId) {
          setTimeout(() => {
            setIsSubmitClicked(true);
          }, 0);
        }
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [editingStatusId]);

  useEffect(() => {
    if (isSubmitClicked && editingStatus) {
      const timeoutId = setTimeout(() => {
        handleUpdateStatus(
          editingStatus.id,
          editingStatus.name,
          editingStatus.isActive
        );
        setEditingStatus(null);
      }, 0);
      return () => clearTimeout(timeoutId);
    }
  }, [isSubmitClicked]);

  const handleUpdateStatus = (id: string, name: string, isActive: boolean) => {
    updateStatus({
      id,
      name,
      isActive,
      onSuccess: () => setForceUpdate((prev) => prev + 1),
    });
  };
  const handleDeleteStatus = (id: string) => {
    deleteStatus({
      id,
      onSuccess: () => setForceUpdate((prev) => prev + 1),
    });
  };
  const handleDeleteStatusTransition = (id: string) => {
    deleteStatusTransition({
      id,
      onSuccess: () => setForceUpdate((prev) => prev + 1),
    });
  };

  const handleSort = (data: { key: string; getSortOrder: string }) => {
    let key: string;
    if (data.key === "number") {
      key = "name";
    } else {
      key = "isActive";
    }

    setSortOrder({ key, order: sortOrder.order === "ASC" ? "DESC" : "ASC" });
    const firstRow = statusWithTransitions.find(
      (row) => row.id === statusEmptyStateID
    );
    const lastRow = statusWithTransitions.find((row) => row.id === addStatusID);

    const rowsToSort = statusWithTransitions.filter(
      (row) => row.id !== statusEmptyStateID && row.id !== addStatusID
    );
    const sortedRows = rowsToSort.sort((a, b) => {
      const aValue = a[key as keyof typeof a];
      const bValue = b[key as keyof typeof b];

      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;
      if (typeof aValue === "string" && typeof bValue === "string") {
        if (sortOrder.order === "ASC") {
          return aValue.localeCompare(bValue);
        }
        return bValue.localeCompare(aValue);
      }
      if (typeof aValue === "boolean" && typeof bValue === "boolean") {
        return sortOrder.order === "ASC"
          ? Number(aValue) - Number(bValue)
          : Number(bValue) - Number(aValue);
      }

      return 0;
    });

    const newRows = [firstRow, ...sortedRows, lastRow];
    setStatusWithTransitions(newRows as StatusWithTransitions[]);
  };

  const head = () => {
    return {
      cells: [
        {
          key: "number",
          content: "Status",
          isSortable: true,
          width: 35,
        },
        {
          key: "can_be_switched_to",
          content: "Can be switched to",
          width: 46,
        },
        {
          key: "employee",
          content: "Employee status",
          isSortable: true,
          width: 7,
        },
        {
          key: "info",
          content: <StatusesPopup />,
          isSortable: false,
          width: 1,
        },

        {
          key: "action",
          content: "Action",
          width: 7,
        },
      ],
    };
  };

  const rows = statusWithTransitions.map(
    ({ id, name, isActive, transitions }) => ({
      key: id,
      cells: [
        {
          key: `name-${id}`,
          content: (
            <StyledStatusRow
              $statusType={id === statusEmptyStateID ? "empty_state" : ""}
            >
              {id === statusEmptyStateID ? (
                <p>Empty state</p>
              ) : id === "extraline" ? (
                <StyledAddStatusBtn>{name}</StyledAddStatusBtn>
              ) : (
                <InlineEdit
                  keepEditViewOpenOnBlur
                  key={nanoid(5)}
                  isEditing={editingStatusId === id}
                  defaultValue={name}
                  editView={({ errorMessage, ...fieldProps }) => (
                    <Textfield {...fieldProps} autoFocus />
                  )}
                  readView={() => (
                    <StyledEditableStatus
                      onClick={() => setEditingStatusId(id)}
                    >
                      {name}
                    </StyledEditableStatus>
                  )}
                  isRequired={true}
                  onConfirm={(value) => {
                    setEditingStatus({ id, name: value, isActive });
                    setEditingStatusId(null);
                    setIsSubmitClicked(false);
                  }}
                  onCancel={() => {
                    setEditingStatusId(null);
                    setEditingStatus(null);
                  }}
                />
              )}
              {id !== "extraline" && (
                <StyledArrowIcon>
                  <ArrowRightIcon label="" />
                </StyledArrowIcon>
              )}
            </StyledStatusRow>
          ),
        },
        {
          key: `can_be_switched_to-${id}`,
          content: addStatusID !== id && (
            <StyledSelectRow>
              <Select
                inputId={`multi-select-${id}`}
                styles={{
                  dropdownIndicator: (
                    base,
                    props: DropdownIndicatorProps<OptionType>
                  ) => ({
                    ...base,
                    display: props.isFocused ? "block" : "none!important",
                  }),
                }}
                classNamePrefix="work-status-select"
                appearance="subtle"
                components={{ ClearIndicator: undefined }}
                options={switchedToStatuses(statusWithTransitions, [
                  id,
                  addStatusID,
                  statusEmptyStateID,
                  ...(transitions?.map((status) => status.toStatusId) ?? []),
                ])}
                value={
                  transitions &&
                  (transitions.map((status) => ({
                    label: status.name,
                    value: status.toStatusId,
                    statusTransitionId: status.id,
                  })) as OptionType[])
                }
                onChange={(value, action) => {
                  switch (action.action) {
                    case "select-option":
                      addStatusTransition(
                        id,
                        findToTransition(
                          value as OptionType[],
                          transitions ?? []
                        )
                      );
                      break;
                    case "remove-value":
                      handleDeleteStatusTransition(
                        findRemoveTransitionID(
                          value as OptionType[],
                          transitions ?? []
                        )
                      );

                      break;
                  }
                }}
                isMulti
                isSearchable={false}
                placeholder=""
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </StyledSelectRow>
          ),
        },
        {
          key: `isActive-${id}`,
          content: id !== addStatusID && (
            <StyledToogleWrapper>
              <Toggle
                isChecked={isActive}
                id={`toggle-${id}`}
                defaultChecked={isActive}
                isDisabled={id === statusEmptyStateID}
                onChange={(event) =>
                  handleUpdateStatus(id, name, event.target.checked)
                }
              />
              {isActive ? "Active" : "Inactive"}
            </StyledToogleWrapper>
          ),
        },
        { key: "info", content: "" },
        {
          key: `action-${id}`,
          content:
            addStatusID !== id &&
            (id !== statusEmptyStateID ? (
              <StyledIconRemove>
                <IconButton
                  icon={EditorRemoveIcon}
                  spacing="compact"
                  label="Remove"
                  onClick={(e) => {
                    handleDeleteStatus(id);
                  }}
                />
              </StyledIconRemove>
            ) : (
              <Tooltip
                delay={0}
                position={"bottom"}
                content={"You can't delete empty state"}
              >
                <IconButton
                  icon={EditorRemoveIcon}
                  isDisabled={true}
                  spacing="compact"
                  label="Remove"
                />
              </Tooltip>
            )),
        },
      ],
    })
  );

  return (
    <StyledStatusesPage>
      {showErrorMessages && (
        <div className="section-message-modal">
          <SectionMessage
            appearance={"error"}
            title={"You cannot delete this status"}
          >
            <p>
              This status is assigned to employees. Unassign status to delete
              it.
            </p>
          </SectionMessage>
        </div>
      )}
      <StyledDynamicTable>
        <DynamicTable
          caption="Work statuses"
          head={head()}
          rows={rows}
          onSort={handleSort}
        />
      </StyledDynamicTable>
    </StyledStatusesPage>
  );
};
