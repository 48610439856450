import {FC} from "react";
import style from "./ProfileAvatar.module.scss";

interface NoImageAvatarProps {
    size?: number;
    imgAvatar?: string | null | undefined;
    firstName: string;
    lastName: string;
}

export const ProfileAvatar: FC<NoImageAvatarProps> = ({size = 32, imgAvatar, firstName, lastName}) => {
    if(imgAvatar) {
        return (<img src={imgAvatar} alt={`${firstName} ${lastName}`} className={style.imgAvatar} />);
    }

    return(
        <div className={style.bgAvatar}>
            <div className={style.textAvatar} style={{
                fontSize: `${size * 0.375}px`,
                lineHeight: `${size}px`
            }}>{firstName.charAt(0).toUpperCase()}{lastName.charAt(0).toUpperCase()}</div>
        </div>
    );
};