import { createBrowserRouter, Navigate } from "react-router-dom";

import {
  _ADMIN,
  AUTH,
  AUTH_SIGN_IN,
  CALENDAR,
  EMPLOYEE,
  EMPLOYEES,
  FORGOT_PASSWORD,
  GENDER_PRONOUNS,
  RANDOM_PHRASES,
  HOLIDAYS,
  JOB_TITLES,
  LEVELS,
  LINKS,
  LOCATIONS,
  MAIN,
  ORGCHART,
  PUSH_NOTIFICATIONS,
  RESET_PASSWORD,
  SET_PASSWORD,
  SIGN_IN,
  STATUSES,
  ADMIN_TIME_OFF_POLICIES,
  FORBIDDEN,
  REQUESTS,
  ATTENDANCE_LOG,
  TEAM_ANALYSIS,
  AI_ANALYSIS,
  SWAGGER,
  ANNOUNCEMENTS
} from "./routes";

import {
  ROLE_ADMIN,
  ROLE_CONTENT_MANAGER,
  ROLE_EMPLOYEE,
  ROLE_HR,
  ROLE_OPERATIONAL_MANAGER,
  ROLE_UNAUTHORIZED,
} from "../HOC/AccessController/AccessController.constants";

import { ProvidersLoader } from "../ProvidersLoader";

import { AccessController } from "../HOC/AccessController";

import { Layout } from "../components/Layout";

import NotFoundPage from "../pages/NotFound";
import MainPage from "../pages/Main";

import AuthPage from "../pages/auth/Auth";
import SignInPage from "../pages/auth/SignIn";
import SetPasswordPage from "../pages/auth/SetPassword";
import ForgotPasswordPage from "../pages/auth/ForgotPassword";
import ResetPasswordPage from "../pages/auth/ResetPassword";

import NewEmployeePage from "../pages/NewEmployee";
import CalendarPage from "../pages/Calendar";
import AttendanceLogPage from "../pages/AttendanceLog";

import AdminPage from "../pages/admin/Admin";
import RandomPhrasesPage from "../pages/admin/RandomPhrases";
import LinksPage from "../pages/admin/Links";
import PushNotificationsPage from "../pages/admin/PushNotifications";
import HolidaysPage from "../pages/admin/Holidays";
import OrgChartPage from "../pages/admin/OrgChart";
import LocationsPage from "../pages/admin/Locations";
import GendersPage from "../pages/admin/Genders";
import LevelsPage from "../pages/admin/Levels";
import JobTitlesPage from "../pages/admin/JobTitles";
import TimeOffPoliciesPage from "../pages/admin/TimeOffPolicies";
import EmployeesPage from "../pages/admin/Employees";
import {AnnouncementsPage} from "../pages/Announcements"; 

import ForbiddenPage from "../pages/Forbidden";
import RequestsPage from "../pages/Requests";
import StatusesNewPage from "../pages/Statuses";
import TeamAnalysisPage from "../pages/TeamAnalysis";
import SwaggerComponent from "../pages/swagger/Swagger";
import AIAnalysisPage from "../pages/AIAnalysis";

export const router = createBrowserRouter([
  {
    element: <ProvidersLoader />,
    children: [
      {
        path: MAIN,
        element: (
          <AccessController
            accessAllowedRoles={[
              ROLE_EMPLOYEE,
              ROLE_HR,
              ROLE_OPERATIONAL_MANAGER,
              ROLE_CONTENT_MANAGER,
              ROLE_ADMIN,
            ]}
            redirectToIfAccessDenied={AUTH_SIGN_IN}
          >
            <Layout />
          </AccessController>
        ),
        children: [
          { index: true, element: <MainPage /> },

          {
            path: EMPLOYEE,
            element: <NewEmployeePage />,
          },
          {
            path: CALENDAR,
            element: (
              <AccessController
                accessAllowedRoles={[ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <CalendarPage />
              </AccessController>
            ),
          },
          {
            path: REQUESTS,
            element: (
              <AccessController
                accessAllowedRoles={[ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <RequestsPage />
              </AccessController>
            ),
          },
          {
            path: ADMIN_TIME_OFF_POLICIES,
            element: (
                <AccessController
                    accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                >
                  <TimeOffPoliciesPage />
                </AccessController>
            ),
          },
          {
            path: STATUSES,
            element: (
              <AccessController
                accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <StatusesNewPage />
              </AccessController>
            ),
          },
          {
            path: ANNOUNCEMENTS,
            element: (
              <AccessController
                accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <AnnouncementsPage />
              </AccessController>
            ),
          },
          {
            path: ATTENDANCE_LOG,
            element: <AttendanceLogPage />,
          },
          {
            path: TEAM_ANALYSIS,
            element: (
              <AccessController
                accessAllowedRoles={[
                  ROLE_HR,
                  ROLE_OPERATIONAL_MANAGER,
                  ROLE_CONTENT_MANAGER,
                  ROLE_ADMIN,
                  ROLE_EMPLOYEE,
                ]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <TeamAnalysisPage />
              </AccessController>
            ),
          },
          {
            path: AI_ANALYSIS,
            element: (
              <AccessController
                accessAllowedRoles={[
                  ROLE_HR,
                  ROLE_OPERATIONAL_MANAGER,
                  ROLE_CONTENT_MANAGER,
                  ROLE_ADMIN,
                  ROLE_EMPLOYEE,
                ]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <AIAnalysisPage />
              </AccessController>
            ),
          },
          {
            path: _ADMIN,
            element: (
              <AccessController
                accessAllowedRoles={[
                  ROLE_HR,
                  ROLE_OPERATIONAL_MANAGER,
                  ROLE_CONTENT_MANAGER,
                  ROLE_ADMIN,
                ]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <AdminPage />
              </AccessController>
            ),
            children: [
              {
                path: RANDOM_PHRASES,
                element: (
                  <AccessController
                    accessAllowedRoles={[
                      ROLE_ADMIN,
                      ROLE_HR,
                      ROLE_CONTENT_MANAGER,
                    ]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <RandomPhrasesPage />
                  </AccessController>
                ),
              },
              {
                path: LINKS,
                element: (
                  <AccessController
                    accessAllowedRoles={[
                      ROLE_ADMIN,
                      ROLE_HR,
                      ROLE_CONTENT_MANAGER,
                    ]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <LinksPage />
                  </AccessController>
                ),
              },
              {
                path: PUSH_NOTIFICATIONS,
                element: (
                  <AccessController
                    accessAllowedRoles={[
                      ROLE_ADMIN,
                      ROLE_HR,
                      ROLE_CONTENT_MANAGER,
                      ROLE_OPERATIONAL_MANAGER,
                    ]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <PushNotificationsPage />
                  </AccessController>
                ),
              },
              {
                path: HOLIDAYS,
                element: (
                  <AccessController
                    accessAllowedRoles={[
                      ROLE_ADMIN,
                      ROLE_HR,
                      ROLE_CONTENT_MANAGER,
                      ROLE_OPERATIONAL_MANAGER,
                    ]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <HolidaysPage />
                  </AccessController>
                ),
              },
              {
                path: ORGCHART,
                element: (
                  <AccessController
                    accessAllowedRoles={[
                      ROLE_ADMIN,
                      ROLE_HR,
                      ROLE_OPERATIONAL_MANAGER,
                    ]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <OrgChartPage />
                  </AccessController>
                ),
              },
              {
                path: LOCATIONS,
                element: (
                  <AccessController
                    accessAllowedRoles={[
                      ROLE_ADMIN,
                      ROLE_HR,
                      ROLE_OPERATIONAL_MANAGER,
                    ]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <LocationsPage />
                  </AccessController>
                ),
              },
              {
                path: GENDER_PRONOUNS,
                element: (
                  <AccessController
                    accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <GendersPage />
                  </AccessController>
                ),
              },
              {
                path: LEVELS,
                element: (
                  <AccessController
                    accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <LevelsPage />
                  </AccessController>
                ),
              },
              {
                path: JOB_TITLES,
                element: (
                  <AccessController
                    accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <JobTitlesPage />
                  </AccessController>
                ),
              },
              {
                path: EMPLOYEES,
                element: (
                  <AccessController
                    accessAllowedRoles={[ROLE_ADMIN, ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <EmployeesPage />
                  </AccessController>
                ),
              },
            ],
          },
          { path: FORBIDDEN, element: <ForbiddenPage /> },
          { path: "*", element: <NotFoundPage /> },
        ],
      },
      {
        path: AUTH,
        element: (
          <AccessController
            accessAllowedRoles={[ROLE_UNAUTHORIZED]}
            redirectToIfAccessDenied={MAIN}
          >
            <AuthPage />
          </AccessController>
        ),
        children: [
          { index: true, element: <Navigate to={AUTH_SIGN_IN} replace /> },
          { path: SIGN_IN, element: <SignInPage /> },
          { path: SET_PASSWORD, element: <SetPasswordPage /> },
          { path: FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
          { path: RESET_PASSWORD, element: <ResetPasswordPage /> },
        ],
      },
      ...(process.env.REACT_APP_ENV === "dev"
        ? [
            {
              path: SWAGGER,
              element: (
                <AccessController
                  accessAllowedRoles={[
                    ROLE_ADMIN,
                    ROLE_EMPLOYEE,
                    ROLE_HR,
                    ROLE_OPERATIONAL_MANAGER,
                  ]}
                  redirectToIfAccessDenied={MAIN}
                >
                  <SwaggerComponent />
                </AccessController>
              ),
            },
          ]
        : []),
    ],
  },
]);
