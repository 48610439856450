import {FC} from "react";
import style from './SuccessChangePassword.module.scss';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import {Button} from '../../../UI/Button';
import {useNavigate} from "react-router-dom";
import {AUTH_SIGN_IN} from '../../../../Router/routes';

interface SuccessChangePasswordProps {
    isResetPassword: boolean;
}

export const SuccessChangePassword: FC<SuccessChangePasswordProps> = ({isResetPassword}) => {
    const navigate = useNavigate();
    const toLogin = () => {
        navigate(AUTH_SIGN_IN, { replace: true });
    };

    return(
        <div>
            <div className={style.imageWrapper}>
                <CheckCircleIcon size="large" label="OK"/>
            </div>
            <h2 className={style.title}>{isResetPassword ? 'Password updated' : ''}</h2>
            <p className={style.paragraph}>{isResetPassword ? 'Your new password has now been successfully set' : 'Your password has been successfully set'}</p>
            <div className={style.link}>
                <Button
                    onClick={toLogin}
                    appearance="link"
                >
                    Sign In to my account
                </Button>
            </div>
        </div>
    );
};