import { token } from "@atlaskit/tokens";
import styled from "styled-components";

// prettier-ignore
export const StyledHomePage = styled.main`
  height: 100%;
  background: #f8f9fd;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.300")};

  @media (min-width: 1920px) {
    padding: ${token("space.300")} ${token("space.800")} ${token("space.600")};
  }

  @media (max-width: 1280px) {
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.200")};
  }

  @media (max-width: 1024px) {
    width: 100vw;
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")};
  }

  @media (max-width: 768px) {
    padding: ${token("space.300")} ${token("space.200")} ${token("space.600")};
  }

  @media (max-width: 480px) {
    width: 100vw;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const StyledHomePageLeft = styled.section`
  width: calc(100% - 340px - ${token("space.300")});

  @media (min-width: 1920px) {
    width: calc(100% - 388px - ${token("space.600")});
  }

  @media (max-width: 1280px) {
    width: calc(100% - 340px - ${token("space.200")});
  }

  @media (max-width: 768px) {
    width: calc(100% - 320px - ${token("space.200")});
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const StyledHomePageRight = styled.section`
  width: 340px;

  @media (min-width: 1920px) {
    width: 388px;
  }

  @media (max-width: 768px) {
    width: 320px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;
