import { FC } from "react";
import { StyledEmptyFiltersBlock } from "./EmptyFiltersPlaceholderStyles";

interface EmptyFiltersPlaceholderProps {
  title: string;
  info: string;
}

export const EmptyFiltersPlaceholder: FC<EmptyFiltersPlaceholderProps> = ({
  title,
  info,
}) => {
  return (
    <>
      <StyledEmptyFiltersBlock>
        <img src="/image/NoFiltersFound.svg" />
        <h3>{title}</h3>
        <p>{info}</p>
      </StyledEmptyFiltersBlock>
    </>
  );
};
