import {SelectValue} from "../../Common/data/SelectValue";
import {Option} from "../../../interfaces/timeOffPolicy/Select/option.interface";

export enum TimeOptions {
    Yearly = 'yearly',
    Monthly = 'monthly',
    Weekly = 'weekly',
    Daily = 'daily',
    NDays = 'daysPerDays',
    HoursWorked = 'hoursWorked',
    Once = 'once',
    YearlyOnHireDate = 'yearlyOnHireDate',
    YearlyOnBirthdayDate = 'yearlyOnBirthdayDate',
    YearlyOnEmploymentDate = 'yearlyOnEmploymentDate',
    OnceOnHireDate = 'onceOnHireDate',
    OnceOnFixedDate = 'onceOnFixedDate',
    HoursWorkedYearly = 'hoursWorkedYearly',
    HoursWorkedMonthly = 'hoursWorkedMonthly',
    HoursWorkedWeekly = 'hoursWorkedWeekly',
    HoursWorkedDaily = 'hoursWorkedDaily',
    HoursWorkedOnHireDate = 'hoursWorkedOnHireDate',
    OnHireDate = 'onHireDate',
}

export enum HoursWorkedTimeOptions {
    Yearly = 'yearly',
    Monthly = 'monthly',
    Weekly = 'weekly',
    Daily = 'daily',
    OnHireDate = 'onHireDate',
}

export const hoursPerWorkingDayOptions = [
    { value: '4', label: '4' },
    { value: '6', label: '6' },
    { value: '8', label: '8' },
] as SelectValue[];

export const accrualScheduleTypeOptions = [
    { value: TimeOptions.Yearly, label: 'year' },
    { value: TimeOptions.Monthly, label: 'month' },
    { value: TimeOptions.NDays, label: 'N days' },
] as SelectValue[];

export const daysOfMonthOptions = [
    { value: '01', label: '1st' },
    { value: '02', label: '2nd' },
    { value: '03', label: '3rd' },
    { value: '04', label: '4th' },
    { value: '05', label: '5th' },
    { value: '06', label: '6th' },
    { value: '07', label: '7th' },
    { value: '08', label: '8th' },
    { value: '09', label: '9th' },
    { value: '10', label: '10th' },
    { value: '11', label: '11th' },
    { value: '12', label: '12th' },
    { value: '13', label: '13th' },
    { value: '14', label: '14th' },
    { value: '15', label: '15th' },
    { value: '16', label: '16th' },
    { value: '17', label: '17th' },
    { value: '18', label: '18th' },
    { value: '19', label: '19th' },
    { value: '20', label: '20th' },
    { value: '21', label: '21st' },
    { value: '22', label: '22nd' },
    { value: '23', label: '23rd' },
    { value: '24', label: '24th' },
    { value: '25', label: '25th' },
    { value: '26', label: '26th' },
    { value: '27', label: '27th' },
    { value: '28', label: '28th' },
    { value: '29', label: '29th' },
    { value: '30', label: '30th' },
    { value: '31', label: '31st' },
] as SelectValue[];

export const daysOfMonthOptionsMonthly = [
    { value: '1', label: '1st' },
    { value: '2', label: '2nd' },
    { value: '3', label: '3rd' },
    { value: '4', label: '4th' },
    { value: '5', label: '5th' },
    { value: '6', label: '6th' },
    { value: '7', label: '7th' },
    { value: '8', label: '8th' },
    { value: '9', label: '9th' },
    { value: '10', label: '10th' },
    { value: '11', label: '11th' },
    { value: '12', label: '12th' },
    { value: '13', label: '13th' },
    { value: '14', label: '14th' },
    { value: '15', label: '15th' },
    { value: '16', label: '16th' },
    { value: '17', label: '17th' },
    { value: '18', label: '18th' },
    { value: '19', label: '19th' },
    { value: '20', label: '20th' },
    { value: '21', label: '21st' },
    { value: '22', label: '22nd' },
    { value: '23', label: '23rd' },
    { value: '24', label: '24th' },
    { value: '25', label: '25th' },
    { value: '26', label: '26th' },
    { value: '27', label: '27th' },
    { value: '0', label: 'Last day of month' },
] as Option[];

export const daysOfWeekOptions = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' },
] as SelectValue[];

export const monthsOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
] as Option[];

export const onceSelectOptions = [
    {value: 'onHireDate', label: 'hire date'},
    {value: 'onFixedDate', label: 'fixed date'},
] as SelectValue[];

export const hoursWorkedTypeOptions = [
    {value: HoursWorkedTimeOptions.Yearly, label: 'yearly'},
    {value: HoursWorkedTimeOptions.Monthly, label: 'monthly'},
    {value: HoursWorkedTimeOptions.Weekly, label: 'weekly'},
    {value: HoursWorkedTimeOptions.Daily, label: 'daily'},
    {value: HoursWorkedTimeOptions.OnHireDate, label: 'on hire date'},
] as SelectValue[];