import { EMPLOYEES } from "../../constants";
import type {
  SubordinatesResponseProps,
  SubordinatesProps,
} from "./subordinates.types";

const transformResponse = (
  subordinates: Array<SubordinatesResponseProps>
): Array<SubordinatesProps> => {
  return subordinates.map((sub) => {
    return {
      key: sub.id,
      src: sub.image,
      name: `${sub.firstNameEn} ${sub.lastNameEn}${sub.nickname ? ` (${sub.nickname})` : ""}`,
      href: `/employee/${sub.id}`,
      firstNameEn: sub.firstNameEn,
      lastNameEn: sub.lastNameEn,
      nickname: sub.nickname,
      orgUnitId: sub.orgUnitId,
      orgUnitName: sub.orgUnitName,
      email: sub.email,
      companyName: sub.companyName,
    };
  });
};

export const fetchSubordinatesAPI = async (idProfile: string) => {
  try {
    const response = await fetch(
      `${EMPLOYEES}/employee/${idProfile}/subordinate-direct-employees`,
      {
        method: "GET",
        headers: { Accept: "application/json" },
      }
    );

    const data = (await response.json()) as Array<SubordinatesResponseProps>;
    return transformResponse(data);
  } catch (error) {
    console.error(error);
    throw new Error();
  }
};
