import React, { FC, useState, useEffect, useRef } from "react";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import Button from "@atlaskit/button";
import NoData from "assets/images/policy/no_data.svg";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";
import DynamicTable from "@atlaskit/dynamic-table";
import Toggle from "@atlaskit/toggle";
import { IconButton } from "@atlaskit/button/new";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import PeopleIcon from "@atlaskit/icon/glyph/people";
import Popup from "@atlaskit/popup";
import SectionMessage, {
  SectionMessageAction,
} from "@atlaskit/section-message";
import { Inline, xcss, Box } from "@atlaskit/primitives";
import {
  StyledBlock,
  StyledHeadline,
  StyledDynamicTable,
  StyledMessageError,
  StyledNoData,
  StyledPopupText,
  StyledPopupTextPolicy,
  StyledIcons,
} from "./TableTimeOffPolicyStyles";
import type { TimeOffPolicyResponseProps } from "../../API/timeOffPolicies/timeOffPolicy.types";
import { useTimeOffPolicy } from "../../API/timeOffPolicies";
import { TypeIconsCollection } from "../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import { TIME_OFF_POLICIES } from "../../utils/constants/api";
import SearchField from "components/Search/SearchField/SearchField";
import CreatePolicy from "./Modal/CreatePolicy/CreatePolicy";
import AllocatePolicy from "./Modal/AllocatePolicy/AllocatePolicy";
import Tooltip from "@atlaskit/tooltip";
import ModalDeletePolicy from "./Modal/DeletePolicy/ModalDeletePolicy";

const head = (
  policyPopupOpen: boolean,
  handlePopupToggle: (key: string) => void
) => ({
  cells: [
    { key: "name", content: "Name", isSortable: true },
    { key: "typeIcon", content: "Icon", isSortable: true },
    { key: "accrualSchedule", content: "Accruals", isSortable: true },
    {
      key: "companyPaidRegardlessOfWorkday",
      content: "Type",
      isSortable: true,
    },
    { key: "isVisible", content: "Activate policy", isSortable: true },
    {
      key: "info",
      content: (
        <Popup
          isOpen={policyPopupOpen}
          onClose={() => handlePopupToggle("header")}
          content={() => (
            <StyledPopupTextPolicy>
              If the policy is inactive, it cannot be assigned to employees
            </StyledPopupTextPolicy>
          )}
          placement="bottom-end"
          trigger={(triggerProps) => (
            <StyledIcons>
              <IconButton
                {...triggerProps}
                icon={EditorPanelIcon}
                appearance="subtle"
                label="info"
                spacing="compact"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePopupToggle("header");
                }}
              />
            </StyledIcons>
          )}
        />
      ),
      isSortable: false,
    },
    { key: "action", content: "Action" },
  ],
});

const TableTimeOffPolicies: FC = () => {
  const [popupOpen, setPopupOpen] = useState<{ [key: string]: boolean }>({});
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isAllocateModalOpen, setAllocateModalOpen] = useState(false);
  const [deleteModalPolicy, setDeleteModalPolicy] =
    useState<TimeOffPolicyResponseProps | null>(null);
  const [allocatePolicy, setAllocatePolicy] =
    useState<TimeOffPolicyResponseProps | null>(null);
  const [allocatePolicyShowAfterSubmit, setAllocatePolicyShowAfterSubmit] =
    useState<TimeOffPolicyResponseProps | null>(null);
  const [isCreatePolicyModalOpen, setCreatePolicyModalOpen] = useState(false);
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [phrase, setPhrase] = useState<string>("");
  const [timeOffPolicies, setTimeOffPolicies] = useState<
    Array<TimeOffPolicyResponseProps>
  >([]);
  const { fetchTimeOffPolicy, timeOffPolicy, isLoading } = useTimeOffPolicy(
    true,
    ""
  );
  const [filteredPolicies, setFilteredPolicies] = useState<
    Array<TimeOffPolicyResponseProps>
  >([]);

  useEffect(() => {
    fetchTimeOffPolicy().then((response) => {
      setTimeOffPolicies(response);
    });
  }, []);

  useEffect(() => {
    if (timeOffPolicies.length > 0) {
      if (phrase === "") {
        setFilteredPolicies(timeOffPolicies);
      } else {
        setFilteredPolicies(
          timeOffPolicies.filter((policy) =>
            policy.name.toLowerCase().includes(phrase.toLowerCase())
          )
        );
      }
    }
  }, [timeOffPolicies, phrase]);

  useEffect(() => {
    if (!isAllocateModalOpen) {
      setAllocatePolicy(null);
    }
  }, [isAllocateModalOpen]);

  const handleAllocatePolicyClick = () => {
    setAllocateModalOpen(true);
  };

  const handleOpenCreatePolicyModal = () => {
    setCreatePolicyModalOpen(true);
  };

  const handleCloseCreatePolicyModal = () => {
    setCreatePolicyModalOpen(false);
  };

  const handleCloseSuccessCreatePolicyModal = () => {
    setCreatePolicyModalOpen(false);
    fetchTimeOffPolicy().then((response) => {
      setTimeOffPolicies(response);
    });
  };

  const handlePopupToggle = (key: string) => {
    setPopupOpen({});
    setPopupOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  function setVisible(id: string, value: any) {
    fetch(TIME_OFF_POLICIES + "/" + id + "/visible", {
      method: value ? "POST" : "DELETE",
    });
  }

  async function deletePolicy(id: string) {
    try {
      const response = await fetch(TIME_OFF_POLICIES + "/" + id, {
        method: "DELETE",
      });

      if (response.ok) {
        setTimeOffPolicies((prevPolicies) =>
          prevPolicies.filter((policy) => policy.id !== id)
        );
      } else if (response.status === 422) {
        setShowErrorMessage(true);
        setAllocatePolicy(
          timeOffPolicies.find((policy) => policy.id === id) ?? null
        );
      }
    } catch (error) {
      setShowErrorMessage(true);
      setAllocatePolicy(
        timeOffPolicies.find((policy) => policy.id === id) ?? null
      );
      console.error("Failed to delete policy:", error);
    }
  }

  const rows = filteredPolicies.map((row) => ({
    key: `row-${row.id}`,
    cells: [
      {
        key: row.name,
        content:
          row.name.length > 25 ? (
            <Tooltip content={row.name}>
              <Box
                xcss={xcss({
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "200px",
                })}
              >
                {row.name}
              </Box>
            </Tooltip>
          ) : (
            <Box
              xcss={xcss({
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "200px",
              })}
            >
              {row.name}
            </Box>
          ),
      },
      {
        key: row.typeIcon,
        content: (
          <Inline space="space.200" alignBlock="center">
            {TypeIconsCollection[
              row.typeIcon as keyof typeof TypeIconsCollection
            ]("large-icon", "withouthover")}
            {row.description && (
              <Popup
                isOpen={popupOpen[row.id] || false}
                onClose={() => handlePopupToggle(row.id)}
                content={() => (
                  <StyledPopupText>{row.description}</StyledPopupText>
                )}
                placement="bottom-end"
                trigger={(triggerProps) => (
                  <StyledIcons>
                    <IconButton
                      {...triggerProps}
                      isSelected={popupOpen[row.id] || false}
                      icon={EditorPanelIcon}
                      label="info"
                      appearance="subtle"
                      spacing="compact"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePopupToggle(row.id);
                      }}
                    />
                  </StyledIcons>
                )}
              />
            )}
          </Inline>
        ),
      },
      {
        key: row.accrualSchedule ? "With accruals" : "Unlimited",
        content: row.accrualSchedule ? "With accruals" : "Unlimited",
      },
      {
        key: row.companyPaidRegardlessOfWorkday ? "Paid" : "Unpaid",
        content: row.companyPaidRegardlessOfWorkday ? "Paid" : "Unpaid",
      },
      {
        key: +row.isVisible,
        content: (
          <Toggle
            defaultChecked={row.isVisible}
            onChange={() => {
              row.isVisible = !row.isVisible;
              setVisible(row.id, row.isVisible);
            }}
          />
        ),
      },
      { key: "info", content: "" },
      {
        key: "action",
        content: (
          <IconButton
            icon={EditorRemoveIcon}
            spacing="compact"
            label="Remove"
            onClick={(e) => {
              setDeleteModalPolicy(row);
            }}
          />
        ),
      },
    ],
  }));

  useEffect(() => {
    if (showSubmitMessage) {
      const timeout = setTimeout(() => {
        setShowSubmitMessage(false);
        setAllocatePolicyShowAfterSubmit(null);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [showSubmitMessage]);

  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowErrorMessage(false);
      setPopupOpen({});
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <>
      <StyledBlock>
        <h3>Time off & special status policies</h3>
        <StyledHeadline>
          <SearchField
            onreset={1}
            placeholder="Search"
            value={phrase}
            onChange={(value) => {
              setPhrase(value);
            }}
            onFocus={() => {}}
            onClick={(e) => {}}
            onPressEnter={() => {}}
          />
          <Inline space="space.200">
            {filteredPolicies.length > 0 && (
              <Button
                iconBefore={<PeopleIcon label="Allocate policy" />}
                appearance="primary"
                onClick={handleAllocatePolicyClick}
              >
                Allocate policy
              </Button>
            )}
            <Button
              iconBefore={<EditorAddIcon label="Create new" />}
              appearance="default"
              onClick={handleOpenCreatePolicyModal}
            >
              Create new
            </Button>
          </Inline>
        </StyledHeadline>

        {rows.length <= 0 && (
          <StyledNoData>
            <img src={NoData} alt="no data" />
            <h2>There is no policy here yet</h2>
            <Button
              iconBefore={<EditorAddIcon label="Create policy" />}
              appearance="default"
              onClick={handleOpenCreatePolicyModal}
            >
              Create policy
            </Button>
          </StyledNoData>
        )}

        {rows.length > 0 && (
          <StyledDynamicTable>
            <DynamicTable
              head={head(popupOpen["header"], handlePopupToggle)}
              rows={rows}
              rowsPerPage={12}
              page={currentPage}
              loadingSpinnerSize="large"
              isRankable
              onSetPage={onPageChange}
            />
          </StyledDynamicTable>
        )}

        {showErrorMessage && (
          <StyledMessageError onClick={(e: any) => e.stopPropagation()}>
            <SectionMessage
              title="You can’t delete this policy"
              appearance="error"
              actions={
                <SectionMessageAction
                  onClick={() => setAllocateModalOpen(true)}
                >
                  Employee list
                </SectionMessageAction>
              }
            >
              <p>
                This policy is assigned to employees. Unassign policy to delete
                it.
              </p>
            </SectionMessage>
          </StyledMessageError>
        )}
      </StyledBlock>
      <AllocatePolicy
        isOpen={isAllocateModalOpen}
        setIsOpen={setAllocateModalOpen}
        onConfirm={() => {
          setAllocateModalOpen(false);
          setAllocatePolicyShowAfterSubmit(allocatePolicy);
          setShowSubmitMessage(true);
        }}
        timeOffPolicies={timeOffPolicies}
        selectedPolicy={allocatePolicy}
        setSelectedPolicy={setAllocatePolicy}
      />

      <CreatePolicy
        isOpen={isCreatePolicyModalOpen}
        onCloseSuccess={handleCloseSuccessCreatePolicyModal}
        onClose={handleCloseCreatePolicyModal}
      />
      <ModalDeletePolicy
        policy={deleteModalPolicy}
        isOpen={deleteModalPolicy != null}
        onConfirm={() => {
          setDeleteModalPolicy(null);
          if (deleteModalPolicy) {
            deletePolicy(deleteModalPolicy.id);
          }
        }}
        onClose={() => setDeleteModalPolicy(null)}
      />

      {showSubmitMessage && (
        <div className="section-message-modal">
          <SectionMessage
            appearance={"success"}
            title={`You’ve allocated the policy ${allocatePolicyShowAfterSubmit?.name} for employees`}
          >
            <SectionMessageAction
              onClick={() => {
                setAllocatePolicy(allocatePolicyShowAfterSubmit);
                setAllocateModalOpen(true);
              }}
            >
              Employee list
            </SectionMessageAction>
          </SectionMessage>
        </div>
      )}
    </>
  );
};

export default TableTimeOffPolicies;
