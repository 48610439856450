import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const StyledPolicySelect = styled.div`
  width: 370px;

  & > div {
    margin-block-start: 0;
  }
    
  [class*="control"] {
    min-height: 32px;
    height: 32px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    &:focus-within {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    & > div {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

    [class*="option"] {
        &:hover {
            box-shadow: inset 2px 0 0 var(--ds-border-selected, #0052CC);
            background: rgb(241, 243, 245);
        }
    }
    
    [class*=" css-363ehx-option"] {
        box-shadow: none;
        background: none;
    }

    [class*="ValueContainer"],
  [class*="IndicatorsContainer"] {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  [class*="indicatorContainer"] {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-policy-select {
    [class*="singleValue"] {
      & > div > p {
        width: auto;
      }

      & > div > span {
        display: none;
      }
    }
  }
`;

export const StyledPolicyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token("space.100")};

  & > p {
    width: max-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  & > span {
    flex-shrink: 0;
    margin-left: auto;
    color: ${token("color.text.subtlest")};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;
