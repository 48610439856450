import { token } from "@atlaskit/tokens";
import styled from "styled-components";
import { xcss } from "@atlaskit/primitives";

export const StyledEmptyDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 324px;
    height: 314px;
  }

  h3 {
    color: ${token("color.text")};
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
  }
`;
