import React, {FC, useRef, useState} from "react";
import style from './ProfileImageLoader.module.scss';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
} from '@atlaskit/modal-dialog';
import {useDropzone} from 'react-dropzone';
import Range from '@atlaskit/range';

import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import ExportIcon from '@atlaskit/icon/glyph/export';

import {Button} from '../../UI/Button';
import Cropper, {ReactCropperElement} from "react-cropper";
import {LoadingButton} from "@atlaskit/button";


import "cropperjs/dist/cropper.css";

interface ProfileImageLoaderProps {
    avatar: string | null | undefined;
    uploadNewAvatar: (data: string) => Promise<any>;
    deleteAvatar: () => Promise<any>;
    isOpen: boolean;
    closeModal: () => void;
}

const typeRegExp = /^image\//i;

export const ProfileImageLoader: FC<ProfileImageLoaderProps> = ({isOpen, closeModal, avatar, uploadNewAvatar, deleteAvatar}) => {
    const cropperRef = useRef<ReactCropperElement>(null);
    const [uploadedImage, setUploadedImage] = useState('');
    const [zoom, setZoom] = useState(0);

    const [isDeleteCurrentPhoto, setDeleteCurrentPhoto] = useState(false);
    const [isUpdate, setUpdate] = useState(false);

    const {getRootProps, getInputProps, inputRef} = useDropzone({
        noClick: true,
        onDrop: acceptedFiles => {
            const file = acceptedFiles[0];
            //if(!typeRegExp.test(file.type) || file.size > 10000000) alert('Unsupported format or file size larger than 10MB');
            if(!typeRegExp.test(file.type)) {
                alert('Unsupported format');
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                setUploadedImage(reader.result as any);
            };
            reader.readAsDataURL(file);
        }
    });

    const onCloseModal = () => {
        closeModal();
        setUploadedImage('');
        setZoom(0);
        setDeleteCurrentPhoto(false);
    };

    return(
        <ModalTransition>
            {isOpen && (
                <Modal onClose={onCloseModal}>
                    <ModalHeader>
                        <ModalTitle>{uploadedImage ? 'Edit Thumbnail' : 'Update Profile Picture'}</ModalTitle>
                        <IconButton
                            appearance="subtle"
                            onClick={onCloseModal}
                            label="Close Modal"
                            icon={CrossIcon}
                        />
                    </ModalHeader>
                    <ModalBody>
                        {uploadedImage ? (
                            <div className={style.cropContainer}>
                                <div>
                                    <div>
                                        <Cropper
                                            //key={imageKey}
                                            ref={cropperRef}
                                            style={{width: "250px", height: "250px"}}
                                            zoomTo={zoom}
                                            aspectRatio={1}
                                            preview=".img-preview"
                                            src={uploadedImage}
                                            viewMode={1}
                                            cropBoxResizable={false}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false}
                                            guides={true}
                                            dragMode="move"
                                            cropBoxMovable={false}
                                            zoomOnTouch={false}
                                            zoomOnWheel={false}
                                        />
                                    </div>
                                    <div className={style.rage}>
                                        <Range
                                            aria-label="controlled range"
                                            step={0.01}
                                            min={0}
                                            max={3}
                                            value={zoom}
                                            onChange={(value) => {
                                                setZoom(value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={style.cropText}>
                                    Please use photo on light background without other people or animals.
                                </div>
                            </div>
                        ) : (
                            <label {...getRootProps({className: style.labelDropZone})}>
                                <input {...getInputProps({accept: 'image/*'})} />
                                <div className={style.labelContent}>
                                    <div className={style.icon}><ExportIcon label="Upload Image" size="medium"
                                                                            primaryColor="#44546F"/></div>
                                    <div className={style.title}>Drop photo here or <span>Upload photo</span></div>
                                    <div className={style.description}>Recommended image formats, webP, jpg or png. 10
                                        MB max size.
                                    </div>
                                </div>
                            </label>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {(avatar && !uploadedImage)&& (
                            <Button isSelected={isDeleteCurrentPhoto} onClick={() => {
                                setDeleteCurrentPhoto(!isDeleteCurrentPhoto);
                            }} className={style.deleteBtn}>
                                Delete Current Photo
                            </Button>
                        )}
                        {uploadedImage && <Button onClick={() => {
                            setUploadedImage('');
                            setZoom(0);
                        }} className={style.deleteBtn}>
                            Delete
                        </Button>}
                        <Button appearance="subtle" onClick={() => {
                            onCloseModal();
                        }}>
                            Cancel
                        </Button>
                        {isUpdate ? (
                                <LoadingButton appearance="primary" isLoading className={style.loadBtn}>Loading...</LoadingButton>
                            ) : (
                            (uploadedImage || isDeleteCurrentPhoto) ? (
                                <Button appearance="primary" onClick={() => {
                                    setUpdate(true);
                                    if(isDeleteCurrentPhoto && !uploadedImage) {
                                        deleteAvatar().then(() => {
                                            setUpdate(false);
                                            onCloseModal();
                                        });
                                    }

                                    if(uploadedImage) {
                                        const croppedIMG = cropperRef.current?.cropper?.getCroppedCanvas({
                                            width: 300,
                                            height: 300,
                                        }).toDataURL('image/jpeg', 0.92);

                                        croppedIMG && uploadNewAvatar(croppedIMG).then(() => {
                                            setUpdate(false);
                                            onCloseModal();
                                        });
                                    }
                                }}>
                                    Save
                                </Button>
                            ): (
                                <Button appearance="primary" onClick={() => {
                                    inputRef.current?.click();
                                    // closeModal();
                                }}>
                                    Upload
                                </Button>
                            )
                        )}
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>
    );
};