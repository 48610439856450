import React from "react";
import { components } from "@atlaskit/select";
import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${token("color.text")};
`;

const OptionCompanyName = styled.div`
  font-size: 11px;
  color: ${token("color.text.subtlest")};
`;

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <OptionContainer>
        <OptionLabel>{props.data.label}</OptionLabel>
        <OptionCompanyName>{props.data.companyName}</OptionCompanyName>
      </OptionContainer>
    </components.Option>
  );
};

export default CustomOption;
