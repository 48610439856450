import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const DeleteAnnouncementModalHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  gap: ${token("space.100")};
  padding: ${token("space.300")};
`

export const DeleteAnnouncementModalBodyStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${token("space.100")};
  padding: 0 ${token("space.300")};

  .dates, .priority, .locations {
    display: grid;
    grid-template-columns: 108px 1fr;
  } 
`