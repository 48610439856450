import styled from "styled-components";
import { token } from "@atlaskit/tokens";
import { xcss } from "@atlaskit/primitives";
import {FC, ReactNode} from "react";

export const ScrollBlock = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  overflow: auto;
`;

export const ResultItem = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 16px;
  padding-left: 4px;
  &:hover {
    box-shadow: inset 2px 0px 0px #0c66e4 !important;
    background: #f1f3f5;
    border: none !important;
  }
  &:active {
    box-shadow: inset 2px 0px 0px #0c66e4 !important;
    background: #091e4224;
    border: none !important;
  }
  &:focus {
    border: 2px solid #0c66e4;
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: none;
    border: 2px solid #0c66e4;
    outline: none;
  }

  &.selected {
    background-color: ${token("color.background.selected")};
  }
`;

export const ButtonsWrapper: FC<{
  children: ReactNode;
}> = styled.div`
  button {
    width: 32px;
    height: 32px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledStatusSelect = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > button {
    height: 32px;
    width: 161px;
    padding: ${token("space.050")} ${token("space.100")};
    gap: ${token("space.025")};
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    color: ${token("color.text.subtlest")};
    border-radius: 3px;

    & > span {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
    }

    &[aria-expanded="true"] {
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    &:hover {
      background-color: transparent;
    }
  }

  [role="menu"] {
    width: 161px;
    padding: ${token("space.100")} 0px;
  }

  button[role="menuitemcheckbox"] {
    color: ${token("color.text")};

    &:hover {
      &::before {
        position: absolute;
        inset: 0 auto 0 0;
        content: "";
        width: 2px;
        height: 100%;
        background-color: ${token("color.border.selected")};
      }
    }

    &[aria-checked="true"] {
      background: ${token("color.background.neutral.subtle.hovered")};
    }
  }
`;

export const StyledLocationSelect = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > button {
    height: 32px;
    width: 161px;
    padding: ${token("space.050")} ${token("space.100")};
    gap: ${token("space.025")};
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    color: ${token("color.text.subtlest")};
    border-radius: 3px;
    justify-content: flex-end;

    & > span {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
    }

    &[aria-expanded="true"] {
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    &:hover {
      background-color: transparent;
    }
  }

  [role="menu"] {
    width: 161px;
    padding: ${token("space.100")} 0px;
  }

  button[role="menuitemcheckbox"] {
    color: ${token("color.text")};

    &:hover {
      &::before {
        position: absolute;
        inset: 0 auto 0 0;
        content: "";
        width: 2px;
        height: 100%;
        background-color: ${token("color.border.selected")};
      }
    }

    &[aria-checked="true"] {
      background: ${token("color.background.neutral.subtle.hovered")};
    }
  }
`;

export const AllocateTable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

// left block
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  padding: 16px 16px 42px 16px !important;
  gap: ${token("space.200")};
  padding: ${token("space.200")};
  border-radius: 3px;
  border: 1px solid ${token("color.border.disabled")};

  h3 {
    color: ${token("color.text")};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  div[role="search"] {
    margin: 0 0 8px;

    span {
      color: ${token("color.text.subtlest")};
    }

    input {
      width: 100%;
      color: ${token("color.text.subtlest")};
      background: ${token("color.background.input")};
      border: 2px solid rgba(9, 30, 66, 0.14);
    }
  }
`;

export const StyledDropdownItemWrap = styled.div`
  button[role="menuitem"] {
    height: 32px;
    padding: 0 12px !important;
  }
`;

export const StyledSelectWrap = styled.div`
  width: 370px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  span {
    display: flex;
    justify-content: flex-start;
    color: ${token("color.text.subtlest")};
    font-size: 14px;
    font-weight: 400;
  }

  div {
    width: inherit;
  }

  & > button {
    height: 32px;
    padding: 0 12px !important;
    background: white !important;
  }
`;

export const StyledMessageEmpty = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 4px;
  color: ${token("color.text.subtlest")};
`;

export const UserBlockWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserBlock = xcss({
  alignItems: "center",
  gap: "space.100",
  color: "color.text.subtlest",
  height: "40px",
  marginLeft: "8px",
});

export const StyledUserBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > a {
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  & > span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;

export const StyledEmployeesSelects = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: ${token("space.200")};
  gap: 0 ${token("space.200")};

  .multi-select {
    width: auto;

    .dropdown-container,
    .dropdown-heading {
      width: auto;
    }
  }
`;

export const StyledEmployeesSearch = styled.div`
  [data-ds--text-field--container] {
    border-radius: 3px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    padding: 0;

    &:focus-within:not([data-disabled]) {
      box-shadow: none;
    }
  }
`;

// middle block
export const MiddleSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${token("space.100")};
`;
// right block
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${token("space.200")};
  width: 370px;
  padding: 16px 16px 42px 16px !important;
  padding: ${token("space.200")};
  border-radius: 3px;
  border: 1px solid ${token("color.border.disabled")};

  h3 {
    color: ${token("color.text")};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  p {
    color: ${token("color.text.subtlest")};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
`;
// footer
export const FooterWrap = styled.div`
  height: 80px;

  div {
    padding-block-start: 24px;
  }

  button {
    padding: 0px ${token("space.150")};

    span {
      margin: 0;
    }

    &:first-child {
      width: 71px;
    }

    &:last-child {
      width: 57px;
    }
  }
`;
