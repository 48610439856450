import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const StyledSearchWrap = styled.div<{
  $openResults: boolean;
  $searchIsOpen: boolean;
}>`
  width: ${(props) => (props.$openResults ? "544px" : "240px")};
  transition: width 0.25s ease-out;

  [data-ds--text-field--container] {
    background-color: #fff;
    height: 32px;
    color: rgba(98, 111, 134, 1);

    [role="img"] {
      color: rgba(98, 111, 134, 1);
      width: 20px;
      height: 20px;
    }
  }

  [data-ds--text-field--container] span {
    margin-left: 6px;
  }

  [data-ds--text-field--input] {
    height: 32px;
    padding-left: 10px !important;
  }

  @media (max-width: 1024px) {
    display: none;
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    align-items: center;
    padding: 0 ${token("space.200")};
    z-index: 2;

    ${(props) =>
      props.$searchIsOpen &&
      `
          display: flex;
          `}
  }
`;

export const StyledSearchResults = styled.div`
  position: absolute;
  width: 544px;
  z-index: 5;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 ${token("space.200")};
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledSearchResultsSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 ${token("space.200")};
  gap: 0 ${token("space.200")};

  & > div {
    width: 100%;

    .multi-select,
    .dropdown-container,
    .dropdown-heading,
    .dropdown-content {
      width: 100%;
    }
  }
`;
