import React from 'react';
import BaseModal from './BaseModal';
import { PROFESSIONAL_HOLIDAYS } from '../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError';
import { OrgUnitFromApi } from '../../../../../../shared/OrgChart/data/OrgUnitFromApi';
import { SelectorData } from '../../../../../Component/Input/DateSelector/data/SelectorData';

type CreateProfessionalHoliday = {
    orgUnits: OrgUnitFromApi[],
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
};

function CreateProfessionalHoliday(props: CreateProfessionalHoliday) {
    function create(
        name: string,
        orgUnits: string[],
        type: string,
        movingType: SelectorData,
        date: Date | null,
        repeatAnnually: boolean,
        text: string | null,
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        let formData = new FormData();
        formData.append('professionalHoliday', JSON.stringify({
            name: name,
            orgUnits: orgUnits,
            type:  type,
            movingType: type === 'moving' ? movingType : null,
            date: date ? date.getFullYear() + '-' + (date.getMonth() + 1) + "-" + date.getDate() : null,
            repeatAnnually: repeatAnnually,
            text: text,
            visible: visible,
        }));

        fetch(PROFESSIONAL_HOLIDAYS, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Professional Holiday'
            name={ '' }
            orgUnitValues={ props.orgUnits }
            orgUnits={ [] }
            date={ null }
            repeatAnnually={ true }
            type={ 'fixed' }
            movingType={ {'month': '1', 'weekNumber': '1', 'dayOfWeek': 'Monday'} as SelectorData }
            text={ null }
            visible={ true }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateProfessionalHoliday;