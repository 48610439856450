import React, { FC, ReactElement, useEffect, useState } from 'react'

import { RequestFromApi } from '../../../../../shared/Calendar/Request/data/RequestFromApi'

import { CALENDAR, FILTERS } from '../../../../../utils/constants/api'
import { EMPLOYEE_PROFILE } from '../../../../../utils/constants/routes'
import { TypeIconsCollection } from '../../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection'

import { xcss, Box } from '@atlaskit/primitives'
import Avatar from '@atlaskit/avatar'
import SuitcaseIcon from '@atlaskit/icon/glyph/suitcase'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import MainTooltip from '../MainTooltip'
import FilterToggle from '../FilterToggle'

const AvatarWrapper: FC<{
  children: ReactElement
}> = styled.div`
  ${(props) => `
   
  & > div>span{
    width:54px;
    height:54px;
    svg{
      width:54px;
      height:54px;
    }
    margin:0px;
  }
  
  `}
`

type AbsentWidget = {
  setLoadingBarProgress: (progress: number) => void
  requestModal: (isOpen: boolean) => void
  employee: any
}

type Option = {
  label: string
  value: string
  company: string
}

function AbsentWidget(props: AbsentWidget) {
  const [approvedRequests, setApprovedRequests] = useState<RequestFromApi[]>([])
  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([])

  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<
    Option[]
  >([])

  const [inited, setInited] = useState(false)

  useEffect(() => {
    if (props.employee) {
      props.setLoadingBarProgress(50)

      fetch(
        CALENDAR +
          '/requests?start-date=' +
          getTodayDateString() +
          '&end-date=' +
          getTodayDateString(),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setApprovedRequests(data)
        })
        .finally(() => props.setLoadingBarProgress(100))

      fetch(FILTERS + '/calendar/org-units-by-employees')
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            return {}
          }
        })
        .then((data) => {
          if ('orgUnits' in data) {
            const orgUnits = Object.values(
              (data as { orgUnits: any }).orgUnits as {
                name: string
                id: string
                companyName: string
              }[]
            ).map((item) => {
              return {
                label: item.name,
                value: item.id,
                company: item.companyName,
              }
            })

            const selectedItems = orgUnits.filter((item) => {
              return item.value === props.employee.orgUnitId
            })
            const unselectedItems = orgUnits.filter((item) => {
              return !selectedItems.includes(item)
            })

            setOrgUnitOptions([...selectedItems, ...unselectedItems])
            setSelectedOrgUnitOptions(selectedItems)

            setInited(true)
          }
        })
    }
  }, [props.employee])

  function getTodayDateString() {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const day = today.getDate()

    return `${year}-${month}-${day}`
  }

  const approvedRequestsFiltered = inited
    ? approvedRequests.filter((item) => {
        return (
          selectedOrgUnitOptions.length === 0 ||
          selectedOrgUnitOptions.some((elm) => {
            return item.requesterOrgUnitId === elm.value
          })
        )
      })
    : []

  return (
    <Box
      xcss={xcss({
        marginTop: 'space.300',
        padding: 'space.200',
        backgroundColor: 'elevation.surface',
        color: 'color.text',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        border: '1px solid',
        borderColor: 'color.border.disabled',
        borderRadius: 'border.radius.050',
        minHeight: '96px',
      })}
    >
      <Box
        xcss={xcss({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        })}
      >
        <Box>
          <Box
            xcss={xcss({
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              color: 'color.text',
            })}
          >
            Skipping work today
          </Box>
        </Box>
        <FilterToggle
          onSelectClick={() => {
            const unselectedItems = orgUnitOptions.filter((item) => {
              return !selectedOrgUnitOptions.includes(item)
            })

            setOrgUnitOptions([...selectedOrgUnitOptions, ...unselectedItems])
          }}
          options={orgUnitOptions}
          onChange={(values) => {
            setSelectedOrgUnitOptions(values as Option[])
          }}
          value={selectedOrgUnitOptions}
        />
      </Box>
      <Box
        xcss={xcss({
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
        })}
      >
        {!(approvedRequestsFiltered.length > 0) ? (
          <Box
            xcss={xcss({
              color: 'color.text.disabled',
              display: 'flex',
              fontFamily: 'inherit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '20px',
              marginTop: '8px',
            })}
          >
            <SuitcaseIcon label="SuitcaseIcon" />
            <Box xcss={xcss({ marginLeft: 'space.100', marginTop: '2px' })}>
              Today everyone is at work. Must be some sort of productivity
              potion going around!
            </Box>
          </Box>
        ) : (
          <Box
            xcss={xcss({
              paddingTop: 'space.100',
              rowGap: 'space.200',
              display: 'flex',
              flexWrap: 'wrap',
            })}
          >
            {approvedRequestsFiltered.map((request, index) => (
              <Box
                key={request.requesterId}
                xcss={xcss({
                  paddingRight: 'space.200',
                })}
              >
                <Box
                  data-tooltip-id={'c_' + index + '-tooltip-key'}
                  xcss={xcss({
                    position: 'relative',
                    height: '54px',
                  })}
                >
                  <Link
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    to={EMPLOYEE_PROFILE + '/' + request.requesterId}
                  >
                    <AvatarWrapper>
                      <Avatar src={request.requesterAvatar} size="xlarge" />
                    </AvatarWrapper>
                  </Link>
                  <Box
                    xcss={xcss({
                      position: 'absolute',
                      inset: '36px 0 0 36px',
                    })}
                  >
                    {TypeIconsCollection[
                      request.typeIcon as keyof typeof TypeIconsCollection
                    ]('circle-icon')}
                  </Box>
                </Box>
                <MainTooltip
                  id={'c_' + index + '-tooltip-key'}
                  requesterName={request.requesterName}
                  requesterId={request.requesterId}
                  requesterAvatar={request.requesterAvatar}
                  typeIcon={request.typeIcon}
                  typeName={request.typeName}
                  startDate={request.startDate}
                  endDate={request.endDate}
                  substituteName={request.substituteName}
                  substituteId={request.substituteId}
                  substituteAvatar={request.substituteAvatar}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AbsentWidget
