import type {CityResponseProps, CityOptionsProps} from "./city.types";
import {COUNTRIES} from "../../../../constants";

const transformToADSSelectOptions = (options: Array<CityResponseProps>, country: string): Array<CityOptionsProps> => {
    return options.map((option) => {
        if(country === 'UA' && option.name === 'Odessa') {
            option.name = 'Odesa';
        }

        return {
            ...option,
            label: option.name,
            value: option.name
        }
    });
};

export const fetchCityAPI = async (country: string) => {
    try {
        const response = await fetch(`${COUNTRIES}/${country}/cities`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });

        const data = await response.json() as Array<CityResponseProps>;
        return transformToADSSelectOptions(data, country);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};