import React, { FC, useState } from "react";
import "./Employees.scss";
import { FormerEmployees } from "./Former/FormerEmployees";
import { CurrentEmployees } from "./Current/CurrentEmployees";
import { DeletedEmployees } from "./Deleted/DeletedEmployees";

export const Employees: FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleLiClick = (index: React.SetStateAction<number>) => {
    setActiveIndex(index);
    getContentComponent();
  };

  const getActiveClass = (index: number) => {
    return index === activeIndex ? "active" : "";
  };

  const getContentComponent = () => {
    switch (activeIndex) {
      case 0:
        return <CurrentEmployees />;
      case 1:
        return <FormerEmployees />;
      case 2:
        return <DeletedEmployees />;
      default:
        return null;
    }
  };
  return (
    <div className="sub-content-block add-employee d-flex">
      <div className="sub-content-header d-flex">
        <div className="sub-title-block d-flex">
          <h3>Employee list</h3>
        </div>
      </div>
      <div className="sub-content-main pad65">
        <ul className={"employee-menu"}>
          <li className={getActiveClass(0)} onClick={() => handleLiClick(0)}>
            Active Employees
          </li>
          <li className={getActiveClass(1)} onClick={() => handleLiClick(1)}>
            Inactive Employees
          </li>
          <li className={getActiveClass(2)} onClick={() => handleLiClick(2)}>
            Deleted Employees
          </li>
        </ul>
        <div className={"content"}>{getContentComponent()}</div>
      </div>
    </div>
  );
};
