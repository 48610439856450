import { useState, useEffect, useCallback, useDebugValue } from "react";
import { adminStatusAPI } from "API/common/status";
import {
  Status,
  StatusWithTransitions,
  Transition,
} from "interfaces/common/status.interface";
import { xcss, Box, Inline } from "@atlaskit/primitives";
import InlineEdit, { InlineEditableTextfield } from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import { OptionType } from "@atlaskit/select";
import { nanoid } from "@reduxjs/toolkit";
import styled from "styled-components";

type FetchStatusesWithTransitionsProps = {
  withExtraLine?: boolean;
  onExtralineConfirmed?: (name: string) => void;
};

export const findToTransition = (
  statuses: OptionType[] | null,
  transitions: Transition[]
) => {
  if (!statuses) {
    return "";
  }
  const transitionIds = transitions.map((transition) => transition.toStatusId);

  for (const status of statuses) {
    if (status && !transitionIds.includes(status.value.toString())) {
      return status.value.toString();
    }
  }

  return "";
};

export const findRemoveTransitionID = (
  statuses: OptionType[] | null,
  transitions: Transition[]
) => {
  if (!statuses) {
    return "";
  }
  const statusesIDs = statuses.map((transition) =>
    transition.statusTransitionId.toString()
  );

  for (const transition of transitions) {
    if (transition && !statusesIDs.includes(transition.id)) {
      return transition.id;
    }
  }

  return "";
};

const useFetchStatusesWithTransitions = ({
  withExtraLine,
  onExtralineConfirmed,
}: FetchStatusesWithTransitionsProps) => {
  const [statusWithTransitions, setStatusWithTransitions] = useState<
    StatusWithTransitions[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const addStatusID = "extraline";

  const fetchStatuses = useCallback(async () => {
    setLoading(true);
    try {
      const statusesResponse = await adminStatusAPI.getStatuses();
      if (!statusesResponse.ok) {
        throw new Error(`HTTP error! status: ${statusesResponse.status}`);
      }
      const statusesData: Status[] = await statusesResponse.json();

      const transitionsResponse = await adminStatusAPI.getStatusesTransitions();
      if (!transitionsResponse.ok) {
        throw new Error(`HTTP error! status: ${transitionsResponse.status}`);
      }
      const transitionsData: {
        id: string;
        fromStatusId: string;
        toStatusId: string;
      }[] = await transitionsResponse.json();

      const transitions = transitionsData.map((transition) => {
        const toStatus = statusesData.find(
          (status) => status.id === transition.toStatusId
        );
        return {
          id: transition.id,
          name: toStatus?.name || "Unknown",
          fromStatusId: transition.fromStatusId,
          toStatusId: transition.toStatusId,
        };
      });

      const combinedData = statusesData.map((status) => ({
        ...status,
        transitions: transitions.filter(
          (transition) => transition.fromStatusId === status.id
        ),
      }));

      const containerStyles = xcss({ position: "absolute" });

      const StyledBtnIcon = styled.div`
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: -8px !important;
        padding: 0 8px;
        cursor: pointer;
      `;

      if (withExtraLine && onExtralineConfirmed) {
        const extraLine: Status = {
          id: addStatusID,
          name: (
            <Box xcss={containerStyles}>
              <InlineEdit
                key={nanoid(5)}
                isRequired={true}
                defaultValue={""}
                editView={({ errorMessage, ...fieldProps }) => {
                  return (
                    <Textfield {...fieldProps} className="test" autoFocus />
                  );
                }}
                readView={() => (
                  <StyledBtnIcon>
                    <EditorAddIcon label="" />
                    Add status
                  </StyledBtnIcon>
                )}
                onConfirm={(value: string) => {
                  onExtralineConfirmed(value);
                }}
              />
            </Box>
          ),
          isActive: false,
        };

        setStatusWithTransitions([...combinedData, extraLine]);
      } else {
        setStatusWithTransitions(combinedData);
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  return {
    statusWithTransitions,
    setStatusWithTransitions,
    loading,
    error,
    fetchStatuses,
    addStatusID,
  };
};

export default useFetchStatusesWithTransitions;
