import { token } from "@atlaskit/tokens";
import { xcss } from "@atlaskit/primitives";
import styled from "styled-components";

export const UserBlock = xcss({
  height: "40px",
  paddingLeft: token("space.050"),
  gap: "space.100",
  alignItems: "center",
  color: "color.text.subtlest",
});

export const StyledUserBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;

  & > a {
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  & > span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;

export const DateBlock = xcss({
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  color: "color.text.subtlest",
  gap: "space.050",
});

export const StyledStatusBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 16px;

  & > span {
    padding: 0px ${token("space.050")};

    span {
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
`;

export const StyledActionsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${token("space.150")};

  [aria-roledescription="cancel"] {
    color: ${token("color.text.subtlest")};

    & > span {
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
    }
  }

  button svg {
    max-width: 18px;
    max-height: 18px;
  }
`;
