import {AccessLevelResponseProps, AccessLevelOptionsProps} from "./accessLevel.types";
import {EMPLOYEE, ACCESS_LEVELS} from "../../../../constants";

const transformToADSSelectOptions = (options: Array<AccessLevelResponseProps>): Array<AccessLevelOptionsProps> => {
    return options.map((option) => {
        return {
            ...option,
            label: option.name,
            value: option.id
        }
    });
};

export const fetchAccessLevelAPI = async (): Promise<Array<AccessLevelOptionsProps>> => {
    try {
        const response = await fetch(ACCESS_LEVELS, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        const data = await response.json() as Array<AccessLevelResponseProps>;
        return transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const fetchCurrentAccessLevelAPI = async (id: string): Promise<Array<AccessLevelOptionsProps>> => {
    try {
        const response = await fetch(`${EMPLOYEE}/${id}/access-levels`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        const data = await response.json() as Array<AccessLevelResponseProps>;
        return transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const addAccessLevelAPI = async (id: string, idsToAdd: Array<string>) => {
    try {
        return await fetch(EMPLOYEE + '/' + id + '/access-levels', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
            body: JSON.stringify({ accessLevelIds: idsToAdd })
        });
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const deleteAccessLevelAPI = async (id: string, idsToRemove: Array<string>) => {
    try {
        return await fetch(EMPLOYEE + '/' + id + '/access-levels', {
            method: 'DELETE',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({ accessLevelIds: idsToRemove })
        })
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};