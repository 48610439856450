import React, {useEffect, useState} from "react";
import { Radio } from "@atlaskit/radio";
import { Box } from "@atlaskit/primitives";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { token } from "@atlaskit/tokens";
import Textfield from "@atlaskit/textfield";
import {
  StyledWrapper,
  StyledLeftSideBig,
  StyledRightSide,
  StyledSettingsPreview,
  StyledList,
  StyledMessageError,
  StyledRuleAllocate,
  StyledRuleSelect, StyledErrorDialog, StyledWarningIcon, StyledMessage, BurnoutBlock,
} from "../StylesCreatePolicy";
import { TypeIconsCollection } from "../../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import AccrualRules from "./AccrualRules";
import { ErrorMessage, Field } from "@atlaskit/form";
import Select, { ValueType } from "@atlaskit/select";
import { AccrualsAndCarryoversStepProps } from "../../../../../interfaces/timeOffPolicy/Steps/accrualsAndCarryoversStepProps.interface";
import { Option } from "../../../../../interfaces/timeOffPolicy/Select/option.interface";
import InlineDialog from "@atlaskit/inline-dialog";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import {monthsOptions} from "../../../../../shared/TimeOffPolicy/constants/AccrualSchedule";

const StepAccruals: React.FC<AccrualsAndCarryoversStepProps> = ({
  policyName,
  policyDescription,
  policyIcon,
  companyPaidRegardlessOfWorkday,
  accrualSchedule,
  setAccrualSchedule,
  setCompanyPaidRegardlessOfWorkday,
  policyDesignatedDayType,
  setPolicyDesignatedDayType,
  balanceLimit,
  setBalanceLimit,
  proratedAtHireStart,
  setProratedAtHireStart,
  proratedAtHireEnd,
  setProratedAtHireEnd,
  accrualRules,
  setAccrualRules,
  burnoutAccrualRule,
  setBurnoutAccrualRule,
  activeBalance,
  setActiveBalance,
  burnout,
  setBurnout,
  ruleError,
  setRuleError,
  balanceError,
  setBalanceError,
  burnoutError,
  setBurnoutError,
  accrualRulesError,
  setAccrualRulesError,
  onValidate,
  companyPaidError,
  setCompanyPaidError,
  dayTypeError,
  setDayTypeError,
  accrualScheduleError,
  setAccrualScheduleError,
  burnoutRadioError,
  setBurnoutRadioError,
  balanceRadioError,
  setBalanceRadioError,
  accrualStartError,
  setAccrualStartError,
  accrualEndError,
  setAccrualEndError,
  statuses,
  proratedRadio,
  setProratedRadio
}) => {
  const options: Option[] = accrualRules.map((policy, index) => ({
    label: "Rule " + (index + 1),
    value: index.toString(),
  }));

  useEffect(() => {
    const hasAllocationDaysGreaterThanOne = accrualRules.some(rule => rule.allocationDays && rule.allocationDays > 1);

    if (hasAllocationDaysGreaterThanOne) {
      setProratedRadio(true);
    } else {
      setProratedRadio(false);
      setProratedAtHireStart(null);
      setProratedAtHireEnd(null);
    }
  }, [accrualRules]);

  return (
    <StyledWrapper>
      <StyledLeftSideBig>
        <Box>
          <h4>What type of policy is this?</h4>
          <Radio
            label="Unpaid"
            name="companyPaidRegardlessOfWorkday"
            isChecked={companyPaidRegardlessOfWorkday === false}
            onChange={() => {
              setCompanyPaidRegardlessOfWorkday(false);
              setCompanyPaidError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <p>
            This type of policy is used when the employee takes an unpaid leave
            or the company pays for this day only upon actual work, for example,
            "work from home"
          </p>
          <Radio
            label="Paid"
            name="companyPaidRegardlessOfWorkday"
            isChecked={companyPaidRegardlessOfWorkday === true}
            onChange={() => {
              setCompanyPaidRegardlessOfWorkday(true);
              setCompanyPaidError(null);
              setPolicyDesignatedDayType(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <p>
            This policy pays regardless if the employee worked that day, for
            example, "vacation" or "sick leave"
          </p>
          {companyPaidError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {companyPaidError}
            </StyledMessageError>
          )}
        </Box>

        <Box>
          <h4>Will there be a schedule for accrual in this policy?</h4>
          <Radio
            label="No, this policy has unlimited balance"
            name="accrualSchedule"
            isChecked={accrualSchedule === false}
            onChange={() => {
              setAccrualSchedule(false);
              setAccrualScheduleError(null);
              setBurnout(null);
              setBurnoutRadioError(null);
              setRuleError(false);
              setAccrualRules([]);
              setBurnoutAccrualRule(null);
              setActiveBalance(null);
              setBalanceRadioError(null);
              setBalanceLimit(null);
              setProratedAtHireEnd(null);
              setProratedAtHireStart(null);
              setPolicyDesignatedDayType(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <p>This type of policy has an unlimited number of days for use</p>
          <Radio
            label="Yes, the policy has regular accruals"
            name="accrualSchedule"
            isChecked={accrualSchedule === true}
            onChange={() => {
              setAccrualSchedule(true);
              setAccrualScheduleError(null);
            }}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
          />
          <p>
            This policy has a set number of accrual days. Only business days are
            counted as days of leave. Weekends and holidays do not count as used
            leave
          </p>
          {accrualScheduleError && (
            <StyledMessageError>
              <ErrorIcon
                size="small"
                primaryColor={token("color.icon.danger")}
                label=""
              />
              {accrualScheduleError}
            </StyledMessageError>
          )}
          {accrualSchedule && (
            <>
              <AccrualRules
                accrualRules={accrualRules}
                setAccrualRules={setAccrualRules}
                setRuleError={setRuleError}
                accrualRulesError={accrualRulesError}
                setAccrualRulesError={setAccrualRulesError}
                onValidate={onValidate}
                statuses={statuses}
                burnoutAccrualRule={burnoutAccrualRule}
                setBurnoutAccrualRule={setBurnoutAccrualRule}
              />
              {ruleError && (
                <StyledMessageError>
                  <ErrorIcon
                    size="small"
                    primaryColor={token("color.icon.danger")}
                    label=""
                  />
                  Please add rule
                </StyledMessageError>
              )}
            </>
          )}
        </Box>

        {accrualSchedule && companyPaidRegardlessOfWorkday === false &&<Box>
          <h4>What type of day is specified by this policy?</h4>
          <Radio
              label="Workday"
              isChecked={policyDesignatedDayType === "workDay"}
              onChange={() => {
                setPolicyDesignatedDayType("workDay");
                setDayTypeError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
          />
          <p>
            This type of policy is used when the company pays for this day only
            upon actual work, for example, "work from home"
          </p>
          <Radio
              label="Unpaid day off"
              isChecked={policyDesignatedDayType === "unpaidDayOff"}
              onChange={() => {
                setPolicyDesignatedDayType("unpaidDayOff");
                setDayTypeError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
          />
          {dayTypeError && (
              <StyledMessageError>
                <ErrorIcon
                    size="small"
                    primaryColor={token("color.icon.danger")}
                    label=""
                />
                {dayTypeError}
              </StyledMessageError>
          )}
        </Box>}

        {accrualSchedule && accrualRules.length > 0 && (
          <Box>
            <h4>Do accrued days burn off on the next accrual date?</h4>
            <Radio
              value="no"
              label="No, accruals keep going"
              isChecked={burnout === false}
              onChange={() => {
                setBurnout(false);
                setBurnoutRadioError(null);
                setBurnoutAccrualRule(null);
                setBurnoutError(false);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            <Radio
              value="yes"
              label="Yes"
              isChecked={burnout === true}
              onChange={() => {
                setBurnout(true);
                setBurnoutRadioError(null);
                setActiveBalance(null);
                if (accrualRules.length === 1) {
                  setBurnoutAccrualRule(0);
                }
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            {burnoutRadioError && (
              <StyledMessageError>
                <ErrorIcon
                  size="small"
                  primaryColor={token("color.icon.danger")}
                  label=""
                />
                {burnoutRadioError}
              </StyledMessageError>
            )}
            {burnout && accrualRules.length > 1 && (
                <BurnoutBlock>
                <StyledMessage>on date by the</StyledMessage>
                <StyledRuleSelect>
                  <Field<ValueType<Option>>
                    name="burnoutAccrualRule"
                  >
                    {({
                      fieldProps: {
                        id,
                        value: _value,
                        onChange: _onChange,
                        ...rest
                      },
                      error,
                    }) => (
                      <>
                        <Select<Option>
                          options={options}
                          maxMenuHeight={204}
                          placeholder="Choose a Rule"
                          onChange={(selectedOption) => {
                            setBurnoutAccrualRule(
                              selectedOption
                                ? parseInt(selectedOption.value)
                                : null
                            );
                            setBurnoutError(false);
                          }}
                          value={
                            options.find(
                              (option) =>
                                parseInt(option.value) === burnoutAccrualRule
                            ) || null
                          }
                          {...rest}
                        />
                        {burnoutError && (
                          <ErrorMessage>Please, choose the rule</ErrorMessage>
                        )}
                      </>
                    )}
                  </Field>
                </StyledRuleSelect>
                {burnoutAccrualRule != null && accrualRules[burnoutAccrualRule] && <div className={'burnout-description'}>
                  {accrualRules[burnoutAccrualRule].intervalType === 'daily' &&
                      <p> per each {accrualRules[burnoutAccrualRule].intervalCount} days</p>}


                  {accrualRules[burnoutAccrualRule].intervalType === 'monthly' &&
                      <p> every {accrualRules[burnoutAccrualRule].intervalCount} month(s) {accrualRules[burnoutAccrualRule].allocationDay != null && <>on {accrualRules[burnoutAccrualRule].allocationDay !== 0
                          ? accrualRules[burnoutAccrualRule].allocationDay + ' day'
                          : 'last day of month'}</>}
                      </p>
                  }

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'hire' &&
                      <p> per each year on hire date</p>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'birthday' &&
                      <p> per each year on birth date</p>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'status' &&
                      <p> per each year on status starts date, namely {statuses.find(
                          (option) => option.value === accrualRules[burnoutAccrualRule].allocationStatusId
                      )?.label || ''}</p>}

                  {accrualRules[burnoutAccrualRule].intervalType === 'yearly' && accrualRules[burnoutAccrualRule].yearlyAllocationDateType === 'custom' &&
                      <p> per each year on custom date, namely {
                          monthsOptions.find(
                              (option) => parseInt(option.value) === accrualRules[burnoutAccrualRule].allocationMonth
                          )?.label || ''
                      } on {accrualRules[burnoutAccrualRule].allocationDay !== 0
                          ? accrualRules[burnoutAccrualRule].allocationDay + ' day'
                          : 'last day of month'}
                      </p>}
                </div>}
                </BurnoutBlock>
            )}
          </Box>
        )}

        {accrualSchedule && burnout === false && (
          <Box>
            <h4>
              Should accruals be stopped, when the balance reaches a limit?
            </h4>
            <Radio
              value="no"
              label="No, accruals keep going"
              isChecked={activeBalance === false}
              onChange={() => {
                setActiveBalance(false);
                setBalanceRadioError(null);
                setBalanceLimit(null)
                setBalanceError(false);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            <Radio
              value="yes"
              label="Yes"
              name="policyType"
              isChecked={activeBalance === true}
              onChange={() => {
                setActiveBalance(true);
                setBalanceRadioError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            {balanceRadioError && (
              <StyledMessageError>
                <ErrorIcon
                  size="small"
                  primaryColor={token("color.icon.danger")}
                  label=""
                />
                {balanceRadioError}
              </StyledMessageError>
            )}
            {activeBalance && (
              <StyledRuleAllocate>
                Stop accrual when the balance reached{" "}
                <Textfield
                  placeholder=""
                  value={balanceLimit ? balanceLimit.toString() : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;

                    if (parseInt(newValue) <= 365 || newValue === '') {
                      setBalanceLimit(
                          newValue && newValue !== ""
                              ? parseInt(newValue, 10)
                              : null
                      );
                      setBalanceError(false);
                    }
                  }}
                />
                {balanceError && (
                    <StyledErrorDialog>
                      <InlineDialog
                          content={'Please enter balance limit'}
                          isOpen={balanceError}
                          placement={'right-end'}
                      >
                        <StyledWarningIcon>
                          <WarningIcon
                              label=""
                              primaryColor={token("color.icon.warning")}
                          />
                        </StyledWarningIcon>
                      </InlineDialog>
                    </StyledErrorDialog>
                )}
                business days
              </StyledRuleAllocate>
            )}
          </Box>
        )}

        {accrualSchedule && proratedRadio && (
          <Box>
            <h4>How does the first accrual occur?</h4>
            <Radio
              value="full"
              label="Full amount"
              isChecked={proratedAtHireStart === false}
              onChange={() => {
                setProratedAtHireStart(false);
                setAccrualStartError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            <Radio
              value="prorated"
              label="Prorated"
              isChecked={proratedAtHireStart === true}
              onChange={() => {
                setProratedAtHireStart(true);
                setAccrualStartError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            {accrualStartError && (
              <StyledMessageError>
                <ErrorIcon
                  size="small"
                  primaryColor={token("color.icon.danger")}
                  label=""
                />
                {accrualStartError}
              </StyledMessageError>
            )}
          </Box>
        )}

        {accrualSchedule && proratedRadio && (
          <Box>
            <h4>
              Should accrued days be recalculated when an employee becomes
              inactive (e.g., terminates employment)?
            </h4>
            <Radio
              value="no"
              label="No"
              isChecked={proratedAtHireEnd === false}
              onChange={() => {
                setProratedAtHireEnd(false);
                setAccrualEndError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            <Radio
              value="yes"
              label="Yes, recalculate prorated"
              isChecked={proratedAtHireEnd === true}
              onChange={() => {
                setProratedAtHireEnd(true);
                setAccrualEndError(null);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
            {accrualEndError && (
              <StyledMessageError>
                <ErrorIcon
                  size="small"
                  primaryColor={token("color.icon.danger")}
                  label=""
                />
                {accrualEndError}
              </StyledMessageError>
            )}
          </Box>
        )}
      </StyledLeftSideBig>

      <StyledRightSide>
        <h3>Policy settings preview</h3>

        <StyledSettingsPreview>
          <h4>Name</h4>
          <span>{policyName}</span>
        </StyledSettingsPreview>
        <StyledSettingsPreview>
          <h4>Icon</h4>
          {TypeIconsCollection[policyIcon as keyof typeof TypeIconsCollection](
            "small-icon",
            "withouthover"
          )}
        </StyledSettingsPreview>
        <StyledSettingsPreview>
          <h4>Description</h4>
          <p>{policyDescription}</p>
        </StyledSettingsPreview>
        <StyledList>
          <h4>Accruals & carryovers</h4>
          <ul>
            {companyPaidRegardlessOfWorkday != null &&
            <li>
              This policy is{" "}
              {companyPaidRegardlessOfWorkday ? "paid" : "unpaid"} by company
            </li>
            }
            {policyDesignatedDayType != null &&
                <li>
                  {policyDesignatedDayType === 'workDay' ? "Workday type" : "Unpaid day off type"} policy
                </li>
            }
            {accrualSchedule != null &&
            <li>
              This is {accrualSchedule ? "an accrual" : "a non-accrual"} policy
            </li>
            }
            {accrualRules.map((rule, index) => {
              let text = 'Employees will accrue ';

              const isValidDaily = rule.intervalType === 'daily' && rule.allocationDays && rule.intervalCount;
              const isValidMonthly = rule.intervalType === 'monthly' && rule.allocationDays && rule.intervalCount && rule.allocationDay !== null;
              const isValidYearlyHire = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'hire' && rule.allocationDays;
              const isValidYearlyBirthday = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'birthday' && rule.allocationDays;
              const isValidYearlyStatus = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'status' && rule.allocationDays && rule.allocationStatusId;
              const isValidYearlyCustom = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'custom' && rule.allocationDays && rule.allocationMonth && rule.allocationDay !== null;

              if (isValidDaily) {
                text += `${rule.allocationDays} business day(s) per each ${rule.intervalCount} day(s)`;
              } else if (isValidMonthly) {
                text += `${rule.allocationDays} business day(s) every ${rule.intervalCount} month(s) on ${rule.allocationDay !== 0
                    ? `${rule.allocationDay}${rule.allocationDay === 1 ? 'st' : 'th'} day`
                    : 'last day of month'
                }`;
              } else if (isValidYearlyHire) {
                text += `${rule.allocationDays} business day(s) per each year on hire date`;
              } else if (isValidYearlyBirthday) {
                text += `${rule.allocationDays} business day(s) per each year on birth date`;
              } else if (isValidYearlyStatus) {
                const statusLabel = statuses.find(
                    (option) => option.value === rule.allocationStatusId
                )?.label || '';
                text += `${rule.allocationDays} business day(s) per each year on status starts date, namely ${statusLabel}`;
              } else if (isValidYearlyCustom) {
                const monthLabel = monthsOptions.find(
                    (option) => parseInt(option.value) === rule.allocationMonth
                )?.label || '';
                text += `${rule.allocationDays} business day(s) per each year on custom date, namely ${monthLabel} on ${rule.allocationDay !== 0
                    ? `${rule.allocationDay}${rule.allocationDay === 1 ? 'st' : 'th'} day`
                    : 'last day of month'
                }`;
              } else {
                return null;
              }

              return (
                  <li style={{ color: 'var(--ds-text-subtlest)' }} key={index}>
                    {text}
                  </li>
              );
            })}

            {burnout === true && burnoutAccrualRule != null && accrualRules[burnoutAccrualRule] && (() => {
              const rule = accrualRules[burnoutAccrualRule];
              const isValidBurnoutDaily = rule.intervalType === 'daily' && rule.intervalCount;
              const isValidBurnoutMonthly = rule.intervalType === 'monthly' && rule.intervalCount && rule.allocationDay !== null;
              const isValidBurnoutYearlyHire = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'hire';
              const isValidBurnoutYearlyBirthday = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'birthday';
              const isValidBurnoutYearlyStatus = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'status' && rule.allocationStatusId;
              const isValidBurnoutYearlyCustom = rule.intervalType === 'yearly' && rule.yearlyAllocationDateType === 'custom' && rule.allocationMonth && rule.allocationDay !== null;

              if (isValidBurnoutDaily || isValidBurnoutMonthly || isValidBurnoutYearlyHire || isValidBurnoutYearlyBirthday || isValidBurnoutYearlyStatus || isValidBurnoutYearlyCustom) {
                return (
                    <li>
                      Accrued days burn off on date by the Rule №{burnoutAccrualRule + 1} (
                      {isValidBurnoutDaily && <>per each {rule.intervalCount} days</>}

                      {isValidBurnoutMonthly && <>every {rule.intervalCount} month(s) {rule.allocationDay != null && <>on {rule.allocationDay !== 0
                          ? rule.allocationDay + ' day'
                          : 'last day of month'}</>}
                      </>
                      }

                      {isValidBurnoutYearlyHire && <>per each year on hire date</>}

                      {isValidBurnoutYearlyBirthday && <>per each year on birth date</>}

                      {isValidBurnoutYearlyStatus && <>per each year on status starts date, namely {statuses.find(
                          (option) => option.value === rule.allocationStatusId
                      )?.label || ''}</>}

                      {isValidBurnoutYearlyCustom && <>per each year on custom date, namely {
                          monthsOptions.find(
                              (option) => parseInt(option.value) === rule.allocationMonth
                          )?.label || ''
                      } on {rule.allocationDay !== 0
                          ? rule.allocationDay + ' day'
                          : 'last day of month'}
                      </>}
                      )
                    </li>
                );
              }

              return null;
            })()}
            {burnout === false &&
                <li>Accrued days carry over from previous period</li>
            }
            {activeBalance != null && <>
              {!activeBalance &&
                  <li>
                    Accruals keep going regardless of the number of days on the balance
                  </li>
              }
              {balanceLimit != null &&
                  <li>
                    Accruals should be stopped when the balance reaches {balanceLimit ? balanceLimit : 0} business days
                  </li>
              }
            </>
            }
            {proratedAtHireStart != null &&
                <li>
                  The first accrual will be {!proratedAtHireStart ? "for the full amount" : "prorated"}
                </li>
            }
            {proratedAtHireEnd != null &&
                <li>
                  {!proratedAtHireEnd
                      ? 'The balance of this policy remains unchanged when an employee becomes inactive'
                      : 'The balance of this policy will be prorated when an employee becomes inactive'
                  }
                </li>
            }
          </ul>
        </StyledList>
      </StyledRightSide>
    </StyledWrapper>
  );
};

export default StepAccruals;
