import { FC, useEffect, useMemo, useState } from "react";
import style from "./AttendanceLog.module.scss";
import { head } from "./table/sample-data";
import DynamicTable from "@atlaskit/dynamic-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "../UI/Button";
import {
  FilterCalendar,
  defaultRangeAttendanceLog,
  DayRange,
} from "../UI/FilterCalendar";
import { Input } from "../UI/Input";
import ExportIcon from "@atlaskit/icon/glyph/export";
import { Lozenge } from "../UI/Lozenge";
import {
  useAttendanceLog,
  useAttendanceLogEditEvent,
} from "../../API/attendanceLog";
import { ProfileAvatar } from "../Profile/ProfileAvatar";
import { useSearchParams } from "react-router-dom";
import { MoreExport } from "./MoreExport";
import { ChangeBreak } from "./ChangeBreak";
import { ChangeTotal } from "./ChangeTotal";
import { assertEmployee, useEmployee } from "../../contexts/EmployeeContext";
import InlineEdit from "@atlaskit/inline-edit";
import { format, parseISO } from "date-fns";
import { formatTime } from "utils/helpers/DateTime/formatTime";
import { CorrectionRecords } from "interfaces/attendance/corrections.interface";
import MultiSelectCustom from "components/UI/MultiSelectCustom/MultiSelectCustom";
import getEmployeesWithOrgUnit from "API/attendanceLog/getEmployeeWithOrgUnit";
import { sortFilter } from "utils/helpers/sortFunc";
import { EmployeeFromApi } from "shared/Employee/data/EmployeeFromApi";
import { EmployeeSummary } from "./EmployeeSummary/EmployeeSummary";
import { EmployeeData } from "interfaces/attendance/employees.interface";
import { nanoid } from "nanoid";
import CorrectionPopup from "./CorrectionPopup";
import { NoResults } from "../UI/table";
import {
  validateTimeFormat,
  exCSV,
  exPDF,
  getDateRangeFromParams,
  generateSelectValuesAndDictionary,
  filterEmployeesByOrgUnits,
  getSelectedOrgUnitsFromParams,
  getOriginalTime,
  setSign,
  dayTypeToLozenge,
  updateURL,
} from "./helper";
import { StyledAttendanceLogPage } from "./AttendanceLogStyles";

interface SelectValue {
  value: string;
  label: string;
  company?: string;
  nickname?: string;
  email?: string;
  avatar?: string;
  disabled?: boolean;
}

interface Option {
  value: any;
  label: string;
  key?: string;
  company?: string;
  avatar?: string;
  email?: string;
  disabled?: boolean;
}

const dayTypeOpts = [
  { label: "Workday", value: "workday" },
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
  { label: "Day off", value: "day_off" },
  { label: "Unpaid day off", value: "unpaid_day_off" },
];

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

export const AttendanceLog: FC = () => {
  const { employee } = useEmployee();
  assertEmployee(employee);

  const { updateTime } = useAttendanceLogEditEvent();

  const [forceUpdate, setForceUpdate] = useState(0);

  const [isOpenChangeBreak, setOpenChangeBreak] = useState(false);
  const [isOpenChangeTotal, setOpenChangeTotal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = new URLSearchParams(window.location.search);
  const initialDateRange = getDateRangeFromParams(params);
  const [dateRange, setDateRange] = useState<DayRange>(initialDateRange);

  const { getAttendanceLog } = useAttendanceLog();

  const [table, setTable] = useState<Array<any>>([]);
  const [CSV, setCSV] = useState<Array<any>>([]);

  const [dataToChangeTotal, setDataToChangeTotal] =
    useState<Record<string, any>>();
  const [dataToChangeTime, setDataToChangeTime] =
    useState<Record<string, any>>();

  const [currentPage, setCurrentPage] = useState(1);

  const [orgUnitOptions, setOrgUnitOptions] = useState<SelectValue[]>([]);
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<
    SelectValue[]
  >([]);
  const [orgUnitLoaded, setOrgUnitLoaded] = useState(false);

  const [employees, setEmployees] = useState<EmployeeFromApi[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<SelectValue[]>([]);

  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState<
    SelectValue[]
  >([]);
  const [selectedDayTypes, setSelectedDayTypes] = useState<Option[]>([]);
  const [attendanceLogEmployees, setAttendanceLogEmmployees] =
    useState<EmployeeData>({});

  const employeesUrl = params.get("employees");
  const orgUnitsUrl = params.get("org-units");
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>(
    employeesUrl ? employeesUrl.split(",") : []
  );
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<string[]>(
    orgUnitsUrl ? orgUnitsUrl.split(",") : []
  );

  useEffect(() => {
    setOrgUnitLoaded(false);
    const dayTypesParam = searchParams.get("dayTypes");
    if (dayTypesParam) {
      const dayTypes = dayTypesParam.split(",").map((value) => {
        return dayTypeOpts.find((option) => option.value === value) as Option;
      });
      setSelectedDayTypes(dayTypes);
    }
    getEmployeesWithOrgUnit()
      .then((data) => {
        const { employeesSelect, orgUnitsSelect } =
          generateSelectValuesAndDictionary(data);
        setOrgUnitOptions(orgUnitsSelect);
        setEmployees(Object.values(data.employees));
        setEmployeeOptions(employeesSelect);
        const employeeFromURL = searchParams.get("employees");
        const employeeData: EmployeeFromApi[] = Object.values(data.employees);
        if (employeeFromURL) {
          const data2 = employeeFromURL.split(",").map((employeeId) => {
            const employee = employeeData.find((empl) => {
              return empl.id === employeeId;
            });
            if (!employee) {
              return null;
            }
            return {
              value: employee.id,
              email: employee.email,
              avatar: employee.avatar ?? undefined,
              label: `${employee.firstNameEn} ${employee.lastNameEn} ${
                employee.nickname ? `(${employee.nickname})` : ""
              }`,
            };
          });
          const filtedrData = data2.filter(
            (employee) => employee !== null
          ) as SelectValue[];
          setSelectedEmployees(filtedrData.map((employee) => employee?.value));
          setSelectedEmployeeOptions(
            filtedrData.filter((employee) => employee !== null) as SelectValue[]
          );
        } else {
          const orgUnitParam = searchParams.get("org-units");
          if (orgUnitParam) {
            const orgUnitIds = orgUnitParam.split(",");
            if (orgUnitIds.length !== 0) {
              const employeeIds = employeeData
                .filter((employee) => {
                  if (employee.orgUnitId) {
                    return orgUnitIds.includes(employee.orgUnitId);
                  }
                })
                .map((employee) => employee.id);
              setSelectedEmployees(employeeIds);
            }

            return;
          }

          setSelectedEmployees([employee.id]);
          setSelectedEmployeeOptions([
            {
              value: employee.id,
              email: employee.email,
              avatar: undefined,
              label: `${employee.firstNameEn} ${employee.lastNameEn} ${
                employee.nickname ? `(${employee.nickname})` : ""
              }`,
            },
          ]);
        }
        setForceUpdate(forceUpdate + 1);
      })
      .finally(() => {
        setOrgUnitLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (orgUnitOptions.length === 0) return;

    const initialSelectedOrgUnits = getSelectedOrgUnitsFromParams(
      params,
      orgUnitOptions
    );
    setSelectedOrgUnitOptions(initialSelectedOrgUnits);

    if (initialSelectedOrgUnits.length > 0) {
      const filteredEmployees = filterEmployeesByOrgUnits(
        employees,
        initialSelectedOrgUnits
      );
      setEmployeeOptions(filteredEmployees);
    }
  }, [orgUnitOptions, employees]);

  useEffect(() => {
    const orgUnitValues = selectedOrgUnitOptions.map((option) => option.value);
    setSelectedOrgUnits(orgUnitValues);
  }, [selectedOrgUnitOptions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange]);

  useEffect(() => {
    const employeesParam = searchParams.get("employees");
    const orgUnitsParam = searchParams.get("org-units");
    if (!orgUnitLoaded) {
      return;
    }
    const employeeIds =
      selectedEmployees.length > 0
        ? selectedEmployees.map((emp) => emp)
        : filterEmployeesByOrgUnits(employees, selectedOrgUnitOptions).map(
            (emp) => emp.value
          );
    if (employeeIds.length === 0 && (employeesParam || orgUnitsParam)) {
      return;
    }
    getAttendanceLog({
      employeeIds: employeeIds,
      dayTypes: selectedDayTypes.map((type) => type.value),
      startDate: `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`,
      endDate: `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`,
    }).then((res) => {
      setAttendanceLogEmmployees(res);

      const { arrToTable, arrToCSV } = getResponseArr(res);

      setTable(arrToTable);
      setCSV(arrToCSV);
    });
  }, [forceUpdate, dateRange, orgUnitLoaded]);

  const getResponseArr = (res: EmployeeData) => {
    const arrToTable: Array<any> = [];
    const arrToCSV: Array<any> = [];

    Object.keys(res).map((key: string) => {
      const emAttLog = res[key];
      const emAttLogKeys = Object.keys(emAttLog);

      for (let i = 0; i < emAttLogKeys.length; i++) {
        const emAttLogKey = emAttLogKeys[i];
        if (
          emAttLogKey === "expectedHours" ||
          emAttLogKey === "remainingHours" ||
          emAttLogKey === "workedHours"
        ) {
          continue;
        }

        const emDate = emAttLog[emAttLogKey];

        arrToTable.push({
          key: `row-${key}-${emAttLogKey}-${nanoid(5)}`,
          isHighlighted: false,
          cells: [
            {
              key: `${emDate.firstName + "_" + emDate.lastName}${key}${emAttLogKey}-${nanoid(5)}`,
              content: (
                <a
                  href={`/employee/${key}`}
                  target="_blank"
                  className={style.userLink}
                >
                  <div className={`${style.user} ${style.cellWidth1}`}>
                    <div className={style.userAvatar}>
                      <ProfileAvatar
                        size={5}
                        imgAvatar={emDate.avatar}
                        firstName={emDate.firstName}
                        lastName={emDate.lastName}
                      />
                    </div>
                    <div className={style.userName}>
                      <h3 className={style.userFullName}>
                        {[emDate.firstName, emDate.lastName].join(" ")}
                      </h3>
                      <span>{emDate.nickname}</span>
                    </div>
                  </div>
                </a>
              ),
            },
            {
              key: `${emAttLogKey}-${nanoid(5)}${key}`,
              content: (
                <div className={`${style.date} ${style.cellWidth2}`}>
                  {format(parseISO(emAttLogKey), "dd MMM, EEE")}
                </div>
              ),
            },
            {
              key: `${emDate.dayType}${key}${emAttLogKey}-${nanoid(5)}`,
              content: (
                <div className={`${style.dayType} ${style.cellWidth3}`}>
                  <Lozenge appearance={dayTypeToLozenge(emDate.dayType)}>
                    {emDate.dayType}
                  </Lozenge>
                </div>
              ),
            },
            {
              key: `${emDate.check_in ? emDate.check_in[0] : "-"}${key}${emAttLogKey}-${nanoid(5)}`,
              content:
                emDate.check_in && emDate.check_in.length ? (
                  <ul className={`${style.breakList} ${style.cellWidth4}`}>
                    {emDate.check_in.map(({ id, time }: any) => {
                      return (
                        <li className={style.color} key={id}>
                          <InlineEdit
                            defaultValue={time}
                            editView={(
                              { errorMessage, ...fieldProps },
                              ref
                            ) => <Input {...fieldProps} ref={ref} />}
                            readView={() => formatTime(time)}
                            validate={validateTimeFormat}
                            onConfirm={(value) => {
                              updateTime({
                                eventId: id,
                                time: value,
                              }).then(() => {
                                setForceUpdate(forceUpdate + 1);
                              });
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  "-"
                ),
            },
            {
              key: `${emDate.check_out ? emDate.check_out[0] : "-"}${key}${emAttLogKey}-${nanoid(5)}`,
              content:
                emDate.check_out && emDate.check_out.length ? (
                  <ul className={`${style.breakList} ${style.cellWidth5}`}>
                    {emDate.check_out.map(({ id, time }: any) => {
                      return (
                        <li className={style.color} key={id}>
                          <InlineEdit
                            defaultValue={time}
                            editView={(
                              { errorMessage, ...fieldProps },
                              ref
                            ) => <Input {...fieldProps} ref={ref} />}
                            readView={() => formatTime(time)}
                            onConfirm={(value) => {
                              updateTime({
                                eventId: id,
                                time: value,
                              }).then(() => {
                                setForceUpdate(forceUpdate + 1);
                              });
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  "-"
                ),
            },
            {
              key: `${emDate.break ? emDate.break[0] : "-"}${key}${emAttLogKey}-${nanoid(5)}`,
              content:
                emDate.break && emDate.break.length ? (
                  <ul className={`${style.breakList} ${style.cellWidth6}`}>
                    {emDate.break.map(({ start, end }: any, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setDataToChangeTime({
                              start: start,
                              end: end,
                            });
                            setOpenChangeBreak(true);
                          }}
                        >
                          {formatTime(start.time)} - {formatTime(end.time)}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  "-"
                ),
            },
            {
              key: `${getOriginalTime(emDate)}${key}${emAttLogKey}-${nanoid(5)}`,
              content: (
                <div className={`${style.difference} ${style.cellWidth7} `}>
                  <div
                    className={
                      emDate.difference != "00:00"
                        ? style.colorWarning
                        : style.color
                    }
                    onClick={() => {
                      setDataToChangeTotal({
                        idEmployee: key,
                        originalTime: emDate.originalTime,
                        date: emAttLogKey,
                        forceUpdate: forceUpdate,
                        setForceUpdate: setForceUpdate,
                      });
                      setOpenChangeTotal(true);
                    }}
                  >
                    {getOriginalTime(emDate)}
                  </div>
                  {emDate.correction && (
                    <CorrectionPopup
                      originalTime={getOriginalTime(emDate)}
                      corrections={emDate.correction as CorrectionRecords}
                    />
                  )}
                </div>
              ),
            },
            {
              key: `${emDate.difference}${key}${emAttLogKey}-${nanoid(5)}`,
              content: (
                <div className={style.differenceBlock}>
                  <div className={`${style.color} ${style.cellWidth8}`}>
                    {emDate.difference === "00:00"
                      ? "-"
                      : setSign(getOriginalTime(emDate), emDate.workSchedule) +
                        formatTime(emDate.difference)}
                  </div>
                </div>
              ),
            },
          ],
        });

        arrToCSV.push({
          Employee: emDate.firstName.concat(" ", emDate.lastName),
          Date: format(parseISO(emAttLogKey), "dd MMM, EEE"),
          "Day type": emDate.dayType,
          "Check in": emDate.check_in
            ? emDate.check_in
                .map(({ time }: any) => formatTime(time))
                .join("\n")
            : "-",
          "Check out": emDate.check_out
            ? emDate.check_out
                .map(({ time }: any) => formatTime(time))
                .join("\n")
            : "-",
          Break:
            emDate.break && emDate.break.length
              ? emDate.break
                  .map(({ start, end }: any) => {
                    return `${formatTime(start.time)} - ${formatTime(end.time)}`;
                  })
                  .join("\n")
              : "-",
          Total: getOriginalTime(emDate),
          Difference:
            emDate.difference === "00:00"
              ? "-"
              : setSign(getOriginalTime(emDate), emDate.workSchedule) +
                formatTime(emDate.difference),
        });
      }
    });

    return { arrToTable, arrToCSV };
  };

  function changeOrgUnitFilter(
    orgUnits: SelectValue[],
    employees: EmployeeFromApi[]
  ) {
    let employeesFromUrl: SelectValue[] = [];

    const employeesParams = searchParams.get("employees");

    let employeeOptionsArr = employees
      .map((employee) => {
        let orgs = orgUnits;
        if (orgs.length === 0) {
          orgs = orgUnitOptions;
        }

        const isOrgUnitExist = orgs.some((orgUnit) => {
          return (
            orgUnit.value === employee.orgUnitId ||
            (orgUnit.value === "null" && employee.orgUnitId === null)
          );
        });
        if (isOrgUnitExist) {
          return {
            value: employee.id,
            email: employee.email,
            avatar: employee.avatar,
            label:
              employee.firstNameEn +
              " " +
              employee.lastNameEn +
              (employee.nickname ? " (" + employee.nickname + ")" : ""),
          };
        } else {
          return {
            value: null,
            label: null,
          };
        }
      })
      .filter((employee) => employee.value !== null && employee.label !== null);

    let actualEmployeesFromURL: string[] = [];
    if (employeesParams) {
      const employeeIds = employeesParams.split(",");
      employeeIds.forEach((item) => {
        const option = employeeOptionsArr.find(
          (employee) => employee.value === item
        );
        if (option) {
          if (option.value && option.label) {
            actualEmployeesFromURL.push(option.value);
            employeesFromUrl.push(option as SelectValue);
          }
        }
      });
    }

    if (employeesFromUrl.length > 0 && employeesParams) {
      const employeeIds = actualEmployeesFromURL.join(",").split(",");
      setSelectedEmployees(employeeIds);
    } else {
      const filteredEmployeeOptions = filterEmployeesByOrgUnits(
        employees,
        orgUnits
      );
      const employeeValues = filteredEmployeeOptions.map((emp) => emp.value);
      setSelectedEmployees(employeeValues);
    }

    setSelectedOrgUnitOptions(orgUnits);
    setEmployeeOptions(employeeOptionsArr as SelectValue[]);
    setSelectedEmployeeOptions(
      employeesFromUrl.length > 0 ? employeesFromUrl : []
    );
  }
  const onPageChange = (page: any) => {
    setCurrentPage(page);
  };

  const changeEmployeeFilter = (values: SelectValue[]) => {
    setSelectedEmployeeOptions(values);
    setSelectedEmployees(values.map((option) => option.value));
  };

  const firstInList = useMemo(() => {
    return employeeOptions
      ? (employeeOptions.find((item) => item.value === employee.id) as Option)
      : null;
  }, [employeeOptions, employee.id]);

  const filteredList = useMemo(() => {
    return employeeOptions
      ? (employeeOptions.filter(
          (item) => item.value !== employee.id
        ) as Option[])
      : [];
  }, [employeeOptions, employee.id]);

  const employeeOptionsFiltered = firstInList
    ? [firstInList, ...filteredList]
    : filteredList;

  return (
    <StyledAttendanceLogPage>
      <ChangeBreak
        dataTime={dataToChangeTime}
        isOpen={isOpenChangeBreak}
        closeModal={() => {
          setOpenChangeBreak(false);
        }}
        updateTime={updateTime}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        breakTimeToChange={dataToChangeTime}
      />
      <ChangeTotal
        isOpen={isOpenChangeTotal}
        closeModal={() => {
          setOpenChangeTotal(false);
        }}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        dataToChangeTotal={dataToChangeTotal}
      />
      <div className={style.header}>
        <div className={style.headerLeft}>
          <h1 className={style.pageTitle}>Attendance log</h1>

          <div>
            <FilterCalendar
              setDateRange={(val) => {
                setDateRange(val);
              }}
              defaultRange={{
                range: dateRange,
                name: defaultRangeAttendanceLog.name,
              }}
              onClose={() => {
                updateURL.date(setSearchParams, dateRange);
              }}
            />
          </div>
          <div className={style.filter}>
            <MultiSelectCustom
              onSelectClick={() => {
                setOrgUnitOptions((val) =>
                  sortFilter(val, selectedOrgUnitOptions)
                );
              }}
              hasSelectAll={false}
              options={orgUnitOptions}
              selectWidth={160}
              selectHeight={28}
              dropdownHorizontalDisplacement="0"
              onChange={(values) => {
                changeOrgUnitFilter(values, employees);
                setForceUpdate(forceUpdate + 1);
                setCurrentPage(1);
                updateURL.orgUnits(setSearchParams, values, orgUnitLoaded);
              }}
              value={selectedOrgUnitOptions}
              placeholder={"Org Unit"}
              labelledBy={"All Org Units"}
            />
            <MultiSelectCustom
              contentWidth={300}
              selectHeight={28}
              selectWidth={160}
              dropdownHorizontalDisplacement="0"
              onSelectClick={() => {
                if (selectedEmployeeOptions.length !== 0) {
                  setEmployeeOptions((val) =>
                    sortFilter(val, selectedEmployeeOptions)
                  );
                }
              }}
              hasSelectAll={false}
              options={employeeOptionsFiltered}
              onChange={(values) => {
                changeEmployeeFilter(values);
                setForceUpdate(forceUpdate + 1);
                setCurrentPage(1);
                updateURL.employees(setSearchParams, values, orgUnitLoaded);
              }}
              value={selectedEmployeeOptions}
              placeholder={"Employee"}
              labelledBy={"All employees"}
            />
            <MultiSelectCustom
              selectHeight={28}
              selectWidth={160}
              contentWidth={160}
              dropdownHorizontalDisplacement="0"
              onSelectClick={() => {
                setSelectedDayTypes((val) => sortFilter(val, selectedDayTypes));
              }}
              hasSelectAll={false}
              options={dayTypeOpts}
              onChange={(values) => {
                setSelectedDayTypes(values);
                setForceUpdate(forceUpdate + 1);
                updateURL.dayType(setSearchParams, values);
              }}
              value={selectedDayTypes}
              permanentPlaceholder={true}
              placeholder={"Day type"}
              labelledBy={"Day type"}
            />

            <div className={style.exportBtn}>
              <Button
                isDisabled={table.length === 0}
                className={style.exportFile}
                onClick={() => exPDF(CSV)}
                iconBefore={<ExportIcon label="Export file" />}
              >
                <p>Export file</p>
              </Button>
              <MoreExport
                isDisabled={table.length === 0}
                exportPDF={() => exPDF(CSV)}
                exportCSV={() => exCSV(CSV)}
              />
            </div>
          </div>
        </div>
        <EmployeeSummary
          show={selectedEmployees.length === 1}
          dateRange={dateRange}
          employee={attendanceLogEmployees}
        />
      </div>
      {table.length > 0 ? (
        <div className={style.dynamicTable}>
          <DynamicTable
            defaultSortKey="Date"
            isFixedSize
            defaultSortOrder="DESC"
            head={head}
            rows={table}
            rowsPerPage={12}
            defaultPage={1}
            loadingSpinnerSize="large"
            page={currentPage}
            onSetPage={onPageChange}
            isRankable
          />
        </div>
      ) : (
        <NoResults />
      )}
    </StyledAttendanceLogPage>
  );
};
