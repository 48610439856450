import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { EMPLOYEE_PROFILE } from "../../../../../../utils/constants/routes";

import { EmployeesForEmployeeStatusWidget } from "../../../../../../shared/Main/EmployeesForEmployeeStatusWidget/data/EmployeesForEmployeeStatusWidget";

import { EMPLOYEES, FILTERS } from "../../../../../../utils/constants/api";

import { xcss, Box } from "@atlaskit/primitives";
import Avatar from "@atlaskit/avatar";
import OfficeBuildingIcon from "@atlaskit/icon/glyph/office-building";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CustomCarousel from "../../CustomCarousel";
import Tooltip from "@atlaskit/tooltip";
import FilterToggle from "../../FilterToggle";

const AvatarWrapper: FC<{
  children: ReactElement;
}> = styled.div`
  ${(props) => `
   
  & > div>span{

    svg{
    
    }
    margin:0px;
  }
  
  `}
`;

type Option = {
  label: string;
  value: string;
  company?: string;
};

function NewbiesWidget({ employee }: any) {
  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([]);

  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<
    Option[]
  >([]);

  const [newbies, setNewbies] = useState<EmployeesForEmployeeStatusWidget[]>(
    []
  );
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (employee) {
      fetch(EMPLOYEES + "/newbies-widget", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setNewbies(data);
        });

      fetch(FILTERS + "/calendar/org-units-by-employees")
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return {};
          }
        })
        .then((data) => {
          if ("orgUnits" in data) {
            const companies = Object.values(
              (data as { orgUnits: any }).orgUnits as {
                name: string;
                id: string;
                companyName: string;
              }[]
            ).map((item) => {
              return {
                label: item.companyName,
                value: item.companyName,
              };
            });

            const uniqCompanies: {
              [key: string]: any;
            } = {};

            companies.forEach((elm) => {
              if (elm.label) {
                uniqCompanies[elm.label] = elm;
              }
            });

            const selectedItems = Object.values(uniqCompanies).filter(
              (item) => {
                return item.value === employee.companyName;
              }
            );
            const unselectedItems = Object.values(uniqCompanies).filter(
              (item) => {
                return !selectedItems.includes(item);
              }
            );

            setOrgUnitOptions([...selectedItems, ...unselectedItems]);
            setSelectedOrgUnitOptions(selectedItems);
            setInited(true);
          }
        });
    }
  }, [employee]);

  const newbiesFiltered = inited
    ? newbies.filter((item) => {
        return (
          selectedOrgUnitOptions.length === 0 ||
          selectedOrgUnitOptions.some((elm) => {
            return item.companyName === elm.value;
          })
        );
      })
    : [];

  return (
    <Box
      xcss={xcss({
        marginTop: "space.300",
        padding: "space.200",
        backgroundColor: "elevation.surface",
        color: "color.text",
        fontFamily: "inherit",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        border: "1px solid",
        borderColor: "color.border.disabled",
        borderRadius: "border.radius.050",
        minHeight: "96px",
      })}
    >
      <Box
        xcss={xcss({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        })}
      >
        <Box>
          <Box
            xcss={xcss({
              fontFamily: "inherit",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
              color: "color.text",
            })}
          >
            New employees
          </Box>
        </Box>
        <FilterToggle
          onSelectClick={() => {
            const unselectedItems = orgUnitOptions.filter((item) => {
              return !selectedOrgUnitOptions.includes(item);
            });

            setOrgUnitOptions([...selectedOrgUnitOptions, ...unselectedItems]);
          }}
          options={orgUnitOptions}
          onChange={(values) => {
            setSelectedOrgUnitOptions(values as Option[]);
          }}
          value={selectedOrgUnitOptions}
        />
      </Box>

      <Box xcss={xcss({ marginTop: "space.100" })}>
        {" "}
        {!(newbiesFiltered.length > 0) ? (
          <Box
            xcss={xcss({
              color: "color.text.disabled",
              display: "flex",
              fontFamily: "inherit",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
              marginTop: "8px",
            })}
          >
            <OfficeBuildingIcon label="OfficeBuildingIcon" />
            <Box xcss={xcss({ marginLeft: "space.100", marginTop: "2px" })}>
              No new team members yet. Stay tuned for any changes!
            </Box>
          </Box>
        ) : (
          <CustomCarousel itemWidth={300}>
            {newbiesFiltered.map((request, index) => (
              <Box
                key={request.id}
                xcss={xcss({
                  paddingTop: "space.200",
                  paddingBottom: "space.200",
                  paddingRight: "space.200",
                  paddingLeft: "space.200",
                  width: "284px",
                  height: "128px",
                  overflow: "hidden",
                  border: "1px solid red",
                  borderRadius: "border.radius.100",
                  borderColor: "color.border.disabled",
                  display: "flex",
                })}
              >
                <Box
                  xcss={xcss({
                    position: "relative",
                    marginRight: "space.200",
                  })}
                >
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to={EMPLOYEE_PROFILE + "/" + request.id}
                  >
                    <AvatarWrapper>
                      <Avatar src={request.avatar} size="xlarge" />
                    </AvatarWrapper>
                  </Link>
                </Box>
                <Box>
                  <Box
                    xcss={xcss({
                      color: "color.text",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    })}
                  >
                    <Link
                      style={{ color: "inherit" }}
                      to={EMPLOYEE_PROFILE + "/" + request.id}
                    >
                      <Tooltip
                        position={"bottom"}
                        content={request.firstNameEn + " " + request.lastNameEn}
                      >
                        <Box
                          xcss={xcss({
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "140px",
                            height: "20px",
                            whiteSpace: "nowrap",
                          })}
                        >
                          {request.firstNameEn} {request.lastNameEn}
                        </Box>{" "}
                      </Tooltip>
                    </Link>
                  </Box>
                  <Box
                    xcss={xcss({
                      color: "color.text.subtlest",

                      fontFamily: "inherit",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px",
                    })}
                  >
                    {request.nickname}
                  </Box>
                  <Box
                    xcss={xcss({
                      color: "color.text.subtle",
                      fontFamily: "inherit",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "22px",
                      marginTop: "4px",
                    })}
                  >
                    <Tooltip position={"bottom"} content={request.companyName}>
                      {(tooltipProps) => (
                        <Box
                          {...tooltipProps}
                          xcss={xcss({
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "146px",
                          })}
                        >
                          {request.companyName}
                        </Box>
                      )}
                    </Tooltip>
                  </Box>
                  <Box
                    xcss={xcss({
                      color: "color.text.subtlest",
                      fontFamily: "inherit",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px",
                    })}
                  >
                    <Tooltip position={"bottom"} content={request.jobTitle}>
                      {(tooltipProps) => (
                        <Box
                          {...tooltipProps}
                          xcss={xcss({
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "146px",
                          })}
                        >
                          {request.jobTitle}
                        </Box>
                      )}
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            ))}
          </CustomCarousel>
        )}
      </Box>
    </Box>
  );
}

export default NewbiesWidget;
