import { LOCATIONS } from "utils/constants/api";
import { baseHeaders } from '../constants' 

export const locationsAPI = {  
  getLocations() {
    return fetch(LOCATIONS, {
      method: "GET",
      headers: baseHeaders,
    });
  },
};
