import {FC, useEffect, useState} from "react";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import styleParent from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Input} from "../../../../UI/Input";
import {InputText} from "../../../ViewingModes/InputText";
import {BlockInfoItem} from "../../../BlockInfo";
import {useProfile} from "../../../context";
import {ContactTypeProps} from "../../../../../API/profile/contactInfo";
import {useSetField} from "../../../../../API/profile/setField/useSetField";
import {Button} from "../../../../UI/Button";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import {IconButton} from "@atlaskit/button/new";
import {Tooltip} from '../../../../UI/Tooltip';

import {ReactComponent as EditorRemoveIconNew} from '../../../../../assets/images/ProfileIcons/trash.svg';
import StarIcon from '@atlaskit/icon/glyph/star';

import {useContactInfo} from "../../../../../API/profile/fields/generalInfo/contactInfo";
import Spinner from '@atlaskit/spinner';
import {formatPhoneNumber} from "../../../../../utils/helpers/EmployeeProfile/formatPhoneNumber";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";

export const PhoneNumber: FC = () => {
    const {contactInfo, isAccessEdit, idOpenProfile, reFetchContactInfo, showErrorNotify} = useProfile();
    const [mainPhone, setMainPhone] = useState<ContactTypeProps>();
    const [otherPhones, setOtherPhones] = useState<Array<ContactTypeProps>>([]);


    const [mainErrorStateServ, setMainErrorStateServ] = useState<{
        clearError?: () => void | undefined;
        error?: string | undefined;
        valueBeforeError: string;
    }>({
        clearError: undefined,
        error: undefined,
        valueBeforeError: ''
    });
    const [otherErrorStateServ, setOtherErrorStateServ] = useState<{
        clearError?: () => void | undefined;
        error?: string | undefined;
        valueBeforeError: string;
    }[]>([]);


    const {setField} = useSetField(idOpenProfile, true);
    const {setField: setFavorite, isLoading: isFavoriteLoading} = useSetField(idOpenProfile, true);
    const {addContact, removeContact, isRemoveLoading} = useContactInfo(idOpenProfile);

    const addPhoneNumber = () => {
        const newPhones = [...otherPhones];
        const allFields = mainPhone ? [mainPhone, ...otherPhones] : otherPhones;
        const existingFields = allFields.map((phone) => parseInt(phone.field));
        const missingFields = [1, 2, 3, 4, 5].filter(field => !existingFields.includes(field));

        const newField = missingFields[0];
        newPhones.push({ field: newField.toString(), value: '', isFavorite: '0' });
        setOtherPhones(newPhones);
    }

    useEffect(() => {
        const phones = contactInfo.find(element => element.type === 'phone');

        if(!phones) {
            setMainPhone({
                field: '1',
                value: '',
                isFavorite: '0'
            });
            return;
        }

        const phonesValues = [...phones.values];

        const mainPhone = phonesValues.shift()

        setMainPhone(mainPhone ? mainPhone : {
            field: '1',
            value: '',
            isFavorite: '0'
        });
        setOtherPhones(phonesValues);
    }, [contactInfo]);

    return(
        <BlockInfoItem title="Phone number">
            <div className={`${style.otherPhones} ${style.firstOtherPhones}`}>
                <div
                    className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
                    <InlineEdit
                        {...(!isAccessEdit && {isEditing: false})}
                        {...(mainErrorStateServ.error && {isEditing: true})}
                        defaultValue={mainErrorStateServ.valueBeforeError ? mainErrorStateServ.valueBeforeError : formatPhoneNumber(mainPhone?.value || '') || '+'}
                        editView={({errorMessage, onChange, ...fieldProps}, ref) => (
                            <Input
                                {...fieldProps}
                                onChange={(event) => {
                                    event.currentTarget.value = formatPhoneNumber(event.currentTarget.value);
                                    onChange(event);
                                }}
                                ref={ref}
                                autoFocus={!mainErrorStateServ.error}
                                isInvalid={!!errorMessage || !!mainErrorStateServ.error}
                                autoComplete="off"
                                placeholder=""
                                className={style.profileInput}
                            />
                        )}
                        readView={() => (<InputText value={formatPhoneNumber(mainPhone?.value || '') || ''}/>)}
                        onConfirm={(valueInput: string) => {
                            mainErrorStateServ.clearError && mainErrorStateServ.clearError();
                            setMainErrorStateServ({
                                error: undefined,
                                valueBeforeError: '',
                                clearError: undefined
                            });
                            const value = valueInput;

                            if (!value || value === '+' || value === mainPhone?.value) {
                                return;
                            }

                            if (!mainPhone?.value || mainPhone?.value === '+') {
                                addContact(['contact-info/phone', mainPhone?.field || '1', value].join('/')).then(() => {
                                    reFetchContactInfo();
                                }).catch((error) => {
                                    setMainErrorStateServ({
                                        clearError: showErrorNotify(String(error.message)),
                                        error: String(error.message),
                                        valueBeforeError: value,
                                    });
                                });
                            } else {
                                setField(['contact-info/phone', mainPhone?.field || '1', value].join('/')).then(() => {
                                    reFetchContactInfo();
                                }).catch((error) => {
                                    setMainErrorStateServ({
                                        clearError: showErrorNotify(String(error.message)),
                                        error: String(error.message),
                                        valueBeforeError: value,
                                    });
                                });
                            }
                        }}
                    />
                </div>
                {isAccessEdit && <div style={{
                    //@ts-ignore
                    '--ds-text': '#44546F',
                    '--ds-background-selected': 'transparent',
                    '--ds-text-selected': '#0C66E4'
                }}>
                    <Tooltip position="bottom" delay={0}
                             content={Boolean(Number(mainPhone?.isFavorite)) ? 'Hide in "Contact me"' : 'Show in "Contact me"'}>
                        {(tooltipProps) => (
                            <IconButton
                                {...tooltipProps}
                                onClick={() => {
                                    setFavorite(['contact-info/phone', mainPhone?.field || 1, 'favorite', Number(!Boolean(Number(mainPhone?.isFavorite)))].join('/'))
                                        .then(() => {
                                            reFetchContactInfo();
                                        });

                                    //employee/{employeeId}/contact-info/{type}/{field}/favorite/{isFavorite}
                                }}
                                isSelected={Boolean(Number(mainPhone?.isFavorite))}
                                appearance="subtle"
                                shape="default"
                                icon={Boolean(Number(mainPhone?.isFavorite)) ? StarFilledIcon : StarIcon}
                                label={Boolean(Number(mainPhone?.isFavorite)) ? "Remove Favorite" : "Add Favorite"}
                                overlay={isFavoriteLoading ? <Spinner size="medium"/> : null}
                            />
                        )}
                    </Tooltip>
                </div>}
            </div>
            {otherPhones.length > 0 && otherPhones.map(({value, isFavorite, field}, index) => {
                const isFavoriteBoolean = Boolean(Number(isFavorite));

                return (
                    <div className={style.otherPhones} key={field}>
                        <div
                            className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
                            <InlineEdit
                                {...(!isAccessEdit && {isEditing: false})}
                                {...(otherErrorStateServ[Number(field)]?.error && {isEditing: true})}
                                defaultValue={otherErrorStateServ[Number(field)]?.error ? otherErrorStateServ[Number(field)]?.valueBeforeError : formatPhoneNumber(value || '') || '+'}
                                editView={({errorMessage, onChange, ...fieldProps}, ref) => (
                                    <Input
                                        {...fieldProps}
                                        onChange={(event) => {
                                            event.currentTarget.value = formatPhoneNumber(event.currentTarget.value);
                                            onChange(event);
                                        }}
                                        ref={ref}
                                        isInvalid={!!errorMessage || !!otherErrorStateServ[Number(field)]?.error}
                                        autoComplete="off"
                                        autoFocus={!otherErrorStateServ[Number(field)]?.error}
                                        placeholder=""
                                        className={style.profileInput}
                                    />
                                )}
                                readView={() => (<InputText value={formatPhoneNumber(value)}/>)}
                                onConfirm={(valueInput: string) => {
                                    const newOtherErrorStateServ = [...otherErrorStateServ];
                                    // @ts-ignore
                                    newOtherErrorStateServ[Number(field)]?.clearError && newOtherErrorStateServ[Number(field)].clearError();
                                    newOtherErrorStateServ[Number(field)] = {
                                        clearError: undefined,
                                        error: undefined,
                                        valueBeforeError: ''
                                    };
                                    setOtherErrorStateServ(newOtherErrorStateServ);
                                    const value = valueInput;

                                    if (!value || value === '+' || value === otherPhones[index].value) {
                                        return;
                                    }

                                    if(!otherPhones[index].value || otherPhones[index].value === '+') {
                                        addContact(['contact-info/phone', field, value || 'null'].join('/')).then(() => {
                                            reFetchContactInfo();
                                        }).catch((error) => {
                                            const newOtherErrorStateServ = [...otherErrorStateServ];
                                            newOtherErrorStateServ[Number(field)] = {
                                                clearError: showErrorNotify(String(error.message)),
                                                error: String(error.message),
                                                valueBeforeError: value
                                            };
                                            setOtherErrorStateServ(newOtherErrorStateServ);
                                        });
                                    } else {
                                        setField(['contact-info/phone', field, value || 'null'].join('/')).then(() => {
                                            reFetchContactInfo();
                                        }).catch((error) => {
                                            const newOtherErrorStateServ = [...otherErrorStateServ];
                                            newOtherErrorStateServ[Number(field)] = {
                                                clearError: showErrorNotify(String(error.message)),
                                                error: String(error.message),
                                                valueBeforeError: value
                                            };
                                            setOtherErrorStateServ(newOtherErrorStateServ);
                                        });
                                    }
                                }}
                            />
                        </div>
                        {isAccessEdit && <div className={style.contactBtnGroup}>
                            <div>
                                <Tooltip position="bottom" delay={0} content={isFavoriteBoolean ? 'Hide in "Contact me"' : 'Show in "Contact me"'}>
                                    {(tooltipProps) => (
                                        <IconButton
                                            {...tooltipProps}
                                            onClick={() => {
                                                setFavorite(['contact-info/phone', field, 'favorite', Number(!isFavoriteBoolean)].join('/'))
                                                    .then(() => {
                                                        reFetchContactInfo();
                                                    });

                                                //employee/{employeeId}/contact-info/{type}/{field}/favorite/{isFavorite}
                                            }}
                                            isSelected={isFavoriteBoolean}
                                            appearance="subtle"
                                            shape="default"
                                            icon={isFavoriteBoolean ? StarFilledIcon : StarIcon}
                                            label={isFavoriteBoolean ? "Remove Favorite" : "Add Favorite"}
                                            overlay={isFavoriteLoading ? <Spinner size="medium" /> : null}
                                        />
                                    )}
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip position="bottom" delay={0} content="Delete">
                                    {(tooltipProps) => (
                                        <IconButton
                                            {...tooltipProps}
                                            onClick={() => {
                                                const newOtherErrorStateServ = [...otherErrorStateServ];
                                                // @ts-ignore
                                                newOtherErrorStateServ[Number(field)]?.clearError && newOtherErrorStateServ[Number(field)].clearError();
                                                newOtherErrorStateServ[Number(field)] = {
                                                    clearError: undefined,
                                                    error: undefined,
                                                    valueBeforeError: ''
                                                };
                                                setOtherErrorStateServ(newOtherErrorStateServ);

                                                removeContact(['contact-info/phone', field].join('/')).then(() => {
                                                    reFetchContactInfo();
                                                });
                                            }}
                                            appearance="subtle"
                                            shape="default"
                                            // @ts-ignore
                                            icon={EditorRemoveIconNew}
                                            overlay={isRemoveLoading ? <Spinner size="medium" /> : null}
                                            label="Delete"
                                        />
                                    )}
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                );
            })}
            {isAccessEdit && otherPhones.length < 4 && <div className={style.addContactField}>
                <Button
                    className={styleParent.btnAdd}
                    iconBefore={<EditorAddIcon primaryColor="#758195" label="Add Phone number"/>}
                    appearance="subtle"
                    spacing="compact"
                    onClick={addPhoneNumber}
                >Add phone number</Button>
            </div>}
        </BlockInfoItem>
    );
};