import { AnnouncementFormType  } from 'interfaces/common/announcements.interface'
import { ANNOUNCEMENTS } from "utils/constants/api";
import { baseHeaders } from '../constants' 

export const announcementAPI = {  
  getAnnouncements() {
    return fetch(ANNOUNCEMENTS, {
      method: "GET",
      headers: baseHeaders,
    });
  },
  createAnnouncement(body: AnnouncementFormType ) {
    return fetch(ANNOUNCEMENTS, {
      method: "POST",
      headers: baseHeaders,
      body: JSON.stringify(body),
    });
  },
  updateAnnouncement(id: string, body: AnnouncementFormType ) {
    return fetch(`${ANNOUNCEMENTS}/${id}`, {
      method: "PUT",
      headers: baseHeaders,
      body: JSON.stringify(body),
    });
  },
  deleteAnnouncement(id: string) {
    return fetch(`${ANNOUNCEMENTS}/${id}`, {
      method: "DELETE",
      headers: baseHeaders,
    });
  },
};
