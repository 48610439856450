import {GOOGLE_AUTH} from "../../utils/constants/api";

export const loginWithGoogle = async (data: any) => {
    try {
        const formData = new FormData();
        formData.append('code', data.code);
        formData.append('scope', data.scope);

        const response = await fetch(GOOGLE_AUTH, {
            method: 'POST',
            body: formData
        });

        if (!response.ok || response.status !== 200) {
            alert('Employee not found');
            throw new Error();
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        alert('Employee not found');
        throw new Error();
    }
};