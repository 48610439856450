import { token } from "@atlaskit/tokens";
import styled from "styled-components";

// prettier-ignore
export const StyledTeamAnalysisPage = styled.main`
  height: 100%;
  padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.300")};

  @media (min-width: 1920px) {
    padding: ${token("space.300")} ${token("space.800")} ${token("space.600")};
  }

  @media (max-width: 1280px) {
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.200")};
  }

  @media (max-width: 1024px) {
    width: 100vw;
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")};
  }

  @media (max-width: 768px) {
    padding: ${token("space.300")} ${token("space.200")} ${token("space.600")};
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;
