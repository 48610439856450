import { useQuery } from "../../useQuery";
import { useState } from "react";
import { useEvent } from "../../../customHooks/useEvent";
import { fetchSubordinatesAPI } from "./subordinates";
import type { SubordinatesProps } from "./subordinates.types";

export const useSubordinates = (idProfile: string) => {
  const [fetchSubordinatesAPIs, isLoading, isSuccess, isError] =
    useQuery(fetchSubordinatesAPI);
  const [subordinates, setSubordinates] = useState<Array<SubordinatesProps>>();

  const fetchSubordinates = useEvent(async () => {
    try {
      const response = await fetchSubordinatesAPIs(idProfile);
      setSubordinates(response);
      return response;
    } catch (error) {
      throw new Error();
    }
  });

  return {
    fetchSubordinates: fetchSubordinates,
    subordinates: subordinates,
    isLoading: isLoading,
    isSuccess: isSuccess,
    isError: isError,
  };
};
