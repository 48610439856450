import {AUTH} from "../../utils/constants/api";

export const signOut = async () => {
    try {
        const response = await fetch(AUTH, { method: 'DELETE' });
        if(!response.ok) throw new Error();

        return await response.text();
    } catch (error) {
        console.error(error)
        throw new Error();
    }
};