import { FC, useEffect, useRef, useState } from "react";
import cn from "classnames";

//atlasskit
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import { IconButton } from "@atlaskit/button/new";
import InlineEdit from "@atlaskit/inline-edit";
import StarIcon from '@atlaskit/icon/glyph/star';
import Spinner from '@atlaskit/spinner';
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';

//context
import { useProfile } from "../../../context";

//api
import { ContactTypeProps } from "../../../../../API/profile/contactInfo";
import { useSetField } from "../../../../../API/profile/setField/useSetField";
import { useContactInfo } from "../../../../../API/profile/fields/generalInfo/contactInfo";

//regex
import { getRegexForSocial } from "../../../../../utils/helpers/EmployeeProfile/getRegexForSocial";

//components
import { Input } from "../../../../UI/Input";
import { InputText } from "../../../ViewingModes/InputText";
import { BlockInfoItem } from "../../../BlockInfo";
import { Button } from "../../../../UI/Button";
import { Tooltip } from '../../../../UI/Tooltip';

//images
import { ReactComponent as EditorRemoveIconNew } from '../../../../../assets/images/ProfileIcons/trash.svg';
import Telegram from "../../../../../assets/images/socialNetworkIcons/Telegram.svg";
import Facebook from "../../../../../assets/images/socialNetworkIcons/Facebook.svg";
import Instagram from "../../../../../assets/images/socialNetworkIcons/Instagram.svg";
import TikTok from "../../../../../assets/images/socialNetworkIcons/TikTok.svg";
import LinkedIn from "../../../../../assets/images/socialNetworkIcons/LinkedIn.svg";
import Reddit from "../../../../../assets/images/socialNetworkIcons/Reddit.svg";
import Calendly from "../../../../../assets/images/socialNetworkIcons/Calendly.svg";
import Signal from "../../../../../assets/images/socialNetworkIcons/Signal.svg";
import Slack from "../../../../../assets/images/socialNetworkIcons/Slack.svg";
import Threads from "../../../../../assets/images/socialNetworkIcons/Threads.svg";
import Threema from "../../../../../assets/images/socialNetworkIcons/Threema.svg";
import WhatsApp from "../../../../../assets/images/socialNetworkIcons/WhatsApp.svg";
import YouTube from "../../../../../assets/images/socialNetworkIcons/YouTube.svg";
import Twitter from "../../../../../assets/images/socialNetworkIcons/Twitter.svg";
import Viber from "../../../../../assets/images/socialNetworkIcons/Viber.svg";

//types
import { ContactInfoType } from "../../../../../shared/Employee/data/ContactInfoType";

//styles
import style from "../../../Profile.module.scss";
import styleParent from "../../../Profile.module.scss";

const SocialNetworkIcons = {
    Telegram: Telegram,
    Facebook: Facebook,
    Instagram: Instagram,
    TikTok: TikTok,
    LinkedIn: LinkedIn,
    Reddit: Reddit,
    Calendly: Calendly,
    Signal: Signal,
    Slack: Slack,
    Threads: Threads,
    Threema: Threema,
    WhatsApp: WhatsApp,
    YouTube: YouTube,
    Twitter: Twitter,
    Viber: Viber,
} as const;

interface OptionsProps {
    label: string, value: string
}

const defaultSocialOptions: Array<OptionsProps> = [
    { label: 'Telegram', value: 'Telegram' },
    { label: 'LinkedIn', value: 'LinkedIn' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Calendly', value: 'Calendly' },
    { label: 'TikTok', value: 'TikTok' },
    { label: 'Reddit', value: 'Reddit' },
    { label: 'Signal', value: 'Signal' },
    { label: 'Slack', value: 'Slack' },
    { label: 'Threads', value: 'Threads' },
    { label: 'Threema', value: 'Threema' },
    { label: 'WhatsApp', value: 'WhatsApp' },
    { label: 'YouTube', value: 'YouTube' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'Viber', value: 'Viber' },
    { label: 'Other', value: 'Other' },
];

export const SocialNetwork: FC = () => {
    const { contactInfo, isAccessEdit, idOpenProfile, reFetchContactInfo } = useProfile();
    const [otherSocials, setOtherSocials] = useState<Array<ContactTypeProps>>([]);
    const [options, setOptions] = useState<Array<OptionsProps>>(defaultSocialOptions);
    const lastSocialInputRef = useRef<HTMLInputElement>(null); 

    const { setField } = useSetField(idOpenProfile, true);
    const { setField: setFavorite, isLoading: isFavoriteLoading } = useSetField(idOpenProfile, true);
    const { addContact, removeContact, isRemoveLoading } = useContactInfo(idOpenProfile);

    const addSocialPlatform = (field: string) => {
        setOtherSocials([...otherSocials, { field: field, value: '', isFavorite: '0' }]);
    };

    useEffect(() => {
        const socials = contactInfo.find(element => element.type === 'social');
        if(socials) {
            const socialsValues = [...socials.values];

            setOtherSocials(socialsValues);
            const filteredOptions = options.filter(option => !socialsValues.some((social: ContactInfoType) => social.field === option.label));
            setOptions(filteredOptions);
        } else {
            setOtherSocials([]);
        }
    }, [contactInfo]);

    const focusLastSocialInput = () => {
        if (lastSocialInputRef.current) {
            lastSocialInputRef.current.focus();
        }
    };

    return (
        <>
            {otherSocials.length > 0 && otherSocials.map(({ value, isFavorite, field }, index) => {
                const isFavoriteBoolean = Boolean(Number(isFavorite));

                return (
                    <BlockInfoItem title={field} key={field}>
                        <div className={cn(style.otherPhones, style.socField)}>
                            {field !== 'Other' && <img
                                // @ts-ignore
                                src={SocialNetworkIcons[field]}
                                alt={`${field} Icon`}
                            />}
                            <div
                                className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing, style.cocInp)}>
                                <InlineEdit
                                    {...(!isAccessEdit || (!!value && !isAccessEdit)) && {isEditing: false}}
                                    {...(otherSocials.length - 1 === index && !value) && {isEditing: true}}
                                    defaultValue={value} 
                                    editView={({errorMessage, onChange, ...fieldProps}, ref) => { 
                                        focusLastSocialInput(); 
                                        return (
                                        <Input
                                            {...fieldProps}
                                            onChange={(event) => {
                                                const regex = getRegexForSocial(field);
                                                const isValid = regex.test(event.currentTarget.value);

                                                if (isValid) {
                                                    onChange(event);
                                                }
                                            }}
                                            autoFocus
                                            ref={lastSocialInputRef}
                                            placeholder=""
                                            className={style.profileInput}
                                        />
                                    )}}
                                    readView={() => (<InputText value={value} ref={lastSocialInputRef}/>)}
                                    onConfirm={(valueInput: string) => {
                                        const value = valueInput;

                                        if (!value || value === otherSocials[index].value) {
                                            return;
                                        }

                                        if (!otherSocials[index].value) {
                                            addContact(['contact-info/social', field, value || 'null'].join('/')).then(() => {
                                                reFetchContactInfo();
                                            });
                                        } else {
                                            setField(['contact-info/social', field, value || 'null'].join('/')).then(() => {
                                                reFetchContactInfo();
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {isAccessEdit && <div className={style.contactBtnGroup}>
                                <div>
                                    <Tooltip position="bottom" delay={0}
                                             content={isFavoriteBoolean ? 'Hide in "Contact me"' : 'Show in "Contact me"'}>
                                        {(tooltipProps) => (
                                            <IconButton
                                                {...tooltipProps}
                                                onClick={() => {
                                                    setFavorite(['contact-info/social', field, 'favorite', Number(!isFavoriteBoolean)].join('/'))
                                                        .then(() => {
                                                            reFetchContactInfo();
                                                        });

                                                    //employee/{employeeId}/contact-info/{type}/{field}/favorite/{isFavorite}
                                                }}
                                                isSelected={isFavoriteBoolean}
                                                appearance="subtle"
                                                shape="default"
                                                icon={isFavoriteBoolean ? StarFilledIcon : StarIcon}
                                                label={isFavoriteBoolean ? "Remove Favorite" : "Add Favorite"}
                                                overlay={isFavoriteLoading ? <Spinner size="medium" /> : null}
                                            />
                                        )}
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip position="bottom" delay={0} content="Delete">
                                        {(tooltipProps) => (
                                            <IconButton
                                                {...tooltipProps}
                                                onClick={() => {
                                                    removeContact(['contact-info/social', field].join('/')).then(() => {
                                                        reFetchContactInfo();
                                                    });
                                                }}
                                                appearance="subtle"
                                                shape="default"
                                                // @ts-ignore
                                                icon={EditorRemoveIconNew}
                                                overlay={isRemoveLoading ? <Spinner size="medium"/> : null}
                                                label="Delete"
                                            />
                                        )}
                                    </Tooltip>
                                </div>
                            </div>}
                        </div>
                    </BlockInfoItem>
                );
            })}
            {isAccessEdit && options.length > 0 && <BlockInfoItem title="Social network">
                <div className={`${style.addContactField}`}>
                    <DropdownMenu<HTMLButtonElement>
                        trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
                            <Button
                                {...providedProps}
                                ref={triggerRef}
                                className={styleParent.btnAdd}
                                iconBefore={<EditorAddIcon primaryColor="#758195" label="Add social" />}
                                appearance="subtle"
                                spacing="compact"
                            >Add social platform</Button>
                        )}
                    >
                        <DropdownItemGroup>
                            {options.map(({ value, label }) => {
                                return (
                                    <DropdownItem onClick={() => {
                                        addSocialPlatform(value);
                                    }} key={value} elemBefore={value !== 'Other' ? <img
                                        // @ts-ignore
                                        src={SocialNetworkIcons[value]} alt={`${value} Icon`} /> : null}>{label}</DropdownItem>
                                );
                            })}
                        </DropdownItemGroup>
                    </DropdownMenu>
                </div>
            </BlockInfoItem>}
        </>
    );
};