import React, { type FC } from "react";
import { Outlet } from "react-router-dom";
import { useEmployee } from "../contexts/EmployeeContext";
import { useCheckAuth } from "../API/auth/useCheckAuth";

export const App: FC = () => {
  const { roles } = useEmployee();
  useCheckAuth();

  return !roles.length ? null : <Outlet />;
};
