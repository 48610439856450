import React from "react";
import BaseModal from "./BaseModal";
import { LEVELS } from "../../../../../../../utils/constants/api";
import { VALIDATION_ERROR } from "../../../../../../../utils/constants/errors";
import { ValidationError } from "../../../../../../../shared/Common/Error/ValidationError";

type CreateLevel = {
  opened: boolean;
  onOk: () => void;
  onClose: () => any;
};

function CreateLevel(props: CreateLevel) {
  function create(
    name: string,
    validate: (errors: ValidationError[]) => void
  ): void {
    let formData = new FormData();
    formData.append(
      "level",
      JSON.stringify({
        name: name,
      })
    );

    fetch(LEVELS, { method: "POST", body: formData }).then((response) => {
      if (response.ok) {
        props.onOk();
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors));
      }
    });
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Create Job Level"
      name={null}
      okText="Create"
      onCancel={props.onClose}
      onOk={create}
    />
  );
}

export default CreateLevel;
