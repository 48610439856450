export const MAIN: string = '/';
export const SIGN_IN: string = '/sign-in';
export const ADMIN_RANDOM_PHRASES: string = '/admin/random-phrases';
export const ADMIN_LINKS: string = '/admin/links';
export const ADMIN_GENDER_PRONOUNS: string = '/admin/gender-pronouns';
export const ADMIN_LEVELS: string = '/admin/levels';
export const ADMIN_JOB_TITLES: string = '/admin/job-titles';
export const ADMIN_STATUSES: string = '/admin/statuses';
export const ADMIN_LOCATIONS: string = '/admin/locations';
export const ADMIN_ORG_CHART: string = '/admin/orgchart';
export const ADMIN_PUSH_NOTIFICATIONS: string = '/admin/push-notifications';
export const ADMIN_HOLIDAYS: string = '/admin/holidays';
export const ADMIN_EMPLOYEES: string = '/admin/employees';
export const EMPLOYEE_PROFILE: string = '/employee';
export const CALENDAR: string = '/calendar';
export const NOT_FOUND: string = '/404';
