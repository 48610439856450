import { CreateAnnouncementModal, DeleteAnnouncementModal, EditAnnouncementModal } from './components'
import { AnnouncementModalTypeEnum } from './constants'
import { IGetAnnouncementModalProps, GetAnnouncementModalType } from './types'

export const getAnnouncementModalType = (props: IGetAnnouncementModalProps): GetAnnouncementModalType => {

  const { onClose, payload, onDeleteAnnouncement, onCreateAnnouncement, onEditAnnouncement } = props

  return {
    [AnnouncementModalTypeEnum.CREATE]: <CreateAnnouncementModal onClose={onClose} onCreate={onCreateAnnouncement} payload={payload} />,
    [AnnouncementModalTypeEnum.DELETE]: <DeleteAnnouncementModal onClose={onClose} onDelete={onDeleteAnnouncement} payload={payload} />,
    [AnnouncementModalTypeEnum.EDIT]: <EditAnnouncementModal onClose={onClose} onEdit={onEditAnnouncement} payload={payload} />
  }
}