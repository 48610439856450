import { toCSVString } from "utils/helpers/CSV/formatToCSV";
import { formatTime } from "utils/helpers/DateTime/formatTime";
import moment from "moment";
import { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import { EmployeeFromApi } from "shared/Employee/data/EmployeeFromApi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  FilterCalendar,
  defaultRangeAttendanceLog,
  DayRange,
} from "../UI/FilterCalendar";
import { SetURLSearchParams } from "react-router-dom";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

interface SelectValue {
  value: string;
  label: string;
  company?: string;
  nickname?: string;
  email?: string;
  avatar?: string;
  disabled?: boolean;
}

interface Option {
  value: any;
  label: string;
  key?: string;
  company?: string;
  avatar?: string;
  email?: string;
  disabled?: boolean;
}

const validateTimeFormat = (value: string) => {
  let validateValue = value;
  let validateTimeoutId: number;

  return new Promise<{ value: string; error: string } | undefined>(
    (resolve) => {
      validateTimeoutId = window.setTimeout(() => {
        const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!timePattern.test(value)) {
          resolve({
            value,
            error: "Invalid time format. Expected HH:MM",
          });
        }
        resolve(undefined);
      }, 100);
    }
  ).then((validateObject) => {
    if (validateObject && validateObject.value === validateValue) {
      return validateObject.error;
    }
    return undefined;
  });
};

const exCSV = (CSV: any) => {
  const csvContent = toCSVString(CSV);
  const blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8,",
  });
  const objUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", objUrl);
  link.setAttribute("download", "attendance_log.csv");
  link.style.display = "none";

  document.body.append(link);
  link.click();
  link.remove();
};

const exPDF = (CSV: any) => {
  if (CSV.length === 0) {
    return;
  }
  const doc = new jsPDF({
    unit: "mm",
    orientation: "landscape",
  });

  const columns = [
    { header: "Employee", dataKey: "employee" },
    { header: "Date", dataKey: "date" },
    { header: "Day type", dataKey: "dayType" },
    { header: "Check in", dataKey: "checkIn" },
    { header: "Check out", dataKey: "checkOut" },
    { header: "Break", dataKey: "break" },
    { header: "Total", dataKey: "total" },
    { header: "Difference", dataKey: "difference" },
  ];

  const rows = CSV.map((row: { [key: string]: any }) => ({
    employee: row["Employee"],
    date: row["Date"],
    dayType: row["Day type"],
    checkIn: row["Check in"],
    checkOut: row["Check out"],
    break: row["Break"],
    total: row["Total"],
    difference: row["Difference"],
  }));

  doc.autoTable({
    startY: 20,
    startX: 70,
    head: [columns.map((col) => col.header)],
    body: rows.map((row: any) => columns.map((col: any) => row[col.dataKey])),
    theme: "grid",
    columnStyles: {
      0: { cellWidth: 50 },
      1: { cellWidth: 50 },
      2: { cellWidth: 30 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 45 },
      6: { cellWidth: 20 },
      7: { cellWidth: 25 },
    },
    styles: {
      halign: "center",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0],
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
  });

  doc.save("attendance_log.pdf");
};

const getDateRangeFromParams = (params: URLSearchParams): DayRange => {
  const startDateParam = params.get("startDate");
  const endDateParam = params.get("endDate");

  if (startDateParam && endDateParam) {
    const startDate = moment.utc(startDateParam, "YYYY-MM-DD").toDate();
    const startDay: DayValue = {
      day: startDate.getUTCDate(),
      month: startDate.getUTCMonth() + 1,
      year: startDate.getUTCFullYear(),
    };

    const endDate = moment.utc(endDateParam, "YYYY-MM-DD").toDate();
    const endDay: DayValue = {
      day: endDate.getUTCDate(),
      month: endDate.getUTCMonth() + 1,
      year: endDate.getUTCFullYear(),
    };

    return { from: startDay, to: endDay };
  } else {
    return defaultRangeAttendanceLog.range;
  }
};

function generateSelectValuesAndDictionary(data: any) {
  const employeesSelect: SelectValue[] = [];
  const orgUnitsSelect: SelectValue[] = [];

  for (const key in data.employees) {
    if (data.employees.hasOwnProperty(key)) {
      const employee = data.employees[key];
      employeesSelect.push({
        value: employee.id,
        label: `${employee.firstNameEn} ${employee.lastNameEn} ${
          employee.nickname ? `(${employee.nickname})` : ""
        }`,
        company: employee.orgUnitName,
        nickname: employee.nickname,
        avatar: employee.avatar ?? undefined,
        email: employee.email,
      });
    }
  }

  for (const key in data.orgUnits) {
    if (data.orgUnits.hasOwnProperty(key)) {
      const orgUnit = data.orgUnits[key];
      orgUnitsSelect.push({
        value: orgUnit.id,
        label: orgUnit.name,
        company: orgUnit.companyName,
      });
    }
  }

  return { employeesSelect, orgUnitsSelect };
}

const filterEmployeesByOrgUnits = (
  employees: EmployeeFromApi[],
  selectedOrgUnitOptions: SelectValue[]
): SelectValue[] => {
  return employees
    .filter((employee) =>
      selectedOrgUnitOptions.some(
        (orgUnit) =>
          orgUnit.value === employee.orgUnitId ||
          (orgUnit.value === "null" && employee.orgUnitId === null)
      )
    )
    .map((employee) => ({
      value: employee.id,
      email: employee.email,
      avatar: employee.avatar ?? undefined,
      label: `${employee.firstNameEn} ${employee.lastNameEn} ${
        employee.nickname ? `(${employee.nickname})` : ""
      }`,
    }));
};

const getSelectedOrgUnitsFromParams = (
  params: URLSearchParams,
  orgUnitOptions: SelectValue[]
): SelectValue[] => {
  const orgUnitsParam = params.get("org-units");
  if (orgUnitsParam) {
    const orgUnitIds = orgUnitsParam.split(",");
    return orgUnitOptions.filter((option) => orgUnitIds.includes(option.value));
  }
  return [];
};

const getOriginalTime = (emDate: any) => {
  if (emDate.correction && emDate.correction.length > 0) {
    emDate.originalTime =
      emDate.correction[emDate.correction.length - 1].changedTo;
  }
  if (emDate.originalTime === "00:00") {
    return "-";
  }
  return formatTime(emDate.originalTime);
};

const setSign = (originalTime: string, workingScheduleTime: string) => {
  const [originalHours, originalMinutes] = originalTime.split(":").map(Number);
  const [workingHours, workingMinutes] = workingScheduleTime
    .split(":")
    .map(Number);

  const originalTotalMinutes = originalHours * 60 + originalMinutes;
  const workingTotalMinutes = workingHours * 60 + workingMinutes;

  if (originalTotalMinutes > workingTotalMinutes) {
    return "+";
  } else if (originalTotalMinutes < workingTotalMinutes) {
    return "-";
  } else {
    return "";
  }
};

const dayTypeToLozenge = (
  dayType: string
): "default" | "inprogress" | "moved" | "new" | "removed" | "success" => {
  if (dayType === "workday") {
    return "inprogress";
  }
  if (dayType === "weekend") {
    return "new";
  }
  if (dayType === "unpaid_day_off") {
    return "moved";
  }
  if (dayType === "holiday") {
    return "success";
  }
  if (dayType === "day_off") {
    return "removed";
  }
  return "default";
};

const updateURL = {
  date: (setSearchParams: SetURLSearchParams, dateRange: any) => {
    setTimeout(() => {
      setSearchParams((searchParams) => {
        // setTimeout to make it mactrotask after states update
        searchParams.set(
          "startDate",
          `${dateRange.from?.year}-${dateRange.from?.month}-${dateRange.from?.day}`
        );
        searchParams.set(
          "endDate",
          `${dateRange.to?.year}-${dateRange.to?.month}-${dateRange.to?.day}`
        );

        return searchParams;
      });
    }, 0);
  },

  dayType: (setSearchParams: SetURLSearchParams, selectedDayTypes: any) => {
    setTimeout(() => {
      setSearchParams((searchParams) => {
        const dayTypes = selectedDayTypes
          .map((option: { value: any }) => option.value)
          .join(",");
        if (dayTypes) {
          searchParams.set("dayTypes", dayTypes);
        } else {
          searchParams.delete("dayTypes");
        }
        return searchParams;
      });
    }, 0);
  },

  employees: (
    setSearchParams: SetURLSearchParams,
    selectedEmployeeOptions: any,
    orgUnitLoaded: boolean
  ) => {
    setTimeout(() => {
      setSearchParams((searchParams) => {
        if (selectedEmployeeOptions.length !== 0) {
          searchParams.set(
            "employees",
            selectedEmployeeOptions
              .map((option: { value: any }) => option.value)
              .join(",")
          );
        } else {
          if (orgUnitLoaded) {
            searchParams.delete("employees");
          }
        }
        return searchParams;
      });
    }, 0);
  },

  orgUnits: (
    setSearchParams: SetURLSearchParams,
    selectedOrgUnitOptions: any,
    orgUnitLoaded: boolean
  ) => {
    setTimeout(() => {
      setSearchParams((searchParams) => {
        if (selectedOrgUnitOptions.length !== 0) {
          searchParams.set(
            "org-units",
            selectedOrgUnitOptions
              .map((option: { value: any }) => option.value)
              .join(",")
          );
        } else {
          if (orgUnitLoaded) {
            searchParams.delete("org-units");
          }
        }
        return searchParams;
      });
    }, 0);
  },
};

export {
  validateTimeFormat,
  exCSV,
  exPDF,
  getDateRangeFromParams,
  generateSelectValuesAndDictionary,
  filterEmployeesByOrgUnits,
  getSelectedOrgUnitsFromParams,
  getOriginalTime,
  setSign,
  dayTypeToLozenge,
  updateURL,
};
