import {useState} from 'react';
import {useEvent} from '../../customHooks/useEvent';
import {sendLetterLink as fetchSendLetterLink} from './sendLetterLink';

export const useSendLetterLink = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);

    const resetStatuses = useEvent(() => {
        setLoading(false);
        setSuccess(false);
        setError(false);
    });

    const sendLetterLink = useEvent((email: string) => {
        setLoading(true);
        fetchSendLetterLink(email)
            .then(() => {
                setSuccess(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return {
        isLoading,
        isSuccess,
        isError,
        sendLetterLink,
        resetStatuses
    };
};