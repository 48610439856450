import React from 'react';
import cn from "classnames";

import ErrorIcon from '../../../../assets/images/resetPassword/failure.svg';
import ErrorBeforeCheckIcon from '../../../../assets/images/resetPassword/beforeCheck.svg';
import SuccessIcon from '../../../../assets/images/resetPassword/success.svg';
import DotIcon from '../../../../assets/images/resetPassword/dot.svg';

//types
import { Rule } from 'API/auth/useValidationRules';

//styles
import styles from './ValidationRules.module.scss';

interface ValidationRulesProps {
    rules: Rule[];
}

const ValidationRules: React.FC<ValidationRulesProps> = ({ rules }) => {
    return (
        <ul className={styles.resetPassList}>
            {rules.map((rule, index) => (
                <li
                    key={index}
                    className={styles.validationItem}
                >
                    {rule.status === 'default' && <img src={DotIcon} alt="Dot" width={24} height={24} />}
                    {rule.status === 'error' && <img src={ErrorIcon} alt="Error" width={24} height={24} />}
                    {rule.status === 'errorBeforeCheck' && <img src={ErrorBeforeCheckIcon} alt="AnotherError" width={24} height={24} />}
                    {rule.status === 'success' && <img src={SuccessIcon} alt="Success" width={24} height={24} />}
                    <p className={cn(styles.validationRule,  {
                            [styles.validationBeforeCheck]: rule.status === "errorBeforeCheck",
                            [styles.validationError]: rule.status === "error",
                            [styles.validationSuccess]: rule.status === "success"
                        })}>
                        {rule.text}
                    </p>
                </li>
            ))}
        </ul>
    );
};

export default ValidationRules;
