import React, { useState, useEffect, FC } from "react";
import moment from "moment";
import { Popover } from "@headlessui/react";

import CreateCorporateEvent from "./Modal/CreateCorporateEvent";
import EditCorporateEvent from "./Modal/EditCorporateEvent";
import DeleteCorporateEvent from "./Modal/DeleteCorporateEvent";
import Button, { Size, Type } from "../../../../Component/Button/Button";

import { CorporateEventFromApi } from "../../../../../shared/Holiday/CorporateEvent/data/CorporateEventFromApi";
import { OrgUnitFromApi } from "../../../../../shared/OrgChart/data/OrgUnitFromApi";
import { LocationFromApi } from "../../Locations/Locations";

import {
  ORG_CHART,
  LOCATIONS,
  CORPORATE_EVENTS,
} from "../../../../../utils/constants/api";
import {
  ROLE_ADMIN,
  ROLE_CONTENT_MANAGER,
  ROLE_OPERATIONAL_MANAGER,
} from "../../../../../shared/Security/constants/AccessLevels";

import "./CorporateEvents.scss";
import { getGMTOffsetByTimezoneName } from "../../../../Common/Timezones/Services";
import {
  assertEmployee,
  useEmployee,
} from "../../../../../contexts/EmployeeContext";

interface CorporateEventsProps {
  year: string;
}

export const CorporateEvents: FC<CorporateEventsProps> = (props) => {
  const { employee } = useEmployee();
  assertEmployee(employee);

  const [createCorporateEventModal, setCreateCorporateEventModal] =
    useState<boolean>(false);
  const [editCorporateEventModal, setEditCorporateEventModal] =
    useState<boolean>(false);
  const [deleteCorporateEventModal, setDeleteCorporateEventModal] =
    useState<boolean>(false);

  const [corporateEvents, setCorporateEvents] = useState<
    CorporateEventFromApi[]
  >([]);
  const [corporateEventToChange, setCorporateEventToChange] =
    useState<CorporateEventFromApi | null>(null);
  const [corporateEventToDelete, deleteCorporateEvent] =
    useState<CorporateEventFromApi | null>(null);

  const [orgUnits, setOrgUnits] = useState<OrgUnitFromApi[]>([]);
  const [locations, setLocations] = useState<LocationFromApi[]>([]);

  function getOrgUnits(): void {
    fetch(ORG_CHART + "?without-hierarchy=true", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((orgUnits: OrgUnitFromApi[]) => setOrgUnits(orgUnits));
  }

  function getLocations(): void {
    fetch(LOCATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((locations: LocationFromApi[]) => setLocations(locations));
  }

  useEffect(() => {
    getOrgUnits();
    getLocations();
    if (props.year) {
      getCorporateEvents(props.year);
    }
  }, [props.year]);

  function getCorporateEvents(year: string) {
    return fetch(CORPORATE_EVENTS + "?year=" + year, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((corporateEvents: CorporateEventFromApi[]) =>
        setCorporateEvents(corporateEvents)
      );
  }

  function editCorporateEventModalVisibility(
    corporateEvent: CorporateEventFromApi
  ): void {
    setCorporateEventToChange(corporateEvent);
    setEditCorporateEventModal(true);
  }

  function confirmDeleteCorporateEventModalVisibility(
    corporateEvent: CorporateEventFromApi
  ): void {
    deleteCorporateEvent(corporateEvent);
    setDeleteCorporateEventModal(true);
  }

  function getLocationNames(locationIds: string[]): string {
    let locationNames: string[] = [];
    locationIds.forEach((locationId) => {
      const location = locations.find((location) => location.id === locationId);
      if (location) {
        locationNames.push(
          location.name +
            " " +
            getGMTOffsetByTimezoneName(location.value.timezone)
        );
      }
    });
    return locationNames.join("; ");
  }

  function showCorporateEvent({
    corporateEvent,
  }: {
    corporateEvent: any;
  }): void {
    fetch(CORPORATE_EVENTS + "/" + corporateEvent.id, {
      method: "PUT",
      body: JSON.stringify({
        name: corporateEvent.value.name,
        orgUnitIds: corporateEvent.value.orgUnitIds,
        locationIds: corporateEvent.value.locationIds,
        date: corporateEvent.value.date,
        dayOff: corporateEvent.value.dayOff,
        paidTimeOff: corporateEvent.value.paidTimeOff,
        text: corporateEvent.value.text,
        visible: !corporateEvent.value.visible,
        archiveAfterFinish: corporateEvent.value.archiveAfterFinish,
      }),
    }).then((response) => {
      if (response.ok) {
        getCorporateEvents(props.year);
      } else {
        response.json().then((jsonData) => alert(jsonData.error));
      }
    });
  }

  // Access levels
  const roles = [
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_OPERATIONAL_MANAGER,
  ] as typeof employee.roles;
  const hasRole = roles.some((role) => employee.roles.includes(role));

  return (
    <div className="corporate-events">
      {hasRole ? (
        <div className="add-button-block">
          {props.year ? (
            <Button
              text="<i className='material-icons add-item'>add</i> Add Corporate Event"
              size={Size.Medium}
              type={Type.Accent}
              onClick={() => setCreateCorporateEventModal(true)}
            />
          ) : null}
        </div>
      ) : null}
      <table className="corporate-events-table">
        <thead>
          <tr>
            <th>Name</th>
            <th className={"center"}>Locations</th>
            <th className={"center"}>Org Unit</th>
            <th className={"center"}>Date</th>
            <th className={"center"}>Text</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {corporateEvents.map((corporateEvent) => {
            return (
              <tr key={corporateEvent.id}>
                <td>{corporateEvent.value.name}</td>
                <td className={"center"}>
                  {getLocationNames(corporateEvent.value.locationIds)}
                </td>
                <td className={"center"}>
                  <div className={"count-margin-fix"}>
                    {corporateEvent.value.orgUnitIds.length}
                  </div>
                </td>
                <td className={"center"}>
                  {corporateEvent.value.date
                    ? moment(corporateEvent.value.date).format("DD.MM.YYYY")
                    : ""}
                </td>
                <td className={"center"}>
                  {corporateEvent.value.text ? (
                    <i className="material-icons check-success">done</i>
                  ) : (
                    <i className="material-icons">remove</i>
                  )}
                </td>
                <td className={"center"}>
                  {hasRole ? (
                    <Popover className="relative">
                      <Popover.Button className={"vert-menu-btn"}>
                        <i className="material-icons">more_vert</i>
                      </Popover.Button>

                      <Popover.Panel className="absolute z-10">
                        <i
                          className="material-icons"
                          onClick={() =>
                            editCorporateEventModalVisibility(corporateEvent)
                          }
                        >
                          edit
                        </i>
                        <i
                          className="material-icons"
                          onClick={() =>
                            confirmDeleteCorporateEventModalVisibility(
                              corporateEvent
                            )
                          }
                        >
                          delete
                        </i>
                        <i
                          className="material-icons"
                          onClick={() =>
                            showCorporateEvent({
                              corporateEvent: corporateEvent,
                            })
                          }
                        >
                          {corporateEvent.value.visible
                            ? "visibility"
                            : "visibility_off"}
                        </i>
                      </Popover.Panel>
                    </Popover>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <CreateCorporateEvent
        opened={createCorporateEventModal}
        onOk={() => {
          getCorporateEvents(props.year);
          setCreateCorporateEventModal(false);
        }}
        onClose={() => setCreateCorporateEventModal(false)}
        orgUnits={orgUnits}
        locations={locations}
      />
      {corporateEventToDelete ? (
        <DeleteCorporateEvent
          opened={deleteCorporateEventModal}
          onOk={() => {
            getCorporateEvents(props.year);
            setDeleteCorporateEventModal(false);
            deleteCorporateEvent(null);
          }}
          onClose={() => {
            setDeleteCorporateEventModal(false);
            deleteCorporateEvent(null);
          }}
          corporateEvent={corporateEventToDelete}
        />
      ) : null}
      {corporateEventToChange ? (
        <EditCorporateEvent
          opened={editCorporateEventModal}
          onOk={() => {
            getCorporateEvents(props.year);
            setEditCorporateEventModal(false);
            setCorporateEventToChange(null);
          }}
          onClose={() => {
            setEditCorporateEventModal(false);
            setCorporateEventToChange(null);
          }}
          orgUnits={orgUnits}
          locations={locations}
          corporateEvent={corporateEventToChange}
        />
      ) : null}
    </div>
  );
};
