import React, { FC } from "react";
import cn from "classnames";
import style from "./TimeOffBalance.module.scss";
import { Button } from "../../../UI/Button";
import { Tooltip } from "../../../UI/Tooltip";
import { TypeIconsCollection } from "../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";

interface TimeOffBalanceProps {
  type: string;
  title: string;
  count: number;
  onClick?: () => void;
  noHover?: boolean;
}

export const TimeOffBalance: FC<TimeOffBalanceProps> = ({
  type,
  title,
  count,
  onClick,
}) => {
  const classNames = cn(style.button, style[type]);

  const handleMouseEnter = (e: any, className: string) => {
    const element = e.target.querySelector(".small-icon ." + className);
    if (element) {
      element.classList.add(className + "-hover");
    }
  };

  const handleMouseLeave = (e: any, className: string) => {
    const element = e.target.querySelector(".small-icon ." + className);
    if (element) {
      element.classList.remove(className);
    }
  };

  return (
    <Tooltip position="bottom" delay={0} content={`${title}`}>
      {(tooltipProps) => (
        <Button
          {...tooltipProps}
          iconBefore={TypeIconsCollection[
            type as keyof typeof TypeIconsCollection
          ]("small-icon", "withouthover")}
          spacing="default"
          onMouseEnter={(e) => handleMouseEnter(e, type)}
          onMouseLeave={(e) => handleMouseLeave(e, type + "-hover")}
          className={classNames}
          appearance="subtle"
          shouldFitContainer
          onClick={onClick}
        >
          {count} days
        </Button>
      )}
    </Tooltip>
  );
};
