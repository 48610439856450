import {ResponseJobTitles, TransformJobTitles} from './jobTitles.types';
import {JOB_TITLES} from "../../../../constants";

const transformToADSSelectOptions = (options: Array<ResponseJobTitles>): Array<TransformJobTitles> => {
    return options.map((option) => {
        return {
            ...option,
            label: option.name,
            value: option.id
        }
    });
};

export const fetchJobTitles = async (): Promise<Array<TransformJobTitles>> => {
    try {
        const response = await fetch(JOB_TITLES, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        const data = await response.json() as Array<ResponseJobTitles>;
        return transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const fetchCurrentJobTitle = async (id: string): Promise<Array<TransformJobTitles>> => {
    try {
        let data: ResponseJobTitles[] = [];
        if (id) {
            const response = await fetch(`${JOB_TITLES}/${id}`, {
                method: 'GET',
                headers: { 'Accept': 'application/json' }
            });

            data = [await response.json()] as Array<ResponseJobTitles>;
        }
        return transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};