import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const StyledSidebar = styled.aside<{ $navIsOpen: boolean }>`
  border-right: 1px solid ${token("color.border")};
  color: ${token("color.text.subtle")};
  position: sticky;
  height: calc(100dvh - 56px);
  inset: 56px auto 0 0;
  overflow-y: hidden;

  nav {
    padding: 0 ${token("space.100")};
    min-width: auto;

    [role="group"] {
      a {
        padding: ${token("space.100")} ${token("space.200")};
        margin-bottom: ${token("space.100")};

        &:last-of-type {
          margin-bottom: 0;
        }

        span {
          white-space: normal;
        }

        @media (max-width: 1024px) {
          &[aria-current="page"] {
            background: ${token("color.background.selected.bold")};
            color: ${token("color.text.inverse")};
          }
        }
      }
    }

    @media (max-width: 1024px) {
      height: calc(100vh - 56px);
      color: ${token("color.text.subtle")};
      background-color: ${token("color.background.accent.blue.subtlest")};
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    inset: 0 auto 0 0;
    height: 100dvh;
    width: 240px;
    transform: ${(props) =>
      props.$navIsOpen ? `translateX(0)` : `translateX(-240px)`};
    transition: transform 0.25s ease-out;
  }
`;

export const StyledSideBarIconBadge = styled.div`
  position: relative;

  [data-testid="badge"] {
    width: max-content;
    padding: ${token("space.025")} ${token("space.075")};
    position: absolute;
    inset: -3px auto auto 9px;

    & > span {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
    }
  }
`;

export const StyledSideBarAdmin = styled.div`
  position: relative;
  margin-top: ${token("space.075")};
  padding-top: ${token("space.075")};

  a {
    padding: ${token("space.100")} ${token("space.200")};

    &[aria-current="page"] {
      color: ${token("color.text.subtle")};
      background-color: ${token("color.background.neutral.subtle.hovered")};

      &::before {
        background: transparent;
      }
    }

    &:hover {
      background-color: ${token("color.background.neutral.subtle.pressed")};
    }
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0 0 auto 0;
    height: 2px;
    background: rgba(9, 30, 66, 0.14);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const StyledSideBarLogo = styled.div`
  display: none;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${token("color.background.accent.blue.subtlest")};
  padding: 0 ${token("space.150")};

  svg {
    width: 145px;
    height: 34px;
  }

  @media (max-width: 1024px) {
    display: flex;
  }
`;
