import React from 'react';
import BaseModal  from './BaseModal';
import { LinkFromApi } from '../../../../../shared/Link/data/LinkFromApi';
import { LINKS } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi';

type EditLink = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    link: LinkFromApi,
    orgUnits: OrgUnitFromApi[],
};

function EditLink(props: EditLink) {
    function edit(name: string, url: string, orgUnits: string[], visible: boolean, validate: (errors: ValidationError[]) => void): void {
        fetch(LINKS + '/' + props.link.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                url: url,
                orgUnits: orgUnits,
                visible: visible
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Link'
            name={ props.link.value.name }
            url={ props.link.value.url }
            visible={props.link.value.visible }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
            selectedOrgUnits={ props.orgUnits }
            orgUnits={props.link.value.orgUnits.map(orgUnit => {
                    let orgUnitName = '';
                    props.orgUnits.map(orgUnitValue => {
                        if (orgUnitValue.id === orgUnit) {
                            orgUnitName = orgUnitValue.name;
                        }
                    })
                    return {
                        label: orgUnitName,
                        value: orgUnit
                    }
                })
            }
         />
    )
}

export default EditLink;