import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import { Box, xcss } from "@atlaskit/primitives";
import Lozenge from '@atlaskit/lozenge';
import { IconButton } from '@atlaskit/button/new';
import dayjs from 'dayjs';
import EditorEditIcon from '@atlaskit/icon/glyph/editor/edit'
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove'
import { dateFormatDB, getFormattedDate } from 'utils/helpers/formatted-date'
import { announcementPriorityColorMap, announcementPriorityLabelMap, AnnouncementPriorityEnum } from 'utils/constants/announcements'
import { LocationsList } from '../locations-list'
import { tableColumnsMap } from './constants';
import { AnnouncementsTableType } from './types';

export const getTableRows = ({sourceData, onDelete, onEdit}: AnnouncementsTableType): Array<RowType> => (
  sourceData.map((announcement) => {
    
    const {id, startDate, endDate, priority, text: content, locations} = announcement;
    const showEdit = dayjs(startDate).format(dateFormatDB) >= dayjs().format(dateFormatDB);

    return {
      key: `row-${id}`,
      cells: [
        {
          key: tableColumnsMap.startDate,
          content: `${getFormattedDate(startDate, 'coreHRDateFormat')} - ${getFormattedDate(endDate, 'coreHRDateFormat')}`,
        },
        {
          key: tableColumnsMap.priority,
          content: (
            <Lozenge appearance={announcementPriorityColorMap[priority as AnnouncementPriorityEnum]}>
              {announcementPriorityLabelMap[priority as AnnouncementPriorityEnum]}
            </Lozenge>
          ),
        },
        {
          key: tableColumnsMap.text,
          content,
        },
        {
          key: tableColumnsMap.locations,
          content: <LocationsList locations={locations} />,
        },
        {
          key: 'actions',
          content: (
            <Box xcss={xcss({
              display: 'flex',
              gap: 'space.200',
              justifyContent: 'flex-end',
            })}>
              {showEdit && (
                <IconButton 
                  icon={EditorEditIcon} 
                  label="Edit" 
                  isTooltipDisabled={false} 
                  spacing="compact" 
                  onClick={() => onEdit(announcement)} 
                />
              )}
              <IconButton 
                icon={EditorRemoveIcon} 
                label="Delete" 
                isTooltipDisabled={false} 
                spacing="compact" 
                onClick={() => onDelete(announcement)} 
              />
            </Box>
          ),
        },
      ],
    }
  })
)