import {forwardRef, type DetailedHTMLProps, type HTMLAttributes} from "react";
import cn from "classnames";
import commonStyle from '../ViewingModes.module.scss';

interface InputTextProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    value: string | null | undefined;
}

export const InputText = forwardRef<HTMLDivElement, InputTextProps>(({value, className, ...restProps}, ref) => {
    const classes = cn(commonStyle.profileInputText, className);

    return (<div {...restProps} ref={ref} className={classes}>{value || '–'}</div>);
});