import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const StyledTimeOffLogModal = styled.div`
  [class*="Label"],
  [class*="label"] {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${token("color.text.subtle")};
  }

  [class*="control"] {
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    min-height: 32px;
    height: 32px;

    &:focus-within {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    & > div {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  [class*="indicatorContainer"] {
    padding-top: 0;
    padding-bottom: 0;
  }

  .timeofflog-modal-policy {
    z-index: 3;

    [class*="singleValue"] {
      & > div > p {
        width: auto;
      }

      & > div > span {
        display: none;
      }
    }
  }

  .timeofflog-amount {
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    min-height: 36px;
    height: 36px;

    &:focus-within:not([data-disabled]) {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    input {
      padding-left: 62px;
    }
  }

  [id*="error"] {
    color: ${token("color.text.danger")};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  textarea[name="comment"] {
    min-height: 86px;
    border: 2px solid rgba(9, 30, 66, 0.14);

    &:focus-within {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }
  }
`;

export const StyledTimeOffLogModalBody = styled.div`
  display: grid;
  gap: ${token("space.100")} 0;

  & > div {
    margin-top: 0;
    position: relative;
  }
`;

export const StyledPolicyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token("space.100")};

  & > p {
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  & > span {
    flex-shrink: 0;
    margin-left: auto;
    color: ${token("color.text.subtlest")};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const StyledRadioGroup = styled.div`
  position: absolute;
  inset: 28px auto auto 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4px;
  height: 24px;

  .timeofflog-radio {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &-btn {
      position: relative;
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      color: ${token("color.icon")};
      background: ${token("color.background.neutral")};
      z-index: 2;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      z-index: 1;

      &:checked {
        & + div {
          color: ${token("color.icon.selected")};
          background-color: ${token("color.background.selected")};
        }
      }
    }
  }
`;

export const StyledTextArea = styled.div`
  position: relative;

  [id*="helper"] {
    justify-content: flex-end;
  }

  [id*="error"] {
    position: relative;
    bottom: 20px;
  }
`;
