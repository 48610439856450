import {EMPLOYEES} from "../../constants";
import type {EmergencyContactProps} from "./emergencyContact.types";

export const getCurrentEmergencyContact = async (idProfile: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        return await response.json() as EmergencyContactProps;
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const addEmergencyContactAPI = async (idProfile: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const editEmergencyContactAPI = async (idProfile: string, id: string, name: string, person: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts/${id}`, {
            method: 'PUT',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
            body: JSON.stringify({
                person: person,
                name: name
            })
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const removeEmergencyContactAPI = async (idProfile: string, id: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts/${id}`, {
            method: 'DELETE',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};

export const addPhoneEmergencyContactAPI = async (idProfile: string, idContact: string, data: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts/${idContact}/phone`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
            body: JSON.stringify({
                phone: data
            })
        });

        if(!response.ok || response.status !== 200) {
            const error = await response.json();
            throw new Error(error[0].message);
        }

        return response;
    } catch(error: any) {
        // @ts-ignore
        return {
            error: String(error.message)
        };
        // @ts-ignore
        throw new Error(error);
    }
};

export const editPhoneEmergencyContactAPI = async (idProfile: string, idContact: string, id: string, data: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts/${idContact}/phone/${id}`, {
            method: 'PUT',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
            body: JSON.stringify({
                phone: data
            })
        });

        if(!response.ok || response.status !== 200) {
            const error = await response.json();
            throw new Error(error[0].message);
        }

        return response;
    } catch(error: any) {
        // @ts-ignore
        return {
            error: String(error.message)
        };
        // @ts-ignore
        throw new Error(error);
    }
};

export const removePhoneEmergencyContactAPI = async (idProfile: string, idContact: string, id: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idProfile}/emergency-contacts/${idContact}/phone/${id}`, {
            method: 'DELETE',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};