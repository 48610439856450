import Button from '@atlaskit/button/new'
import AddIcon from "@atlaskit/icon/glyph/add";
import NoDataSvg from "assets/images/policy/no_data.svg";
import {EmptyTableTemplate} from '../empty-table-tamplate'
import {INoDataProps} from './types'

export const NoData = ({onClick, title, btnText}: INoDataProps) => (
  <EmptyTableTemplate>
    <>
      <img src={NoDataSvg} alt="No data" />
      <h2 className='title'>{title}</h2>
      {onClick && (
        <Button
          appearance="default"
          onClick={onClick}
          iconBefore={(iconProps) => <AddIcon {...iconProps} size="small" />}
        >
          {btnText && 'Create new'}
        </Button>
      )}
    </>
  </EmptyTableTemplate>
)