import React, {FC} from 'react';
import './Forbidden.scss';

export const Forbidden: FC = () => {
    return (
        <div className={'forbidden'}>
            <h1>Emmmm..... </h1>
            <h1>I think it is Forbidden here.</h1>
            <h1>But I am not sure.</h1>
            <h1>Maybe you should ask your boss.</h1>
            <h1>Or you can just go back to the main page.</h1>
            <h1>Or you can just sign out.</h1>
            <h1>Or you can just close the browser.</h1>
            <h1>Or you can just turn off the computer.</h1>
            <h1>Or you can just throw the computer out of the window.</h1>
            <h1>Did you think about vacation?</h1>
            <h1>Or you can just go to the beach.</h1>
            <h1>Or you can just go to the mountain.</h1>
            <h1>Or you can just go to the forest.</h1>
        </div>
    );
};