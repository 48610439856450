import React, { FC, ReactNode } from "react";
import style from "./Auth.module.scss";
import LogoCore from "../../assets/images/logo/logo_core.svg";

interface AuthLayoutProps {
  children: ReactNode;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <>
      <div className={style.logWrapper}>
        <img src={LogoCore} alt="logo" />
      </div>
      {children}
    </>
  );
};
