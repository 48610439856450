import React from "react";
import BaseModal from "./BaseModal";
import { LevelFromApi } from "../../../../../../../shared/Employee/Level/data/LevelFromApi";
import { LEVELS } from "../../../../../../../utils/constants/api";
import { VALIDATION_ERROR } from "../../../../../../../utils/constants/errors";
import { ValidationError } from "../../../../../../../shared/Common/Error/ValidationError";

type EditLevel = {
  opened: boolean;
  onOk: () => void;
  onClose: () => any;
  level: LevelFromApi;
};

function EditLevel(props: EditLevel) {
  function edit(
    name: string,
    validate: (errors: ValidationError[]) => void
  ): void {
    fetch(LEVELS + "/" + props.level.id, {
      method: "PUT",
      body: JSON.stringify({
        name: name,
      }),
    }).then((response) => {
      if (response.ok) {
        props.onOk();
      } else if (response.status === VALIDATION_ERROR) {
        response.json().then((errors: ValidationError[]) => validate(errors));
      }
    });
  }

  return (
    <BaseModal
      opened={props.opened}
      modalTitle="Edit Job Level"
      name={props.level.name}
      okText="Edit"
      onCancel={props.onClose}
      onOk={edit}
    />
  );
}

export default EditLevel;
