import { http, graphql, HttpResponse } from "msw";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const handlers = [
  http.get(`${BASE_URL}/api/auth`, () => {
    return HttpResponse.json({
      roles: ["ROLE_EMPLOYEE", "ROLE_HR", "ROLE_ADMIN"],
      userIdentifier: "i.dmytrenko@netpeak.group",
      orgUnitId: "0189d5c0-9c4d-7203-a060-1c3bb681d4b3",
      nickname: "Gopher",
      email: "i.dmytrenko@netpeak.group",
      password: null,
      firstNameEn: "Ivan",
      lastNameEn: "Dmytrenko",
      id: "018fe2f5-e10e-73ed-8f21-21d648442ba6",
      imageUrl:
        "https://test02.one.netpeak.group/api/employees/images/018fe2f5-e10e-73ed-8f21-21d648442ba6/cropped_11835a66dc8f2992.jpg",
      accessLevels: ["ROLE_EMPLOYEE", "ROLE_HR", "ROLE_ADMIN"],
      workLocationId: "01887637-04f2-733c-9585-bd694c77e826",
      companyId: "0189d5c0-9c4d-7203-a060-1c3bb681d4b3",
      companyName: "Core General team",
    });
  }),
];
