export const createHead = (withWidth: boolean) => {
  return {
    cells: [
      {
        key: "Employee",
        content: "Employee",
        isSortable: true,
        width: withWidth ? 19 : undefined,
      },
      {
        key: "Date",
        content: "Date",
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 11 : undefined,
      },
      {
        key: "Day type",
        content: "Day type",
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 11 : undefined,
      },
      {
        key: "Check in",
        content: "Check in",
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 11 : undefined,
      },
      {
        key: "Check out",
        content: "Check out",
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 12 : undefined,
      },
      {
        key: "Break",
        content: "Break",
        shouldTruncate: true,
        isSortable: true,
        width: withWidth ? 14 : undefined,
      },
      {
        key: "Total",
        content: "Total",
        isSortable: true,
        shouldTruncate: true,
      },
      {
        key: "Difference",
        content: "Difference",
        isSortable: true,
        shouldTruncate: true,
      },
    ],
  };
};

export const head = createHead(true);
