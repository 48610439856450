import { Box, xcss } from "@atlaskit/primitives";
import { SpanBlock, RoundSpanBlock } from "components/UI/skeleton-table";

export default function RequestsSkeleton() {
  return (
    <Box
      xcss={xcss({
        marginLeft: "space.300",
        marginTop: "space.300",
      })}
    >
      <Box>
        <Box>
          <SpanBlock width="474px" height="28px" />
        </Box>
        <Box
          xcss={xcss({
            marginTop: "space.100",
          })}
        >
          <SpanBlock width="258px" height="32px" />
        </Box>
        <Box
          xcss={xcss({
            marginTop: "space.100",
            display: "flex",
            gap: "space.200",
            justifyContent: "space-between",
          })}
        >
          <Box
            xcss={xcss({
              display: "flex",
              gap: "space.200",
            })}
          >
            {new Array(2).fill("").map((_: unknown, index) => (
              <SpanBlock key={index} width="160px" height="32px" />
            ))}
          </Box>
          <SpanBlock width="224px" height="32px" />
        </Box>
      </Box>

      <Box
        xcss={xcss({
          marginTop: "space.400",
        })}
      >
        <Box
          xcss={xcss({
            display: "grid",
            gridTemplateColumns: "216px 116px 64px 284px 216px 132px 116px",
            borderBottom: "2px solid",
            borderColor: "color.border",
            paddingBottom: "space.050",
          })}
        >
          {new Array(7).fill("").map((_: unknown, index) => (
            <SpanBlock
              key={index}
              width="48px"
              height="16px"
              marginLeft="space.100"
            />
          ))}
        </Box>
        <Box
          xcss={xcss({
            display: "flex",
            flexDirection: "column",
            gap: "space.300",
            paddingTop: "space.200",
            paddingBottom: "space.200",
            borderBottom: "2px solid",
            borderColor: "color.border",
          })}
        >
          {new Array(3).fill("").map((_: unknown, index) => (
            <TableRow key={index} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function TableRow() {
  return (
    <Box
      xcss={xcss({
        display: "grid",
        gridTemplateColumns: "216px 116px 64px 284px 216px 132px 116px",
      })}
    >
      <Box
        xcss={xcss({
          display: "flex",
          gap: "space.100",
          paddingLeft: "space.100",
        })}
      >
        <RoundSpanBlock width="24px" height="24px" />{" "}
        <SpanBlock width="116px" height="24px" />
      </Box>

      <SpanBlock width="57px" height="24px" marginLeft="space.100" />
      <SpanBlock width="24px" height="24px" marginLeft="space.100" />
      <SpanBlock width="244px" height="24px" marginLeft="space.100" />
      <Box
        xcss={xcss({
          display: "flex",
          gap: "space.100",
          marginLeft: "space.100",
        })}
      >
        <RoundSpanBlock width="24px" height="24px" />{" "}
        <SpanBlock width="116px" height="24px" />
      </Box>
      {new Array(2).fill("").map((_: unknown, index) => (
        <SpanBlock
          key={index}
          width="70px"
          height="24px"
          marginLeft="space.100"
        />
      ))}
    </Box>
  );
}
