import {FC, useState} from "react";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import {BlockInfoItem} from "../../../BlockInfo";
import InlineEdit from "@atlaskit/inline-edit";
import {Input} from "../../../../UI/Input";
import {useProfile} from "../../../context";
import {InputText} from "../../../ViewingModes/InputText";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const WorkSchedule: FC = () => {
    const {isAccessEditRole, jobInfo, idOpenProfile, showErrorNotify} = useProfile();
    const [value, setValue] = useState<string>(jobInfo?.currentJobInfoValue.workSchedule || '');

    const {setField} = useSetField(idOpenProfile);

    const [mainErrorStateServ, setMainErrorStateServ] = useState<{
        clearError?: () => void | undefined;
        error?: string | undefined;
        valueBeforeError: string;
    }>({
        clearError: undefined,
        error: undefined,
        valueBeforeError: ''
    });

    return(
        <BlockInfoItem title="Work schedule">
            <div
                className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    {...(mainErrorStateServ.error && {isEditing: true})}
                    defaultValue={mainErrorStateServ.valueBeforeError ? mainErrorStateServ.valueBeforeError : value}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Input
                            {...fieldProps}
                            onChange={(event) => {
                                const value = event.currentTarget.value;
                                const isValid = /^([0-9]+)?$/.test(value);
                                if(isValid && (Number(value) >= 0 && Number(value) <= 24 || value === "")) {
                                    onChange(event);
                                }
                            }}
                            autoFocus={!mainErrorStateServ.error}
                            isInvalid={!!errorMessage || !!mainErrorStateServ.error}
                            autoComplete="off"
                            placeholder=""
                            className={style.profileInput}
                        />
                    )}
                    readView={() => (<InputText value={value}/>)}
                    onConfirm={(value) => {
                        mainErrorStateServ.clearError && mainErrorStateServ.clearError();
                        setMainErrorStateServ({
                            error: undefined,
                            valueBeforeError: '',
                            clearError: undefined
                        });
                        setField(['current-job-info', 'work_schedule', value || '0'].join('/')).then(() => {
                            setValue(value);
                        }).catch((error) => {
                            setMainErrorStateServ({
                                clearError: showErrorNotify(String(error.message)),
                                error: String(error.message),
                                valueBeforeError: value,
                            });
                        });
                    }}
                />
            </div>
        </BlockInfoItem>
    );
};