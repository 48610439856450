export interface StatusData {
    id: string;
    name: string;
    isActive: boolean;
}

export interface OptionType {
    label: string;
    value: string;
    isActive: boolean;
}

export interface GroupedOptionType {
    label: string;
    options: OptionType[];
}

export const formatAllowedStatusesTransitions = (data: StatusData[]): GroupedOptionType[] => {
    const activeOptions = data.filter(item => item.isActive).map(status => ({
        label: status.name,
        value: status.id,
        isActive: status.isActive
    }));

    const inactiveOptions = data.filter(item => !item.isActive).map(status => ({
        label: status.name,
        value: status.id,
        isActive: status.isActive
    }));

    return [
        { label: "Active Employee Status", options: activeOptions },
        { label: "Inactive Employee Status", options: inactiveOptions }
    ];
};