const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const AUTH: string = BASE_URL + "/api/auth";
export const GOOGLE_AUTH: string = BASE_URL + "/api/auth/google";
export const RANDOM_PHRASES: string = BASE_URL + "/api/random-phrases";
export const LINKS: string = BASE_URL + "/api/links";
export const GENDERS: string = BASE_URL + "/api/genders";
export const LEVELS: string = BASE_URL + "/api/levels";
export const STATUSES: string = BASE_URL + "/api/statuses";
export const STATUSES_TRANSITIONS: string =
  BASE_URL + "/api/statuses-transitions";
export const LOCATIONS: string = BASE_URL + "/api/locations";
export const ORG_CHART: string = BASE_URL + "/api/orgchart";
export const COUNTRIES: string = BASE_URL + "/api/countries";
export const RANDOM_PHRASES_WIDGET: string = BASE_URL + "/api/random-phrases/widget";
export const LINKS_WIDGET: string = BASE_URL + "/api/links/widget";
export const JOB_TITLES: string = BASE_URL + "/api/job-titles";
export const JOB_TITLES_CATEGORIES: string =
  BASE_URL + "/api/job-title-categories";
export const PUSH_NOTIFICATIONS: string = BASE_URL + "/api/push-notifications";
export const PROFESSIONAL_HOLIDAYS: string =
  BASE_URL + "/api/professional-holidays";
export const CORPORATE_EVENTS: string = BASE_URL + "/api/corporate-events";
export const STATE_HOLIDAYS: string = BASE_URL + "/api/state-holidays";
export const EMPLOYEES: string = BASE_URL + "/api/employees";
export const PAYROLL: string = BASE_URL + "/api/payroll";
export const EMPLOYEE: string = BASE_URL + "/api/employee";
export const TIME_OFF_POLICIES: string = BASE_URL + "/api/time-off-policies";
export const CALENDAR: string = BASE_URL + "/api/calendar";
export const HOLIDAYS: string = BASE_URL + "/api/holidays";
export const SEARCH: string = BASE_URL + "/api/employee-search";
export const ACCRUED_DAYS_BALANCES: string =
  BASE_URL + "/api/accrued-days-balances";
export const CALENDAR_WIDGET: string = BASE_URL + "/api/holidays/widget";
export const ACCESS_LEVELS: string = BASE_URL + "/api/access-levels";
export const EMPLOYEE_SETTINGS: string = BASE_URL + "/api/employee-settings";
export const FILTERS: string = BASE_URL + "/api/filters";
export const INTERVIEWS: string = BASE_URL + "/api/interviews";
export const ANNOUNCEMENTS: string = BASE_URL + "/api/announcements";
