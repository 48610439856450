import { FC, useState } from "react";
import Button from "@atlaskit/button/new";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Select, { type ValueType } from "@atlaskit/select";
import Form, { Field, ErrorMessage, HelperMessage } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import Tooltip from "@atlaskit/tooltip";
import { TypeIconsCollection } from "shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import EditorHorizontalRuleIcon from "@atlaskit/icon/glyph/editor/horizontal-rule";
import {
  StyledPolicyItem,
  StyledRadioGroup,
  StyledTextArea,
  StyledTimeOffLogModal,
  StyledTimeOffLogModalBody,
} from "./TimeOffLogModalStyles";

const policies = [
  {
    label: (
      <StyledPolicyItem>
        {TypeIconsCollection["vacation" as keyof typeof TypeIconsCollection](
          "small-icon",
          "withouthover"
        )}
        <p>(Auto-approve) NG general vacation, general vacation</p>
      </StyledPolicyItem>
    ),
    value: "1",
  },
  {
    label: (
      <StyledPolicyItem>
        {TypeIconsCollection[
          "businessTrip" as keyof typeof TypeIconsCollection
        ]("small-icon", "withouthover")}
        <p>Business-trip</p>
      </StyledPolicyItem>
    ),
    value: "2",
  },
  {
    label: (
      <StyledPolicyItem>
        {TypeIconsCollection["sickDay" as keyof typeof TypeIconsCollection](
          "small-icon",
          "withouthover"
        )}
        <p>Sick day</p>
      </StyledPolicyItem>
    ),
    value: "3",
  },
];

interface PolicyProps {
  label: JSX.Element;
  value: string;
}

interface TimeOffModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

export const TimeOffLogModal: FC<TimeOffModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const [commentCount, setCommentCount] = useState(0);

  return (
    <>
      <ModalTransition>
        {isModalOpen && (
          <Modal
            onClose={closeModal}
            shouldScrollInViewport
            autoFocus={false}
            width={400}
          >
            <StyledTimeOffLogModal>
              <Form
                onSubmit={() => {
                  console.log("Submit");
                }}
              >
                {({ formProps }) => (
                  <form id="form-with-id" {...formProps}>
                    <ModalHeader>
                      <ModalTitle>Edit Balance</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      <StyledTimeOffLogModalBody>
                        <Field<ValueType<PolicyProps>>
                          name="policy"
                          label="Select policy"
                        >
                          {({ fieldProps: { id, ...rest }, error }) => (
                            <>
                              <Select<PolicyProps>
                                inputId={id}
                                options={policies}
                                maxMenuHeight={204}
                                placeholder="Policy"
                                className="timeofflog-modal-policy"
                                {...rest}
                              />
                              <ErrorMessage>
                                Please, choose the policy
                              </ErrorMessage>
                            </>
                          )}
                        </Field>

                        <Field label="Amount" name="amount">
                          {({ fieldProps, error }: any) => (
                            <>
                              <StyledRadioGroup>
                                <label className="timeofflog-radio">
                                  <input
                                    type="radio"
                                    name="amount-sign"
                                    id="amountSignPlus"
                                  />
                                  <Tooltip content="Add">
                                    {(tooltipProps) => (
                                      <>
                                        <div
                                          className="timeofflog-radio-btn"
                                          {...tooltipProps}
                                        >
                                          <EditorAddIcon
                                            label="plus"
                                            size="small"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </Tooltip>
                                </label>
                                <label className="timeofflog-radio">
                                  <input
                                    type="radio"
                                    name="amount-sign"
                                    id="amountSignMinus"
                                  />
                                  <Tooltip content="Subtract">
                                    {(tooltipProps) => (
                                      <>
                                        <div
                                          className="timeofflog-radio-btn"
                                          {...tooltipProps}
                                        >
                                          <EditorHorizontalRuleIcon
                                            label="minus"
                                            size="small"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </Tooltip>
                                </label>
                              </StyledRadioGroup>
                              <Textfield
                                className="timeofflog-amount"
                                type="number"
                                min={0}
                                {...fieldProps}
                              />
                              <HelperMessage>
                                Enter the number to add or subtract
                              </HelperMessage>
                              <ErrorMessage>
                                Enter the number to add or subtract
                              </ErrorMessage>
                              <ErrorMessage>
                                The number of days must not exceed 366 days
                              </ErrorMessage>
                            </>
                          )}
                        </Field>

                        <Field label="Add a comment" name="comment">
                          {({ fieldProps, error }: any) => (
                            <StyledTextArea>
                              <TextArea
                                placeholder="Add a comment"
                                maxHeight={86}
                                maxLength={107}
                                {...fieldProps}
                              />
                              <HelperMessage>
                                {commentCount} / 107
                              </HelperMessage>
                              <ErrorMessage>Enter comment</ErrorMessage>
                            </StyledTextArea>
                          )}
                        </Field>
                      </StyledTimeOffLogModalBody>
                    </ModalBody>
                    <ModalFooter>
                      <Button appearance="subtle" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button appearance="primary" type="submit">
                        Save
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Form>
            </StyledTimeOffLogModal>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
