import {useState} from 'react';
import {useEvent} from '../../customHooks/useEvent';
import {signIn as fetchSignIn} from './signIn';

export const useSignIn = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);

    const resetStatuses = useEvent(() => {
        setLoading(false);
        setSuccess(false);
        setError(false);
    });

    const signIn = useEvent((email: string, password: string) => {
        setLoading(true);
        return fetchSignIn(email, password)
            .then((data) => {
                setSuccess(true);
                return data;
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return {
        isLoading,
        isSuccess,
        isError,
        signIn,
        resetStatuses
    };
};