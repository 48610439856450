import { FC, useState, useEffect } from "react";
import { TypeIconsCollection } from "../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import Select, { ValueType } from "@atlaskit/select";
import { Field, ErrorMessage } from "@atlaskit/form";
import { StyledPolicyItem, StyledPolicySelect } from "./PolicySelectStyles";
import { OptionJSX } from "../../../../interfaces/timeOffPolicy/Select/optionJSX.interface";
import { PolicySelectProps } from "../../../../interfaces/timeOffPolicy/Select/PolicySelectProps.interface";

export const PolicySelect: FC<PolicySelectProps> = ({
  selectedPolicy,
  setSelectedPolicy,
  timeOffPolicies,
  setLoadingPolicies,
}) => {
  const options: OptionJSX[] = timeOffPolicies.map((policy) => ({
    label: (
      <StyledPolicyItem>
        {TypeIconsCollection[
          policy.typeIcon as keyof typeof TypeIconsCollection
        ]("small-icon", "withouthover")}
        <p>{policy.name}</p>
      </StyledPolicyItem>
    ),
    value: policy.id,
    name: policy.name,
  }));

  const [defaultValue, setDefaultValue] = useState<ValueType<OptionJSX>>(null);

  useEffect(() => {
    const foundOption = selectedPolicy
      ? options.find((option) => option.value === selectedPolicy.id)
      : null;
    setDefaultValue(foundOption ?? null);
  }, [selectedPolicy, options]);

  const handleChange = (selectedOption: ValueType<OptionJSX>) => {
    setLoadingPolicies(true);
    const selectedId = (selectedOption as OptionJSX)?.value;
    const selectedPolicy =
      timeOffPolicies.find((policy) => policy.id === selectedId) || null;
    setSelectedPolicy(selectedPolicy);
  };

  return (
    <StyledPolicySelect>
      <Field<ValueType<OptionJSX>> name="policy" label="Select policy">
        {({
          fieldProps: { id, value: _value, onChange: _onChange, ...rest },
          error,
        }) => (
          <>
            <Select<OptionJSX>
              inputId={id}
              isSearchable={true}
              options={options}
              maxMenuHeight={204}
              placeholder="Policy"
              className="modal-policy-select"
              onChange={handleChange}
              value={defaultValue}
              filterOption={(option, inputValue) =>
                option.data.name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              {...rest}
            />
            {error && <ErrorMessage>Please, choose the policy</ErrorMessage>}
          </>
        )}
      </Field>
    </StyledPolicySelect>
  );
};
