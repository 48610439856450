import React from 'react';
import {RANDOM_PHRASES} from '../../../../../utils/constants/api';
import { RandomPhraseFromApi } from '../../../../../shared/RandomPhrase/data/RandomPhraseFromApi';
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';

type DeleteRandomPhrase = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    randomPhrase: RandomPhraseFromApi
};

function DeleteRandomPhrase(props: DeleteRandomPhrase) {
    function deleteRandomPhrase(): void {
        fetch(RANDOM_PHRASES + '/' + props.randomPhrase.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then();
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Random Phrase'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.randomPhrase.text + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteRandomPhrase }
        />
    )
}

export default DeleteRandomPhrase;