import React from 'react';
import BaseModal  from './BaseModal';
import { GenderFromApi } from '../../../../../../shared/Employee/Gender/data/GenderFromApi';
import { GENDERS } from '../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError';

type EditGender = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    gender: GenderFromApi,
};

function EditGender(props: EditGender) {
    function edit(name: string, validate: (errors: ValidationError[]) => void): void {
        fetch(GENDERS + '/' + props.gender.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Gender Pronouns'
            name={ props.gender.name }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditGender;