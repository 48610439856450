import { useState, useCallback } from "react";
import { AnnouncementFormType  } from 'interfaces/common/announcements.interface'
import { announcementAPI } from 'API/announcements/announcements';

export const useEditAnnouncement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);


  const editAnnouncement = useCallback(async (id: string, payload: AnnouncementFormType) => {
    setLoading(true);
    
    try {
      await announcementAPI.updateAnnouncement(id, payload);

    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    loading,
    error,
    editAnnouncement,
  };
};
