import {FC, useEffect, useState} from "react";
import {useProfile} from "../../../context";
import {useLevel, LevelProps} from "../../../../../API/profile/fields/jobInfo/level";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {type OnChangeValue, Select} from "../../../../UI/Select";
import {InputText} from "../../../ViewingModes/InputText";
import {BlockInfoItem} from "../../../BlockInfo";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const LevelField: FC = () => {
    const {isAccessEditRole, jobInfo, idOpenProfile} = useProfile();
    const {fetchLevels, levels, isLoading} = useLevel();

    const [picked, setPicked] = useState<LevelProps>();

    const {setField} = useSetField(idOpenProfile);

    useEffect(() => {
        fetchLevels().then((response) => {
            if(jobInfo?.currentJobInfoValue.levelId) {
                const level = response.find(
                    level => level.value === jobInfo.currentJobInfoValue.levelId
                );

                setPicked(level);
            }
        });
    }, []);

    return(
        <BlockInfoItem title="Job level">
            <div className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    hideActionButtons
                    defaultValue={picked}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Select
                            {...fieldProps}
                            appearance="subtle"
                            spacing="compact"
                            inputId="single-select-example-clearable1"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(newValue: OnChangeValue<LevelProps, false>) => {
                                onChange(newValue as any);
                                setField(['current-job-info', 'level_id', newValue?.id || 'null'].join('/'));
                            }}
                            className={style.profileSelect}
                            autoFocus
                            openMenuOnFocus
                            isLoading={isLoading}
                            options={levels}
                            placeholder="Choose a Employee Level"
                        />
                    )}
                    readView={() => (<InputText value={picked && picked.label} />)}
                    onConfirm={(value) => setPicked(value)}
                />
            </div>
        </BlockInfoItem>
    );
};