import {
  ATTENDANCE,
  WORK_TIME_CORRECTION,
  ATTENDANCE_EVENT,
} from "../constants";
import {
  CorrectionTotalTimeProps,
  GetAttendanceLogProps,
  UpdateTimeAPIProps,
} from "./attendanceLog.types";

export const attendanceLog = async ({
  employeeIds,
  dayTypes,
  startDate,
  endDate,
}: GetAttendanceLogProps) => {
  try {
    const response = await fetch(ATTENDANCE, {
      method: "POST",
      body: JSON.stringify({
        employeeIds: employeeIds,
        dayTypes: dayTypes,
        startDate: startDate,
        endDate: endDate,
      }),
    });

    if (!response.ok || response.status !== 200) {
      const error = await response.json();
      throw new Error(error[0].message);
    }

    return await response.json();
  } catch (error: any) {
    return {
      error: String(error.message),
    };
  }
};

export const correctionTotalTime = async ({
  employeeId,
  actionEmployeeId,
  date,
  timeCorrection,
}: CorrectionTotalTimeProps) => {
  try {
    const response = await fetch(WORK_TIME_CORRECTION, {
      method: "POST",
      body: JSON.stringify({
        employeeId: employeeId,
        actionEmployeeId: actionEmployeeId,
        date: date,
        timeCorrection: timeCorrection,
      }),
    });

    if (!response.ok || response.status !== 200) {
      const error = await response.json();
      throw new Error(error[0].message);
    }

    return await response.json();
  } catch (error: any) {
    return {
      error: String(error.message),
    };
  }
};

export const updateTimeAPIs = async ({ eventId, time }: UpdateTimeAPIProps) => {
  try {
    const response = await fetch(`${ATTENDANCE_EVENT}/${eventId}`, {
      method: "PUT",
      body: JSON.stringify({
        time: time,
      }),
    });

    if (!response.ok || response.status !== 200) {
      const error = await response.json();
      throw new Error(error[0].message);
    }

    return await response;
  } catch (error: any) {
    return {
      error: String(error.message),
    };
  }
};
