import React from 'react';
import BaseModal  from './BaseModal';
import { PROFESSIONAL_HOLIDAYS } from '../../../../../../utils/constants/api';
import { ProfessionalHolidayFromApi } from '../../../../../../shared/Holiday/ProfessionalHoliday/data/ProfessionalHolidayFromApi';
import moment from 'moment/moment';
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError';
import { OrgUnitFromApi } from '../../../../../../shared/OrgChart/data/OrgUnitFromApi';
import { SelectorData } from '../../../../../Component/Input/DateSelector/data/SelectorData';

type EditProfessionalHoliday = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    professionalHoliday: ProfessionalHolidayFromApi,
    orgUnits: OrgUnitFromApi[],
};

function EditProfessionalHoliday(props: EditProfessionalHoliday) {
    function edit(
        name: string,
        orgUnits: string[],
        type: string,
        movingType: SelectorData | null,
        date: Date | null,
        repeatAnnually: boolean,
        text: string | null,
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(PROFESSIONAL_HOLIDAYS + '/' + props.professionalHoliday.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                orgUnits: orgUnits,
                type:  type,
                movingType: type === 'moving' ? movingType : null,
                date: date ? date.getFullYear() + '-' + (date.getMonth() + 1) + "-" + date.getDate() : null,
                repeatAnnually: repeatAnnually,
                text: text,
                visible: visible,
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit Professional Holiday'
            name={ props.professionalHoliday.value.name }
            text={ props.professionalHoliday.value.text }
            orgUnitValues={ props.orgUnits }
            orgUnits={props.professionalHoliday.value.orgUnits.map(orgUnit => {
                let orgUnitName = '';
                props.orgUnits.map(orgUnitValue => {
                    if (orgUnitValue.id === orgUnit) {
                        orgUnitName = orgUnitValue.name;
                    }
                })
                return {
                    label: orgUnitName,
                    value: orgUnit
                }
            }).filter(orgUnit => orgUnit.label !== '')}
            date={ props.professionalHoliday.value.date ? new Date(moment(props.professionalHoliday.value.date).format('YYYY-MM-DD HH:mm')) : null }
            repeatAnnually={ props.professionalHoliday.value.repeatAnnually }
            type={ !props.professionalHoliday.value.movingType ? 'fixed' : 'moving' }
            visible={ props.professionalHoliday.value.visible }
            movingType={ props.professionalHoliday.value.movingType
                ? props.professionalHoliday.value.movingType
                : {'month': '1', 'weekNumber': '1', 'dayOfWeek': 'Monday'} as SelectorData
        }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditProfessionalHoliday;