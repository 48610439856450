import {
  ReactNode,
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Box, Stack, Inline, xcss } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import SuccessIcon from "@atlaskit/icon/glyph/check-circle";
import Flag from "@atlaskit/flag";
import styled from "styled-components";

const FlagWrapper: FC<{
  type?: string;
  children: ReactNode;
}> = styled.div`
  div[role="alert"] > div {
    background-color: ${(props) =>
      props.type === "success"
        ? token("color.background.success")
        : "auto"} !important;
  }
`;

export default function Notification({
  notes,
  setNotes,
  type,
}: {
  notes: string[];
  setNotes: (arg: string[]) => void;
  type?: string;
}) {
  useEffect(() => {
    setTimeout(() => {
      setNotes([]);
    }, 2000);
  }, [notes[0]]);

  return (
    <Box
      xcss={xcss({
        width: "348px",
        position: "absolute",
        inset: "0 0 0 0",
        top: "100%",
        left: "24px",
        marginTop: "-84px",
      })}
    >
      <FlagWrapper type={type}>
        <Flag
          icon={
            <SuccessIcon
              primaryColor={token("color.icon.success")}
              label="Success"
            />
          }
          id="1"
          key="1"
          title={notes[0]}
          actions={[]}
        />
      </FlagWrapper>
    </Box>
  );
}
