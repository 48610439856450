import {useState} from "react";
import {GenderPronounceOptionProps} from './genderPronounce.types';
import {useQuery} from "../../../../useQuery";
import {fetchGenderPronounceAPI} from "./genderPronounce";
import {useEvent} from "../../../../../customHooks/useEvent";

export const useGenderPronounce = () => {
    const [genderPronounce, setGenderPronounce] = useState<Array<GenderPronounceOptionProps>>([]);
    const [getAllGenderPronounce, isLoading, isSuccess, isError] = useQuery(fetchGenderPronounceAPI);

    const fetchGenderPronounce = useEvent(async () => {
        return getAllGenderPronounce().then((response) => {
            setGenderPronounce(response);
            return response;
        });
    });

    return {
        genderPronounce: genderPronounce,
        fetchGenderPronounce: fetchGenderPronounce,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
    };
};