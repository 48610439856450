import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './FormerEmployees.scss';
import Button, {Size, Type} from '../../../../../Component/Button/Button';
import {CreateEmployee} from '../Current/Modal/CreateEmployee';
import {EMPLOYEES} from '../../../../../../utils/constants/api';
import {EmployeeFromApi} from '../../../../../../shared/Employee/data/EmployeeFromApi';
import {Link} from "react-router-dom";
import {EMPLOYEE_PROFILE} from "../../../../../../utils/constants/routes";
import moment from "moment";
import Select from "../../../../../Component/Input/SelectInput/SelectInput";
import {SelectValue} from "../../../../../../shared/Common/data/SelectValue";
import DeleteEmployee from "../Current/Modal/DeleteEmployee";
import {ROLE_ADMIN, ROLE_HR} from "../../../../../../shared/Security/constants/AccessLevels";
import RehireEmployee from "../../Profile/JobInfo/Modal/RehireEmployee";
import Pagination from "../../../../../../utils/helpers/Pagination";
import {assertEmployee, useEmployee} from "../../../../../../contexts/EmployeeContext";

export const FormerEmployees: FC = () => {
    const {employee} = useEmployee();
    assertEmployee(employee);

    const perPageOptions: SelectValue[] = [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
    ];
    const navigate = useNavigate();
    const [perPage, setPerPage] = useState({ label: '50', value: '50' });
    const [page, setPage] = useState('1');
    const [createEmployeeModal, setCreateEmployeeModal] = useState<boolean>(false);
    const [employees, setEmployees] = useState<EmployeeFromApi[]>([]);
    const [former, setFormer] = useState<string>('0');
    const fetchIdRef = useRef(0);
    const [deleteEmployeeModal, setDeleteEmployeeModal] = useState<boolean>(false);
    const [employeeToDelete, deleteEmployee] = useState<EmployeeFromApi | null>(null);
    const hasAdminRole = employee.roles.includes(ROLE_ADMIN);
    const hasHRRole = employee.roles.includes(ROLE_HR);
    const [isRehireModalOpen, setIsRehireModalOpen] = useState(false);
    const [employeeToRehire, setRehireEmployee] = useState<EmployeeFromApi | null>(null);

    useEffect(() => {
        getEmployees();
    }, [page, perPage])

    useEffect(() => {
        getFormer();
    },[])

    useEffect(() => {
        setPage('1');
    }, [perPage]);

    function getEmployees() {
        const currentFetchId = ++fetchIdRef.current;
        const pageParam = page ? page : "1";
        const perPageParam = perPage ? perPage.value : "50";
        return fetch(EMPLOYEES + '/type/inactive' + '?page=' + pageParam + '&per-page=' + perPageParam, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((employees: EmployeeFromApi[]) => {
                if (currentFetchId === fetchIdRef.current) {
                    setEmployees(employees);
                }
            });
    }

    function getFormer() {
        return fetch(EMPLOYEES + '/total?status=inactive', {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-type': 'application/json' }
        })
            .then(response => response.json())
            .then((former: string) => setFormer(former));
    }

    function confirmDeleteEmployeeModalVisibility(employee: EmployeeFromApi): void {
        deleteEmployee(employee)
        setDeleteEmployeeModal(true);
    }

    const handleOk = () => {
        setIsRehireModalOpen(false);
        navigate(EMPLOYEE_PROFILE + '/' + employeeToRehire?.id);
        setRehireEmployee(null);
    };

    return (
        <div className={'former-employees'}>
            <div className='add-button-block'>
            <Button
                text="<i className='material-icons add-item'>add</i> Add Employee"
                size={ Size.Medium }
                type={ Type.Accent }
                onClick={ () => setCreateEmployeeModal(true) }
            />
            </div>
            <table className="former-employees-table">
                <thead>
                <tr>
                    <th>
                        Employee
                    </th>
                    <th>
                        First Name(EN)
                    </th>
                    <th>
                        Last Name(EN)
                    </th>
                    <th>
                        Email
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {employees.map(employee => {
                    return (
                        <tr key={employee.id}>
                            <td>
                                <Link to={EMPLOYEE_PROFILE + '/' + employee.id} target={'_blank'}>
                                    {employee.avatar ?
                                        <div className='with-avatar'>
                                            <img
                                                src={employee.avatar} alt="cropped"
                                            />
                                        </div> :
                                        <div className='without-avatar'>
                                            <p>{employee.firstNameEn.charAt(0).toUpperCase()}</p>
                                        </div>
                                    }
                                </Link>
                            </td>
                            <td>{employee.firstNameEn}</td>
                            <td>{employee.lastNameEn}</td>
                            <td>{employee.email}</td>
                            <td>
                                <Link to={EMPLOYEE_PROFILE + '/' + employee.id}>
                                    <i title="view profile" className='material-icons'>account_box</i>
                                </Link>
                                {hasAdminRole || hasHRRole ? <i
                                    className='material-icons'
                                    title="rehire employee"
                                    onClick={() => {
                                        setRehireEmployee(employee)
                                        setIsRehireModalOpen(true);
                                    }}
                                >
                                    person_add
                                </i> : null
                                }
                                {hasAdminRole && <i
                                    title="delete employee"
                                    className='material-icons'
                                    onClick={() => confirmDeleteEmployeeModalVisibility(employee)}
                                >
                                    delete
                                </i>
                                }
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination">
            <div className="pagination-info">
                    <span>
                        <i onClick={() => parseInt(page) > 1 && setPage((parseInt(page) - 1).toString())}
                           className={`material-icons left ${page === '1' ? 'disabled-icon' : ''}`}
                        >keyboard_arrow_left</i>
                    </span>

                    {page} / {Math.ceil(parseInt(former) / parseInt(perPage.value))}

                    <span>
                      <i onClick={() => parseInt(page) < Math.ceil(parseInt(former) / parseInt(perPage.value)) && setPage((parseInt(page) + 1).toString())}
                         className={`material-icons right ${page === Math.ceil(parseInt(former) / parseInt(perPage.value)).toString() ? 'disabled-icon' : ''}`}
                      >keyboard_arrow_right</i>
                    </span>

                    <span className="pagination-total">{former} <span>results</span></span>
                </div>
                <Pagination currentPage={parseInt(page)} totalPages={Math.ceil(parseInt(former) / parseInt(perPage.value))} setPage={(page) => setPage(page.toString())} />
                <div className={'rows-per-page'}>
                    <span>Rows per page:</span>
                    <div className={'rows-per-page-filter'}>
                        <Select
                            label={''}
                            required={false}
                            error={''}
                            isMulti={ false }
                            value={ perPage }
                            options={ perPageOptions }
                            onChange={ (e: any) => {
                                setPerPage(e);
                            }}
                            isSearchable={ false }
                        />
                    </div>
                </div>
            </div>
            <CreateEmployee
                opened={ createEmployeeModal }
                onOk={ () => { getEmployees(); setCreateEmployeeModal(false); } }
                onClose={ () => setCreateEmployeeModal(false) }
            />
            {
                employeeToDelete ?
                    <DeleteEmployee
                        opened={ deleteEmployeeModal }
                        onOk={ () => { getEmployees(); setDeleteEmployeeModal(false); deleteEmployee(null) } }
                        onClose={ () => { setDeleteEmployeeModal(false); deleteEmployee(null) } }
                        employee={ employeeToDelete }
                    /> : null
            }
            {employeeToRehire && isRehireModalOpen ?
                <RehireEmployee
                    opened={isRehireModalOpen}
                    onOk={() => handleOk()}
                    onClose={() => {
                        setIsRehireModalOpen(false);
                        setRehireEmployee(null);
                    }}
                    employee={employeeToRehire}
                /> : null
            }
        </div>
    );
};