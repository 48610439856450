import React, { FC, useEffect, useState } from "react";
import style from "./ChangeBreak.module.scss";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Button } from "../../UI/Button";
import { Input } from "../../UI/Input";
import { UpdateTimeAPIProps } from "API/attendanceLog/attendanceLog.types";

interface ChangeBreakProps {
  isOpen: boolean;
  closeModal: () => void;
  dataTime: Record<string, any> | undefined;
  breakTimeToChange: Record<string, any> | undefined;
  forceUpdate: number;
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>;
  updateTime: (data: UpdateTimeAPIProps) => Promise<
    | Response
    | {
        error: string;
      }
  >;
}

export const ChangeBreak: FC<ChangeBreakProps> = ({
  dataTime,
  isOpen,
  closeModal,
  breakTimeToChange,
  forceUpdate,
  setForceUpdate,
  updateTime,
}) => {
  const [start, setStart] = useState(dataTime?.start.time);
  const [end, setEnd] = useState(dataTime?.end.time);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setStart(dataTime?.start.time);
      setEnd(dataTime?.end.time);
    }
  }, [isOpen, dataTime]);

  useEffect(() => {
    validate();
  }, [start, end]);

  const changeBreakTimes = async () => {
    if (breakTimeToChange) {
      try {
        await updateTime({
          eventId: breakTimeToChange.start.id,
          time: start,
        });
        await updateTime({
          eventId: breakTimeToChange.end.id,
          time: end,
        });
        setForceUpdate(forceUpdate + 1);
      } catch (error) {
        console.error("Error updating times:", error);
      }
    }
    closeModal();
  };

  const validate = () => {
    const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (!start || !end || !timeFormat.test(start) || !timeFormat.test(end)) {
      setIsValid(false);
      return;
    }

    const [startHours, startMinutes] = start.split(":").map(Number);
    const [endHours, endMinutes] = end.split(":").map(Number);

    if (
      endHours < startHours ||
      (endHours === startHours && endMinutes <= startMinutes)
    ) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal} width='small'>
          <ModalHeader>
            <ModalTitle>Change break hours</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className={style.originTime}>
              Original Time{" "}
              <span>
                {dataTime?.start.time} - {dataTime?.end.time}
              </span>
            </div>
            <div>
              <div className={style.inputTitle}>Start break</div>
              <Input
                value={start}
                onChange={(event) => {
                  setStart(event.currentTarget.value);
                }}
              />
            </div>
            <div>
              <div className={style.inputTitle}>End break</div>
              <Input
                value={end}
                onChange={(event) => {
                  setEnd(event.currentTarget.value);
                }}
              />
            </div>
            <div className={style.originTimeTotal}>
              New break time{" "}
              <span>
                {start} - {end}
              </span>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button appearance='subtle' onClick={closeModal}>
              Cancel
            </Button>
            <Button
              appearance='primary'
              onClick={() => {
                if (isValid) {
                  changeBreakTimes();
                }
              }}
              isDisabled={!isValid}
            >
              Change
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};
