import { Link } from "react-router-dom";
import {
  ReactElement,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";
import DrawerWithSpoiler from "../UI/DrawerWithSpoiler";
import Avatar from "@atlaskit/avatar";
import { Box, xcss } from "@atlaskit/primitives";
import { INTERVIEWS, FILTERS, ORG_CHART } from "../../utils/constants/api";
import { EMPLOYEE_PROFILE } from "../../utils/constants/routes";
import MultiSelectCustom from "../UI/MultiSelectCustom/MultiSelectCustom";
import Button from "@atlaskit/button";
import { token } from "@atlaskit/tokens";
import styled from "styled-components";
import { ReactComponent as Stars } from "../../assets/images/ProfileIcons/Stars.svg";
import { useEmployee } from "../../contexts/EmployeeContext";
import { useSubordinates } from "../../API/profile/subordinates";
import Tooltip from "@atlaskit/tooltip";
import { StyledAIAnalysisPage } from "./AIAnalysisStyles";

const AdviceButtonWrapper: FC<{
  children: ReactNode;
}> = styled.div`
  button {
    width: 216px;
    background-color: ${(props) => token("color.background.neutral")};
    color: ${token("color.text.inverse")};
    padding-left: ${token("space.150")};
    padding-right: ${token("space.150")};
    display: flex !important;
    justify-content: center;
    align-items: center;

    svg path {
      fill: ${token("color.text.discovery")};
    }
  }

  button span {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const AvatarWrapper: FC<{
  children: ReactElement;
}> = styled.div`
  & > div > span {
    svg {
    }
    margin: 0px;
  }
`;

interface User {
  advice: { [key: string]: string[] };
  avatar: string;
  date: string;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  nickname: string;
  orgUnitId: string;
  orgUnitName: string;
  status: string;
  companyName: string;
}

function Tile({
  user,
  onButtonPress,
}: {
  user: User;
  onButtonPress: () => void;
}) {
  return (
    <Box
      key={user.employeeId}
      xcss={xcss({
        paddingTop: "space.200",
        paddingBottom: "space.200",
        paddingRight: "space.300",
        paddingLeft: "space.200",
        width: "368px",
        height: "128px",
        overflow: "hidden",
        border: "1px solid red",
        borderRadius: "border.radius.100",
        borderColor: "color.border.disabled",
        display: "flex",
      })}
    >
      <Box
        xcss={xcss({
          position: "relative",
          marginRight: "space.200",
        })}
      >
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={EMPLOYEE_PROFILE + "/" + user.employeeId}
        >
          <AvatarWrapper>
            <Avatar src={user.avatar} size="xlarge" />
          </AvatarWrapper>
        </Link>
      </Box>
      <Box>
        <Box
          xcss={xcss({
            color: "color.text.subtle",
            fontFamily: "inherit",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
          })}
        >
          <Link
            style={{ color: "inherit" }}
            to={EMPLOYEE_PROFILE + "/" + user.employeeId}
          >
            <Tooltip
              position={"bottom"}
              content={`${user.firstName} ${user.lastName}`}
            >
              <Box
                xcss={xcss({
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "216px",
                  height: "24px",
                  whiteSpace: "nowrap",
                })}
              >
                {user.firstName} {user.lastName}
              </Box>{" "}
            </Tooltip>
          </Link>
        </Box>
        <Box
          xcss={xcss({
            color: "color.text.subtlest",

            fontFamily: "inherit",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",

            minHeight: "20px",
          })}
        >
          {user.nickname}
        </Box>

        <Box
          xcss={xcss({
            color: "color.text.subtlest",
            fontFamily: "inherit",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            marginTop: "space.250",
          })}
        >
          <AdviceButtonWrapper>
            <Button
              onClick={(e) => {
                onButtonPress();
              }}
            >
              <Stars style={{ marginRight: "4px" }} />
              Show recommendations
            </Button>
          </AdviceButtonWrapper>
        </Box>
      </Box>
    </Box>
  );
}

type Option = {
  label: string;
  value: string;
  company?: string;
  orgUnitId?: string | null;
};

export function AIAnalysis() {
  const { employee, signIn } = useEmployee();

  const { subordinates, fetchSubordinates, isLoading } = useSubordinates(
    employee?.id as string
  );

  const [employeesOptions, setEmployeesOptions] = useState<Option[]>([]);
  const [selectedEmployeesOptions, setSelectedEmployeesOptions] = useState<
    Option[]
  >([]);

  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([]);
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<
    Option[]
  >([]);

  const [advices, setAdvices] = useState<User[]>([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedEmployeeAdvice, setSelectedEmployeeAdvice] = useState<{
    [key: string]: string[];
  }>({});

  const [selectedEmployee, setSelectedEmployee] = useState<{
    [key: string]: any;
  }>({});

  useEffect(() => {
    fetchSubordinates();

    fetch(INTERVIEWS + "/advices-for-team")
      .then((res) => {
        return res.json();
      })
      .then((data: User[]) => {
        setAdvices(data);

        const orgUnits: { label: string; value: string; company: string }[] =
          [];

        Object.entries(data).forEach((item) => {
          if (orgUnits.find((obj) => obj.value === item[1].orgUnitId)) {
          } else {
            orgUnits.push({
              label: item[1].orgUnitName,
              value: item[1].orgUnitId,
              company: item[1].companyName,
            });
          }
        });

        setOrgUnitOptions(orgUnits);

        setEmployeesOptions(
          Object.entries(data).map((item: any) => {
            return {
              value: item[1].employeeId,
              label: `${item[1].firstName} ${item[1].lastName} ${
                item[1].nickname ? "(" + item[1].nickname + ")" : ""
              }`,
              email: item[1].email,
              orgUnitId: item[1].orgUnitId,
            };
          })
        );
      });
  }, []);

  const employeesOptionsFiltered =
    selectedOrgUnitOptions.length > 0
      ? employeesOptions.filter((item) => {
          return selectedOrgUnitOptions.find((unit) => {
            return unit.value === item.orgUnitId;
          });
        })
      : employeesOptions;

  return (
    <StyledAIAnalysisPage>
      <Box>
        <h1
          style={{
            color: token("color.text"),
            fontFamily: "inherit",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "28px",
          }}
        >
          AI personality analysis
        </h1>
      </Box>
      <Box
        xcss={xcss({
          marginTop: "space.300",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        })}
      >
        <Box
          xcss={xcss({
            display: "flex",
            gap: "space.200",
          })}
        >
          {orgUnitOptions.length > 1 && (
            <MultiSelectCustom
              onSelectClick={() => {}}
              hasSelectAll={false}
              placeholder={"Org Unit"}
              labelledBy={"All Org Units"}
              options={orgUnitOptions}
              onChange={(values) => {
                setSelectedOrgUnitOptions(values as Option[]);
              }}
              value={selectedOrgUnitOptions}
            />
          )}
          {employeesOptions.length > 1 && (
            <MultiSelectCustom
              onSelectClick={() => {}}
              hasSelectAll={false}
              placeholder={"Employee"}
              labelledBy={"Employee"}
              options={employeesOptionsFiltered}
              onChange={(values) => {
                setSelectedEmployeesOptions(values as Option[]);
              }}
              value={selectedEmployeesOptions}
            />
          )}
        </Box>
      </Box>
      <Box
        xcss={xcss({
          display: "flex",
          flexWrap: "wrap",
          columnGap: "space.250",
          rowGap: "space.300",
          paddingTop: "space.300",
        })}
      >
        {advices
          .filter((user) => {
            return selectedEmployeesOptions.length > 0
              ? selectedEmployeesOptions.find((item) => {
                  return item.value === user.employeeId;
                })
              : employeesOptionsFiltered.find((item) => {
                  return item.value === user.employeeId;
                });
          })
          .map((user) => {
            return (
              <Tile
                key={user.employeeId}
                user={user}
                onButtonPress={() => {
                  setOpenDrawer(true);
                  setSelectedEmployeeAdvice(user.advice);
                  setSelectedEmployee(user);
                }}
              />
            );
          })}
      </Box>

      <DrawerWithSpoiler
        open={openDrawer}
        setOpen={setOpenDrawer}
        advice={selectedEmployeeAdvice}
        title={"Оцінка та рекомендації"}
        subTitle={
          <Box
            xcss={xcss({
              display: "flex",
              alignItems: "center",
              paddingTop: "space.150",
              paddingBottom: "space.150",
            })}
          >
            <Box
              xcss={xcss({
                paddingLeft: "space.100",
                paddingRight: "space.100",
              })}
            >
              <Avatar src={selectedEmployee.avatar} size="small" />
            </Box>
            <Box
              xcss={xcss({
                color: "color.text",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
              })}
            >
              {selectedEmployee.firstName} {selectedEmployee.lastName}
            </Box>
          </Box>
        }
      />
    </StyledAIAnalysisPage>
  );
}
