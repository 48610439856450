import React, {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import InfoIcon from '../../../assets/images/resetPassword/info.svg';
import {LoadingButton} from '@atlaskit/button';
import {ErrorMessage, HelperMessage, Label} from "@atlaskit/form";

import {useSignIn} from '../../../API/auth/useSignIn';
import {useLoginWithGoogle} from '../../../API/auth/useLoginWithGoogle';

import {AUTH_FORGOT_PASSWORD, MAIN} from '../../../Router/routes';

import {useEmployee} from "../../../contexts/EmployeeContext";

import { AuthLayout } from '../AuthLayout';
import { Input } from '../../UI/Input';
import { PasswordInput } from "../../UI/PasswordInput";
import { Button } from '../../UI/Button';

import { ReactComponent as GoogleIcon } from '../../../assets/images/logo/google-logo.svg';

import styleAuth from "../Auth.module.scss";
import style from './SignIn.module.scss';

interface SignInFormFields {
    email: string;
    password: string;
}

const signInSchema = yup.object().shape({
    email: yup
        .string()
        .required('Email don\'t match')
        .email('Email is invalid'),
    password: yup
        .string()
        .required('Password don\'t match')
}).required();

export const SignIn: FC = () => {
    const {signIn: signInEmployee} = useEmployee();
    const navigate = useNavigate();

    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [focusedField, setFocusedField] = useState<string | null>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm<SignInFormFields>({
        mode: 'onSubmit',
        defaultValues: {
            email: '',
            password: ''
        },
        resolver: yupResolver(signInSchema)
    });

    const toForgotPassword = () => {
        const email = getValues('email');
        navigate(AUTH_FORGOT_PASSWORD, { state: { email } });
    };
    
    const toMain = () => {
        navigate(MAIN, {replace: true});
    };

    const {isLoading, isError, signIn, resetStatuses} = useSignIn();
    const {isLoading: isLoadingGoogle, isError: isErrorGoogle, loginWithGoogle, resetStatuses: resetStatusesGoogle} = useLoginWithGoogle();

    const onSubmit: SubmitHandler<SignInFormFields> = (values) => {
        resetStatuses();
        signIn(values.email, values.password).then((dta) => {
            signInEmployee(dta);
            toMain();
        });
    };

    const handleKeyUp = (fieldName: string, event: React.KeyboardEvent<HTMLInputElement>) => {

        if (event.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    };
    

    return(
        <AuthLayout>
            <div className={style.greeting}>Welcome to CoreHR! We’re glad you’re here</div>
            <h1 className={style.title}>Sign in to your account</h1>

            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className={styleAuth.inputContainer}>
                    <Input
                        {...register('email')}
                        label="Email"
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        isInvalid={!!errors.email?.message}
                        onKeyUp={(event) => handleKeyUp('email', event)}
                        onFocus={() => setFocusedField('email')}
                    />
                    {isCapsLockOn && focusedField === 'email' && (
                            <div className={style.infoWrapper}>
                                <img src={InfoIcon} width={16} height={16} alt="info" className={style.infoImage} />
                                <HelperMessage>Caps Lock is on</HelperMessage>
                            </div>
                        )}
                </div>

                <div className={styleAuth.inputContainer}>
                    <PasswordInput
                        {...register('password')}
                        label="Password"
                        placeholder="Enter your password"
                        isInvalid={!!errors.password?.message}
                        onKeyUp={(event) => handleKeyUp('password', event)}
                        onFocus={() => setFocusedField('password')}
                    />
                    {isCapsLockOn && focusedField === 'password' && (
                            <div className={style.infoWrapper}>
                                <img src={InfoIcon} width={16} height={16} alt="info" className={style.infoImage} />
                                <HelperMessage>Caps Lock is on</HelperMessage>
                            </div>
                        )}
                    {(!!errors.email?.message || !!errors.password?.message) && <ErrorMessage>Invalid email and (or) password. Check the keyboard layout and Caps Lock</ErrorMessage>}
                </div>

                <div className={style.fagotWrapper}>
                    <Button spacing="none" onClick={toForgotPassword} className={style.fagotBtn} appearance="link">Forgot
                        password?</Button>
                </div>

                <div className={styleAuth.sendWrapper}>
                    {isLoading ? (
                        <LoadingButton appearance="primary" isLoading
                                       className={styleAuth.sendBtn}>Loading...</LoadingButton>
                    ) : (
                        <Button appearance="primary" type="submit" className={styleAuth.sendBtn}>Sign In</Button>
                    )}
                </div>

                <div className={styleAuth.sendWrapper}>
                    <Button onClick={loginWithGoogle} appearance="subtle" className={`${styleAuth.sendBtn} ${styleAuth.googleBtn}`}>
                            <span className={styleAuth.googleWrapContent}>
                                <span className={styleAuth.googleIcon}><GoogleIcon/></span>
                                <span className={styleAuth.signInWithGoogleText}>Sign in with Google</span>
                            </span>
                    </Button>
                </div>
            </form>
        </AuthLayout>
    );
};