import React, {forwardRef, useId} from "react";
import cn from "classnames";
import style from './Input.module.scss';
import TextField, {TextFieldProps} from '@atlaskit/textfield';
import {Label} from "@atlaskit/form";

interface InputProps extends TextFieldProps {
    label?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({label, className, id, ...restProps}, ref) => {
    const randID = useId();
    const idProps = id ? id : randID;

    return (
        <div>
            {label && (
                <div className={style.label}>
                    <Label htmlFor={idProps}>{label}</Label>
                </div>
            )}
            <TextField {...restProps} id={idProps} ref={ref} className={cn(style.input, className)} />
        </div>
    );
});