import { useState, useCallback } from "react";
import { AnnouncementFormType  } from 'interfaces/common/announcements.interface'
import { announcementAPI } from 'API/announcements/announcements';

export const useCreateAnnouncement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);


  const createAnnouncement = useCallback(async (payload: AnnouncementFormType) => {
    setLoading(true);
    
    try {
      const response = await announcementAPI.createAnnouncement(payload);
      const id: string = await response.json();
      
      return id;

    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    loading,
    error,
    createAnnouncement,
  };
};
