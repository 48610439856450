// https://netpeak-one.atlassian.net/browse/N1-972
// Next task
import Button from '@atlaskit/button/new'
import AddIcon from "@atlaskit/icon/glyph/add";
import { TableFiltersStyles } from './styles'
import { ITableFiltersProps } from './types'

export const TableFilters = (props: ITableFiltersProps) => (
  <TableFiltersStyles>
    <Button
      appearance="default"
      onClick={props.onClick}
      iconBefore={(iconProps) => <AddIcon {...iconProps} size="small" />}
    >
      Create new
    </Button>
  </TableFiltersStyles>
)