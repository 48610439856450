import type {ManagersOpenProfileProps} from './managersOpenProfile.types';
import {EMPLOYEES} from "../../constants";

export const getManagersOpenProfileAPI = async (idOpenProfile: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/${idOpenProfile}/managers`, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        });

        const data = await response.json() as ManagersOpenProfileProps | [];
        return data.length === 0 ? null : data as ManagersOpenProfileProps;
    } catch (error) {
        console.error(error);
        throw new Error();
    }
};