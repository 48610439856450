import {FC, useEffect, useState} from "react";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Select} from "../../../../UI/Select";
import {MultiSelect} from "../../../ViewingModes/MultiSelect";
import {BlockInfoItem} from "../../../BlockInfo";
import {type TimeOffPolicyProps, useTimeOffPolicy} from "../../../../../API/profile/fields/jobInfo/timeOffPolicy";
import {useProfile} from "../../../context";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const TimeOffPolicy: FC = () => {
    const {isAccessEditRole, jobInfo, idOpenProfile, updateTimeOffBalance, setUpdateTimeOffBalance} = useProfile();
    const {fetchTimeOffPolicy, timeOffPolicy, isLoading} = useTimeOffPolicy();

    const [picked, setPicked] = useState<Array<TimeOffPolicyProps>>([]);
    const {setField} = useSetField(idOpenProfile);

    useEffect(() => {
        fetchTimeOffPolicy().then((response) => {
            if(jobInfo?.currentJobInfoValue.timeOffPolicyIds) {
                let resultPolicies = jobInfo?.currentJobInfoValue.timeOffPolicyIds.map(timeOffPolicy => {
                    return timeOffPolicy.policyId;
                })
                const timeOffPolicies = response.filter(
                    timeOffPolicy => resultPolicies.includes(timeOffPolicy.value)
                );

                setPicked(timeOffPolicies);
            }
        });
    }, []);

    return(
        <BlockInfoItem title="Time off policy">
            <div
                className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    hideActionButtons
                    defaultValue={picked}
                    editView={({errorMessage, onChange, ...fieldProps}, ref) => (
                        <Select
                            {...fieldProps}
                            onChange={(event: any) => {
                                onChange(event);
                                const dataSend = event.length ? event.map((opt: any) => opt.id).join(',') : 'null';

                                setField(['current-job-info', 'time_off_policy_ids', dataSend].join('/')).then(() => {
                                    setUpdateTimeOffBalance(updateTimeOffBalance + 1);
                                });
                            }}
                            appearance="subtle"
                            spacing="default"
                            isMulti
                            closeMenuOnSelect={false}
                            inputId="single-select-example-clearable1"
                            isSearchable={true}
                            isClearable={true}
                            className={style.profileSelect}
                            autoFocus
                            openMenuOnFocus
                            isLoading={isLoading}
                            options={timeOffPolicy}
                            placeholder="Choose a Time Off Policy"
                            ref={ref}
                        />
                    )}
                    readView={() => (<MultiSelect value={picked} />)}
                    onConfirm={(value) => setPicked(value)}
                />
            </div>
        </BlockInfoItem>
    );
};