import { FC, useState } from "react";
import cn from "classnames";

//atlaskit
import InlineEdit from "@atlaskit/inline-edit";

//api
import {
  OrgUnitTransformProps,
  useOrgUnit,
} from "../../../../../API/profile/fields/jobInfo/orgUnit";
import { useSetField } from "../../../../../API/profile/setField/useSetField";

//context
import { useProfile } from "../../../context";

//select
import { Select, type OnChangeValue } from "../../../../UI/Select";

//components
import { InputText } from "../../../ViewingModes/InputText";
import { BlockInfoItem } from "../../../BlockInfo";

//styles
import style from "../../../Profile.module.scss";
import CustomOption from "./OrgUnitSelectOption";

export const OrgUnitField: FC = () => {
  const { isAccessEditRole, jobInfo, idOpenProfile, setCompanyName } =
    useProfile();
  const { fetchOrgUnits, orgUnits, isLoading } = useOrgUnit();
  const { setField } = useSetField(idOpenProfile);

  const currentOrgUnit = jobInfo?.currentJobInfoValue.orgUnitId
    ? {
        value: jobInfo.currentJobInfoValue.orgUnitId!,
        label: jobInfo.currentJobInfoValue.orgUnitName!,
      }
    : undefined;

  const [picked, setPicked] = useState<OrgUnitTransformProps>(
    currentOrgUnit as OrgUnitTransformProps
  );

  return (
    <BlockInfoItem title='Org unit'>
      <div
        className={cn(
          style.inputWrapper,
          isAccessEditRole && style.profileInputTextIsEditing
        )}
      >
        <InlineEdit
          {...(!isAccessEditRole && { isEditing: false })}
          hideActionButtons
          defaultValue={picked}
          editView={({ errorMessage, onChange, ...fieldProps }) => (
            <Select
              {...fieldProps}
              appearance='subtle'
              spacing='compact'
              inputId='single-select-example-clearable1'
              isSearchable={true}
              isClearable={true}
              onChange={async (
                newValue: OnChangeValue<OrgUnitTransformProps, false>
              ) => {
                onChange(newValue as any);
                await setField(
                  [
                    "current-job-info",
                    "org_unit_id",
                    newValue?.id || "null",
                  ].join("/")
                );
                setCompanyName(newValue?.companyName ?? "");
              }}
              className={style.profileSelect}
              autoFocus
              openMenuOnFocus
              onFocus={() => {
                !orgUnits.length && fetchOrgUnits();
              }}
              isLoading={isLoading}
              options={orgUnits}
              components={{ Option: CustomOption }}
              placeholder='Choose a Org Unit'
            />
          )}
          readView={() => <InputText value={picked && picked.label} />}
          onConfirm={(value) => setPicked(value)}
        />
      </div>
    </BlockInfoItem>
  );
};
