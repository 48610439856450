import React, { FC, useEffect, useState } from "react";
import { ProfileAvatar } from "../ProfileAvatar";
import { useProfile } from "../context";
import EditorPhotoIcon from "@atlaskit/icon/glyph/editor/photo";
import Button from "@atlaskit/button/new";
import Drawer from "@atlaskit/drawer";
import TimeOffLogDrawer from "./TimeOffLogDrawer/TimeOffLogDrawer";

import CopyIcon from "@atlaskit/icon/glyph/copy";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";

import EmailIcon from "@atlaskit/icon/glyph/email";
import MobileIcon from "@atlaskit/icon/glyph/mobile";

import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import EmojiFrequentIcon from "@atlaskit/icon/glyph/emoji/frequent";
import { TimeOffBalanceList } from "./TimeOffBalance/TimeOffBalanceList";
import { LeaveStatus } from "./LeaveStatus";

import { ProfileImageLoader } from "../ProfileImageLoader";
import { useAvatar } from "../../../API/profile/avatar/useAvatar";

import { DirectManager } from "./DirectManager";
import { Subordinates } from "./Subordinates";
import moment from "moment/moment";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import Telegram from "../../../assets/images/socialNetworkIcons/Telegram.svg";
import Facebook from "../../../assets/images/socialNetworkIcons/Facebook.svg";
import Instagram from "../../../assets/images/socialNetworkIcons/Instagram.svg";
import TikTok from "../../../assets/images/socialNetworkIcons/TikTok.svg";
import LinkedIn from "../../../assets/images/socialNetworkIcons/LinkedIn.svg";
import Reddit from "../../../assets/images/socialNetworkIcons/Reddit.svg";
import Calendly from "../../../assets/images/socialNetworkIcons/Calendly.svg";
import Signal from "../../../assets/images/socialNetworkIcons/Signal.svg";
import Slack from "../../../assets/images/socialNetworkIcons/Slack.svg";
import Threads from "../../../assets/images/socialNetworkIcons/Threads.svg";
import Threema from "../../../assets/images/socialNetworkIcons/Threema.svg";
import WhatsApp from "../../../assets/images/socialNetworkIcons/WhatsApp.svg";
import YouTube from "../../../assets/images/socialNetworkIcons/YouTube.svg";
import Twitter from "../../../assets/images/socialNetworkIcons/Twitter.svg";
import Viber from "../../../assets/images/socialNetworkIcons/Viber.svg";
import {
  ContactInfoProps,
  ContactTypeProps,
} from "../../../API/profile/contactInfo";
import { Tooltip } from "../../UI/Tooltip";
import { xcss, Box } from "@atlaskit/primitives";
import { toast } from "react-toastify";
import { Flag } from "../../UI/Flag";
import { TypeIconsCollection } from "../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import AIButton from "./AIButton";
import AIModal from "./TimeOffBalance/AIModal/AIModal";
import { RootState } from "../../../store/store";
import style from "./ShortInformationCard.module.scss";

import { INTERVIEWS, EMPLOYEES } from "../../../utils/constants/api";

import { useSelector } from "react-redux";

const SocialNetworkIcons = {
  Telegram: Telegram,
  Facebook: Facebook,
  Instagram: Instagram,
  TikTok: TikTok,
  LinkedIn: LinkedIn,
  Reddit: Reddit,
  Calendly: Calendly,
  Signal: Signal,
  Slack: Slack,
  Threads: Threads,
  Threema: Threema,
  WhatsApp: WhatsApp,
  YouTube: YouTube,
  Twitter: Twitter,
  Viber: Viber,
} as const;

const socialLinks = {
  Telegram: "https://t.me/",
  LinkedIn: "https://linkedin.com/in/",
  Facebook: "https://www.facebook.com/",
  Instagram: "https://www.instagram.com/",
  Calendly: "https://calendly.com/",
  TikTok: "https://www.tiktok.com/@",
  Reddit: "https://www.reddit.com/user/",
  Signal: "",
  Slack: "",
  Threads: "https://threads.net/@",
  Threema: "",
  WhatsApp: "",
  YouTube: "https://www.youtube.com/@",
  Twitter: "https://twitter.com/",
  Viber: "",
  Other: "",
} as const;

export const ShortInformationCard: FC = () => {
  const rootReducer = useSelector((state: RootState) => state.rootReducer);
  const [interviewsID, setInterviewsID] = useState("");
  const [modalContent, setModalContent] = useState(1);

  const today: Date = new Date();

  const notify = (title: string) => {
    toast(
      <Flag
        id="ok"
        appearance="success"
        title={title}
        icon={<CheckCircleIcon secondaryColor="#1f845a" label="Ok" />}
      />,
      {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: style.toster,
      }
    );
  };

  const addFlag = (flag: string) => {
    notify(flag);
  };

  const {
    isAccessEdit,
    isManagerOpenProfile,
    profile,
    jobInfo,
    idOpenProfile,
    currentJobTitle,
    contactInfo,
    setCreateRequestModal,
    icon,
    companyName,
    isActiveProfile,
  } = useProfile();

  const { avatar, fetchAvatar, uploadNewAvatar, deleteAvatar } =
    useAvatar(idOpenProfile);

  const [isOpenProfileImageLoader, setOpenProfileImageLoader] = useState(false);

  const [emails, setEmails] = useState<ContactInfoProps["values"]>([]);
  const [socials, setSocials] = useState<ContactInfoProps["values"]>([]);
  const [phones, setPhones] = useState<ContactInfoProps["values"]>([]);

  const [isNotEmptyDM, setIsNotEmptyDM] = useState<boolean>(true);
  const [isNotEmptyS, setIsNotEmptyS] = useState<boolean>(true);
  const [isNotEmptyTB, setIsNotEmptyTB] = useState<boolean>(false);

  const [AIOpen, setAIOpen] = useState(false);
  const [logDrawerOpen, setLogDrawerOpen] = useState(false);

  const getDifferenceInYearsMonthsDays = (
    startDate: string,
    endDate: string | null
  ): string => {
    const start = moment(new Date(startDate.substring(0, 10)));
    const end = moment(
      !endDate || new Date(endDate.substring(0, 10)) > today
        ? today
        : new Date(endDate.substring(0, 10))
    );

    const years = end.diff(start, "years");
    start.add(years, "years");

    const months = end.diff(start, "months");
    start.add(months, "months");

    const days = end.diff(start, "days");

    return `${years > 0 ? `${years} Y, ` : ""}${
      months > 0 ? `${months} M, ` : ""
    }${days > 0 ? `${days} D` : ""}`;
  };

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        addFlag("Сopied");
      })
      .catch((err) => {
        console.log("Something went wrong", err);
      });
  };

  useEffect(() => {
    fetchAvatar();
  }, []);

  useEffect(() => {
    const e = contactInfo.find((element) => element.type === "email")?.values;
    let emailFilter: React.SetStateAction<ContactTypeProps[]> = [];
    if (e) {
      emailFilter = e.filter(({ isFavorite }) => isFavorite === "1");
    }
    setEmails(emailFilter);
  }, [contactInfo]);

  useEffect(() => {
    const s = contactInfo.find((element) => element.type === "social")?.values;
    let socialFilter: React.SetStateAction<ContactTypeProps[]> = [];
    if (s) {
      socialFilter = s.filter(({ isFavorite }) => isFavorite === "1");
    }
    setSocials(socialFilter);
  }, [contactInfo]);

  useEffect(() => {
    const p = contactInfo.find((element) => element.type === "phone")?.values;
    let phoneFilter: React.SetStateAction<ContactTypeProps[]> = [];
    if (p) {
      phoneFilter = p.filter(({ isFavorite }) => isFavorite === "1");
    }
    setPhones(phoneFilter);
  }, [contactInfo]);

  useEffect(() => {
    fetch(EMPLOYEES + "/" + profile.id + "/interviews/open-request")
      .then((res) => res.json())
      .then((data) => {
        if (typeof data === "string") {
          setInterviewsID(data);
        } else {
          setInterviewsID("");
        }
      });
  }, [modalContent, rootReducer.AIButtonUpdate]);

  return (
    <div className={style.baseInfo}>
      <div className={style.avatarWrapper}>
        <ProfileAvatar
          size={128}
          imgAvatar={avatar}
          firstName={profile.firstNameEn!}
          lastName={profile.lastNameEn!}
        />
        {isAccessEdit && (
          <div
            className={style.editPhoto}
            onClick={() => setOpenProfileImageLoader(true)}
          >
            <EditorPhotoIcon
              size="large"
              primaryColor="#fff"
              label="Edit Photo"
            />
          </div>
        )}
        <ProfileImageLoader
          isOpen={isOpenProfileImageLoader}
          closeModal={() => {
            setOpenProfileImageLoader(false);
          }}
          avatar={avatar}
          uploadNewAvatar={uploadNewAvatar}
          deleteAvatar={deleteAvatar}
        />
        {icon && (
          <div className={style.iconStatus}>
            {TypeIconsCollection[icon as keyof typeof TypeIconsCollection](
              "large-icon",
              "withouthover"
            )}
          </div>
        )}
      </div>
      <h1 className={style.nameTitle}>
        {profile.firstNameEn} {profile.lastNameEn}
      </h1>
      {profile.nickname && (
        <h2 className={style.nickname}>{profile.nickname}</h2>
      )}
      {currentJobTitle && (
        <div className={style.jobTitleContainer}>
          <h3 className={style.jobTitle}>{currentJobTitle.label}</h3>
        </div>
      )}
      <div className={style.urlCompany}>
        <a href="#">{companyName}</a>
      </div>

      {jobInfo?.currentJobInfoValue.dates.hireDate && (
        <div className={style.employedPeriodWrapper}>
          <div className={style.employedPeriod}>
            Employed for{" "}
            {getDifferenceInYearsMonthsDays(
              jobInfo.currentJobInfoValue.dates.hireDate,
              jobInfo.currentJobInfoValue.dates.terminationDate
                ? jobInfo.currentJobInfoValue.dates.terminationDate
                : new Date().toISOString().substring(0, 10)
            )}
          </div>
        </div>
      )}

      <LeaveStatus />
      {interviewsID.length > 0 && (
        <Box
          xcss={xcss({
            marginTop: "space.200",
          })}
        >
          <AIButton
            isActive={AIOpen}
            onClick={(e) => {
              setAIOpen((val) => !val);
            }}
          />
        </Box>
      )}
      <AIModal
        interviesID={interviewsID}
        setContent={setModalContent}
        content={modalContent}
        isOpen={AIOpen}
        setIsOpen={(val) => {
          setAIOpen(val);
        }}
      />
      <div className={style.otherInfo}>
        <div className={style.contacts}>
          <div className={style.otherInfoBlockTitle}>Contact me:</div>
          <div>
            <ul className={style.contactsList}>
              {phones &&
                phones.length > 0 &&
                phones.map(({ field, value }) => {
                  return (
                    <li
                      key={`${field}${value}`}
                      className={style.contactsListItemLP}
                    >
                      <a href={`tel:${value}`} target="_blank">
                        <span className={style.contactImg}>
                          <MobileIcon
                            primaryColor="#44546F"
                            size="medium"
                            label="Phone"
                          />
                        </span>
                        <span className={style.valueText}>{value}</span>
                      </a>
                      <div className={style.controlGroup}>
                        <Tooltip position="bottom" delay={0} content="Copy">
                          {(tooltipProps) => (
                            <div
                              {...tooltipProps}
                              onClick={() => {
                                copyToClipboard(value);
                              }}
                              className={style.controlSoc}
                            >
                              <CopyIcon
                                primaryColor="#44546F"
                                label="Copy"
                                size="medium"
                              />
                            </div>
                          )}
                        </Tooltip>
                        <Tooltip
                          position="bottom"
                          delay={0}
                          content="Open in new tab"
                        >
                          {(tooltipProps) => (
                            <div
                              {...tooltipProps}
                              onClick={() => {
                                window.open(`tel:${value}`, "_blank");
                              }}
                              className={style.controlSoc}
                            >
                              <ShortcutIcon
                                primaryColor="#44546F"
                                label="Open"
                                size="medium"
                              />
                            </div>
                          )}
                        </Tooltip>
                      </div>
                    </li>
                  );
                })}
              <li className={style.contactsListItemLP}>
                <a href={`mailto:${profile.email}`} target="_blank">
                  <span className={style.contactImg}>
                    <EmailIcon
                      primaryColor="#44546F"
                      size="medium"
                      label="Email"
                    />
                  </span>
                  <Tooltip position="bottom" delay={0} content={profile.email}>
                    <span className={style.valueText}>{profile.email}</span>
                  </Tooltip>
                </a>
                <div className={style.controlGroup}>
                  <Tooltip position="bottom" delay={0} content="Copy">
                    {(tooltipProps) => (
                      <div
                        {...tooltipProps}
                        onClick={() => {
                          copyToClipboard(profile.email);
                        }}
                        className={style.controlSoc}
                      >
                        <CopyIcon
                          primaryColor="#44546F"
                          label="Copy"
                          size="medium"
                        />
                      </div>
                    )}
                  </Tooltip>
                  <Tooltip
                    position="bottom"
                    delay={0}
                    content="Open in new tab"
                  >
                    {(tooltipProps) => (
                      <div
                        {...tooltipProps}
                        onClick={() => {
                          window.open(`mailto:${profile.email}`, "_blank");
                        }}
                        className={style.controlSoc}
                      >
                        <ShortcutIcon
                          primaryColor="#44546F"
                          label="Open"
                          size="medium"
                        />
                      </div>
                    )}
                  </Tooltip>
                </div>
              </li>
              {emails &&
                emails.length > 0 &&
                emails.map(({ field, value }) => {
                  return (
                    <li
                      key={`${field}${value}`}
                      className={style.contactsListItemLP}
                    >
                      <a href={`mailto:${value}`} target="_blank">
                        <span className={style.contactImg}>
                          <EmailIcon
                            primaryColor="#44546F"
                            size="medium"
                            label="Email"
                          />
                        </span>
                        <Tooltip position="bottom" delay={0} content={value}>
                          <span className={style.valueText}>{value}</span>
                        </Tooltip>
                      </a>
                      <div className={style.controlGroup}>
                        <Tooltip position="bottom" delay={0} content="Copy">
                          {(tooltipProps) => (
                            <div
                              {...tooltipProps}
                              onClick={() => {
                                copyToClipboard(value);
                              }}
                              className={style.controlSoc}
                            >
                              <CopyIcon
                                primaryColor="#44546F"
                                label="Copy"
                                size="medium"
                              />
                            </div>
                          )}
                        </Tooltip>
                        <Tooltip
                          position="bottom"
                          delay={0}
                          content="Open in new tab"
                        >
                          {(tooltipProps) => (
                            <div
                              {...tooltipProps}
                              onClick={() => {
                                window.open(`mailto:${value}`, "_blank");
                              }}
                              className={style.controlSoc}
                            >
                              <ShortcutIcon
                                primaryColor="#44546F"
                                label="Open"
                                size="medium"
                              />
                            </div>
                          )}
                        </Tooltip>
                      </div>
                    </li>
                  );
                })}
              {socials &&
                socials.length > 0 &&
                socials.map(({ value, field }) => {
                  //@ts-ignore
                  const link = socialLinks[field];

                  return (
                    <li
                      key={`${field}${value}`}
                      className={
                        link
                          ? style.contactsListItemLP
                          : style.contactsListItemSP
                      }
                    >
                      {link ? (
                        <a
                          //@ts-ignore
                          href={`${link}${value}`}
                          target="_blank"
                        >
                          <span className={style.contactImg}>
                            <img
                              // @ts-ignore
                              src={SocialNetworkIcons[field]}
                              alt={`${value} Icon`}
                            />
                          </span>
                          <Tooltip position="bottom" delay={0} content={value}>
                            <span className={style.valueText}>{value}</span>
                          </Tooltip>
                        </a>
                      ) : (
                        <span>
                          <span className={style.contactImg}>
                            <img
                              // @ts-ignore
                              src={SocialNetworkIcons[field]}
                              alt={`${value} Icon`}
                            />
                          </span>
                          <Tooltip position="bottom" delay={0} content={value}>
                            <span className={style.valueText}>{value}</span>
                          </Tooltip>
                        </span>
                      )}
                      <div className={style.controlGroup}>
                        <Tooltip position="bottom" delay={0} content="Copy">
                          {(tooltipProps) => (
                            <div
                              {...tooltipProps}
                              onClick={() => {
                                copyToClipboard(value);
                              }}
                              className={style.controlSoc}
                            >
                              <CopyIcon
                                primaryColor="#44546F"
                                label="Copy"
                                size="medium"
                              />
                            </div>
                          )}
                        </Tooltip>
                        {link && (
                          <Tooltip
                            position="bottom"
                            delay={0}
                            content="Open in new tab"
                          >
                            {(tooltipProps) => (
                              <div
                                {...tooltipProps}
                                onClick={() => {
                                  window.open(`${link}${value}`, "_blank");
                                }}
                                className={style.controlSoc}
                              >
                                <ShortcutIcon
                                  primaryColor="#44546F"
                                  label="Open"
                                  size="medium"
                                />
                              </div>
                            )}
                          </Tooltip>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        {(isNotEmptyDM || isNotEmptyS) && <div className={style.separator} />}

        <div>
          <DirectManager setIsNotEmptyDM={setIsNotEmptyDM} />

          <Subordinates setIsNotEmptyS={setIsNotEmptyS} />
        </div>

        {isNotEmptyTB && <div className={style.separator} />}

        {(isAccessEdit || isManagerOpenProfile) && (
          <TimeOffBalanceList setIsNotEmptyTB={setIsNotEmptyTB} />
        )}

        {isActiveProfile && (isAccessEdit || isManagerOpenProfile) && (
          <div className={style.requestTimeOffButtonWrap}>
            <button
              className={style.requestTimeOffButton}
              onClick={() => {
                setCreateRequestModal(true);
              }}
            >
              <span className={style.requestTimeOffButtonIcon}>
                <EditorAddIcon
                  label="Request Time Off"
                  size="medium"
                  primaryColor="#44546F"
                />
              </span>
              <span>Request Time Off</span>
            </button>

            <Button
              iconBefore={EmojiFrequentIcon}
              shouldFitContainer={true}
              onClick={() => setLogDrawerOpen(true)}
            >
              Log
            </Button>
          </div>
        )}
      </div>
      <Drawer
        label="Time off drawer"
        onClose={() => setLogDrawerOpen(false)}
        isOpen={logDrawerOpen}
        width="extended"
        zIndex={100}
      >
        <TimeOffLogDrawer />
      </Drawer>
    </div>
  );
};
