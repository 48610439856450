import { token } from "@atlaskit/tokens";
import styled from "styled-components";
import { xcss } from "@atlaskit/primitives";

export const StyledEmptyFiltersBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 324px;
    height: 356px;
  }

  h3 {
    color: ${token("color.text")};
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
  }

  p {
    max-width: 293px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${token("color.text.subtle")};
    margin-top: ${token("space.100")};
  }
`;
