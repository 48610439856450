import styled from "styled-components";
import { xcss, Box } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import { useReducer, ReactNode, FC } from "react";
import { IconButton } from "@atlaskit/button/new";
import ChevronLeftIcon from "@atlaskit/icon/glyph/chevron-left";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";

const ScrollBlock = styled.div`
  /* width */

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  overflow: auto;
`;

function BoxText({ content }: { content: number }) {
  if (content === 1) {
    return (
      <>
        Про себе: Розкажіть про свої хобі, улюблені фільми, книги. Як ви
        зазвичай проводите вільний час? Що вас надихає?
      </>
    );
  } else if (content === 2) {
    return (
      <>
        Досвід роботи: Де і на яких позиціях ви раніше працювали? Які основні
        навички та знання ви придбали? Які ваші найсильніші професійні якості та
        навички?
      </>
    );
  } else if (content === 3) {
    return (
      <>
        Спілкування: Як ви вважаєте, який стиль комунікації вам найбільше
        підходить? Як ви зазвичай вирішуєте конфлікти?
      </>
    );
  } else if (content === 4) {
    return (
      <>
        Підходи до роботи: Як ви організовуєте свій робочий день? Як ви
        ставитеся до мультизадачності? Що вас мотивує в роботі?
      </>
    );
  } else if (content === 5) {
    return (
      <>
        Взаємодія з командою: Як ви вважаєте, що є ключем до успішної роботи в
        команді? Опишіть свій стиль комунікації та взамодії в команді.
      </>
    );
  } else if (content === 6) {
    return (
      <>
        Вирішення задач: Як ви підходите до складних завдань? Що робите коли
        зустрілися з задачею, яку ніколи раніше не виконували?
      </>
    );
  } else if (content === 7) {
    return (
      <>
        Ставлення до навчання: Якими методами ви зазвичай опановуєте нові
        знання? Які нові навички або знання ви набули нещодавно?
      </>
    );
  } else {
    return <></>;
  }
}

const Dot: FC<{
  $isActive?: boolean;
}> = styled.div`
  border-radius: 100px;
  width: 12px;
  height: 12px;
  background-color: ${(props) =>
    props.$isActive
      ? token("color.icon.brand")
      : token("color.background.neutral")};
`;

export default function QuestionsBlock({
  checkpointState,
  setCheckpointState,
}: {
  checkpointState: number;
  setCheckpointState: (arg: any) => void;
}) {
  return (
    <Box>
      <Box
        xcss={xcss({
          color: "color.text.subtle",
          fontFamily: "inherit",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          marginBottom: "space.200",
        })}
      >
        Перш ніж відповісти, прочитайте кожне запитання вголос.
      </Box>
      <ScrollBlock>
        <Box
          xcss={xcss({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "space.200",
            marginTop: "space.100",
          })}
        >
          <Box>
            <IconButton
              isDisabled={checkpointState < 2}
              label="button"
              icon={(iconProps) => (
                <ChevronLeftIcon
                  {...iconProps}
                  size="large"
                  primaryColor={
                    checkpointState < 2 ? "#091E424F" : "rgba(68, 84, 111, 1)"
                  }
                />
              )}
              onClick={() => {
                if (checkpointState > 1) {
                  setCheckpointState((val: number) => val - 1);
                }
              }}
            />
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "46px",
              width: "90%",
              paddingLeft: "38px",
              paddingRight: "38px",
            }}
          >
            <Dot $isActive={checkpointState === 1} />
            <Dot $isActive={checkpointState === 2} />
            <Dot $isActive={checkpointState === 3} />
            <Dot $isActive={checkpointState === 4} />
            <Dot $isActive={checkpointState === 5} />
            <Dot $isActive={checkpointState === 6} />
            <Dot $isActive={checkpointState === 7} />
          </div>
          <Box>
            <IconButton
              isDisabled={checkpointState > 6}
              label="button"
              icon={(iconProps) => (
                <ChevronRightIcon
                  {...iconProps}
                  size="large"
                  primaryColor={
                    checkpointState > 6 ? "#091E424F" : "rgba(68, 84, 111, 1)"
                  }
                />
              )}
              onClick={() => {
                if (checkpointState < 7) {
                  setCheckpointState((val: number) => val + 1);
                }
              }}
            />
          </Box>
        </Box>
        <Box
          xcss={xcss({
            color: "color.text.subtlest",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px",
          })}
        >
          Питання {checkpointState + "/7"}
        </Box>
        <Box
          xcss={xcss({
            paddingTop: "space.100",
            display: "flex",
            flexDirection: "column",
            gap: "space.100",
            height: "84px",
          })}
        >
          <span
            style={{
              color: "color.text",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            <BoxText content={checkpointState} />
          </span>
        </Box>
      </ScrollBlock>
    </Box>
  );
}
