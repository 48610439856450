import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const StyledTextCancel = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
`;

export const StyledExitButton = styled.div`
  button {
    width: 50px;
  }
`;

export const StyledCancelButton = styled.div`
  button {
    width: 71px;
  }
`;
