import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { xcss, Box } from "@atlaskit/primitives";

import styled from "styled-components";

import ChevronRightCircleIcon from "@atlaskit/icon/glyph/chevron-right-circle";
import ChevronLeftCircleIcon from "@atlaskit/icon/glyph/chevron-left-circle";
import { ReactElement, FC, ReactNode } from "react";

type ResponsiveType = {
  desktop: {
    breakpoint: { max: number; min: number };
    items: number;
    slidesToSlide: number;
  };
  tablet: {
    breakpoint: { max: number; min: number };
    items: number;
    slidesToSlide: number;
  };
  mobile: {
    breakpoint: { max: number; min: number };
    items: number;
    slidesToSlide: number;
  };
};

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1440, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CarouselWrapper: FC<{
  children: ReactElement<any, any>;
  $itemWidth: number;
}> = styled.div`
  .react-multi-carousel-item {
    width: ${(props) => props.$itemWidth}px !important;
  }
  li.react-multi-carousel-item {
    margin: 0px !important;
  }

  .react-multi-carousel-list {
    span[aria-label="right_arrow"] {
      display: none;
    }
    span[aria-label="left_arrow"] {
      display: none;
    }
  }

  .react-multi-carousel-list:hover {
    span[aria-label="right_arrow"] {
      display: block;
    }
    span[aria-label="left_arrow"] {
      display: block;
    }
  }
`;
const CarouselButtonWrapper = styled.div`
  span {
    width: 36px;
    height: 36px;
  }

  span svg {
    width: 36px;
    height: 36px;
  }
`;

export default function CustomCarousel({
  children,
  responsiveParams = responsive,
  itemWidth = 307,
}: {
  children: ReactNode;
  responsiveParams?: ResponsiveType;
  itemWidth?: number;
}) {
  return (
    <CarouselWrapper $itemWidth={itemWidth}>
      <Carousel
        responsive={responsiveParams}
        customRightArrow={
          <Box
            xcss={xcss({
              opacity: "0.5",
              left: "calc(100% - 36px)",
              position: "absolute",
            })}
          >
            <CarouselButtonWrapper>
              <ChevronRightCircleIcon label="right_arrow" size="large" />
            </CarouselButtonWrapper>
          </Box>
        }
        customLeftArrow={
          <Box xcss={xcss({ opacity: "0.5", position: "absolute" })}>
            <CarouselButtonWrapper>
              {" "}
              <ChevronLeftCircleIcon label="left_arrow" size="large" />
            </CarouselButtonWrapper>
          </Box>
        }
      >
        {children}
      </Carousel>
    </CarouselWrapper>
  );
}
