import { token } from "@atlaskit/tokens";
import { Inline, Box, xcss } from "@atlaskit/primitives";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";
import { TDirectManager } from "./AIStatusButton";
import styled from "styled-components";
import { FC, ReactNode } from "react";

const LinkElm: FC<HTMLAnchorElement | { children: ReactNode }> = styled.a`
  margin-top: ${token("space.200")};
  color: ${token("color.link")};
  display: "flex";
  align-items: "start";
  font-size: "14px";
  font-style: "normal";
  font-weight: 500;
  line-height: "20px";
`;

export default function ManagerLinkButton({
  directManager,
}: {
  directManager: TDirectManager;
}) {
  return (
    <Box
      xcss={xcss({
        display: "flex",
      })}
    >
      <LinkElm href={"/employee/" + directManager.id}>
        Manager's profile
      </LinkElm>
      <LinkElm href={"/employee/" + directManager.id} target="_blank">
        <Inline xcss={xcss({ marginLeft: token("space.100") })}>
          <ShortcutIcon label="ShortcutIcon" />
        </Inline>
      </LinkElm>
    </Box>
  );
}
