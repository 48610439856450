import React from 'react';
import { ORG_CHART } from '../../../../../utils/constants/api';
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi';
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';
import {ValidationError} from "../../../../../shared/Common/Error/ValidationError";

type DeleteOrgUnit = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    orgUnit: OrgUnitFromApi
};

function DeleteOrgUnit(props: DeleteOrgUnit) {
    function deleteOrgUnit(): void {
        fetch(ORG_CHART + '/' + props.orgUnit.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then((jsonData: any) => {
                        if (jsonData.error) {
                            alert(jsonData.error);
                        } else if (Array.isArray(jsonData) && jsonData.length > 0) {
                            const errors: ValidationError[] = jsonData;
                            validate(errors);
                        }
                    });
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Org Unit'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.orgUnit.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteOrgUnit }
        />
    )
}

function validate(errors: ValidationError[]): void {
    errors.forEach((error: ValidationError) => {
        if (error.property === 'orgUnit') {
            alert(error.message);
        }
    });
}
export default DeleteOrgUnit;