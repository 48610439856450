import { FC, useState } from "react";
import { NavProps } from "./Layout.types";
import { Header } from "./Header";
import { SideBar } from "./SideBar";
import { useEvent } from "../../customHooks/useEvent";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { StyledContentLayout, StyledMainLayout } from "./LayoutStyles";

export const Layout: FC = () => {
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const switchStatus = useEvent(() => {
    setNavIsOpen(!navIsOpen);
  });

  const navProps: NavProps = {
    navIsOpen: navIsOpen,
    navSwitchStatus: switchStatus,
  };

  return (
    <>
      <Header {...navProps} />
      <StyledContentLayout>
        <SideBar {...navProps} />
        <StyledMainLayout $navIsOpen={navIsOpen}>
          <Outlet />
        </StyledMainLayout>
      </StyledContentLayout>
      <ToastContainer />
    </>
  );
};
