export interface Transition {
  id: string;
  name: string;
  fromStatusId: string;
  toStatusId: string;
}

export interface Status {
  id: string;
  name: any;
  isActive: boolean;
  isRemovable?: true;
}

export type StatusWithTransitions = Status & {
  transitions?: Transition[];
};

export const statusEmptyStateID = "00000000-0000-0000-0000-000000000000";
