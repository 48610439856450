import { token } from "@atlaskit/tokens";
import { xcss } from "@atlaskit/primitives";
import styled from "styled-components";

export const StyledRequestModal = styled.div`
  [class*="Label"],
  [class*="label"] {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${token("color.text.subtle")};
  }

  [class*="control"] {
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    min-height: 32px;
    height: 32px;

    &:focus-within {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    & > div {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  [class*="indicatorContainer"] {
    padding-top: 0;
    padding-bottom: 0;
  }

  .request-modal-employee {
    [class*="singleValue"] {
      & > div > [role="img"] {
        display: none !important;
      }

      & > div > div:not([role="img"]) > span {
        display: none;
      }
    }
  }

  .request-modal-policy {
    [class*="singleValue"] {
      & > div > p {
        width: auto;
      }

      & > div > span {
        display: none;
      }
    }
  }

  .request-modal-substitute {
    [class*="singleValue"] {
      & > div > [role="img"] {
        display: none !important;
      }

      & > div > div:not([role="img"]) > span {
        display: none;
      }
    }
  }

  [id*="error"] {
    color: ${token("color.text.danger")};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  textarea[name="request-form-comment"] {
    min-height: 86px;
    border: 2px solid rgba(9, 30, 66, 0.14);

    &:focus-within {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }
  }
`;

export const StyledRequestModalBody = styled.div`
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: ${token("space.300")};
`;

export const StyledPolicyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token("space.100")};

  & > p {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  & > span {
    flex-shrink: 0;
    margin-left: auto;
    color: ${token("color.text.subtlest")};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const UserBlock = xcss({
  paddingRight: token("space.100"),
  gap: "space.100",
  alignItems: "center",
  color: "color.text.subtlest",
});

export const StyledUserBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;

  & > p {
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;

export const StyledRequestCalendar = styled.div`
  & > div {
    margin-top: 0;
  }

  button[data-selected="true"] {
    background-color: white;
  }

  button[data-prev-selected="true"] {
    background-color: ${token("color.background.selected")};
  }

  label {
    margin-bottom: 0;
    padding: 0 ${token("space.300")};
  }

  [aria-label="calendar"] {
    display: block;
    padding: ${token("space.200")} 0 ${token("space.200")} ${token("space.200")};
    margin: 0 auto;
  }

  [id*="header"] {
    font-weight: 700;
  }

  [role="columnheader"] {
    text-transform: uppercase;

    span {
      font-size: 11px;
    }
  }

  [data-testid="calendar--day"] {
    color: ${token("color.text.subtle")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &[data-prev-selected="true"] {
      color: ${token("color.text.selected")};
    }
  }

  [id*="error"] {
    padding: 0 ${token("space.300")};
    color: ${token("color.text.danger")};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const StyledCalendarInfo = styled.div`
  & > p {
    display: flex;
    justify-content: space-between;
    height: 32px;
    padding: ${token("space.075")} ${token("space.150")} ${token("space.075")}
      28px;
    gap: ${token("space.075")};
    align-self: stretch;
    color: ${token("color.text.subtlest")};
  }

  & > div {
    display: grid;
    margin-top: ${token("space.100")};
    padding-left: ${token("space.200")};

    p {
      position: relative;
      margin: 0;
      padding-left: 12px;
      color: ${token("color.text.subtlest")};
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;

      &::before {
        content: "";
        position: absolute;
        inset: 3px auto auto 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      &:first-child {
        &::before {
          background-color: #b3b9c4;
        }
      }
    }
  }
`;
