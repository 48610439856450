import {FC} from "react";
import style from './HeaderBlockStats.module.scss';

interface HeaderBlockStatsProps {
    title: string;
    hours: string;
    minutes: string;
}

export const HeaderBlockStats: FC<HeaderBlockStatsProps> = ({title, minutes, hours}) => {
    return(
        <div className={style.blockStats}>
            <div className={style.title}>{title}</div>
            <div className={style.time}>
                {hours}<span>h</span> {minutes}<span>m</span>
            </div>
        </div>
    );
}