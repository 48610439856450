import {type FC, useEffect, useState} from "react";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Select} from "../../../../UI/Select";
import {InputText} from "../../../ViewingModes/InputText";
import {BlockInfoItem} from "../../../BlockInfo";
import {TransformJobTitles, useJobTitles} from "../../../../../API/profile/fields/jobInfo/jobTitles";
import {useProfile} from "../../../context";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const JobTitleField: FC = () => {
    const {isAccessEditRole, currentJobTitle, setCurrentJobTitle, idOpenProfile} = useProfile();
    const {fetchJobTitles, jobTitles, isLoading} = useJobTitles();

    const [picked, setPicked] = useState<TransformJobTitles>();

    const {setField} = useSetField(idOpenProfile);

    useEffect(() => {
        setPicked(currentJobTitle!);
    }, [currentJobTitle]);

    return(
        <BlockInfoItem title="Job title">
            <div className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    hideActionButtons
                    defaultValue={picked}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Select
                            {...fieldProps}
                            onChange={(event: any) => {
                                onChange(event);
                                setField(['current-job-info', 'job_title_id', event?.id || 'null'].join('/')).then(() => {
                                    setCurrentJobTitle(event);
                                });
                            }}
                            appearance="subtle"
                            spacing="compact"
                            inputId="single-select-example-clearable1"
                            isSearchable={true}
                            isClearable={true}
                            className={style.profileSelect}
                            autoFocus
                            openMenuOnFocus
                            onFocus={() => {
                                !jobTitles.length && fetchJobTitles();
                            }}
                            isLoading={isLoading}
                            options={jobTitles}
                            placeholder="Choose a Job Title"
                        />
                    )}
                    readView={() => (<InputText value={picked?.label}/>)}
                    onConfirm={(value) => setPicked(value)}
                />
            </div>
        </BlockInfoItem>
    );
};//${EMPLOYEE}/api/employee/${id}/${tab}/${field}/${value}