import { type FC } from "react";

//context
import { useProfile } from "../../context";

//components
import { TabContainer } from "../TabContainer";
import { BlockInfo, BlockInfoSkeletonItem } from "../../BlockInfo";
import { JobTitleField } from "../../Fields/jobInfo/JobTitle";
import { OrgUnitField } from "../../Fields/jobInfo/OrgUnit";
import { LevelField } from "../../Fields/jobInfo/Level";
import { DirectManager } from "../../Fields/jobInfo/DirectManager";
import { WorkLocation } from "../../Fields/jobInfo/WorkLocation";
import { WorkSchedule } from "../../Fields/jobInfo/WorkSchedule";
import { TimeOffPolicy } from "../../Fields/jobInfo/TimeOffPolicy";
import { TimeTracking } from "../../Fields/jobInfo/TimeTracking";
import { EmployeeStatusHistory } from "../../../../application/Page/Admin/Employee/Profile/JobInfo/EmployeeStatusHistory";

//images
import { ReactComponent as PersonalIcon } from "../../BlockInfo/Icons/profile_job.svg";

interface JobInfoTabProps {
  setIsCurStatusInactive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobInfoTab: FC<JobInfoTabProps> = ({ setIsCurStatusInactive }) => {
  const { jobInfo, profile } = useProfile();

  return (
    <TabContainer>
      <BlockInfo title='Job Info'>
        {!jobInfo ? <BlockInfoSkeletonItem /> : <OrgUnitField />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <JobTitleField />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <LevelField />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <DirectManager />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <WorkLocation />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <WorkSchedule />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <TimeTracking />}
        {!jobInfo ? <BlockInfoSkeletonItem /> : <TimeOffPolicy />}
      </BlockInfo>

      {/*<BlockInfo Icon={WorkHistoryIcon} title="Employee Status History">*/}
      {/*    {!jobInfo ? (<BlockInfoSkeletonItem />) : (<HireDate />)}*/}
      {/*    {!jobInfo ? (<BlockInfoSkeletonItem />) : (<EmployeeStatuses />)}*/}
      {/*</BlockInfo>*/}

      {/*<BlockInfo Icon={WorkHistoryIcon} title="Employee Status History">
                <EmployeeStatusHistory
                    //@ts-ignore
                    employee={ profile }
                    onDataUpdate={ () => {} }
                    triggerParentUpdate={  () => {}  }
                    //@ts-ignore
                    currentJobInfo={ jobInfo }
                    updateCurrentJobInfo={() => {}}
                    setIsHired={ () => {} }
                />
            </BlockInfo>*/}

      <EmployeeStatusHistory
        setIsCurStatusInactive={setIsCurStatusInactive}
        //@ts-ignore
        employee={profile}
        onDataUpdate={() => {}}
        triggerParentUpdate={() => {}}
        //@ts-ignore
        currentJobInfo={jobInfo}
        updateCurrentJobInfo={() => {}}
        setIsHired={() => {}}
      />
    </TabContainer>
  );
};
