import { Box, xcss } from "@atlaskit/primitives";
import { Link, Navigate } from "react-router-dom";
import Avatar from "@atlaskit/avatar";
import { EmployeeDataFromApi } from "./types";
import { EMPLOYEE_PROFILE } from "utils/constants/routes";
import styled from "styled-components";
import { SimpleTag as Tag } from "@atlaskit/tag";

const ResultItem = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 16px;
  &:hover {
    box-shadow: inset 2px 0px 0px #0c66e4 !important;
    background: #f1f3f5;
    border: none !important;
  }
  &:active {
    box-shadow: inset 2px 0px 0px #0c66e4 !important;
    background: #091e4224;
    border: none !important;
  }
  &:focus {
    border: 2px solid #0c66e4;
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: none;
    border: 2px solid #0c66e4;
    outline: none;
  }
`;

function ResultItems({ result }: { result: EmployeeDataFromApi }) {
  return (
    <ResultItem
      tabIndex={0}
      className="searchResultItem"
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          window.location.href = `${EMPLOYEE_PROFILE}/${result.id}`;
        }
      }}
    >
      <Box
        xcss={xcss({
          display: "flex",
          alignItems: "center",
        })}
      >
        {" "}
        <Box>
          <Avatar
            size="small"
            name={result.nickname ? result.nickname : ""}
            src={result.imageUrl ? result.imageUrl : ""}
          />
        </Box>
        <Box
          xcss={xcss({
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
          })}
        >
          <Box>
            <Box
              xcss={xcss({
                display: "flex",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "color.text",
                maxWidth: "280px",
                minWidth: "200px",
                overflow: "auto",
              })}
            >
              {result.firstNameEn} {result.lastNameEn}{" "}
              {result.nickname ? "(" + result.nickname + ")" : null}
              {!result.isActive && (
                <Box xcss={xcss({ marginLeft: "space.100" })}>
                  <Tag text={"Inactive"} />
                </Box>
              )}
            </Box>
            {result.email ? (
              <Box
                xcss={xcss({
                  display: "flex",
                  fontFamily: "inherit",
                  fontSize: "11px",
                  fontStyle: "normal",
                  fontWeight: 400,
                })}
              >
                <div style={{ color: "#626F86" }}> {result.email}</div>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        xcss={xcss({
          fontFamily: "inherit",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          maxWidth: "280px",
          overflow: "auto",
        })}
      >
        <div style={{ color: "#626F86" }}> {result.companyName}</div>
      </Box>
    </ResultItem>
  );
}

function SearchResults({
  results,
  onSelect,
  showInactive,
}: {
  results: EmployeeDataFromApi[];
  onSelect: (arg: any) => void;
  showInactive: boolean;
}) {
  return (
    <Box
      xcss={xcss({
        backgroundColor: "elevation.surface",
      })}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <Box>
        {results
          ? results
              .filter((item) => {
                return item.isActive || showInactive;
              })
              .map((result, index) => (
                <Link
                  onClick={(e) => {
                    onSelect(result);
                  }}
                  style={{ textDecoration: "none" }}
                  to={`${EMPLOYEE_PROFILE}/${result.id}`}
                  key={index}
                >
                  <ResultItems key={result?.id} result={result} />
                </Link>
              ))
          : null}
      </Box>
    </Box>
  );
}

export default SearchResults;
