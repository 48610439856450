import { FC, useState } from "react";
import Popup from "@atlaskit/popup";
import { IconButton } from "@atlaskit/button/new";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { StyledBtnWrap, StyledPopupText } from "./StatusesStyles";

const StatusesPopup: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-end"
      content={() => (
        <StyledPopupText>
          In an inactive status, an employee is not displayed in selects, in a
          subordination list, on the main page, and the calendar
        </StyledPopupText>
      )}
      trigger={(triggerProps) => (
        <StyledBtnWrap>
          <IconButton
            {...triggerProps}
            label="Info"
            appearance="subtle"
            spacing="compact"
            isSelected={isOpen}
            icon={EditorPanelIcon}
            onClick={() => setIsOpen(!isOpen)}
          />
        </StyledBtnWrap>
      )}
    />
  );
};

export default StatusesPopup;
