import React, { FC, ReactNode, useEffect, useState, useRef } from "react";
import Announcement from "./Announcement";
import { ANNOUNCEMENTS } from "../../../../../utils/constants/api";

type TAnnouncements = {
  createdAt: string;
  endDate: string;
  id: string;
  priority: "low" | "medium" | "high" | "critical";
  startDate: string;
  text: string;
};

export default function AnnouncementBlock() {
  const [announcements, setAnnouncements] = useState<TAnnouncements[]>([]);

  useEffect(() => {
    fetch(ANNOUNCEMENTS + "/widget")
      .then((res) => res.json())
      .then((data: TAnnouncements[]) => {
        if (data) {
          setAnnouncements(data);
        }
      });
  }, []);

  return <Announcement announcements={announcements} />;
}
