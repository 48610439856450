const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const EMPLOYEE = BASE_URL + '/api/employee';
export const EMPLOYEES = BASE_URL + '/api/employees';
export const ACCESS_LEVELS = BASE_URL + '/api/employee/access-levels';
export const ACTIVE_EMPLOYEES = `${EMPLOYEES}/type/active`;
export const JOB_TITLES = BASE_URL + '/api/job-titles';
export const ORG_CHART = BASE_URL + '/api/orgchart';
export const LEVELS = BASE_URL + '/api/levels';
export const LOCATIONS = BASE_URL + '/api/locations';

export const TIME_OFF_POLICIES = BASE_URL + '/api/time-off-policies';
export const GENDERS = BASE_URL + '/api/genders';
export const COUNTRIES = BASE_URL + '/api/countries';
export const CALENDAR = BASE_URL + '/api/calendar';

export const ACCRUED_DAYS_BALANCES = BASE_URL + '/api/accrued-days-balances';

export const ATTENDANCE = BASE_URL + '/api/attendance';
export const ATTENDANCE_EVENT = BASE_URL + '/api/attendance/event';
export const WORK_TIME_CORRECTION = BASE_URL + '/api/attendance/work-time-correction';

export const baseHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};