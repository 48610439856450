import { ThemeAppearance } from "@atlaskit/lozenge";

export enum AnnouncementPriorityEnum {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export const announcementPriorityLabelMap: { [key in AnnouncementPriorityEnum]: string } = {
  [AnnouncementPriorityEnum.Critical]: 'Critical',
  [AnnouncementPriorityEnum.High]: 'High',
  [AnnouncementPriorityEnum.Medium]: 'Medium',
  [AnnouncementPriorityEnum.Low]: 'Low',
}

export const announcementPriorityColorMap: {
  [key in AnnouncementPriorityEnum]: ThemeAppearance
} = {
  [AnnouncementPriorityEnum.Critical]: 'removed',
  [AnnouncementPriorityEnum.High]: 'moved',
  [AnnouncementPriorityEnum.Medium]: 'inprogress',
  [AnnouncementPriorityEnum.Low]: 'new',
}