import React, { FC, useMemo, useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import { eachDayOfInterval, addDays } from "date-fns";
import { EmployeeFromApi } from "../../../shared/Employee/data/EmployeeFromApi";
import "./Calendar.scss";
import { assertEmployee, useEmployee } from "../../../contexts/EmployeeContext";
import Head from "../../Component/Head/Head";
import { Box, xcss } from "@atlaskit/primitives";
import { SelectValue } from "../../../shared/Common/data/SelectValue";
import { OrgUnitFromApi } from "../../../shared/OrgChart/data/OrgUnitFromApi";
import { ReactSVG } from "react-svg";
import renderCalendar from "./renderCalendar";
import MultiSelectCustom from "../../../components/UI/MultiSelectCustom/MultiSelectCustom";
import { sortFilter } from "../../../utils/helpers/sortFunc";
import { CALENDAR, ORG_CHART } from "../../../utils/constants/api";
import { formatDate } from "../../../utils/helpers/DateTime/formatDate";
import getEmployeesWithOrgUnit from "../../../API/Employees/getEmployeesWithOrgUnit";
import ChevronLeftIcon from "@atlaskit/icon/glyph/chevron-left";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import { IconButton } from "@atlaskit/button/new";
import Button from "@atlaskit/button/new";
import CustomDatePicker from "../../Component/Input/CustomDatePicker/CustomDatePicker";

export const Calendar: FC = (props) => {
  const { employee } = useEmployee();
  assertEmployee(employee);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const employeesUrl = params.get("employees");
  const orgUnitsUrl = params.get("org-units");
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>(
    employeesUrl ? employeesUrl.split(",") : []
  );
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<string[]>(
    orgUnitsUrl ? orgUnitsUrl.split(",") : []
  );

  function validDate(date: string) {
    if (!isNaN(new Date(date).valueOf())) {
      return new Date(date);
    }

    return new Date();
  }

  const [calendarData, setCalendarData] = useState<{ [key: string]: any }>({});
  let dateForCalendar = params.get("date")
    ? validDate(params.get("date") as string)
    : new Date();
  if (!params.get("date")) {
    dateForCalendar = moment().startOf("month").toDate();
  } else {
    dateForCalendar = moment(dateForCalendar).toDate();
  }
  const [selectedDate, setSelectedDate] = useState<Date>(dateForCalendar);
  const monthDates = eachDayOfInterval({
    start: selectedDate,
    end: addDays(selectedDate, 30),
  });

  const [employeeOptions, setEmployeeOptions] = useState<SelectValue[]>([]);
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState<
    SelectValue[]
  >([]);

  const [orgUnitOptions, setOrgUnitOptions] = useState<SelectValue[]>([]);
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<
    SelectValue[]
  >([]);

  const [employees, setEmployees] = useState<EmployeeFromApi[]>([]);

  const [loading, setLoading] = useState(false);

  const offset = useRef(0);

  const limit = useRef(15);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const fetchOrgUnitOptions = async (): Promise<SelectValue[]> => {
    const response = await fetch(ORG_CHART + "?without-hierarchy=true", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    });

    const orgUnits: OrgUnitFromApi[] = response.ok ? await response.json() : [];

    return orgUnits.map((orgUnit) => ({
      value: orgUnit.id,
      label: orgUnit.name,
      company: orgUnit.companyName ? orgUnit.companyName : undefined,
    }));
  };

  useEffect(() => {
    fetchOrgUnitOptions().then((options) => {
      getEmployeesWithOrgUnit()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return [];
          }
        })
        .then((employees: EmployeeFromApi[]) => {
          const employeesWithCompany = employees.map((employee) => {
            const company = options.find(
              (element) => employee.orgUnitId === element.value
            );
            return { ...employee, company: company?.company };
          });

          setEmployees(employeesWithCompany);

          if (employeesWithCompany.length > 0) {
            emptyEmployeesFilter(true, employeesWithCompany);
          }
        });
    });
  }, []);

  useEffect(() => {
    const employeeValues = selectedEmployeeOptions.map(
      (option) => option.value
    );

    setSelectedEmployees(employeeValues);
    setCalendarData({});
    offset.current = 0;

    fetchCalendar();
  }, [selectedEmployeeOptions, selectedDate]);

  useEffect(() => {
    const orgUnitValues = selectedOrgUnitOptions.map((option) => option.value);
    setSelectedOrgUnits(orgUnitValues);
    setCalendarData({});
  }, [selectedOrgUnitOptions]);

  useEffect(() => {
    const container = document.querySelector(".calendar-data-table");
    if (container) {
      container.addEventListener("wheel", handleScroll);
      container.addEventListener("touchmove", handleScroll);
      return () => {
        container.removeEventListener("wheel", handleScroll);
        container.removeEventListener("touchmove", handleScroll);
      };
    }
  }, [selectedOrgUnitOptions, selectedEmployeeOptions, selectedDate]);

  function handleScroll(event: any) {
    const calendarTable = document.querySelector(".calendar-data-table");

    if (calendarTable) {
      const isScrollable =
        calendarTable.scrollHeight >= calendarTable.clientHeight;
      if (!loading && isScrollable) {
        const container = containerRef.current;
        if (container) {
          const scrollTop = container.scrollTop;
          const clientHeight = container.clientHeight;
          const scrollHeight = container.scrollHeight;

          if (scrollTop + clientHeight === scrollHeight) {
            fetchCalendar();
          }
        }
      }
    }
  }

  const handleDateChange = (date: Date) => {
    setSelectedDate(moment(date).toDate());

    let employeesFromUrl: SelectValue[] = [];
    selectedEmployees.forEach((selectedEmployee) => {
      const option = employeeOptions.find(
        (employee) => employee.value === selectedEmployee
      );
      if (option) {
        employeesFromUrl.push(option);
      }
    });
    updateURL(
      employeesFromUrl,
      selectedOrgUnitOptions,
      moment(date).toDate(),
      selectedDate
    );
    const button = document.querySelector(".css-198bg3t");
    if (button) {
      button.dispatchEvent(new MouseEvent("click"));
    }
  };

  function emptyEmployeesFilter(
    firstLoad: boolean = false,
    employees: EmployeeFromApi[]
  ) {
    let orgUnitOptions = employees
      .map((employee) => {
        return {
          value: employee.orgUnitId,
          label: employee.orgUnitName,
          company: employee.company,
        };
      })
      .filter((orgUnit, index, self) => {
        const foundIndex = self.findIndex((c) => c.value === orgUnit.value);
        return foundIndex === index;
      })
      .filter((orgUnit) => orgUnit.value !== null && orgUnit.label !== null);

    orgUnitOptions.push({
      label: "Without Org Unit",
      value: "null",
      company: undefined,
    });

    setOrgUnitOptions(orgUnitOptions as SelectValue[]);

    if (
      employee?.orgUnitId &&
      firstLoad &&
      selectedOrgUnits.length === 0 &&
      selectedEmployees.length === 0
    ) {
      let unitOption = orgUnitOptions.find(
        (unit) => unit.value === employee.orgUnitId
      );
      if (unitOption && unitOption.value && unitOption.label) {
        changeOrgUnitFilter([unitOption] as SelectValue[], employees);
      }
      return;
    }

    if (selectedOrgUnits.length > 0 && orgUnitOptions.length > 1 && firstLoad) {
      let orgUnitsFromUrl: SelectValue[] = [];
      selectedOrgUnits.forEach((selectedOrgUnit) => {
        const option = orgUnitOptions.find(
          (orgUnit) => orgUnit.value === selectedOrgUnit
        );
        if (option) {
          if (option.value && option.label) {
            orgUnitsFromUrl.push(option as SelectValue);
          }
        }
      });
      changeOrgUnitFilter(orgUnitsFromUrl, employees);
      return;
    }
  }

  function changeOrgUnitFilter(
    orgUnits: SelectValue[],
    employees: EmployeeFromApi[]
  ) {
    let employeeOptionsArr = employees
      .map((employee) => {
        let orgs = orgUnits;
        if (orgs.length === 0) {
          orgs = orgUnitOptions;
        }

        const isOrgUnitExist = orgs.some((orgUnit) => {
          return (
            orgUnit.value === employee.orgUnitId ||
            (orgUnit.value === "null" && employee.orgUnitId === null)
          );
        });
        if (isOrgUnitExist) {
          return {
            value: employee.id,
            email: employee.email,
            avatar: employee.avatar,
            label:
              employee.firstNameEn +
              " " +
              employee.lastNameEn +
              (employee.nickname ? " (" + employee.nickname + ")" : ""),
          };
        } else {
          return {
            value: null,
            label: null,
          };
        }
      })
      .filter((employee) => employee.value !== null && employee.label !== null);

    let employeesFromUrl: SelectValue[] = [];
    if (selectedEmployees.length > 0) {
      selectedEmployees.forEach((selectedEmployee) => {
        const option = employeeOptionsArr.find(
          (employee) => employee.value === selectedEmployee
        );
        if (option) {
          if (option.value && option.label) {
            employeesFromUrl.push(option as SelectValue);
          }
        }
      });
    }

    updateURL(
      employeesFromUrl.length > 0 ? employeesFromUrl : [],
      orgUnits,
      null,
      selectedDate
    );

    setSelectedOrgUnitOptions(orgUnits);

    setEmployeeOptions(employeeOptionsArr as SelectValue[]);

    setSelectedEmployeeOptions(
      employeesFromUrl.length > 0 ? employeesFromUrl : []
    );
  }

  const changeEmployeeFilter = (values: SelectValue[]) => {
    setSelectedEmployeeOptions(values);
    updateURL(values, selectedOrgUnitOptions, null, selectedDate);
  };

  function updateURL(
    selectedEmployeeOptions: SelectValue[],
    selectedOrgUnitOptions: SelectValue[],
    date: Date | null = null,
    selectedDate: Date
  ) {
    const queryParams = new URLSearchParams();

    const formatedDate = formatDate(date ? date : selectedDate);

    queryParams.append("date", formatedDate ? formatedDate : "");

    const employeeValues = selectedEmployeeOptions
      .map((option) => option.value)
      .join(",");
    const orgUnitValues = selectedOrgUnitOptions
      .map((option) => option.value)
      .join(",");

    if (employeeValues) {
      queryParams.append("employees", employeeValues);
    }

    if (orgUnitValues) {
      queryParams.append("org-units", orgUnitValues);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

    window.history.replaceState("", "", newUrl);
  }

  function fetchCalendar() {
    const startDateString = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`
      : "";
    const endDate = moment(selectedDate).add(30, "day").toDate();
    const endDateString = endDate
      ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`
      : "";

    const needEmployees =
      selectedEmployeeOptions.length > 0
        ? selectedEmployeeOptions.map((option) => option.value)
        : employeeOptions.map((option) => option.value);

    const needEmployeesOffset = needEmployees.filter(
      (option, index) =>
        index >= offset.current && index < offset.current + limit.current
    );

    if (
      offset.current + limit.current <= needEmployees.length + limit.current &&
      startDateString &&
      endDateString &&
      employeeOptions.length > 0
    ) {
      setLoading(true);

      return fetch(
        `${CALENDAR}?employees=${JSON.stringify(needEmployeesOffset)}&start-date=${startDateString}&end-date=${endDateString}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return {};
          }
        })
        .then((responseCalendarData) => {
          if (responseCalendarData) {
            setCalendarData((calendarData: any) => {
              return { ...calendarData, ...responseCalendarData };
            });

            offset.current += limit.current;
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  interface Option {
    value: any;
    label: string;
    key?: string;
    company?: string;
    avatar?: string;
    email?: string;
    disabled?: boolean;
  }

  const firstInList = employeeOptions
    ? (employeeOptions.find((item) => item.value === employee.id) as Option)
    : null;
  const filteredList = employeeOptions
    ? (employeeOptions.filter((item) => item.value !== employee.id) as Option[])
    : [];

  const employeeOptionsFiltered = firstInList
    ? [firstInList, ...filteredList]
    : filteredList;

  const renderCalendarMemo: any[] | null = useMemo(
    () => renderCalendar(calendarData),
    [calendarData]
  );

  return (
    <div className="calendarPage">
      <Head
        title="Time Off Calendar — CoreHR"
        metaNameTitle="Time Off Calendar — CoreHR"
        description="Time Off Calendar — CoreHR"
        url="/calendar"
      />
      <div className="main-content calendar-main-content">
        <div className="sub-content-calendar-block d-flex">
          <div className="sub-content-main">
            <div className={"calendar-head"}>
              <h4 className={"calendar-title"}>Calendar</h4>

              <Box
                xcss={xcss({
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                })}
              >
                <Box
                  xcss={xcss({
                    display: "flex",

                    alignItems: "center",
                  })}
                >
                  <Button
                    appearance="default"
                    onClick={() => {
                      const currentDate = new Date();

                      currentDate.setDate(1);
                      handleDateChange(new Date(currentDate));
                    }}
                  >
                    <div
                      style={{
                        minWidth: "99px",
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                      }}
                    >
                      Current month
                    </div>
                  </Button>
                  <Box
                    xcss={xcss({
                      marginLeft: "space.100",
                      marginRight: "space.100",
                      display: "flex",
                    })}
                  >
                    {" "}
                    <Box
                      xcss={xcss({
                        marginRight: "space.025",
                      })}
                    >
                      <IconButton
                        label="button"
                        appearance="subtle"
                        icon={(iconProps) => (
                          <ChevronLeftIcon
                            {...iconProps}
                            primaryColor={"rgba(68, 84, 111, 1)"}
                          />
                        )}
                        onClick={() => {
                          const currentDate = new Date(selectedDate);

                          const currentMonth = currentDate.getMonth();

                          const newMonth = currentMonth - 1;

                          currentDate.setMonth(newMonth);

                          handleDateChange(new Date(currentDate));
                        }}
                      />
                    </Box>
                    <Box
                      xcss={xcss({
                        marginLeft: "space.025",
                      })}
                    >
                      <IconButton
                        label="button"
                        appearance="subtle"
                        icon={(iconProps) => (
                          <ChevronRightIcon
                            {...iconProps}
                            primaryColor={"rgba(68, 84, 111, 1)"}
                          />
                        )}
                        onClick={() => {
                          const currentDate = new Date(selectedDate);

                          const currentMonth = currentDate.getMonth();

                          const newMonth = currentMonth + 1;

                          currentDate.setMonth(newMonth);

                          handleDateChange(new Date(currentDate));
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <CustomDatePicker
                      selectedDate={selectedDate}
                      onChange={(val) => {
                        handleDateChange(new Date(val));
                      }}
                    />
                  </Box>
                </Box>
                <div
                  style={{
                    display: "flex",
                    marginRight: "4px",
                  }}
                >
                  <Box
                    xcss={xcss({
                      marginRight: "space.200",
                    })}
                  >
                    <MultiSelectCustom
                      onSelectClick={() => {
                        setOrgUnitOptions((val) =>
                          sortFilter(val, selectedOrgUnitOptions)
                        );
                      }}
                      hasSelectAll={false}
                      options={orgUnitOptions}
                      onChange={(values) => {
                        changeOrgUnitFilter(values, employees);
                      }}
                      value={selectedOrgUnitOptions}
                      placeholder={"Org Unit"}
                      labelledBy={"All Org Units"}
                    />
                  </Box>
                  <MultiSelectCustom
                    contentWidth={300}
                    dropdownHorizontalDisplacement="-94px"
                    onSelectClick={() => {
                      if (selectedEmployeeOptions.length !== 0) {
                        setEmployeeOptions((val) =>
                          sortFilter(val, selectedEmployeeOptions)
                        );
                      }
                    }}
                    hasSelectAll={false}
                    options={employeeOptionsFiltered}
                    onChange={(values) => {
                      changeEmployeeFilter(values);
                    }}
                    value={selectedEmployeeOptions}
                    placeholder={"Employee"}
                    labelledBy={"All employees"}
                  />
                </div>
              </Box>
            </div>
            <div className={"content"}>
              <div className={"calendar"} ref={containerRef}>
                <table className="calendar-data-table">
                  <thead className={"thead-shadow-scrolling"}>
                    <tr>
                      <th key={"employee"}>
                        <div className={"first-column"}>Employee</div>
                      </th>
                      {monthDates.map((elem, index) => {
                        const currentDate = new Date(elem);
                        const isWeekend =
                          currentDate.getDay() === 0 ||
                          currentDate.getDay() === 6;

                        return (
                          <th
                            className={`${isWeekend ? "weekend" : ""}`}
                            key={index}
                          >
                            <div
                              className={
                                new Date(elem).toDateString() ===
                                new Date().toDateString()
                                  ? "today"
                                  : "th-box"
                              }
                            >
                              <span className={"day"}>
                                {currentDate.getDate()}
                              </span>
                              <span className={"weekday"}>
                                {currentDate
                                  .toLocaleString("en-US", { weekday: "short" })
                                  .slice(0, 3)}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>{renderCalendarMemo}</tbody>
                </table>
                {loading && (
                  <section className={"loading"}>
                    <span className="svg-spinner">
                      <ReactSVG src="/image/spinner.svg" />
                    </span>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
