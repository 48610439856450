import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const StyledIconMultiSelect = styled.div<{
    dropdownHorizontalDisplacement?: string;
}>`
    transition: none;

    .dropdown {
        &-container {
            height: 40px;
            padding: ${token("space.050")} ${token("space.100")};
            gap: ${token("space.025")};
            border: 2px solid rgba(9, 30, 66, 0.14);
            background: ${token("color.background.input")};
            color: ${token("color.text.subtlest")};
            border-radius: 3px;
            cursor: pointer;

            &:focus-within {
                box-shadow: none;
            }

            &:hover {
                color: ${token("color.text")};
            }

            &[aria-expanded="true"] {
                border: 2px solid ${token("color.border.focused")};
                background: ${token("color.background.input.pressed")};
            }
        }

        &-heading {
            height: 100%;
            padding: 0;
            cursor: pointer;

            &-value {
                height: 100%;
                display: flex;
                align-items: center;
                font-weight: 400;

                & > span {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: inherit;
                    color: ${token("color.text.subtlest")};
                }
            }
        }

        &-content {
            display: flex;
            padding: ${token("space.100")} 0px;
            margin-top: ${token("space.100")};
            flex-direction: column;
            align-items: center;
            border-radius: 3px;
            background: ${token("elevation.surface.overlay")};
            box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
            0px 8px 12px 0px rgba(9, 30, 66, 0.15);
            left: ${(props) =>
                    props.dropdownHorizontalDisplacement
                            ? props.dropdownHorizontalDisplacement
                            : "0"};

            .panel-content {
                width: 100%;
                box-shadow: none;
            }

            .options {
                max-height: 300px;
                overflow-x: hidden;
            }

            .select-item {
                width: 100%;
                height: 32px;
                padding: 0;

                &:hover,
                &:active {
                    box-shadow: inset 2px 0px 0px ${token("color.border.selected")};
                    background: ${token("color.background.neutral.subtle.hovered")};
                }

                &.selected {
                    background: none;
                }
            }

            .item-renderer {
                display: flex;
                height: 32px;
                padding: 0px ${token("space.150")} 0px ${token("space.200")};
                align-items: center;
                align-self: stretch;
                gap: ${token("space.050")};
                font-size: 14px;
                font-style: normal;
                font-weight: 400;

                img {
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                }
            }

            .item-textline {
                width: 100%;
                height: 20px;
                display: flex;
                align-items: center;
                gap: 0 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: ${token("color.text")};

                .small-icon {
                    pointer-events: none;
                }
            }
        }
    }

    // Overwrite default
    .clear-selected-button {
        display: none !important;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(102, 102, 102, 0.4);
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;



export const StyledIconSelect = styled.div`
  width: 320px;

  & > div {
    margin-block-start: 0;
  }

  [class*="control"] {
    height: 32px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background: ${token("color.background.input")};
    &:focus-within {
      box-shadow: none;
      border-radius: 3px;
      border: 2px solid ${token("color.border.focused")};
      background: ${token("color.background.input.pressed")};
    }

    & > div {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

    [class*="option"] {
        &:hover {
            box-shadow: inset 2px 0 0 var(--ds-border-selected, #0052CC);
            background: rgb(241, 243, 245);
        }
    }
    
    [class*=" css-363ehx-option"] {
        box-shadow: none;
        background: none;
    }

    [class*="ValueContainer"],
  [class*="IndicatorsContainer"] {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  [class*="indicatorContainer"] {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-icon-select {
    [class*="singleValue"] {
      & > div > p {
        padding: 0;
        width: auto;
      }

      & > div > span {
        display: none;
      }
    }
  }
`;

export const StyledIconPlaceholder = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token("space.050")};

  span {
    display: flex;
    align-items: center;
  }

  & > p {
    height: 20px;

    & > span {
      margin: 0;
    }
  }

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledIconItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${token("space.100")};

  & > p {
    width: max-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  & > span {
    flex-shrink: 0;
    margin-left: auto;
    color: ${token("color.text.subtlest")};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;
