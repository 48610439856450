import { useState, useEffect } from "react";
import { adminStatusAPI } from "API/common/status";
import { OptionType } from "@atlaskit/select";
import { Status } from "interfaces/common/status.interface";

export const switchedToStatuses = (
  statuses: Status[],
  excludeStatus: string[]
): readonly OptionType[] => {
  const filteredStatuses = statuses
    .filter((status) => !excludeStatus.includes(status.id))
    .map((status) => ({ label: status.name.toString(), value: status.id }));
  return filteredStatuses;
};

const useStatuses = (
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>
) => {
  const addStatusTransition = async (fromStatus: string, toStatus: string) => {
    const response = await adminStatusAPI.addStatusesTransitions(
      fromStatus,
      toStatus
    );
    if (response.ok) {
      setForceUpdate((prev) => prev + 1);
    }
  };

  return { addStatusTransition };
};

export default useStatuses;
