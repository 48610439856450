import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const StyledProgresTracker = styled.div`
  ul {
    width: 100%;
    max-width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: ${token("space.300")};
    inset-inline-start: 0;
  }
  a {
    outline: none;
  }
`;

export const StyledHeaderWrap = styled.div`
  div {
    padding: 24px !important;
  }
`;

export const StyledBodyWrap = styled.div`
  .css-ci8l2m-ModalBody {
    padding: 0px 24px !important;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
`;

export const StyledLeftSide = styled.div`
  width: 352px;
  padding-left: 32px;
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
  }

  textarea,
  div[role="presentation"] {
    height: 40px;
    border: 2px solid rgba(9, 30, 66, 0.14) !important;

    &:focus-within {
      box-shadow: none;
    }

    &:hover {
      color: ${token("color.text")};
      border: 2px solid ${token("color.border.focused")} !important;
    }

    &:focus {
      border: 2px solid ${token("color.border.focused")} !important;
      background: ${token("color.background.input.pressed")};
    }
  }

  h4 {
    color: ${token("color.text.subtle")};
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  p {
    color: ${token("color.text.subtlest")};
    margin: 0;
    padding: 0px 0px 4px 28px;
    font-size: 12px;
  }
`;

export const StyledLeftSideBig = styled.div`
  width: 512px;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .burnout-description {
    p {
      padding: 2px;
    }
  }
  label {
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
    width: fit-content;

    input {
      &:focus {
        outline: none !important;
      }
    }
  }

  .isError {
    border: 2px solid ${token("color.text.danger")} !important;
  }

  textarea,
  div[role="presentation"] {
    height: 36px;
    border: 2px solid rgba(9, 30, 66, 0.14) !important;

    &:focus-within {
      box-shadow: none;
    }

    &:hover {
      color: ${token("color.text")};
      border: 2px solid ${token("color.border.focused")} !important;
    }

    &:focus {
      border: 2px solid ${token("color.border.focused")} !important;
      background: ${token("color.background.input.pressed")};
    }
  }

  h4 {
    color: ${token("color.text.subtle")};
    font-size: 12px;
    font-weight: 590;
    margin-bottom: 4px;
  }

  p {
    color: ${token("color.text.subtlest")};
    margin: 0;
    padding: 0px 0px 4px 28px;
    font-size: 12px;
  }
`;

export const StyledRightSide = styled.div`
  width: 357px;
  height: 100%;
  min-height: 276px;
  display: flex;
  flex-direction: column;
  gap: ${token("space.200")};
  padding: ${token("space.200")};
  border: 1px solid ${token("color.border.disabled")};

  h3 {
    color: ${token("color.text")};
    font-size: 16px;
    font-weight: 600;
  }

  p {
    color: ${token("color.text.subtlest")};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding-left: 24px;
    gap: ${token("space.100")};

    li {
      margin: 0;
      color: ${token("color.text.subtlest")};
    }

    li::marker {
      color: ${token("color.text.subtlest")};
    }
  }
`;

export const StyledSettingsPreview = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 24px;

  h4 {
    font-size: 14px;
    width: 80px;
  }

  span {
    color: ${token("color.text")};
    word-break: break-word;
  }

  p {
    color: ${token("color.text.subtle")};
    word-break: break-word;
    white-space: pre-wrap;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px; 
  .rules {
    ul {
      list-style-type: decimal;
    }
    h4 {
      margin-bottom: 8px;
    }
  }
  h4 {
    font-size: 14px;
  }
`;

export const StyledMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${token("color.text.subtlest")};
`;

export const BurnoutBlock = styled.div`
  margin-left: 27px;
`;

export const StyledMessageEmpty = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 4px;
  color: ${token("color.text.subtlest")};
`;

export const StyledTextAreaNumer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  flex-grow: 1;
  color: ${token("color.text.subtlest")};
`;

export const StyledErrorField = styled.div`
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
  gap: 4px;
`;

export const StyledMessageError = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0 0 16px;
  color: ${token("color.text.danger")};
`;

export const StyledMessageErrorCustom = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${token("color.text.danger")};
`;

export const StyledNotesText = styled.div`
  textarea {
    height: 108px !important;
  }
`;

export const StyledPolicyDescript = styled.div`
  margin-top: 16px;
`;

export const StyledFooterWrap = styled.div`
  div {
    padding: 24px !important;
  }
`;

export const StyledTextField = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div[role="presentation"] {
    border: 2px solid rgba(9, 30, 66, 0.14) !important;

    &:focus-within {
      box-shadow: none;
    }

    &:hover {
      color: ${token("color.text")};
      border: 2px solid ${token("color.border.focused")} !important;
    }

    &:focus {
      border: 2px solid ${token("color.border.focused")} !important;
      background: ${token("color.background.input.pressed")};
    }
  }

  p {
    font-size: 14px;
    color: ${token("color.text")};
    padding: 0 0 0 28px;
  }

  span {
    font-size: 14px;
    color: ${token("color.text")};
    padding: 0;
  }
`;

export const StyledTextInput = styled.div`
  width: 84px;
  height: 36px;

  input {
    width: 84px;
    height: 36px;
    padding: ${token("space.100")};
  }
`;

export const StyledRuleBlock = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledRuleTop = styled.div`
  padding: 0 0 0 28px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledRuleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(9, 30, 66, 0.14);

  h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    color: ${token("color.text.subtlest")};
  }
`;

export const StyledRuleAccruals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    color: ${token("color.text.subtle")};
  }
`;

export const StyledRuleSelect = styled.div`
    width: 146px;
        color: ${token("color.text.subtlest")};
    .css-wwet8f {
        margin-top: 0;
    }
    .css-x50wjx-menu {
        min-width: 100%;
        width: max-content;
    }
    .css-573cd-control {
        border: 2px solid rgba(9, 30, 66, 0.14) !important;
    }
`;

export const StyledInput = styled.input`
  width: 60px;
  padding: 4px;
  border: 1px solid ${token("color.border")};
  border-radius: 4px;

  input {
    width: 84px;
    height: 36px;
    color: ${token("color.text.subtlest")};
  }
`;

export const StyledRuleAllocate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: ${token("color.text")};

  div[role="presentation"] {
    width: 84px !important;
    max-width: 84px;
    height: 36px !important;
    border: 2px solid rgba(9, 30, 66, 0.14) !important;

    &:focus-within {
      box-shadow: none;
    }

    &:hover {
      color: ${token("color.text")};
      border: 2px solid ${token("color.border.focused")} !important;
    }

    &:focus {
      border: 2px solid ${token("color.border.focused")} !important;
      background: ${token("color.background.input.pressed")};
    }

    input {
      width: 84px !important;
    }
  }
`;

export const StyledAllocateInputs = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

export const StyledAddButton = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  color: ${token("color.text.accent.gray")};
  cursor: pointer;
`;

export const StyledTrashButton = styled.div`
  display: flex;
  margin-bottom: 4px;
  transition: background-color 0.3s ease;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: ${token("color.background.neutral.subtle.hovered")};
    transition: background-color 0.3s ease;
  }

  &:focus {
    background-color: ${token("color.background.neutral.subtle.pressed")};
    transition: background-color 0.3s ease;
  }
`;

export const StyledCalculator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${token("color.text.subtlest")};
  margin-bottom: 16px;

  div {
    height: 24px;
  }
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const StyledWarningIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: -35px;
`;

export const StyledErrorDialog = styled.div`
  .css-1mk12qt {
      margin-bottom: -12px!important;
  }
  p {
    padding: 0;
    color: ${token("color.text")};
    font-size: 14px;
  }
`;
