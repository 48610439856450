import {FC} from "react";
import style from './Lozenge.module.scss';
import LozengeADS, {LozengeProps} from '@atlaskit/lozenge';

export const Lozenge: FC<LozengeProps> = ({children, ...restProps}) => {
    return(
        <div className={style.lozenge}>
            <LozengeADS {...restProps}>{children}</LozengeADS>
        </div>
    );
}