import { AUTH } from "../../utils/constants/api";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errors";

export const checkAuth = async () => {
  try {
    const response = await fetch(AUTH);

    if (response.status === AUTHORIZATION_ERROR) return null;

    if (!response.ok)
      throw new Error("Network response was not ok: " + response.statusText);

    return await response.json();
  } catch (error) {
    console.error(error);
    throw new Error();
  }
};
