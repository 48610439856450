import React, { useEffect, useState, useRef } from "react";
import { xcss, Box } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import MultiSelectCustom from "../../../../../../components/UI/MultiSelectCustom/MultiSelectCustom";
import { SelectValue } from "../../../../../../shared/Common/data/SelectValue";
import { Checkbox } from "@atlaskit/checkbox";
import styled from "styled-components";
import Button from "@atlaskit/button/new";

const SelectWrapper = styled.div`
  ${(props) => `
  .dropdown-container{
    width:278px;
  }
  .dropdown-container, .dropdown-heading{
    width:278px;
  }
`}
`;

export default function FilterModal({
  holidaysTypesFilter,
  setHolidaysTypesFilter,
  countryOptions,
  selectedCountryOptions,
  setSelectedCountryOptions,
  orgUnitOptions,
  setSelectedOrgUnitOptions,
  selectedOrgUnitOptions,
  onCancel,
  onApply,
}: {
  holidaysTypesFilter: string[];
  setHolidaysTypesFilter: (arg: string[]) => void;
  countryOptions: SelectValue[];
  selectedCountryOptions: SelectValue[];
  setSelectedCountryOptions: (arg: SelectValue[]) => void;
  orgUnitOptions: SelectValue[];
  setSelectedOrgUnitOptions: (arg: SelectValue[]) => void;
  selectedOrgUnitOptions: SelectValue[];
  onCancel: () => void;
  onApply: () => void;
}) {
  useEffect(() => {
    function closeModal() {
      onCancel();
    }

    document.addEventListener("click", closeModal);

    return () => {
      document.removeEventListener("click", closeModal);
    };
  }, []);

  return (
    <Box
      xcss={xcss({
        inset: "56px 0 0 10px",
        position: "absolute",
        width: "312px",
        height: "310px",
        backgroundColor: "elevation.surface.overlay",
        boxShadow: "elevation.shadow.overlay",
        paddingTop: "space.200",
        paddingBottom: "space.200",
        paddingLeft: "space.200",
        paddingRight: "space.200",
      })}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      }}
    >
      <Box
        xcss={xcss({
          marginBottom: "space.200",
        })}
      >
        <Box
          xcss={xcss({
            fontFamily: "inherit",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px",
            color: "color.text",
          })}
        >
          Holiday type
        </Box>
        <Box>
          <Box
            xcss={xcss({
              display: "flex",
              alignItems: "center",
              paddingTop: "space.200",
            })}
          >
            <Checkbox
              isChecked={holidaysTypesFilter.includes("corporate_event")}
              value="default checkbox"
              label="Corporate event"
              onChange={() => {
                if (holidaysTypesFilter.includes("corporate_event")) {
                  setHolidaysTypesFilter(
                    holidaysTypesFilter.filter((item) => {
                      return item !== "corporate_event";
                    })
                  );
                } else {
                  setHolidaysTypesFilter([
                    "corporate_event",
                    ...holidaysTypesFilter,
                  ]);
                }
              }}
              name="checkbox-default"
              testId="cb-default"
            />
            <div
              style={{
                backgroundColor: token(
                  "color.background.accent.purple.subtlest.pressed"
                ),
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                marginLeft: "8px",
              }}
            ></div>
          </Box>

          <Box
            xcss={xcss({
              display: "flex",
              alignItems: "center",
              paddingTop: "space.100",
            })}
          >
            <Checkbox
              isChecked={holidaysTypesFilter.includes("professional_holiday")}
              value="default checkbox"
              label="Professional holiday"
              onChange={() => {
                if (holidaysTypesFilter.includes("professional_holiday")) {
                  setHolidaysTypesFilter(
                    holidaysTypesFilter.filter((item) => {
                      return item !== "professional_holiday";
                    })
                  );
                } else {
                  setHolidaysTypesFilter([
                    "professional_holiday",
                    ...holidaysTypesFilter,
                  ]);
                }
              }}
              name="checkbox-default"
              testId="cb-default"
            />
            <div
              style={{
                backgroundColor: token(
                  "color.background.accent.lime.subtler.pressed"
                ),
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                marginLeft: "8px",
              }}
            ></div>
          </Box>
          <Box
            xcss={xcss({
              display: "flex",
              alignItems: "center",
              paddingTop: "space.100",
            })}
          >
            <Checkbox
              isChecked={holidaysTypesFilter.includes("state_holiday")}
              value="default checkbox"
              label="State holiday"
              onChange={() => {
                if (holidaysTypesFilter.includes("state_holiday")) {
                  setHolidaysTypesFilter(
                    holidaysTypesFilter.filter((item) => {
                      return item !== "state_holiday";
                    })
                  );
                } else {
                  setHolidaysTypesFilter([
                    "state_holiday",
                    ...holidaysTypesFilter,
                  ]);
                }
              }}
              name="checkbox-default"
              testId="cb-default"
            />
            <div
              style={{
                backgroundColor: token(
                  "color.background.accent.orange.subtler.pressed"
                ),
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                marginLeft: "8px",
              }}
            ></div>
          </Box>
        </Box>
      </Box>
      <Box
        xcss={xcss({
          height: "2px",
          borderRadius: "border.radius.050",
          backgroundColor: "color.background.neutral.hovered",
          marginBottom: "space.075",
          marginTop: "space.075",
        })}
      ></Box>
      <Box
        xcss={xcss({
          marginTop: "space.250",
        })}
      >
        <Box
          xcss={xcss({
            marginBottom: "space.150",
          })}
        >
          <SelectWrapper>
            <MultiSelectCustom
              contentWidth={278}
              hasSelectAll={false}
              options={countryOptions}
              onChange={(values) => {
                setSelectedCountryOptions(values);
              }}
              value={selectedCountryOptions}
              placeholder={"Work location"}
              labelledBy={"All work locations"}
            />
          </SelectWrapper>
        </Box>
        <Box
          xcss={xcss({
            marginBottom: "space.150",
          })}
        >
          <SelectWrapper>
            <MultiSelectCustom
              contentWidth={278}
              hasSelectAll={false}
              options={orgUnitOptions}
              onChange={(values) => {
                setSelectedOrgUnitOptions(values);
              }}
              value={selectedOrgUnitOptions}
              placeholder={"Org Unit"}
              labelledBy={"All Org Units"}
            />
          </SelectWrapper>
        </Box>
      </Box>
      <Box
        xcss={xcss({
          display: "flex",
          justifyContent: "flex-end",
        })}
      >
        <Box>
          <Box
            xcss={xcss({
              marginRight: "space.050",
              display: "inline-block",
            })}
          >
            <Button
              appearance="subtle"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
          <Button
            appearance="primary"
            onClick={() => {
              onApply();
            }}
          >
            Apply filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
