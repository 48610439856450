import { ValidationRuleSet } from "API/auth/useValidationRules";

export const resetPasswordValidationRules: ValidationRuleSet[] = [
    {
        name: 'newPassword',
        rules: [
            { text: 'Only latin letters', status: 'default' },
            { text: 'At least 8 characters', status: 'default' },
            { text: 'At least 1 uppercase', status: 'default' },
            { text: 'At least 1 lowercase', status: 'default' },
            { text: 'At least 1 number', status: 'default' },
            { text: 'At least 1 special character', status: 'default' },
        ],
    },
    {
        name: 'confirmedPassword',
        rules: [],
    },
];
