import { FC, ReactNode, useEffect, useState } from "react";
import { TabContainer } from "../TabContainer";
import { useProfile } from "../../context";
import Spoiler from "../../../UI/Spoiler";
import { ReactComponent as Stars } from "../../../../assets/images/ProfileIcons/Stars.svg";
import styled from "styled-components";
import { INTERVIEWS, EMPLOYEES } from "../../../../utils/constants/api";
import { xcss, Box } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import Button from "@atlaskit/button/new";
import ShortcutIcon from "@atlaskit/icon/glyph/shortcut";

import ErrorIcon from "@atlaskit/icon/glyph/error";
import WarningIcon from "@atlaskit/icon/glyph/warning";

const ReassignButtonWrapper: FC<{
  children: ReactNode;
}> = styled.div`
  margin-top: ${token("space.200")};
  button {
    width: 216px;
  }
`;

const ButtonWrapper: FC<{ children: ReactNode; isSelected: boolean }> =
  styled.div`
    button {
      width: 84px;
    }
    ${(props) =>
      !props.isSelected
        ? `button {
    color: ${token("color.text.subtlest")} !important;
  }`
        : ""}
  `;

const BoxScroll = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(102, 102, 102, 0.4);
      border-radius: 4px;
    }
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const AITab: FC<{
  isManagerOpenProfile: boolean;
  isAccessEdit: boolean;
}> = ({ isManagerOpenProfile, isAccessEdit }) => {
  const { contactInfo, profile } = useProfile();
  const [currentAdviceDate, setCurrentAdviceDate] = useState("");
  const [dataByDateObj, setdataByDateObj] = useState<{ [key: string]: any }>(
    {}
  );

  const [directManager, setDirectManager] = useState<{
    firstNameEn: string;
    id: string;
    image: string;
    lastNameEn: string;
    nickname: string;
  } | null>(null);

  const [reassignLoading, setReassignLoading] = useState(false);

  useEffect(() => {
    fetch(EMPLOYEES + `/${profile.id}/interviews`)
      .then((res) => {
        return res.json();
      })
      .then((data: { [key: string]: any }[]) => {
        const dataByDate: { [key: string]: any } = {};

        data.forEach((item) => {
          dataByDate[item.date] = item;
        });

        setdataByDateObj(dataByDate);
        if (Object.keys(dataByDate).length > 0) {
          setCurrentAdviceDate(Object.keys(dataByDate)[0]);
        }
      });

    fetch(EMPLOYEES + "/employee/" + profile.id + "/direct-manager")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        setDirectManager(data);
      });
  }, [profile.id, reassignLoading]);

  return (
    <TabContainer>
      {Object.keys(dataByDateObj).filter(
        isAccessEdit || isManagerOpenProfile
          ? () => true
          : (item) => {
              return (
                dataByDateObj[item].status !== "failed" &&
                dataByDateObj[item].status !== "processing"
              );
            }
      ).length > 0 ? (
        <Box
          xcss={xcss({
            gap: "space.0",
          })}
        >
          <h4
            style={{
              color: token("color.text"),
              fontFamily: "inherit",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "25.2px",
            }}
          >
            Оцінка та рекомендації
          </h4>
          <Box
            xcss={xcss({
              height: "2px",
              borderRadius: "border.radius.050",
              backgroundColor: "color.background.neutral.hovered",
              marginBottom: "space.150",
              marginTop: "space.150",
            })}
          ></Box>
          {Object.keys(dataByDateObj).length > 1 && (
            <BoxScroll>
              <Box
                xcss={xcss({
                  display: "flex",
                  marginBottom: "space.150",
                  gap: "space.100",
                  overflowX: "auto",
                })}
              >
                {Object.keys(dataByDateObj).map((item) => {
                  return (
                    <ButtonWrapper
                      isSelected={
                        dataByDateObj[item].date === currentAdviceDate
                      }
                    >
                      <Button
                        onClick={() => {
                          setCurrentAdviceDate(dataByDateObj[item].date);
                        }}
                        isSelected={
                          dataByDateObj[item].date === currentAdviceDate
                        }
                        appearance={
                          dataByDateObj[item].date === currentAdviceDate
                            ? "default"
                            : "subtle"
                        }
                      >
                        {new Date(dataByDateObj[item].date).toLocaleDateString(
                          "uk-UA",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          }
                        )}
                      </Button>
                    </ButtonWrapper>
                  );
                })}
              </Box>
            </BoxScroll>
          )}
          <Box
            xcss={xcss({
              display: "flex",
              flexDirection: "column",
              gap: "space.200",
            })}
          >
            {currentAdviceDate.length > 0 ? (
              dataByDateObj[currentAdviceDate].status === "failed" &&
              (isAccessEdit || isManagerOpenProfile) ? (
                <Box
                  xcss={xcss({
                    paddingTop: "space.100",
                  })}
                >
                  <Box
                    xcss={xcss({
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    <ErrorIcon
                      primaryColor={token("color.icon.danger")}
                      label="error"
                    />
                    <Box
                      xcss={xcss({
                        marginLeft: "space.050",
                        color: "color.text",

                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px",
                      })}
                    >
                      Something went wrong
                    </Box>
                  </Box>
                  <Box
                    xcss={xcss({
                      paddingTop: "space.100",
                      color: "color.text.subtle",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "20px",
                    })}
                  >
                    {directManager?.id
                      ? "It looks like the AI personality analysis didn't go as planned. No worries, though! Your manager can request a new analysis at any time."
                      : "It looks like the AI personality analysis didn't go as planned. No worries, though! HR manager can request a new analysis at any time."}
                  </Box>
                  <Box>
                    {isManagerOpenProfile && (
                      <ReassignButtonWrapper>
                        <Button
                          isLoading={reassignLoading}
                          onClick={(e) => {
                            setReassignLoading(true);
                            fetch(INTERVIEWS + "/request", {
                              method: "POST",
                              body: JSON.stringify([profile.id]),
                            }).then((res) => {
                              setReassignLoading(false);
                            });
                          }}
                          appearance="primary"
                        >
                          Reassign analysis
                        </Button>
                      </ReassignButtonWrapper>
                    )}
                    {!isManagerOpenProfile && isAccessEdit ? (
                      <Box
                        xcss={xcss({
                          display: "flex",
                        })}
                      >
                        <a
                          href={"/employee/" + directManager?.id}
                          style={{
                            marginTop: token("space.200"),
                            color: token("color.link"),
                            display: "flex",
                            alignItems: "start",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                        >
                          Manager's profile
                        </a>
                        <a
                          href={"/employee/" + directManager?.id}
                          style={{
                            marginTop: token("space.200"),
                            color: token("color.link"),
                            display: "flex",
                            alignItems: "start",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                          target="_blank"
                        >
                          <span style={{ marginLeft: token("space.100") }}>
                            <ShortcutIcon label="ShortcutIcon" />
                          </span>
                        </a>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ) : dataByDateObj[currentAdviceDate].status === "processing" &&
                (isAccessEdit || isManagerOpenProfile) ? (
                <Box
                  xcss={xcss({
                    paddingTop: "space.100",
                  })}
                >
                  <Box
                    xcss={xcss({
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    <WarningIcon
                      primaryColor={token("color.icon.information")}
                      label="warning"
                    />
                    <Box
                      xcss={xcss({
                        marginLeft: "space.050",
                        color: "color.text",

                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px",
                      })}
                    >
                      AI is processing your responses, your results will appear
                      here soon
                    </Box>
                  </Box>
                </Box>
              ) : (dataByDateObj[currentAdviceDate].status === "failed" ||
                  dataByDateObj[currentAdviceDate].status === "processing") &&
                !(isAccessEdit || isManagerOpenProfile) ? null : (
                Object.keys(dataByDateObj[currentAdviceDate].advice).map(
                  (title) => {
                    return (
                      <Spoiler
                        key={title + "_" + dataByDateObj[currentAdviceDate].id}
                        title={title}
                        text={dataByDateObj[currentAdviceDate].advice[title]}
                      />
                    );
                  }
                )
              )
            ) : null}
          </Box>
        </Box>
      ) : (
        <EmptyState />
      )}
    </TabContainer>
  );
};

function EmptyState() {
  return (
    <Box
      xcss={xcss({
        margin: "auto",

        display: "flex",
        flexDirection: "column",

        alignItems: "center",
      })}
    >
      <img src="/image/NoResultsFound.svg" style={{ width: "324px" }} />
      <h3
        style={{
          marginTop: "0",
          color: token("color.text"),
          fontFamily: "inherit",
          fontSize: "35px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "40px",
        }}
      >
        There is no information here yet
      </h3>
    </Box>
  );
}
