import {EMPLOYEES} from "../../constants";
import {type ContactInfoProps} from './contactInfo.type';


export const fetchContactInfoAPI = async (idProfile: string) => {
    try {
        const response = await fetch(`${EMPLOYEES}/employee/${idProfile}/contact-info/`, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-type': 'application/json'}
        });

        return await response.json() as Array<ContactInfoProps>;
    } catch(error) {
        console.error(error);
        throw new Error;
    }
};