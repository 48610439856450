import {useState} from "react";
import {LevelProps} from "./level.types";
import {useQuery} from "../../../../useQuery";
import {fetchLevelAPI} from "./level";
import {useEvent} from "../../../../../customHooks/useEvent";

export const useLevel = () => {
    const [levels, setLevels] = useState<Array<LevelProps>>([]);
    const [getAllLevels, isLoading, isSuccess, isError] = useQuery(fetchLevelAPI);

    const fetchLevels = useEvent(async () => {
        return getAllLevels()
            .then((response) => {
                setLevels(response);
                return response;
            });
    });

    return {
        levels: levels,
        fetchLevels: fetchLevels,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError
    };
};