import { FC, useState } from "react";
import cn from "classnames";
import InlineEdit from "@atlaskit/inline-edit";
import { useSetField } from "../../../../../API/profile/setField/useSetField";
import { BlockInfoItem } from "../../../BlockInfo";
import { InputText } from "../../../ViewingModes/InputText";
import { DatePicker } from "../../../../UI/DatePicker";
import ModalPolicyNotification from "components/Profile/Modal/PolicyNotification/ModalPolicyNotification";
import { dateFormatterB } from "../../dateFormatter";
import { useProfile } from "../../../context";
import style from "../../../Profile.module.scss";
import { ProfileModalChangeDateWithPolicyAccrual } from "../../../../../interfaces/timeOffPolicy/profileModalChangeDateWithPolicyAccrual.interface";
import { parse, format, addDays } from "date-fns";

const todayDate = new Date();

export const DateOfBirth: FC = () => {
  const {
    isAccessEditRole,
    isAccessEdit,
    profile,
    idOpenProfile,
    isManagerOpenProfile,
  } = useProfile();
  const [value, setValue] = useState<string | null>(
    profile.dateOfBirth || null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [policies, setPolicies] = useState<
    ProfileModalChangeDateWithPolicyAccrual[]
  >([]);
  const { setField } = useSetField(idOpenProfile, true);

  const handleDateChange = async (event: string | null) => {
    setValue(event);

    try {
      const res = await setField(["date-of-birth", event].join("/"));

      if (res) {
        const policiesArray = Object.values(
          res
        ) as ProfileModalChangeDateWithPolicyAccrual[];
        setPolicies(policiesArray);
        if (policiesArray.length > 0) {
          setIsModalOpen(true);
        }
      }
    } catch (error: any) {
      console.error("Error changing date of birth:", error.message);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPolicies([]);
  };

  const handleConfirmModal = () => {
    setIsModalOpen(false);
  };

  const parseInputValue = (date: string, dateFormat: string): Date => {
    const parsedDate = parse(date, "dd.MM.yyyy", new Date());
    return parsedDate;
  };

  return (
    <BlockInfoItem title="Date of birth">
      <div
        className={cn(
          style.inputWrapper,
          isAccessEditRole && style.profileInputTextIsEditing
        )}
      >
        <InlineEdit
          {...(!isAccessEditRole && { isEditing: false })}
          hideActionButtons
          defaultValue={value}
          editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
            <DatePicker
              {...fieldProps}
              onChange={(event) => {
                handleDateChange(event);
              }}
              appearance="subtle"
              maxDate={`${todayDate.getFullYear() - 14}-${("00" + (todayDate.getMonth() + 1)).slice(-2)}-${("00" + todayDate.getDate()).slice(-2)}`}
              spacing="compact"
              dateFormat="DD.MM.YYYY"
              placeholder="Pick a Date of birth"
              parseInputValue={parseInputValue}
              autoFocus
              weekStartDay={1}
            />
          )}
          readView={() => (
            <InputText
              value={
                value
                  ? dateFormatterB(
                      value,
                      !(isAccessEdit || isManagerOpenProfile)
                    )
                  : value
              }
            />
          )}
          onConfirm={(value) => setValue(value)}
        />
      </div>

      {isModalOpen && (
        <ModalPolicyNotification
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmModal}
          policies={policies}
        />
      )}
    </BlockInfoItem>
  );
};
