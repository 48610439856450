import {ORG_CHART} from "../../../../constants";
import {OrgUnitResponseProps, OrgUnitTransformProps} from "./orgUnit.types";

const transformToADSSelectOptions = (options: Array<OrgUnitResponseProps>): Array<OrgUnitTransformProps> => {
    return options.map((option) => {
        return {
            ...option,
            label: option.name,
            value: option.id
        }
    });
};

const transformToHierarchy = (options: Array<OrgUnitResponseProps>): Array<OrgUnitTransformProps> => {
    return options.map((option) => {
        if(option.children && option.children.length) {
            return {
                ...option,
                label: option.name,
                value: option.id,
                options: transformToHierarchy(option.children)
            }
        }

        return {
            ...option,
            label: option.name,
            value: option.id
        }
    });
};

export const fetchOrgUnitAPI = async (isHierarchy = false) => {
    try {
        const response = await fetch(`${ORG_CHART}${isHierarchy ? '' : '?without-hierarchy=true'}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        });

        const data = await response.json() as Array<OrgUnitResponseProps>;
        return isHierarchy ? transformToHierarchy(data) : transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};