import { forwardRef } from "react";

import {
  DatePicker as DatePickerADS,
  DatePickerProps,
} from "@atlaskit/datetime-picker";

export const DatePicker = forwardRef<any, DatePickerProps>(
  ({ ...restProps }, ref) => {
    return (
      <div>
        <DatePickerADS {...restProps} ref={ref} />
      </div>
    );
  }
);
