export function formatPhoneNumber(number: string, active: boolean = false) {
    if(!number) {
        return number;
    }
    let formatted = number.replace(/\D/g, "");
    if (formatted.length > 0) {
        formatted = `+${formatted.substring(0, 3)} ${formatted.substring(3, 5)} ${formatted.substring(5, 8)} ${formatted.substring(8, 10)} ${formatted.substring(10, 12)}`;
        if (number.length > 1 && number[number.length - 1] === ' ' && number[number.length - 2] === ' ') {
            formatted = formatted.slice(0, -1);
        }
    } else if (number === '+' || number === '') {
        formatted = active ? '+' : '';
    }

    return formatted.trim();
}
