import { useState } from "react";
import { OrgUnitTransformProps } from "./orgUnit.types";
import { useQuery } from "../../../../useQuery";
import { fetchOrgUnitAPI } from "./orgUnit";
import { useEvent } from "../../../../../customHooks/useEvent";

export const useOrgUnit = (isHierarchy = false) => {
  const [orgUnits, setOrgUnits] = useState<Array<OrgUnitTransformProps>>([]);
  const [getAllOrgUnits, isLoading, isSuccess, isError] =
    useQuery(fetchOrgUnitAPI);

  const fetchOrgUnits = useEvent(async () => {
    getAllOrgUnits(isHierarchy).then((response) => {
      setOrgUnits(response);
    });
  });

  return {
    orgUnits: orgUnits,
    fetchOrgUnits: fetchOrgUnits,
    isLoading: isLoading,
    isSuccess: isSuccess,
    isError: isError,
  };
};
