const m= ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const dateFormatter = (date: string): string => {
    const nDate = new Date(date);

    return `${nDate.getDate()}.${nDate.getMonth() + 1}.${nDate.getFullYear()}`;
}

export const dateFormatterB = (date: string, isHideYer = false): string => {
    const nDate = new Date(date);

    return isHideYer ? `${m[nDate.getMonth()]}, ${nDate.getDate()}` : `${('00'+nDate.getDate()).slice(-2)}.${('00'+(nDate.getMonth() + 1)).slice(-2)}.${nDate.getFullYear()}`;
}