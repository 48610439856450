import styled from "styled-components";

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  font-size: 14px;
    .modal-policy-accrual-notification {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        align-items: flex-start;
        padding: 0 0 0 6px;
        .inner-block {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 280px;
            .small-icon {
                margin-top: 3px;
            }
            strong {
                margin-left: 10px;
                word-break: break-all;
            }
        }
    }
`;

export const StyledExitButton = styled.div`
  button {
    width: 62px;
  }
`;
