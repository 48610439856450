import { useState, useCallback } from "react";
import { announcementAPI } from 'API/announcements/announcements';
import { IAnnouncement, AnnouncementsDataType } from "interfaces/common/announcements.interface";

export const useAnnouncementsData = () => {
  const [announcementsData, setAnnouncementsData] = useState<AnnouncementsDataType>({items: [], total: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onCreateAnnouncement = useCallback((newAnnouncement: IAnnouncement) => {
    setAnnouncementsData((prevAnnouncementsData) => {
      return {
        items: [newAnnouncement, ...prevAnnouncementsData.items],
        total: prevAnnouncementsData.total + 1,
      };
    });
  }, [])

  const onEditAnnouncement = useCallback((editedAnnouncement: IAnnouncement) => {
    setAnnouncementsData((prevAnnouncementsData) => {
      const updatedAnnouncementsData = prevAnnouncementsData.items.map((announcement) => {
        if (announcement.id === editedAnnouncement.id) {
          return editedAnnouncement;
        }
        return announcement;
      });

      return {
        items: updatedAnnouncementsData,
        total: prevAnnouncementsData.total,
      };
    });
  }, [])

  const onDeleteAnnouncement = useCallback((id: string) => {
    setAnnouncementsData((prevAnnouncementsData) => {
      const updatedAnnouncementsData = prevAnnouncementsData.items.filter((announcement) => announcement.id !== id);

      return {
        items: updatedAnnouncementsData,
        total: prevAnnouncementsData.total - 1,
      };
    });
  }, [])

  const fetchAnnouncements = useCallback(async () => {
    setLoading(true);
    
    try {
      const response = await announcementAPI.getAnnouncements();

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const announcementsData: AnnouncementsDataType = await response.json();

      setAnnouncementsData(announcementsData);

    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    announcementsData,
    loading,
    error,
    fetchAnnouncements,
    onCreateAnnouncement,
    onEditAnnouncement,
    onDeleteAnnouncement,
  };
};
