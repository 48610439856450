import {useQuery} from "../../useQuery";
import {setFieldAPI} from "./setField";
import {useEvent} from "../../../customHooks/useEvent";

export const useSetField = (id: string, addEmployee = false) => {
    const [setFieldAPIs, isLoading, isSuccess, isError] = useQuery(setFieldAPI);

    const setField = useEvent( async (data: string) => {
        try {
            const res = await setFieldAPIs(id, data, addEmployee);
            //@ts-ignore
            if(res.error) {
                //@ts-ignore
                throw new Error(String(res.error));
            }
            return res;
        } catch (error: any) {
            throw new Error(String(error.message));
        }
    });

    return {
        setField: setField,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
    };
}