import {useState} from "react";
import type {TimeOffPolicyProps} from "./timeOffPolicy.types";
import {useQuery} from "../../../../useQuery";
import {fetchTimeOffPolicyAPI} from "./timeOffPolicy";
import {useEvent} from "../../../../../customHooks/useEvent";

export const useTimeOffPolicy = () => {
    const [timeOffPolicy, setTimeOffPolicy] = useState<Array<TimeOffPolicyProps>>([]);
    const [getAllTimeOffPolicy, isLoading, isSuccess, isError] = useQuery(fetchTimeOffPolicyAPI);

    const fetchTimeOffPolicy = useEvent(async () => {
        return getAllTimeOffPolicy()
            .then((response) => {
                setTimeOffPolicy(response);
                return response;
            });
    });

    return {
        timeOffPolicy: timeOffPolicy,
        fetchTimeOffPolicy: fetchTimeOffPolicy,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError
    }
};