import { useState, useCallback } from "react";
import { announcementAPI } from 'API/announcements/announcements';

export const useDeleteAnnouncement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);


  const deleteAnnouncement = useCallback(async (id: string) => {
    setLoading(true);
    
    try {
      const response = await announcementAPI.deleteAnnouncement(id);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    loading,
    error,
    deleteAnnouncement,
  };
};
