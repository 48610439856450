import { useEffect, useState, useCallback, useMemo } from 'react'
import { NoData } from 'components/UI/table'
import { IAnnouncement } from "interfaces/common/announcements.interface";
import { 
  SkeletonTable, 
  AnnouncementsTable, 
  TableFilters
 } from './components'
import { AnnouncementModalTypeEnum, announcementModalDefaultState } from './constants'
import { useAnnouncementsData } from './hooks'
import { AnnouncementsStyles } from './style' 
import { IAnnouncementsModalProps, GetAnnouncementModalType } from './types'
import { getAnnouncementModalType } from './helpers'


export const Announcements = () => {
  const [ announcementModalState, setAnnouncementModalState ] = useState<IAnnouncementsModalProps>(announcementModalDefaultState)
  const { isOpen, payload, type: modalType } = announcementModalState
  const { 
    announcementsData, 
    loading, 
    fetchAnnouncements, 
    onDeleteAnnouncement, 
    onCreateAnnouncement, 
    onEditAnnouncement
   } = useAnnouncementsData()
  const { items: announcementsList, total } = announcementsData

  const hasAnnouncements = Boolean(total)
   
  const onOpenDeleteAnnouncementModal = useCallback((announcement: IAnnouncement): void => (
    setAnnouncementModalState({
      isOpen: true,
      payload: announcement,
      type: AnnouncementModalTypeEnum.DELETE
    })
  ), [])

  const onOpenEditAnnouncementModal = useCallback((announcement: IAnnouncement): void => (
    setAnnouncementModalState({
      isOpen: true,
      payload: announcement,
      type: AnnouncementModalTypeEnum.EDIT
    })
  ), [])

  const onOpenCreateAnnouncementModal = () => (
    setAnnouncementModalState({
      isOpen: true,
      payload: null,
      type: AnnouncementModalTypeEnum.CREATE
    })
  )

  const onClose = () => (
    setAnnouncementModalState({
      isOpen: false,
      payload: null,
      type: AnnouncementModalTypeEnum.CREATE
    })
  )

  const AnnouncementModal = useMemo(() => {
    const announcementModalType: GetAnnouncementModalType = getAnnouncementModalType(
      {onClose, payload, onDeleteAnnouncement, onCreateAnnouncement, onEditAnnouncement}
    )
    
    return announcementModalType[modalType]

  }, [modalType]) 

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <AnnouncementsStyles>
      {loading && <SkeletonTable />}
      <TableFilters onClick={onOpenCreateAnnouncementModal} />
      {
        hasAnnouncements ? 
          <AnnouncementsTable
            sourceData={announcementsList} 
            onDelete={onOpenDeleteAnnouncementModal} 
            onEdit={onOpenEditAnnouncementModal} 
          /> : 
          <NoData
            title="There are no announcements here yet" 
            onClick={onOpenCreateAnnouncementModal}
            btnText="Create new"
          />
      }
      {isOpen && AnnouncementModal}
    </AnnouncementsStyles>
  )
}