import {FC, useState} from "react";
import {useProfile} from "../../../context";
import {type GenderPronounceOptionProps, useGenderPronounce} from "../../../../../API/profile/fields/generalInfo/GenderPronounce";
import {BlockInfoItem} from "../../../BlockInfo";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Select} from "../../../../UI/Select";
import {InputText} from "../../../ViewingModes/InputText";
import {useSetField} from "../../../../../API/profile/setField/useSetField";

export const GenderPronounce: FC = () => {
    const {isAccessEdit, profile, idOpenProfile} = useProfile();

    const {genderPronounce, fetchGenderPronounce, isLoading} = useGenderPronounce();

    const [picked, setPicked] = useState<GenderPronounceOptionProps | null>(profile.genderId && profile.genderName ? {
        id: profile.genderId,
        name: profile.genderName,
        value: profile.genderId,
        label: profile.genderName
    } : null);

    const {setField} = useSetField(idOpenProfile, true);

    return(
        <BlockInfoItem title="Gender pronounce">
            <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEdit && {isEditing: false})}
                    hideActionButtons
                    defaultValue={picked}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Select
                            {...fieldProps}
                            onChange={(event: any) => {
                                onChange(event);

                                setField(['gender', event?.id || 'null'].join('/'));
                            }}
                            appearance="subtle"
                            spacing="compact"
                            inputId="single-select-example-clearable1"
                            isSearchable={true}
                            isClearable={true}
                            className={style.profileSelect}
                            autoFocus
                            openMenuOnFocus
                            onFocus={() => {
                                !genderPronounce.length && fetchGenderPronounce();
                            }}
                            isLoading={isLoading}
                            options={genderPronounce}
                            placeholder="Choose a Gender"
                        />
                    )}
                    readView={() => (<InputText value={picked?.label}/>)}
                    onConfirm={(value) => setPicked(value)}
                />
            </div>
        </BlockInfoItem>
    );
};