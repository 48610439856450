import React from 'react';
import BaseModal from './BaseModal';
import { RANDOM_PHRASES } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { LocationFromApi}  from '../../Locations/Locations';

type CreateRandomPhrase = {
    locations: LocationFromApi[],
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
};

function CreateRandomPhrase(props: CreateRandomPhrase) {
    function create(
        text: string,
        locations: string[],
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        let data = {
            text: text,
            locations: locations,
            visible: visible,
        };

        fetch(RANDOM_PHRASES, { method: 'POST',  body: JSON.stringify(data) })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Phrase'
            text={ null }
            locations={ [] }
            selectedLocations={ props.locations }
            visible={ true }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
        />
    )
}

export default CreateRandomPhrase;