import {FC, useEffect, useState} from "react";
import {useProfile} from "../../../context";
import {AccessLevelOptionsProps, useAccessLevel} from "../../../../../API/profile/fields/generalInfo/accessLevel";
import {BlockInfoItem} from "../../../BlockInfo";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Select} from "../../../../UI/Select";
import {MultiSelect} from "../../../ViewingModes/MultiSelect";

export const AccessLevel: FC = () => {
    const {isAccessEditRole, idOpenProfile} = useProfile();
    const {fetchAccessLevel, accessLevel, isLoading, fetchCurrentAccessLevel, addAccessLevel, deleteAccessLevel} = useAccessLevel(idOpenProfile);

    const [picked, setPicked] = useState<Array<AccessLevelOptionsProps>>();

    const handleAccessLevelsChange = (oldOpt: Array<AccessLevelOptionsProps>, newOpt: Array<AccessLevelOptionsProps>) => {
        const oldIds = oldOpt.map(accessLevel => accessLevel.value);
        const newIds = newOpt.map(accessLevel => accessLevel.value);

        const idsToAdd = newIds.filter(id => !oldIds.includes(id));
        const idsToRemove = oldIds.filter(id => !newIds.includes(id));

        if (idsToAdd.length > 0) {
            addAccessLevel(idsToAdd);
        }

        if (idsToRemove.length > 0 && newOpt.length > 0) {
            deleteAccessLevel(idsToRemove);
        }
    };

    useEffect(() => {
        fetchCurrentAccessLevel().then(response => setPicked(response));
    }, []);

    return(
        <BlockInfoItem title="Access level">
            <div className={cn(style.inputWrapper, isAccessEditRole && style.profileInputTextIsEditing)}>
                <InlineEdit
                    {...(!isAccessEditRole && {isEditing: false})}
                    hideActionButtons
                    defaultValue={picked}
                    editView={({errorMessage, onChange, ...fieldProps}) => (
                        <Select
                            {...fieldProps}
                            onChange={(event: any) => {
                                onChange(event);

                                handleAccessLevelsChange(picked!, event);
                            }}
                            isMulti
                            appearance="subtle"
                            spacing="default"
                            inputId="single-select-example-clearable1"
                            isSearchable={true}
                            isClearable={true}
                            className={style.profileSelect}
                            autoFocus
                            openMenuOnFocus
                            onFocus={() => {
                                !accessLevel.length && fetchAccessLevel();
                            }}
                            isLoading={isLoading}
                            options={accessLevel}
                            placeholder="Choose a Access level"
                        />
                    )}
                    readView={() => (<MultiSelect value={picked}/>)}
                    onConfirm={(value) => setPicked(value)}
                />
            </div>
        </BlockInfoItem>
    );
};