import { forwardRef } from "react";
import { LinkItem, type LinkItemProps } from "@atlaskit/side-navigation";
import { useLocation, useNavigate } from "react-router-dom";

interface NavLinkItemProps extends Omit<LinkItemProps, "isSelected"> {
  to: string;
}

export const NavLinkItem = forwardRef<HTMLAnchorElement, NavLinkItemProps>(
  ({ to, children, onClick, ...restProps }, ref) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
      <LinkItem
        {...restProps}
        href={to}
        isSelected={to === pathname}
        onClick={(event) => {
          event.preventDefault();
          navigate(to);
          onClick && onClick(event);
        }}
        ref={ref}
      >
        {children}
      </LinkItem>
    );
  }
);
