import type {TimeOffPolicyResponseProps} from './timeOffPolicy.types';

import {TIME_OFF_POLICIES} from "../constants";

export const fetchTimeOffPolicyAPI = async (all = true, phrase = '') => {
    try {
        const url = `${TIME_OFF_POLICIES}?all=${all}&phrase=${encodeURIComponent(phrase)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        return await response.json() as Array<TimeOffPolicyResponseProps>;
    } catch (error) {
        console.error(error);
        throw new Error();
    }
};