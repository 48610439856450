import { FC } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { token } from "@atlaskit/tokens";
import { Inline } from "@atlaskit/primitives";
import {
  StyledTextCancel,
  StyledExitButton,
  StyledCancelButton,
} from "./StylesModalCancel";

interface ModalCancelCreationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalCancelCreation: FC<ModalCancelCreationProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small" height={"224px"}>
          <ModalHeader>
            <ModalTitle>
              <Inline alignBlock="center" space="space.100">
                <ErrorIcon primaryColor={token("color.icon.danger")} label="" />
                Cancel policy creation
              </Inline>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <StyledTextCancel>
              Are you sure you want to cancel policy creation? <br />
              Your policy will not be saved.
            </StyledTextCancel>
          </ModalBody>
          <ModalFooter>
            <StyledCancelButton>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
            </StyledCancelButton>
            <StyledExitButton>
              <Button appearance="danger" onClick={onConfirm}>
                Exit
              </Button>
            </StyledExitButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ModalCancelCreation;
