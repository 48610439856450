import React, { useState, useEffect, FC } from "react";
import "./StateHolidays.scss";
import CreateStateHoliday from "./Modal/CreateStateHoliday";
import EditStateHoliday from "./Modal/EditStateHoliday";
import moment from "moment";
import {
  ORG_CHART,
  LOCATIONS,
  STATE_HOLIDAYS,
} from "../../../../../utils/constants/api";
import DeleteStateHoliday from "./Modal/DeleteStateHoliday";
import Button, { Size, Type } from "../../../../Component/Button/Button";
import { StateHolidayFromApi } from "../../../../../shared/Holiday/StateHoliday/data/StateHolidayFromApi";
import { Popover } from "@headlessui/react";
import { OrgUnitFromApi } from "../../../../../shared/OrgChart/data/OrgUnitFromApi";
import { LocationFromApi } from "../../Locations/Locations";
import {
  ROLE_ADMIN,
  ROLE_HR,
} from "../../../../../shared/Security/constants/AccessLevels";
import {
  assertEmployee,
  useEmployee,
} from "../../../../../contexts/EmployeeContext";

interface StateHolidayProps {
  year: string;
  country: string;
}
export const StateHolidays: FC<StateHolidayProps> = (props) => {
  const { employee } = useEmployee();
  assertEmployee(employee);

  const [createStateHolidayModal, setCreateStateHolidayModal] =
    useState<boolean>(false);
  const [editStateHolidayModal, setEditStateHolidayModal] =
    useState<boolean>(false);
  const [deleteStateHolidayModal, setDeleteStateHolidayModal] =
    useState<boolean>(false);

  const [stateHolidays, setStateHolidays] = useState<StateHolidayFromApi[]>([]);
  const [stateHolidayToChange, setStateHolidayToChange] =
    useState<StateHolidayFromApi | null>(null);
  const [stateHolidayToDelete, deleteStateHoliday] =
    useState<StateHolidayFromApi | null>(null);

  const [orgUnits, setOrgUnits] = useState<OrgUnitFromApi[]>([]);
  const [locations, setLocations] = useState<LocationFromApi[]>([]);

  function getOrgUnits() {
    fetch(ORG_CHART + "?without-hierarchy=true", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((orgUnits: OrgUnitFromApi[]) => setOrgUnits(orgUnits));
  }

  function getLocations() {
    fetch(LOCATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((locations: LocationFromApi[]) => setLocations(locations));
  }

  useEffect(() => {
    getOrgUnits();
    if (locations.length == 0) {
      getLocations();
    }
    if (props.year && props.country) {
      getStateHolidays(props.year, props.country);
    }
  }, [props.year, props.country, locations]);

  function getStateHolidays(year: string, country: string) {
    return fetch(STATE_HOLIDAYS + "?year=" + year, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((stateHolidays: StateHolidayFromApi[]) => {
        let neededLocations = locations
          .filter((location) => location.value.countryCode === country)
          .map((location) => location.id);
        setStateHolidays(
          stateHolidays.filter((item) =>
            item.value.locations.some((locationId) =>
              neededLocations.includes(locationId)
            )
          )
        );
      });
  }

  function showStateHoliday({ stateHoliday }: { stateHoliday: any }): void {
    fetch(STATE_HOLIDAYS + "/" + stateHoliday.id, {
      method: "PUT",
      body: JSON.stringify({
        name: stateHoliday.value.name,
        orgUnits: stateHoliday.value.orgUnits,
        locations: stateHoliday.value.locations,
        movingType: stateHoliday.value.movingType,
        date: stateHoliday.value.date,
        repeatAnnually: stateHoliday.value.repeatAnnually,
        dayOff: stateHoliday.value.dayOff,
        paidTimeOff: stateHoliday.value.paidTimeOff,
        additionalPaidTimeOff: stateHoliday.value.additionalPaidTimeOff,
        additionalPaidTimeDate: stateHoliday.value.additionalPaidTimeDate
          ? stateHoliday.value.additionalPaidTimeDate.getFullYear() +
            "-" +
            (stateHoliday.value.additionalPaidTimeDate.getMonth() + 1) +
            "-" +
            stateHoliday.value.additionalPaidTimeDate.getDate()
          : null,
        text: stateHoliday.value.text,
        visible: !stateHoliday.value.visible,
      }),
    }).then((response) => {
      if (response.ok) {
        getStateHolidays(props.year, props.country);
      } else {
        response.json().then((jsonData) => alert(jsonData.error));
      }
    });
  }

  function editStateHolidayModalVisibility(
    stateHoliday: StateHolidayFromApi
  ): void {
    setStateHolidayToChange(stateHoliday);
    document
      .getElementById("popover-btn")
      ?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
    setEditStateHolidayModal(true);
  }

  function confirmDeleteStateHolidayModalVisibility(
    stateHoliday: StateHolidayFromApi
  ): void {
    deleteStateHoliday(stateHoliday);
    document
      .getElementById("popover-btn")
      ?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
    setDeleteStateHolidayModal(true);
  }

  // Access levels
  const roles = [ROLE_ADMIN, ROLE_HR] as typeof employee.roles;
  const hasRole = roles.some((role) => employee.roles.includes(role));

  return (
    <div className="state-holidays">
      {hasRole ? (
        <div className="add-button-block">
          {props.year ? (
            <Button
              text="<i className='material-icons add-item'>add</i> Add state holiday"
              size={Size.Medium}
              type={Type.Accent}
              onClick={() => setCreateStateHolidayModal(true)}
            />
          ) : null}
        </div>
      ) : null}
      <table className="state-holidays-table">
        <thead>
          <tr>
            <th>Name</th>
            <th className={"center"}>Location</th>
            <th className={"center"}>Org Unit</th>
            <th className={"center"}>Date</th>
            <th className={"center"}>Repeat Annualy</th>
            <th className={"center"}>Paid Time Off</th>
            <th className={"center"}>Text</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stateHolidays.map((stateHoliday) => {
            return (
              <tr key={stateHoliday.id}>
                <td>{stateHoliday.value.name}</td>
                <td className={"center"}>
                  <div className={"count-margin-fix"}>
                    {stateHoliday.value.locations.length}
                  </div>
                </td>
                <td className={"center"}>
                  <div className={"count-margin-fix"}>
                    {stateHoliday.value.orgUnits.length}
                  </div>
                </td>
                <td className={"center"}>
                  {stateHoliday.value.date
                    ? moment(stateHoliday.value.date).format("DD.MM.YYYY")
                    : ""}
                </td>
                <td className={"center"}>
                  {stateHoliday.value.repeatAnnually ? (
                    <i className="material-icons check-success">done</i>
                  ) : (
                    <i className="material-icons">remove</i>
                  )}
                </td>
                <td className={"center"}>
                  {stateHoliday.value.paidTimeOff ? (
                    <i className="material-icons check-success">done</i>
                  ) : (
                    <i className="material-icons">remove</i>
                  )}
                </td>
                <td className={"center"}>
                  {stateHoliday.value.text ? (
                    <i className="material-icons check-success">done</i>
                  ) : (
                    <i className="material-icons">remove</i>
                  )}
                </td>
                <td className={"center"}>
                  {hasRole ? (
                    <Popover className="relative">
                      <Popover.Button
                        id="popover-btn"
                        className={"vert-menu-btn"}
                      >
                        <i className="material-icons">more_vert</i>
                      </Popover.Button>

                      <Popover.Panel className="absolute z-10">
                        <i
                          className="material-icons"
                          onClick={() =>
                            editStateHolidayModalVisibility(stateHoliday)
                          }
                        >
                          edit
                        </i>
                        <i
                          className="material-icons"
                          onClick={() =>
                            confirmDeleteStateHolidayModalVisibility(
                              stateHoliday
                            )
                          }
                        >
                          delete
                        </i>
                        <i
                          className="material-icons"
                          onClick={() =>
                            showStateHoliday({ stateHoliday: stateHoliday })
                          }
                        >
                          {stateHoliday.value.visible
                            ? "visibility"
                            : "visibility_off"}
                        </i>
                      </Popover.Panel>
                    </Popover>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <CreateStateHoliday
        orgUnits={orgUnits}
        locations={locations}
        opened={createStateHolidayModal}
        onOk={() => {
          getStateHolidays(props.year, props.country);
          setCreateStateHolidayModal(false);
        }}
        onClose={() => setCreateStateHolidayModal(false)}
      />
      {stateHolidayToDelete ? (
        <DeleteStateHoliday
          opened={deleteStateHolidayModal}
          onOk={() => {
            getStateHolidays(props.year, props.country);
            setDeleteStateHolidayModal(false);
            deleteStateHoliday(null);
          }}
          onClose={() => {
            setDeleteStateHolidayModal(false);
            deleteStateHoliday(null);
          }}
          stateHoliday={stateHolidayToDelete}
        />
      ) : null}
      {stateHolidayToChange ? (
        <EditStateHoliday
          orgUnits={orgUnits}
          locations={locations}
          opened={editStateHolidayModal}
          onOk={() => {
            getStateHolidays(props.year, props.country);
            setEditStateHolidayModal(false);
            setStateHolidayToChange(null);
          }}
          onClose={() => {
            setEditStateHolidayModal(false);
            setStateHolidayToChange(null);
          }}
          stateHoliday={stateHolidayToChange}
        />
      ) : null}
    </div>
  );
};
