import React from 'react';
import { CORPORATE_EVENTS } from '../../../../../../utils/constants/api';
import ConfirmDeleteModal from '../../../../Layer/Modal/ConfirmDeleteModal';
import { CorporateEventFromApi } from '../../../../../../shared/Holiday/CorporateEvent/data/CorporateEventFromApi';

type DeleteCorporateEvent = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    corporateEvent: CorporateEventFromApi
};

function DeleteCorporateEvent(props: DeleteCorporateEvent) {
    function deleteTitle(): void {
        fetch(CORPORATE_EVENTS + '/' + props.corporateEvent.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then();
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Corporate Event'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.corporateEvent.value.name + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteTitle }
        />
    )
}

export default DeleteCorporateEvent;