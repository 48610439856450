import React, { useState, useEffect, FC } from "react";
import "./PushNotifications.scss";
import {
  ORG_CHART,
  LOCATIONS,
  PUSH_NOTIFICATIONS,
} from "../../../../utils/constants/api";
import Button, { Size, Type } from "../../../Component/Button/Button";
import CreatePushNotification from "./Modal/CreatePushNotification";
import DeletePushNotification from "./Modal/DeletePushNotification";
import EditPushNotification from "./Modal/EditPushNotification";
import { PushNotificationFromApi } from "../../../../shared/PushNotification/data/PushNotificationFromApi";
import { OrgUnitFromApi } from "../../../../shared/OrgChart/data/OrgUnitFromApi";
import { LocationFromApi } from "../Locations/Locations";
import moment from "moment/moment";
import {
  ROLE_ADMIN,
  ROLE_HR,
  ROLE_OPERATIONAL_MANAGER,
} from "../../../../shared/Security/constants/AccessLevels";

import {
  useEmployee,
  assertEmployee,
} from "../../../../contexts/EmployeeContext";

export const PushNotifications: FC = () => {
  const { employee } = useEmployee();
  assertEmployee(employee);
  const [createPushNotificationModal, setCreatePushNotificationModal] =
    useState<boolean>(false);
  const [editPushNotificationModal, setEditPushNotificationModal] =
    useState<boolean>(false);
  const [deletePushNotificationModal, setDeletePushNotificationModal] =
    useState<boolean>(false);

  const [pushNotifications, setPushNotifications] = useState<
    PushNotificationFromApi[]
  >([]);
  const [pushNotificationToChange, setPushNotificationToChange] =
    useState<PushNotificationFromApi | null>(null);
  const [pushNotificationToDelete, deletePushNotification] =
    useState<PushNotificationFromApi | null>(null);

  const [orgUnits, setOrgUnits] = useState<OrgUnitFromApi[]>([]);
  const [locations, setLocations] = useState<LocationFromApi[]>([]);

  useEffect(getPushNotifications, []);
  useEffect(getOrgUnits, []);
  useEffect(getLocations, []);

  function getPushNotifications() {
    fetch(PUSH_NOTIFICATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((pushNotifications: PushNotificationFromApi[]) =>
        setPushNotifications(pushNotifications)
      );
  }

  function getOrgUnits(): void {
    fetch(ORG_CHART + "?without-hierarchy=true", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((orgUnits: OrgUnitFromApi[]) => setOrgUnits(orgUnits));
  }

  function getLocations(): void {
    fetch(LOCATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((locations: LocationFromApi[]) => setLocations(locations));
  }

  function editPushNotificationModalVisibility(
    pushNotification: PushNotificationFromApi
  ): void {
    setPushNotificationToChange(pushNotification);
    setEditPushNotificationModal(true);
  }

  function confirmDeletePushNotificationModalVisibility(
    pushNotification: PushNotificationFromApi
  ): void {
    deletePushNotification(pushNotification);
    setDeletePushNotificationModal(true);
  }

  function isSentOrScheduled(
    pushNotification: PushNotificationFromApi
  ): boolean {
    const now = new Date();
    const sendAt = new Date(pushNotification.value.sendAt);
    return sendAt > now;
  }

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  // Access levels
  const roles = [
    ROLE_ADMIN,
    ROLE_HR,
    ROLE_OPERATIONAL_MANAGER,
  ] as typeof employee.roles;
  const hasRole = roles.some((role) => employee.roles.includes(role));

  return (
    <div className={"admin-panel-board"}>
      <div className="sub-content-block push-notification d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Announcement</h3>
          </div>
          <div className="add-button-block">
            {hasRole && (
              <div>
                <Button
                  text="<i className='material-icons add-item'>add</i> Add Push Notification"
                  size={Size.Medium}
                  type={Type.Accent}
                  onClick={() => setCreatePushNotificationModal(true)}
                />
              </div>
            )}
          </div>
        </div>

        {pushNotifications.length > 0 && (
          <div className="sub-content-main">
            <table className="push-notifications-table">
              <thead>
                <tr>
                  <th>Push Notification</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {pushNotifications.map((notification) => {
                  const isSentColor = isSentOrScheduled(notification)
                    ? "$blackBaseColor"
                    : "lightgrey";
                  const date = new Date(notification.value.sendAt);
                  return (
                    <tr key={notification.id}>
                      <td
                        title={notification.value.subject}
                        style={{ color: isSentColor }}
                      >
                        {notification.value.subject}
                      </td>
                      <td style={{ color: isSentColor }}>
                        {moment(date).format("DD.MM.YYYY H:mm")}
                      </td>
                      <td style={{ color: isSentColor }}>
                        {isSentOrScheduled(notification) ? "Scheduled" : "Sent"}{" "}
                      </td>
                      <td style={{ color: isSentColor }}>
                        {hasRole && (
                          <>
                            <i
                              className="material-icons"
                              onClick={() =>
                                editPushNotificationModalVisibility(
                                  notification
                                )
                              }
                            >
                              edit
                            </i>
                            <i
                              className="material-icons"
                              onClick={() =>
                                confirmDeletePushNotificationModalVisibility(
                                  notification
                                )
                              }
                            >
                              delete
                            </i>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <CreatePushNotification
        opened={createPushNotificationModal}
        onOk={() => {
          getPushNotifications();
          setCreatePushNotificationModal(false);
        }}
        onClose={() => setCreatePushNotificationModal(false)}
        orgUnits={orgUnits}
        locations={locations}
      />
      {pushNotificationToDelete ? (
        <DeletePushNotification
          opened={deletePushNotificationModal}
          onOk={() => {
            getPushNotifications();
            setDeletePushNotificationModal(false);
            deletePushNotification(null);
          }}
          onClose={() => {
            setDeletePushNotificationModal(false);
            deletePushNotification(null);
          }}
          pushNotification={pushNotificationToDelete}
        />
      ) : null}
      {pushNotificationToChange ? (
        <EditPushNotification
          opened={editPushNotificationModal}
          onOk={() => {
            getPushNotifications();
            setEditPushNotificationModal(false);
            setPushNotificationToChange(null);
          }}
          onClose={() => {
            setEditPushNotificationModal(false);
            setPushNotificationToChange(null);
          }}
          orgUnits={orgUnits}
          locations={locations}
          pushNotification={pushNotificationToChange}
        />
      ) : null}
    </div>
  );
};
