import { useMemo } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import { tableHead } from './table-head';
import { getTableRows } from './table-rows';
import { AnnouncementsTableType } from './types';
import { AnnouncementsTableStyles } from './styles'

export const AnnouncementsTable = ({sourceData, onDelete, onEdit}: AnnouncementsTableType) => {

  const tableRows: Array<RowType> = useMemo(
    () =>
      getTableRows({
        sourceData,
        onEdit,
        onDelete,
      }),
    [sourceData, onEdit, onDelete],
  )

  return (
    <AnnouncementsTableStyles>
      <DynamicTable
        head={tableHead}
        rows={tableRows}
        rowsPerPage={5}
        defaultPage={1}
        loadingSpinnerSize="large"
      />
    </AnnouncementsTableStyles>
  )
}