import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button/new";
import { Box, Stack, xcss } from "@atlaskit/primitives";
import Avatar from "@atlaskit/avatar";
import {
  StyledEmployeesSearch,
  StyledEmployeesSelects,
  ScrollBlock,
  ResultItem,
  ButtonsWrapper,
  StyledMessageEmpty,
} from "./StylesAllocatePolicy";
import { FILTERS, TIME_OFF_POLICIES } from "../../../../utils/constants/api";
import { TimeOffPolicyResponseProps } from "../../../../API/timeOffPolicies/timeOffPolicy.types";
import SearchField from "components/Search/SearchField/SearchField";
import HipchatChevronDoubleUpIcon from "@atlaskit/icon/glyph/hipchat/chevron-double-up";
import ChevronRightLargeIcon from "@atlaskit/icon/glyph/chevron-right-large";
import ChevronLeftLargeIcon from "@atlaskit/icon/glyph/chevron-left-large";
import { sortFilter } from "../../../../utils/helpers/sortFunc";
import MultiSelectCustom from "../../../UI/MultiSelectCustom/MultiSelectCustom";
import { Option } from "react-multi-select-component";
import { SelectValue } from "../../../../shared/Common/data/SelectValue";
import { PolicySelect } from "../../Select/PolicySelect";
import Spinner from "@atlaskit/spinner";

function EmployeeList({
  height,
  availableEmployees,
  selectedEmployees,
  onClick,
  filter,
  loadingPolicies,
  issetSelectedPolicy,
}: {
  height?: number;
  availableEmployees: Employee[];
  selectedEmployees: Employee[];
  onClick: (arg: Employee) => void;
  filter: (arg: Employee) => boolean;
  loadingPolicies: boolean;
  issetSelectedPolicy: boolean;
}) {
  return (
    <ScrollBlock
      style={{
        maxHeight: height ? height + "px" : "320px",
        height: height ? height + "px" : "320px",
      }}
    >
      {!issetSelectedPolicy && (
        <StyledMessageEmpty>
          Select a policy to see a list of employees.
        </StyledMessageEmpty>
      )}
      {loadingPolicies && (
        <Box
          xcss={xcss({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          })}
        >
          <Spinner size="medium" />
        </Box>
      )}
      {availableEmployees.filter(filter).map((item) => (
        <ResultItem
          className={
            selectedEmployees.find((obj) => {
              return obj.id === item.id;
            })
              ? "selected"
              : ""
          }
          onClick={() => {
            onClick(item);
          }}
        >
          <Box
            xcss={xcss({
              display: "flex",
              alignItems: "center",
            })}
          >
            {" "}
            <Box>
              <Avatar
                size="small"
                name={item.nickname ? item.nickname : ""}
                src={item.avatar ? item.avatar : ""}
              />
            </Box>
            <Box
              xcss={xcss({
                display: "flex",
                alignItems: "center",
                marginLeft: "8px",
              })}
            >
              <Box>
                <Box
                  xcss={xcss({
                    display: "flex",
                    fontFamily: "inherit",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "color.text",
                    maxWidth: "280px",
                    minWidth: "200px",
                    overflow: "auto",
                  })}
                >
                  {item.firstName} {item.lastName}{" "}
                  {item.nickname ? "(" + item.nickname + ")" : null}
                </Box>
                {item.email ? (
                  <Box
                    xcss={xcss({
                      display: "flex",
                      fontFamily: "inherit",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    })}
                  >
                    <div style={{ color: "#626F86" }}> {item.email}</div>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </ResultItem>
      ))}
    </ScrollBlock>
  );
}

type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  nickname: string | null;
  email: string;
  avatar: string;
  orgUnitId: string;
  orgUnitName: string;
  workLocationId: string;
  workLocationName: string;
  company: string | null;
};

export default function AllocatePolicy({
  isOpen,
  setIsOpen,
  onConfirm,
  timeOffPolicies,
  selectedPolicy,
  setSelectedPolicy,
}: {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  onConfirm: () => void;
  timeOffPolicies: TimeOffPolicyResponseProps[];
  selectedPolicy: TimeOffPolicyResponseProps | null;
  setSelectedPolicy: (
    selectedPolicy: TimeOffPolicyResponseProps | null
  ) => void;
}) {
  const [search, setSearch] = useState("");
  const [changes, setChanges] = useState(false);
  const [availableEmployees, setAvailableEmployees] = useState<Employee[]>([]);
  const [movedEmployees, setMovedEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [selectedMovedEmployees, setSelectedMovedEmployees] = useState<
    Employee[]
  >([]);

  const [orgUnitOptions, setOrgUnitOptions] = useState<Option[]>([]);
  const [selectedOrgUnitOptions, setSelectedOrgUnitOptions] = useState<
    Option[]
  >([]);

  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState<
    Option[]
  >([]);

  const [filteredEmployees, setFilteredEmployees] =
    useState<Employee[]>(availableEmployees);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPolicies, setLoadingPolicies] = useState<boolean>(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setMovedEmployees([]);
    setAvailableEmployees([]);
    setFilteredEmployees([]);
    setLocationOptions([]);
    setSelectedLocationOptions([]);
    setOrgUnitOptions([]);
    setSelectedOrgUnitOptions([]);
    setChanges(false);
  };

  useEffect(() => {
    setAvailableEmployees([]);
    setFilteredEmployees([]);
    setMovedEmployees([]);
    if (selectedPolicy && isOpen) {
      setLoadingPolicies(true);
      fetch(FILTERS + "/policies/" + selectedPolicy.id + "/employees")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLoadingPolicies(false);
          setAvailableEmployees(data["withoutPolicy"]);
          setFilteredEmployees(data["withoutPolicy"]);
          setMovedEmployees(data["withPolicy"]);
        });
    } else {
      setLoadingPolicies(false);
    }
  }, [selectedPolicy, isOpen]);

  function sendEmployees(callback: () => void) {
    if (selectedPolicy) {
      fetch(TIME_OFF_POLICIES + "/" + selectedPolicy.id + "/employees", {
        method: "POST",
        body: JSON.stringify(movedEmployees.map((item) => item.id)),
      })
        .then((res) => {
          if (res.ok) {
            setLoading(false);
            closeModal();
            onConfirm();
            return res.json();
          } else {
            throw new Error("Error when save");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setLoading(false);
    }
  }

  function filterByNameEmail(item: Employee) {
    const string = `${item.firstName} ${item.lastName} ${item.nickname ? "(" + item.nickname + ")" : ""}`;
    const lowerCaseSearch = search.toLowerCase();
    return (
      string.toLowerCase().includes(lowerCaseSearch) ||
      item.email.toLowerCase().includes(lowerCaseSearch)
    );
  }

  useEffect(() => {
    if (availableEmployees.length > 0) {
      const orgUnitOptions = availableEmployees
        .map((employee) => ({
          value: employee.orgUnitId,
          label: employee.orgUnitName,
          company: employee.company,
        }))
        .filter(
          (orgUnit, index, self) =>
            self.findIndex((c) => c.value === orgUnit.value) === index
        )
        .filter((orgUnit) => orgUnit.value !== null && orgUnit.label !== null);

      orgUnitOptions.push({
        label: "Without Org Unit",
        value: "null",
        company: "",
      });
      setOrgUnitOptions(orgUnitOptions as SelectValue[]);

      const locationsOptions = availableEmployees
        .map((employee) => ({
          value: employee.workLocationId,
          label: employee.workLocationName,
        }))
        .filter(
          (location, index, self) =>
            self.findIndex((c) => c.value === location.value) === index
        )
        .filter(
          (location) => location.value !== null && location.label !== null
        );

      locationsOptions.push({ label: "Without Location", value: "null" });
      setLocationOptions(locationsOptions as SelectValue[]);
    }
  }, [availableEmployees]);

  const filterEmployees = (
    orgUnits: SelectValue[],
    locations: SelectValue[]
  ) => {
    const filtered = availableEmployees.filter((employee) => {
      const orgUnitMatch =
        orgUnits.length === 0 ||
        orgUnits.some(
          (orgUnit) =>
            orgUnit.value === employee.orgUnitId ||
            (orgUnit.value === "null" && employee.orgUnitId === null)
        );
      const locationMatch =
        locations.length === 0 ||
        locations.some(
          (location) =>
            location.value === employee.workLocationId ||
            (location.value === "null" && employee.workLocationId === null)
        );
      return orgUnitMatch && locationMatch;
    });
    setFilteredEmployees(filtered);
  };

  const filterEmployeesByCriteria = (
    employees: Employee[],
    orgUnits: SelectValue[],
    locations: SelectValue[]
  ) => {
    return employees.filter((employee) => {
      const orgUnitMatch =
        orgUnits.length === 0 ||
        orgUnits.some(
          (orgUnit) =>
            orgUnit.value === employee.orgUnitId ||
            (orgUnit.value === "null" && employee.orgUnitId === null)
        );
      const locationMatch =
        locations.length === 0 ||
        locations.some(
          (location) =>
            location.value === employee.workLocationId ||
            (location.value === "null" && employee.workLocationId === null)
        );
      return orgUnitMatch && locationMatch;
    });
  };

  const updateOptions = (orgUnits: SelectValue[], locations: SelectValue[]) => {
    const filteredOrgUnitOptions = availableEmployees
      .filter(
        (employee) =>
          locations.length === 0 ||
          locations.some(
            (location) =>
              location.value === employee.workLocationId ||
              (location.value === "null" && employee.workLocationId === null)
          )
      )
      .map((employee) => ({
        value: employee.orgUnitId,
        label: employee.orgUnitName,
        company: employee.company,
      }))
      .filter(
        (orgUnit, index, self) =>
          self.findIndex((c) => c.value === orgUnit.value) === index
      )
      .filter((orgUnit) => orgUnit.value !== null && orgUnit.label !== null);

    filteredOrgUnitOptions.push({
      label: "Without Org Unit",
      value: "null",
      company: "",
    });

    const filteredLocationOptions = availableEmployees
      .filter(
        (employee) =>
          orgUnits.length === 0 ||
          orgUnits.some(
            (orgUnit) =>
              orgUnit.value === employee.orgUnitId ||
              (orgUnit.value === "null" && employee.orgUnitId === null)
          )
      )
      .map((employee) => ({
        value: employee.workLocationId,
        label: employee.workLocationName,
      }))
      .filter(
        (location, index, self) =>
          self.findIndex((c) => c.value === location.value) === index
      )
      .filter((location) => location.value !== null && location.label !== null);

    filteredLocationOptions.push({ label: "Without Location", value: "null" });

    setOrgUnitOptions(filteredOrgUnitOptions as SelectValue[]);
    setLocationOptions(filteredLocationOptions as SelectValue[]);
  };

  const changeOrgUnitFilter = (orgUnits: SelectValue[]) => {
    setSelectedOrgUnitOptions(orgUnits);
    filterEmployees(orgUnits, selectedLocationOptions);
    updateOptions(orgUnits, selectedLocationOptions);
  };

  const changeLocationFilter = (locations: SelectValue[]) => {
    setSelectedLocationOptions(locations);
    filterEmployees(selectedOrgUnitOptions, locations);
    updateOptions(selectedOrgUnitOptions, locations);
  };

  return (
    <>
      {isOpen && (
        <Modal
          onClose={closeModal}
          width="868px"
          height="704px"
          shouldScrollInViewport
        >
          <ModalHeader>
            <ModalTitle>Allocate policy</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Stack
              xcss={xcss({
                height: "100%",
              })}
            >
              <PolicySelect
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
                timeOffPolicies={timeOffPolicies}
                setLoadingPolicies={setLoadingPolicies}
              />

              <Box
                xcss={xcss({
                  height: "100%",
                  display: "grid",
                  gridTemplateColumns: "370px 1fr 370px",
                  gap: "space.300",
                  marginTop: "space.300",
                })}
              >
                <Box
                  xcss={xcss({
                    width: "100%",
                    padding: "space.200",
                    borderRadius: "border.radius.050",
                    border: "1px solid",
                    borderColor: "color.border.disabled",
                  })}
                >
                  <Box
                    xcss={xcss({
                      color: "color.text",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    })}
                  >
                    Available employees
                  </Box>
                  <Box
                    xcss={xcss({
                      marginTop: "space.200",
                      marginBottom: "space.100",
                    })}
                  >
                    <StyledEmployeesSelects>
                      <MultiSelectCustom
                        onSelectClick={() => {
                          setOrgUnitOptions((val) =>
                            sortFilter(val, selectedOrgUnitOptions)
                          );
                        }}
                        hasSelectAll={false}
                        options={orgUnitOptions}
                        contentWidth={161}
                        selectHeight={28}
                        dropdownHorizontalDisplacement="0"
                        onChange={(values) => changeOrgUnitFilter(values)}
                        value={selectedOrgUnitOptions}
                        placeholder={"Org Unit"}
                        labelledBy={"All Org Units"}
                        loading={loadingPolicies}
                      />
                      <MultiSelectCustom
                        onSelectClick={() => {
                          setLocationOptions((val) =>
                            sortFilter(val, selectedLocationOptions)
                          );
                        }}
                        hasSelectAll={false}
                        options={locationOptions}
                        contentWidth={161}
                        selectHeight={28}
                        dropdownHorizontalDisplacement="0"
                        onChange={(values) => changeLocationFilter(values)}
                        value={selectedLocationOptions}
                        placeholder={"Work Location"}
                        labelledBy={"All Work Locations"}
                        loading={loadingPolicies}
                      />
                    </StyledEmployeesSelects>
                    <StyledEmployeesSearch>
                      <SearchField
                        onreset={1}
                        placeholder="Search"
                        value={search}
                        onChange={(value) => {
                          setSearch(value);
                          setSelectedMovedEmployees([]);
                        }}
                        onFocus={() => {}}
                        onClick={(e) => {}}
                        onPressEnter={() => {}}
                      />
                    </StyledEmployeesSearch>
                  </Box>
                  <EmployeeList
                    filter={filterByNameEmail}
                    availableEmployees={filteredEmployees}
                    selectedEmployees={selectedEmployees}
                    onClick={(item: Employee) => {
                      setSelectedEmployees((val) => {
                        if (
                          val.find((obj) => {
                            return obj.id === item.id;
                          })
                        ) {
                          return val.filter((obj) => {
                            return obj.id !== item.id;
                          });
                        } else {
                          return [...val, item];
                        }
                      });
                    }}
                    loadingPolicies={loadingPolicies}
                    issetSelectedPolicy={selectedPolicy !== null}
                  />
                </Box>
                <Box
                  xcss={xcss({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  })}
                >
                  {" "}
                  <ButtonsWrapper>
                    <Box
                      xcss={xcss({
                        display: "flex",
                        flexDirection: "column",
                        gap: "space.100",
                        justifyContent: "center",
                      })}
                    >
                      <Button
                        isDisabled={
                          filteredEmployees.filter(filterByNameEmail).length ===
                            0 || !selectedPolicy?.isVisible
                        }
                        onClick={() => {
                          if (filteredEmployees.length > 0) {
                            const objToMove =
                              filteredEmployees.filter(filterByNameEmail);
                            setAvailableEmployees((val) =>
                              val.filter(
                                (employee) => !objToMove.includes(employee)
                              )
                            );
                            setFilteredEmployees((val) =>
                              val.filter(
                                (employee) => !objToMove.includes(employee)
                              )
                            );
                            setMovedEmployees((val) => [...val, ...objToMove]);
                            setSelectedEmployees([]);
                            setSelectedMovedEmployees([]);
                            setChanges(true);
                          }
                        }}
                      >
                        <div style={{ transform: "rotate(90deg)" }}>
                          <HipchatChevronDoubleUpIcon label="double arrow right" />
                        </div>
                      </Button>
                      <Button
                        isDisabled={
                          selectedEmployees.length === 0 ||
                          !selectedPolicy?.isVisible
                        }
                        onClick={() => {
                          setAvailableEmployees((val) => {
                            return val.filter((item) => {
                              return !selectedEmployees.find((obj) => {
                                return obj.id === item.id;
                              });
                            });
                          });
                          setFilteredEmployees((val) => {
                            return val.filter((item) => {
                              return !selectedEmployees.find((obj) => {
                                return obj.id === item.id;
                              });
                            });
                          });
                          setMovedEmployees((val) => [
                            ...val,
                            ...selectedEmployees,
                          ]);
                          setSelectedEmployees([]);
                          setSelectedMovedEmployees([]);
                          setChanges(true);
                        }}
                      >
                        <ChevronRightLargeIcon label="arrow right" />
                      </Button>
                      <Button
                        isDisabled={selectedMovedEmployees.length === 0}
                        onClick={() => {
                          setMovedEmployees((val) => {
                            return val.filter((item) => {
                              return !selectedMovedEmployees.find((obj) => {
                                return obj.id === item.id;
                              });
                            });
                          });
                          const filteredMovedEmployees =
                            filterEmployeesByCriteria(
                              selectedMovedEmployees,
                              selectedOrgUnitOptions,
                              selectedLocationOptions
                            );
                          setFilteredEmployees((val) => [
                            ...val,
                            ...filteredMovedEmployees,
                          ]);
                          setAvailableEmployees((val) => [
                            ...val,
                            ...selectedMovedEmployees,
                          ]);
                          setSelectedEmployees([]);
                          setSelectedMovedEmployees([]);
                          setChanges(true);
                        }}
                      >
                        <ChevronLeftLargeIcon label="arrow left" />
                      </Button>
                      <Button
                        isDisabled={movedEmployees.length === 0}
                        onClick={() => {
                          const filteredMovedEmployees =
                            filterEmployeesByCriteria(
                              movedEmployees,
                              selectedOrgUnitOptions,
                              selectedLocationOptions
                            );
                          setFilteredEmployees((val) => {
                            return [...val, ...filteredMovedEmployees];
                          });
                          setAvailableEmployees((val) => {
                            return [...val, ...movedEmployees];
                          });
                          setMovedEmployees([]);
                          setSelectedEmployees([]);
                          setSelectedMovedEmployees([]);
                          setChanges(true);
                        }}
                      >
                        <div style={{ transform: "rotate(-90deg)" }}>
                          <HipchatChevronDoubleUpIcon label="double arrow right" />
                        </div>
                      </Button>
                    </Box>
                  </ButtonsWrapper>
                </Box>
                <Box
                  xcss={xcss({
                    width: "100%",
                    padding: "space.200",
                    borderRadius: "border.radius.050",
                    border: "1px solid",
                    borderColor: "color.border.disabled",
                  })}
                >
                  <Box
                    xcss={xcss({
                      color: "color.text",

                      fontFamily: "inherit",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    })}
                  >
                    Selected employees
                  </Box>
                  {movedEmployees.length === 0 ? (
                    <Box
                      xcss={xcss({
                        marginTop: "space.050",
                        color: "color.text.subtlest",
                        fontFamily: "inherit",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                      })}
                    >
                      You haven’t added any employees yet.
                    </Box>
                  ) : (
                    <Box
                      xcss={xcss({
                        marginTop: "space.200",
                      })}
                    ></Box>
                  )}
                  <EmployeeList
                    filter={(item) => true}
                    availableEmployees={movedEmployees}
                    selectedEmployees={selectedMovedEmployees}
                    onClick={(item: Employee) => {
                      setSelectedMovedEmployees((val) => {
                        if (
                          val.find((obj) => {
                            return obj.id === item.id;
                          })
                        ) {
                          return val.filter((obj) => {
                            return obj.id !== item.id;
                          });
                        } else {
                          return [...val, item];
                        }
                      });
                    }}
                    loadingPolicies={false}
                    issetSelectedPolicy={true}
                  />
                </Box>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              isDisabled={!changes}
              appearance="primary"
              onClick={() => {
                setLoading(true);
                sendEmployees(() => {});
              }}
              isLoading={loading}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
