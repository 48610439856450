import React, {FC} from 'react';
import './NotFound.scss';
import {Link} from "react-router-dom";
import {MAIN} from "../../../utils/constants/routes";

export const NotFound: FC = () => {
    return (
        <div>
            <div className={'not-found'}>
                <p><span>404</span></p>
                <h1>Sorry, we can’t find that page.</h1>
                <Link to={ MAIN }>Back to Homepage</Link>
            </div>
        </div>
    );
};