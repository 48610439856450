import {FC, type ReactNode} from "react";
import {
    ROLE_UNAUTHORIZED,
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_EMPLOYEE,
    ROLE_HR,
    ROLE_OPERATIONAL_MANAGER
} from './AccessController.constants';
import {useEmployee} from "../../contexts/EmployeeContext";
import {Navigate} from "react-router-dom";

interface AccessControllerProps {
    accessAllowedRoles: Array<
        | typeof ROLE_EMPLOYEE
        | typeof ROLE_HR
        | typeof ROLE_CONTENT_MANAGER
        | typeof ROLE_OPERATIONAL_MANAGER
        | typeof ROLE_ADMIN
    > | [typeof ROLE_UNAUTHORIZED];
    redirectToIfAccessDenied: string;
    children: ReactNode;
}

export const AccessController: FC<AccessControllerProps> = ({accessAllowedRoles, redirectToIfAccessDenied, children}) => {
    const {roles} = useEmployee();
    const isAccess = accessAllowedRoles.some(role => roles.includes(role));

    if(isAccess) return <>{children}</>;
    return <Navigate to={redirectToIfAccessDenied} replace />;
};