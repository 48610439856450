import { FC, useState } from "react";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import { Toggle } from "../../../../UI/Toggle";
import { BlockInfoItem } from "../../../BlockInfo";
import { useProfile } from "../../../context";
import { useSetField } from "../../../../../API/profile/setField/useSetField";

export const TimeTracking: FC = () => {
  const { jobInfo, idOpenProfile, isAccessEditRole } = useProfile();
  const [checked, setChecked] = useState(
    jobInfo?.currentJobInfoValue.timeTracking || false
  );

  const { setField } = useSetField(idOpenProfile);

  return (
    <BlockInfoItem title="Time tracking">
      <div className={cn(style.inputWrapper, style.toggle)}>
        <Toggle
          isDisabled={!isAccessEditRole}
          isChecked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            setField(
              [
                "current-job-info",
                "time_tracking",
                event.target.checked.toString(),
              ].join("/")
            );
          }}
        />
      </div>
    </BlockInfoItem>
  );
};
