// TODO: Remove old ts FC
// FC is not recommended to use.  See - https://github.com/facebook/create-react-app/pull/8177

import React, { FC } from "react";
import { Statuses } from "../application/Page/Statuses/Statuses";

const StatusesPage: FC = () => {
  return <Statuses />;
};

export default StatusesPage;
