import { FC, useState } from "react";
import style from "./CheckInOut.module.scss";
import { Button } from "../../../UI/Button";
import FlagFilledIcon from "@atlaskit/icon/glyph/flag-filled";
import VidPauseIcon from "@atlaskit/icon/glyph/vid-pause";
import VidPlayIcon from "@atlaskit/icon/glyph/vid-play";
import {
  useEmployee,
  assertEmployee,
} from "../../../../contexts/EmployeeContext";
import { useCheckInOut } from "../../../../API/checkInOut/useCheckInOut";
import Spinner from "@atlaskit/spinner";
import { Flag } from "../../../UI/Flag";
import InfoIcon from "@atlaskit/icon/glyph/info";

import { toast } from "react-toastify";

export const CheckInOut: FC = () => {
  const notify = (props: { t: string; d: string; r: number }) => {
    toast(
      <Flag
        id={`id${props.r}`}
        title={props.t}
        description={props.d}
        icon={<InfoIcon primaryColor="#0b66e4" label="info" />}
      />,
      {
        position: "bottom-left",
        autoClose: 15000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: style.toster,
      }
    );
  };

  const addFlag = (flag: { t: string; d: string; r: number }) => {
    notify(flag);
  };

  const { employee } = useEmployee();
  assertEmployee(employee);

  const {
    status,
    isInitLoading,
    isInitError,
    isSyncLoading,
    onWork,
    onBreak,
    checkIn,
    isCheckInLoading,
    pause,
    isPauseLoading,
    unpause,
    isUnpauseLoading,
    checkOut,
    isCheckOutLoading,
  } = useCheckInOut(employee.id);

  if (isInitError) return <div>Ops...</div>;

  return (
    <>
      {isInitLoading ? (
        <Spinner size={36} />
      ) : (
        <div className={style.checkInOut}>
          <div className={style.timeInfo}>
            {onWork && (
              <div className={style.timeInfoPoint}>
                <div>On work:</div>
                <div>{onWork}</div>
              </div>
            )}
            {onBreak && (
              <div className={style.timeInfoPoint}>
                <div>On break:</div>
                <div>{onBreak}</div>
              </div>
            )}
          </div>
          {(status === "init" || status === "check_out") && (
            <div>
              <Button
                onClick={() => {
                  !isSyncLoading &&
                    !isCheckInLoading &&
                    checkIn().then(() => {
                      addFlag({
                        t: "Hi!",
                        d: "You have started your workday. Have a good day!",
                        r: Math.random(),
                      });
                    });
                }}
                appearance="primary"
                iconBefore={
                  isCheckInLoading ? (
                    <Spinner appearance="invert" size="medium" />
                  ) : (
                    <VidPlayIcon label="Start" />
                  )
                }
              >
                Start
              </Button>
            </div>
          )}
          {(status === "check_in" || status === "unpause") && (
            <>
              <div>
                <Button
                  onClick={() => {
                    !isCheckOutLoading &&
                      !isSyncLoading &&
                      !isPauseLoading &&
                      pause().then(() => {
                        addFlag({
                          t: "I hope to see you soon!",
                          d: "You have paused your workday.",
                          r: Math.random(),
                        });
                      });
                  }}
                  iconBefore={
                    isPauseLoading ? (
                      <Spinner size="medium" />
                    ) : (
                      <VidPauseIcon label="Pause" />
                    )
                  }
                >
                  Pause
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    !isPauseLoading &&
                      !isSyncLoading &&
                      !isCheckOutLoading &&
                      checkOut().then(() => {
                        addFlag({
                          t: "See you soon. Bye!",
                          d: "You have completed your workday. Have a good one!",
                          r: Math.random(),
                        });
                      });
                  }}
                  appearance="primary"
                  iconBefore={
                    isCheckOutLoading ? (
                      <Spinner appearance="invert" size="medium" />
                    ) : (
                      <FlagFilledIcon label="Finish" />
                    )
                  }
                >
                  Finish
                </Button>
              </div>
            </>
          )}
          {status === "pause" && (
            <div>
              <Button
                onClick={() => {
                  !isSyncLoading &&
                    !isUnpauseLoading &&
                    unpause().then(() => {
                      addFlag({
                        t: "Glad to see you back!",
                        d: "Welcome back after your pause.",
                        r: Math.random(),
                      });
                    });
                }}
                iconBefore={
                  isUnpauseLoading ? (
                    <Spinner size="medium" />
                  ) : (
                    <VidPlayIcon label="Unpause" />
                  )
                }
              >
                Unpause
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
