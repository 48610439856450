import React, {FC} from "react";
import style from "./ErrorChangePassword.module.scss";
import styleAuth from "../../Auth.module.scss";
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left'
import {Button} from "../../../UI/Button";
import {useNavigate} from 'react-router-dom';
import {AUTH_SIGN_IN, AUTH_FORGOT_PASSWORD} from '../../../../Router/routes';

interface ErrorChangePasswordProps {
    isResetPassword: boolean;
}

export const ErrorChangePassword: FC<ErrorChangePasswordProps> = ({isResetPassword}) => {
    const navigate = useNavigate();
    const toLogin = () => {
        navigate(AUTH_SIGN_IN, { replace: true });
    };

    const toForgotPassword = () => {
        if(!isResetPassword) {
            window.location.reload();
            return;
        }

        navigate(AUTH_FORGOT_PASSWORD, { replace: true });
    };

    return(
        <div className={style.wrapper}>
            <div className={style.imageWrapper}>
                <CrossCircleIcon size="large" label="OK"/>
            </div>

            <h2 className={style.title}>We’re sorry, but something went wrong.</h2>

            <p className={style.paragraph}>We could not {isResetPassword ? 'reset' : 'set'} your password at this time. Please try again later.</p>

            <div className={style.btnWrapper}>
                <Button onClick={toForgotPassword} className={styleAuth.sendBtn} appearance="primary">Try again</Button>
            </div>

            <div>
                <Button
                    onClick={toLogin}
                    appearance="link"
                    spacing="none"
                    iconBefore={<ArrowLeftIcon label="Back to login" />}
                    className={styleAuth.linkBtn}
                >
                    Return to login
                </Button>
            </div>
        </div>
    );
};