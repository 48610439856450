import Drawer from '@atlaskit/drawer'
import { useEffect, useState, type FC, type ReactNode } from 'react'
import { Box, xcss } from '@atlaskit/primitives'
import Spoiler from './Spoiler'
import { token } from '@atlaskit/tokens'
import styled from 'styled-components'

const ScrollBlock = styled.div`
  /* width */

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(102, 102, 102, 0.4);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: ${token('space.200')};
  padding-right: ${token('space.100')};
  height: 80vh;
`

export default function DrawerWithSpoiler({
  open,
  setOpen,
  advice,
  title,
  subTitle,
}: {
  open: boolean
  setOpen: (arg: boolean) => void
  advice: { [key: string]: string[] }
  title?: ReactNode
  subTitle?: ReactNode
}) {
  const drawerOverrides = {
    Sidebar: {},
    Content: {
      cssFn: (defaultStyles: any) => ({
        ...defaultStyles,
      }),
    },
    cssFn: (defaultStyles: any) => ({
      ...defaultStyles,
    }),
  }

  return (
    <Drawer
      width="wide"
      label="drawer"
      onClose={() => setOpen(false)}
      isOpen={open}
      overrides={drawerOverrides}
    >
      <h2
        style={{
          color: token('color.text'),
          fontFamily: 'inherit',
          fontSize: '29px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '32px',
        }}
      >
        {title}
      </h2>
      <Box>{subTitle}</Box>
      <ScrollBlock>
        {Object.keys(advice).map((title, id) => {
          return (
            <Box
              xcss={xcss({
                width: '518px',
              })}
            >
              <Spoiler
                key={title + '_' + id}
                title={title}
                text={advice[title]}
              />
            </Box>
          )
        })}
      </ScrollBlock>
    </Drawer>
  )
}
