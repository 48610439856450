import type {GenderPronounceResponseProps, GenderPronounceOptionProps} from './genderPronounce.types';
import {GENDERS} from "../../../../constants";

const transformToADSSelectOptions = (options: Array<GenderPronounceResponseProps>): Array<GenderPronounceOptionProps> => {
    return options.map((option) => {
        return {
            ...option,
            label: option.name,
            value: option.id
        }
    });
};

export const fetchGenderPronounceAPI = async () => {
    try {
        const response = await fetch(GENDERS, {
            method: 'GET',
            headers: { 'Accept': 'application/json' }
        });

        const data = await response.json() as Array<GenderPronounceResponseProps>;
        return transformToADSSelectOptions(data);
    } catch(error) {
        console.error(error);
        throw new Error();
    }
};