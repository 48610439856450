import { useEffect, useMemo } from 'react'
import Button from '@atlaskit/button/new'
import Modal, { ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import { getFormattedDate} from 'utils/helpers/formatted-date'
import { AnnouncementFormType } from 'interfaces/common/announcements.interface'
import Select, { type ValueType as Value } from '@atlaskit/select'
import { ErrorMessage, Label } from '@atlaskit/form'
import TextArea from '@atlaskit/textarea'
import dayjs from 'dayjs';
import { useForm, Controller, SubmitHandler, FormProvider } from "react-hook-form"
import { useLocationsData } from '../../hooks'
import { DateRange } from '../date-range'
import { IAnnouncementForm, Option } from '../types'
import { FieldCounter } from '../field-counter'  
import { announcementFormErrorsMsgMap, announcementPrioritySelectOptions } from '../constants'
import { useCreateAnnouncement } from './hooks'
import { ICreateAnnouncementModalProps } from './types';
import { CreateAnnouncementModalBodyStyles } from './styles'


export const CreateAnnouncementModal = (props: ICreateAnnouncementModalProps) => {
  const { onClose, onCreate } = props

  const methods = useForm<IAnnouncementForm>()
  const { handleSubmit, control } = methods

  const { locationsList, fetchLocations, loading: isLoadingLocations } = useLocationsData();
  const { createAnnouncement, loading: createAnnouncementLoading} = useCreateAnnouncement()

  const locationSelectOptions: Value<Option[]> = useMemo(() => {
    return locationsList.map(({name: label, id: value}) => ({
      label,
      value,
    }))
  }, [locationsList])


  const onSubmit: SubmitHandler<IAnnouncementForm> = async (data) => {

    const payload: AnnouncementFormType = {
      ...data,
      priority: data.priority.value,
      locations: data.locations.map(({ value }: Option) => value),
    }

    try {
       const announcementId: string | undefined = await createAnnouncement(payload)

      if (announcementId ) {
        onCreate({
          ...payload,
          locations: data.locations.map(({label, value}) => ({name: label, id: value})),
          id: announcementId,
          createdAt: getFormattedDate(dayjs(), 'isoDateFormat')
        })
      }
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!locationsList.length) {
      fetchLocations();
    }
  }, []);

  return (
    <ModalTransition>
      <Modal onClose={onClose} width={448} autoFocus={false}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              <ModalTitle>Create announcement</ModalTitle>
            </ModalHeader>
            <CreateAnnouncementModalBodyStyles>
              <DateRange />
              <Controller
                name="priority"
                control={control}
                rules={{ required: true }}
                render={({ field: {value, ...rest}, fieldState: {error} }) => (
                  <div>
                    <Label htmlFor="priority-select">Priority</Label>
                    <Select
                      {...rest}
                      inputId="priority-select"
                      placeholder="Priority"
                      options={announcementPrioritySelectOptions}
                      isClearable
                      value={value}
                    />
                    { error && <ErrorMessage>{announcementFormErrorsMsgMap['priority']['required']}</ErrorMessage> }
                  </div>
                )}
              />
              <Controller
                name="locations"
                control={control}
                rules={{ required: true }}
                render={({ field: {value, ...rest}, fieldState: {error} }) => (
                  <div>
                    <Label htmlFor="locations-select">Location</Label>
                    <Select
                      {...rest}
                      inputId="locations-select"
                      placeholder={isLoadingLocations ? "Loading locations..." : "Select location"}
                      isLoading={isLoadingLocations}
                      options={locationSelectOptions}
                      isClearable
                      isMulti
                      value={value}
                    />
                    {error && <ErrorMessage>{announcementFormErrorsMsgMap['locations']['required']}</ErrorMessage>}
                  </div>
                )}
              />
              <Controller
                name="text"
                control={control}
                rules={{ required: true }}
                render={({ field: {value, ...rest}, fieldState: {error} }) => (
                  <div className='text-area'>
                    <>
                      <Label htmlFor="text">Announcement</Label>
                      {/* TODO: Fix TS! */}
                      {/* @ts-ignore */}
                      <TextArea
                        {...rest}
                        id="text"
                        maxHeight="216px" 
                        minimumRows={10}
                        placeholder="Enter text"
                        maxLength={500}
                        value={value} 
                      />
                      <FieldCounter className="field-counter" value={value ? value.length : 0}  maxLength={500}/>  
                    </>
                    {error && <ErrorMessage>{announcementFormErrorsMsgMap['text']['required']}</ErrorMessage>}
                  </div>
                )}
              />  
            </CreateAnnouncementModalBodyStyles>
            <ModalFooter>
              <Button onClick={onClose} appearance="subtle">
                Cancel
              </Button>
              <Button type="submit" appearance="primary" isLoading={createAnnouncementLoading}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </Modal>
    </ModalTransition>
  )
}