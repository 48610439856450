import React, { type FC, type SVGProps, type ReactNode, useState } from "react";
import style from "./BlockInfo.module.scss";
import { ReactComponent as PersonalIcon } from "./Icons/person_outline.svg";
import { LineSkeleton } from "../../UI/LineSkeleton";
import { IconButton } from "@atlaskit/button/new";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import Popup from "@atlaskit/popup";

interface BlockInfoProps {
  title: string;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  children: ReactNode;
}

export const BlockInfo: FC<BlockInfoProps> = ({ title, Icon, children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className={style.blockInfo}>
      {title ? (
        <div className={style.titleBlock}>
          {Icon && (
            <div className={style.iconWrapper}>
              <Icon className={style.icon} />
            </div>
          )}
          <h4 className={style.title}>{title}</h4>
          {title === "Emergency Contact Information" && (
            <>
              <div className={style.iconInfo}>
                <IconButton
                  label="Info"
                  appearance="subtle"
                  spacing="compact"
                  icon={EditorPanelIcon}
                  onClick={() => setIsPopupOpen(!isPopupOpen)}
                />
              </div>
              <Popup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                content={() => (
                  <div
                    style={{
                      padding: "16px",
                      maxWidth: "411px",
                      fontSize: "11px",
                    }}
                  >
                    Add info of someone who should be contacted if you have an
                    emergency.
                  </div>
                )}
                trigger={(triggerProps) => <span {...triggerProps} />}
                placement="bottom-start"
              />
            </>
          )}
        </div>
      ) : (
        <LineSkeleton height="28px" width="250px" />
      )}
      <hr className={style.hr} />
      <table className={style.table}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
