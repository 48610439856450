import {useQuery} from "../../useQuery";
import {fetchContactInfoAPI} from "./contactInfo";
import {useState} from "react";
import type {ContactInfoProps} from "./contactInfo.type";
import {useEvent} from "../../../customHooks/useEvent";

export const useContactInfo = (idProfile: string) => {
    const [fetchContactInfoAPIs, isLoading, isSuccess, isError] = useQuery(fetchContactInfoAPI);
    const [contactInfo, setContactInfo] = useState<Array<ContactInfoProps>>();

    const fetchContactInfo = useEvent(async () => {
        try {
            const response = await fetchContactInfoAPIs(idProfile);
            setContactInfo(response);
            return response;
        } catch (error) {
            throw new Error();
        }
    });

    const reFetchContactInfo = useEvent(async () => {
        try {
            const response = await fetchContactInfoAPI(idProfile);
            setContactInfo(response);
            return response;
        } catch (error) {
            throw new Error();
        }
    });

    return {
        fetchContactInfo: fetchContactInfo,
        contactInfo: contactInfo,
        reFetchContactInfo: reFetchContactInfo,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError
    }
};