import { format, parseISO as parseISOFromPackage, isValid } from "date-fns";
import { parse } from "date-fns";

export const formatTime = (time?: string) => {
  if (
    !time ||
    typeof time !== "string" ||
    time === "-" ||
    time.startsWith("00:00")
  ) {
    return "-";
  }

  const [hours, minutes] = time.split(":");
  const formattedHours = parseInt(hours, 10);
  if (isNaN(formattedHours) || !minutes) {
    return "-";
  }
  return `${formattedHours}:${minutes}`;
};

export const toCSVString = (itemsArray: Array<any>) => {
  const csvString = [
    [
      "Employee",
      "Date",
      "Day type",
      "Check in",
      "Check out",
      "Break",
      "Total",
      "Difference",
    ],
    ...itemsArray.map((item) => [
      `"${item["Employee"]}"`,
      `"${isValid(parse(item["Date"], "dd MMM, EEE", new Date())) ? format(parse(item["Date"], "dd MMM, EEE", new Date()), "dd MMM, EEE") : "Invalid Date"}"`,
      `"${item["Day type"]}"`,
      `"${item["Check in"]}"`,
      `"${item["Check out"]}"`,
      `"${item["Break"] || "-"}"`,
      `"${item["Total"]}"`,
      `"${item["Difference"]}"`,
    ]),
  ]
    .map((e) => e.join(","))
    .join("\n");

  return csvString;
};
