import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const StyledSubMenu = styled.nav`
  background: #fff;
  box-shadow:
    0 0.15px 0.45px rgb(0 0 0 / 11%),
    0 0.8px 1.8px rgb(0 0 0 / 13%);
  height: auto;
  max-height: calc(100% - 180px);
  margin: 90px 24px 24px;
  padding: 32px 18px 32px 26px;
  position: fixed;
  width: 244px;
  overflow-y: auto;
  border: none;
  box-sizing: border-box;
  outline: none;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: ${token("space.100")} ${token("space.200")};

      span,
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: ${token("color.text.subtle")};
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      div {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }

      ul {
        border-left: 2px solid ${token("color.border.disabled")};
        margin-top: 10px;
        padding: 0;
        li {
          padding-left: 12px;
        }
        li a {
          font-weight: 400;
        }
        li:first-child {
          padding-top: 10px;
        }
        li:last-child {
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
    }

    .sub-menu-active {
      padding-bottom: 0;
    }

    .inner-sub-menu-active {
      background-color: ${token("color.background.selected")};
      box-shadow: inset 2px 0 0 ${token("color.text.selected")};
    }

    .material-icons {
      width: 24px;
      height: 24px;
      transition: transform 0.25s ease;
    }
  }

  .isHidden {
    display: none;
  }
`;
