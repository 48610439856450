import React, {FC, useEffect, useState} from 'react';
import BaseModal from './BaseModal';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import {EMPLOYEE_PROFILE} from 'utils/constants/routes';
import { EMPLOYEES } from '../../../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../../shared/Common/Error/ValidationError';
import {assertEmployee, useEmployee} from "../../../../../../../contexts/EmployeeContext";
import { Link } from 'react-router-dom';

interface CreateEmployeeProps {
    opened: boolean;
    onOk: () => void;
    onClose: () => any;
};

export const CreateEmployee: FC<CreateEmployeeProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState<{isOpen: boolean, createdEmployeeId: string | null}>({isOpen: false, createdEmployeeId: null});  

    const {employee} = useEmployee();
    assertEmployee(employee);

    const create = (
        nickname: string | null,
        lastNameEn: string,
        firstNameEn: string,
        email: string,
        validate: (errors: ValidationError[]) => void
    ): void => {
        setLoading(true);

        let formData = new FormData();
        formData.append('employee', JSON.stringify({
            nickname: nickname,
            lastNameEn: lastNameEn,
            firstNameEn: firstNameEn,
            email: email,
        }));

        fetch(EMPLOYEES, { method: 'POST', body: formData })
            .then((response) => {
                console.log({response});

                if (response.ok) {
                    response.json().then((id) => {
                        setShowSuccessMsg({isOpen: true, createdEmployeeId: id as string});
                        props.onOk();
                    });

                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        let timer: NodeJS.Timeout

        if (showSuccessMsg) {
            timer = setTimeout(() => {
                setShowSuccessMsg({isOpen: false, createdEmployeeId: null})
            }, 30000)
        }

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [showSuccessMsg])

    return (
        <>
            <BaseModal
                opened={ props.opened }
                modalTitle='Create Employee'
                okText='Create'
                onCancel={ props.onClose }
                onOk={ create }
                loading={ loading }
            />
            {
                showSuccessMsg.createdEmployeeId && (
                    <div className='success-notification'>
                        <SectionMessage
                            title="You’ve added new employee to the system"
                            appearance="success"
                            actions={[
                                <SectionMessageAction>
                                    <Link to={ EMPLOYEE_PROFILE + '/' + showSuccessMsg.createdEmployeeId } target={'_blank'}>Go to employee profile</Link>
                                </SectionMessageAction>,
                            ]}
                        >
                            <p>You can go to the employee's profile to fill in additional information. Until you set the working status in the profile, the employee will remain in the inactive employees section</p>
                        </SectionMessage>
                    </div>
                )
            }
        </>
        
    )
};