import React, {FC} from "react";
import {Auth} from "../../components/Auth/Auth";
import {useEmployee} from "../../contexts/EmployeeContext";
import {Navigate} from "react-router-dom";
import {MAIN} from '../../Router/routes';

const AuthPage: FC = () => {
    return(<Auth />);
};

export default AuthPage;