import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button/new'
import { Link } from 'react-router-dom'
import { Checkbox } from '@atlaskit/checkbox'
import { xcss, Box } from '@atlaskit/primitives'
import { useReducer } from 'react'
import styled from 'styled-components'
import { token } from '@atlaskit/tokens'

// Define the state and action types
type State = {
  checkbox1: boolean
  checkbox2: boolean
}

type Action = { type: 'checkbox1' } | { type: 'checkbox2' }

// Reducer function
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'checkbox1':
      return {
        ...state,
        checkbox1: !state.checkbox1,
      }
    case 'checkbox2':
      return {
        ...state,
        checkbox2: !state.checkbox2,
      }
    default:
      throw new Error('Unknown action.')
  }
}

export default function ModalContent2({
  setContent,
  setIsOpen,
  interviesID,
}: {
  setContent: (arg: number) => void
  setIsOpen: (arg: boolean) => void
  interviesID: string
}) {
  const [state, dispatch] = useReducer(reducer, {
    checkbox1: false,
    checkbox2: false,
  })

  const CheckBoxesWrapper = styled.div`
    padding-top: ${token('space.200')};

    label {
      color: ${token('color.text')};
      font-family: inherit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  `

  return (
    <div>
      <ModalHeader>
        <ModalTitle>
          {' '}
          <Box
            xcss={xcss({
              color: 'color.text',
              fontFamily: 'inherit',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            })}
          >
            Personality analysis by AI
          </Box>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Box
          xcss={xcss({
            color: 'color.text',
            fontFamily: 'inherit',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          })}
        >
          {' '}
          Перед тим як розпочати, будь ласка, надайте згоду на обробку особистих
          даних. Ваші особисті дані будуть оброблятися відповідно до нашої{' '}
          <Link to="">політики конфіденційності.</Link>
        </Box>
        <CheckBoxesWrapper>
          <Box>
            <Checkbox
              isChecked={state.checkbox1}
              value="default checkbox"
              label="Я даю згоду на обробку моїх особистих даних"
              onChange={() => dispatch({ type: 'checkbox1' })}
              name="checkbox-default"
              testId="cb-default"
            />
          </Box>
          <Box
            xcss={xcss({
              paddingTop: 'space.200',
            })}
          >
            <Checkbox
              isChecked={state.checkbox2}
              value="default checkbox"
              label="Я даю згоду на те, що мої результати будуть доступні всім до перегляду"
              onChange={() => dispatch({ type: 'checkbox2' })}
              name="checkbox-default"
              testId="cb-default"
            />
          </Box>
        </CheckBoxesWrapper>
      </ModalBody>
      <ModalFooter>
        <Button
          appearance="subtle"
          onClick={() => {
            setIsOpen(false)
            setContent(1)
          }}
        >
          Відмінити
        </Button>
        <Button
          isDisabled={!(state.checkbox1 && state.checkbox2)}
          appearance="primary"
          onClick={() => {
            setContent(3)
          }}
        >
          Продовжити
        </Button>
      </ModalFooter>
    </div>
  )
}
