import React, { useRef, useState } from 'react';
import './TextField.scss';
import '../index.scss';
import Visibility from '../../Button/Visibility';
import TextareaAutosize from 'react-textarea-autosize';

export enum Type {
    Text = 'text',
    Textarea = 'textarea',
    Password = 'password',
}

type TextFieldProps = {
    id?: string,
    className?: string,
    label?: string,
    placeholder: string,
    required: boolean,
    value: string,
    maxLength: number,
    type: Type,
    horizontal: boolean,
    error: string | null,
    onChange: (value: string) => void,
    onBlur?: (value: string) => void,
    pattern?: string,
    onFocus?: (value: string) => void,
    isClicked?: boolean,
    disabled?: boolean,
    onClick?: () => void,
    onKeyDown?: (event: any) => void,
    style?: any,
    onPaste?: boolean,
};

function TextField(props: TextFieldProps) {
    const [showText, setShowText] = useState<boolean>(!isPassword());
    const [clicked, setClicked] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const inputRef = React.useRef<HTMLTextAreaElement | null>(null);
    const {
        onPaste = true,
    } = props;
    const originalValue = useRef<string>(props.value);
    const newValue = useRef<string>(props.value);

    function isPassword(): boolean {
        return props.type === Type.Password;
    }

    function isTextarea(): boolean {
        return props.type === Type.Textarea;
    }

    return (
        <div className={'input' + (props.horizontal ? ' horizontal-input' : '')} onClick={props.onClick}>
            {props.label ? <label>{props.label}{props.required ? <span>*</span> : ''}</label> : null}
            <div className={'input-container' + (isPassword() ? ' password' : '')}>
                {isTextarea() ? (
                    <TextareaAutosize
                        style={props.style}
                        id={props.id}
                        className={props.className}
                        placeholder={props.placeholder}
                        value={props.value}
                        ref={inputRef}
                        onChange={(e) => {
                            props.onChange(e.target.value.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, ''));
                            newValue.current = e.target.value.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '');
                            setClicked(true);
                        }}
                        onBlur={(e) => {
                            setIsFocused(false);
                            if (props.onBlur) {
                                if (originalValue.current !== newValue.current) {
                                    props.onBlur(newValue.current.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, ''));
                                    originalValue.current = newValue.current
                                }
                            }
                            setClicked(false);
                        }}
                        onFocusCapture={(e: any) => {
                            originalValue.current = e.target.value
                        }}
                        onKeyUp={() => {
                            props.error = null;
                        }}
                        onKeyDown={(e) => {
                            if (props.onKeyDown) {
                                props.onKeyDown(e);
                            }
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                const inputElement = e.target as HTMLInputElement | HTMLTextAreaElement;
                                const sanitizedValue = inputElement.value.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, '');
                                props.onChange(sanitizedValue);
                                if (inputRef.current) {
                                    inputRef.current.blur();
                                }
                            }
                            if (e.key === 'Escape') {
                                props.onChange(originalValue.current);
                                newValue.current = originalValue.current
                                if (inputRef.current) {
                                    inputRef.current.blur();
                                }
                            }
                        }}
                        maxLength={props.maxLength}
                        onFocus={(e) => {
                            props.onFocus ? props.onFocus(e.target.value) : setIsFocused(true)
                        }}
                        disabled={props.disabled}
                        onPaste={(e) => {
                            if (!onPaste) {
                                e.preventDefault();
                            }
                        }}
                    />
                ) : (
                    <input
                        type={isPassword() && !showText ? 'password' : 'text'}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={(e) => {
                            props.onChange(e.target.value);
                            setClicked(props.isClicked !== undefined ? props.isClicked : true);
                        }}
                        onBlur={() => {
                            setClicked(false);
                        }}
                        onKeyUp={() => {
                            props.error = null;
                        }}
                        maxLength={props.maxLength}
                        disabled={props.disabled}
                        onPaste={(e) => {
                            if (!onPaste) {
                                e.preventDefault();
                            }
                        }}
                    />
                )}
                {isPassword() ? <Visibility onChange={() => setShowText(!showText)} /> : ''}
                {(() => {
                    if (props.error && !clicked) {
                        return (
                            <div className="error">
                                <svg
                                    width="18"
                                    height="17"
                                    viewBox="0 0 18 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.83317 6.00002H8.1665V4.33335H9.83317M9.83317 12.6667H8.1665V7.66669H9.83317M8.99984 0.166687C7.90549 0.166687 6.82185 0.382235 5.81081 0.801024C4.79976 1.21981 3.8811 1.83364 3.10728 2.60746C1.54448 4.17027 0.666504 6.28988 0.666504 8.50002C0.666504 10.7102 1.54448 12.8298 3.10728 14.3926C3.8811 15.1664 4.79976 15.7802 5.81081 16.199C6.82185 16.6178 7.90549 16.8334 8.99984 16.8334C11.21 16.8334 13.3296 15.9554 14.8924 14.3926C16.4552 12.8298 17.3332 10.7102 17.3332 8.50002C17.3332 7.40567 17.1176 6.32204 16.6988 5.31099C16.28 4.29994 15.6662 3.38128 14.8924 2.60746C14.1186 1.83364 13.1999 1.21981 12.1889 0.801024C11.1778 0.382235 10.0942 0.166687 8.99984 0.166687Z"
                                        fill="#E05D44"
                                    />
                                </svg>
                                <span>{props.error}</span>
                            </div>
                        );
                    }
                })()}
            </div>
        </div>
    );
}

export default TextField;
