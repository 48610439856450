import React from 'react';
import BaseModal from './BaseModal';
import { LINKS } from '../../../../../utils/constants/api';
import { VALIDATION_ERROR } from '../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../shared/Common/Error/ValidationError';
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi';

type CreateLink = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    orgUnits: OrgUnitFromApi[],
};

function CreateLink(props: CreateLink) {
    function create(name: string, url: string, orgUnits: string[], visible: boolean, validate: (errors: ValidationError[]) => void): void {
        let formData = new FormData();
        formData.append('link', JSON.stringify({
            name: name,
            url: url,
            orgUnits: orgUnits,
            visible: visible
        }));

        fetch(LINKS, { method: 'POST', body: formData })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Create Link'
            name={ null }
            url={ null }
            visible={ true }
            okText='Create'
            onCancel={ props.onClose }
            onOk={ create }
            selectedOrgUnits={ props.orgUnits }
            orgUnits={ [] }
        />
    )
}

export default CreateLink;