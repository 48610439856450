import { STATUSES, STATUSES_TRANSITIONS } from "../../utils/constants/api";
import { baseHeaders } from '../constants' 

export const adminStatusAPI = {
  getStatuses() {
    return fetch(STATUSES, {
      method: "GET",
      headers: baseHeaders,
    });
  },
  getStatusesTransitions() {
    return fetch(STATUSES_TRANSITIONS, {
      method: "GET",
      headers: baseHeaders,
    });
  },
  addStatusesTransitions(fromStatus: string, toStatus: string) {
    return fetch(STATUSES_TRANSITIONS, {
      method: "POST",
      headers: baseHeaders,
      body: JSON.stringify({
        fromStatus: fromStatus,
        toStatus: toStatus,
      }),
    });
  },
  addStatus(name: string, isActive: boolean) {
    return fetch(STATUSES, {
      method: "POST",
      headers: baseHeaders,
      body: JSON.stringify({
        name: name,
        isActive: isActive,
      }),
    });
  },
  updateStatus(id: string, name: string, isActive: boolean) {
    return fetch(`${STATUSES}/${id}`, {
      method: "PUT",
      headers: baseHeaders,
      body: JSON.stringify({
        name: name,
        isActive: isActive,
      }),
    });
  },
  deleteStatus(id: string) {
    return fetch(`${STATUSES}/${id}`, {
      method: "DELETE",
      headers: baseHeaders,
    });
  },
  deleteStatusTransition(id: string) {
    return fetch(`${STATUSES_TRANSITIONS}/${id}`, {
      method: "DELETE",
      headers: baseHeaders,
    });
  },
};
