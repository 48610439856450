import { FC, useEffect, useMemo, useState } from "react";
import style from "./AttendanceLog.module.scss";
import { format, parseISO } from "date-fns";
import { formatTime } from "utils/helpers/DateTime/formatTime";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { token } from "@atlaskit/tokens";
import Popup from "@atlaskit/popup";
import { Box, xcss } from "@atlaskit/primitives";
import React from "react";
import { CorrectionRecords } from "interfaces/attendance/corrections.interface";

const contentStyles = xcss({
  padding: "space.200",
  width: "198px",
});

type PopupProps = {
  originalTime: string;
  corrections: CorrectionRecords;
};

const CorrectionPopup: FC<PopupProps> = (popupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      isOpen={isOpen}
      placement={"bottom-end"}
      onClose={() => setIsOpen(false)}
      content={() => (
        <Box xcss={contentStyles}>
          <div className={style.popupBlock}>
            <div className={style.popupList}>
              <div className={style.popupTitle}>
                Original Time: <span>{popupProps.originalTime}</span>
              </div>
              {popupProps.corrections.map((record, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <div className={style.popupDivider}></div>}
                  <div className={style.popupItems}>
                    <div className={style.popupItem}>
                      <p>Date:</p>
                      <span>
                        {format(parseISO(record.date), "dd MMM, EEE")}
                      </span>
                    </div>

                    <div className={style.popupItem}>
                      <p>Changed to: </p>
                      <span>{formatTime(record.changedTo)} hours </span>
                    </div>

                    <div className={style.popupItem}>
                      <p>Changed by: </p>
                      <span>{record.changedBy}</span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Box>
      )}
      trigger={(triggerProps) => (
        <div
          {...triggerProps}
          className={style.differenceIcon}
          onClick={() => setIsOpen(!isOpen)}
        >
          <EditorPanelIcon
            primaryColor={token("color.icon.selected")}
            label="panel"
          />
        </div>
      )}
    />
  );
};

export default CorrectionPopup;
