import { useState, useCallback } from "react";
import { locationsAPI } from 'API/locations/locations';
import { ILocation } from "interfaces/common/location.interface";

export const useLocationsData = () => {
  const [locationsList, setLocationsList] = useState<Array<ILocation>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);


  const fetchLocations = useCallback(async () => {
    setLoading(true);
    
    try {
      const response = await locationsAPI.getLocations();

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const locationsData: ILocation[] = await response.json();

      setLocationsList(locationsData);

    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    locationsList,
    loading,
    error,
    fetchLocations,
  };
};
