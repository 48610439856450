import React from 'react';
import BaseModal  from './BaseModal';
import { STATE_HOLIDAYS } from '../../../../../../utils/constants/api';
import { StateHolidayFromApi } from '../../../../../../shared/Holiday/StateHoliday/data/StateHolidayFromApi';
import moment from 'moment/moment';
import { VALIDATION_ERROR } from '../../../../../../utils/constants/errors';
import { ValidationError } from '../../../../../../shared/Common/Error/ValidationError';
import { OrgUnitFromApi } from '../../../../../../shared/OrgChart/data/OrgUnitFromApi';
import { SelectorData } from 'application/Component/Input/DateSelector/data/SelectorData';
import { LocationFromApi } from '../../../Locations/Locations';
import { SelectValue } from "../../../../../../shared/Common/data/SelectValue";
import { getGMTOffsetByTimezoneName } from "../../../../../Common/Timezones/Services";

type EditStateHoliday = {
    opened: boolean,
    onOk: () => void;
    onClose: () => any,
    stateHoliday: StateHolidayFromApi,
    orgUnits: OrgUnitFromApi[],
    locations: LocationFromApi[],
};

function EditStateHoliday(props: EditStateHoliday) {
    function edit(
        name: string,
        locations: string[],
        orgUnits: string[],
        type: string,
        movingType: SelectorData | null,
        date: Date | null,
        repeatAnnually: boolean,
        dayOff: boolean,
        paidTimeOff: boolean,
        additionalPaidTimeOff: SelectValue,
        additionalPaidTimeDate: Date | null,
        text: string | null,
        visible: boolean,
        validate: (errors: ValidationError[]) => void
    ): void {
        fetch(STATE_HOLIDAYS + '/' + props.stateHoliday.id, {
            method: 'PUT',
            body: JSON.stringify({
                name: name,
                orgUnits: orgUnits,
                locations: locations,
                type:  type,
                movingType: type === 'moving' ? movingType : null,
                date: date ? date.getFullYear() + '-' + (date.getMonth() + 1) + "-" + date.getDate() : null,
                repeatAnnually: repeatAnnually,
                dayOff: dayOff,
                paidTimeOff: paidTimeOff,
                additionalPaidTimeOff: additionalPaidTimeOff ? additionalPaidTimeOff.value : null,
                additionalPaidTimeDate: additionalPaidTimeDate
                    ? additionalPaidTimeDate.getFullYear() + '-' + (additionalPaidTimeDate.getMonth() + 1) + "-" + additionalPaidTimeDate.getDate()
                    : null,
                text: text,
                visible: visible,
            })
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else if (response.status === VALIDATION_ERROR) {
                    response.json().then((errors: ValidationError[]) => validate(errors));
                }
            });
    }

    const additionalPaidTimeOffOptions: SelectValue[] = [
        { label: 'No', value: '0' },
        { label: 'Yes, next working day', value: '1' },
        { label: 'Yes, other date', value: '2' },
    ];

    return (
        <BaseModal
            opened={ props.opened }
            modalTitle='Edit State Holiday'
            name={ props.stateHoliday.value.name }
            text={ props.stateHoliday.value.text }
            locationValues={ props.locations }
            locations={props.stateHoliday.value.locations.map(location => {
                let locationName = '';
                props.locations.map(locationValue => {
                    if (locationValue.id === location) {
                        locationName = locationValue.name + ' ' + getGMTOffsetByTimezoneName(locationValue.value.timezone);
                    }
                })
                return {
                    label: locationName,
                    value: location
                }
            }).filter(orgUnit => orgUnit.label !== '')}
            orgUnitValues={ props.orgUnits }
            orgUnits={props.stateHoliday.value.orgUnits.map(orgUnit => {
                let orgUnitName = '';
                props.orgUnits.map(orgUnitValue => {
                    if (orgUnitValue.id === orgUnit) {
                        orgUnitName = orgUnitValue.name;
                    }
                })
                return {
                    label: orgUnitName,
                    value: orgUnit
                }
            }).filter(orgUnit => orgUnit.label !== '')}
            date={ props.stateHoliday.value.date
                ? new Date(moment(props.stateHoliday.value.date).format('YYYY-MM-DD HH:mm'))
                : null }
            repeatAnnually={ props.stateHoliday.value.repeatAnnually }
            dayOff={ props.stateHoliday.value.dayOff ? 'true' : 'false' }
            paidTimeOff={ props.stateHoliday.value.paidTimeOff ? 'true' : 'false' }
            additionalPaidTimeOff={ additionalPaidTimeOffOptions[parseInt(props.stateHoliday.value.additionalPaidTimeOff)] }
            additionalPaidTimeDate={ props.stateHoliday.value.additionalPaidTimeDate
                ? new Date(moment(props.stateHoliday.value.date).format('YYYY-MM-DD HH:mm'))
                : null }
            type={ !props.stateHoliday.value.movingType ? 'fixed' : 'moving' }
            visible={ props.stateHoliday.value.visible }
            movingType={ props.stateHoliday.value.movingType
                ? props.stateHoliday.value.movingType
                : {'month': '1', 'weekNumber': '1', 'dayOfWeek': 'Monday'} as SelectorData
        }
            okText='Edit'
            onCancel={ props.onClose }
            onOk={ edit }
         />
    )
}

export default EditStateHoliday;