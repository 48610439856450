import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button/new";
import { xcss, Box } from "@atlaskit/primitives";
import VideoRecorder from "./VideoRecorder";
import { useState } from "react";
import { INTERVIEWS } from "../../../../../utils/constants/api";
import { ToastContainer, toast } from "react-toastify";
import { LongTextTooltip } from "components/UI/LongTextTooltip";
import QuestionsBlock from "./QuestionsBlock";

export default function ModalContent4({
  setContent,
  setIsOpen,
  interviesID,
}: {
  setContent: (arg: number) => void;
  setIsOpen: (arg: boolean) => void;
  interviesID: string;
}) {
  const [videoURL, setVideoURL] = useState<string>("");
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [checkpointState, setCheckpointState] = useState(1);

  const [sending, setSending] = useState(false);

  const allNotChecked = !(checkpointState === 7 && videoBlob);

  return (
    <div>
      <ModalHeader>
        <ModalTitle>
          <Box
            xcss={xcss({
              color: "color.text",
              fontFamily: "inherit",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
            })}
          >
            Personality analysis by AI
          </Box>
        </ModalTitle>
      </ModalHeader>
      <Box
        xcss={xcss({
          paddingLeft: "space.300",
          paddingRight: "space.300",
        })}
      >
        <Box
          xcss={xcss({
            // display: "flex",
            // justifyContent: "space-between",
            // gap: "space.400",
          })}
        >
          <QuestionsBlock
            checkpointState={checkpointState}
            setCheckpointState={setCheckpointState}
          />

          <Box xcss={xcss({ marginTop: "space.100" })}>
            <VideoRecorder
              setVideoURL={setVideoURL}
              setVideoBlob={setVideoBlob}
            />
          </Box>
        </Box>
      </Box>
      <ModalFooter>
        <Button
          appearance="subtle"
          onClick={() => {
            setIsOpen(false);
            setContent(1);
          }}
        >
          Відмінити
        </Button>
        <LongTextTooltip
          content={"Необхідно вибрати всі пункти та записати відео!"}
          maxContentLength={allNotChecked ? 20 : 100}
          position="top"
        >
          <Button
            isLoading={sending}
            isDisabled={allNotChecked}
            appearance="primary"
            onClick={() => {
              if (videoBlob) {
                const formData = new FormData();
                formData.append("video", videoBlob, "video.webm");
                setSending(true);
                fetch(INTERVIEWS + "/" + interviesID, {
                  method: "POST",
                  body: formData,
                })
                  .then((response) => {
                    setSending(false);
                    if (response.ok) {
                      setContent(5);
                    } else {
                      toast.error(
                        "Something went wrong with AI analysis. " +
                          (response.status ? "Error:" + response.status : ""),
                        {
                          position: "bottom-left",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          theme: "light",
                        }
                      );
                    }
                  })
                  .catch((error) => console.error("Error:", error));
              } else {
                console.log("video empty");
              }
            }}
          >
            Відправити результати
          </Button>
        </LongTextTooltip>
      </ModalFooter>
    </div>
  );
}
