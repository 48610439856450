import { OptionType } from "@atlaskit/select";
import { statusAPI } from "API/Employees/status";
import { useState, useCallback, useEffect } from "react";
import { SetStatusModalProps } from "./SetStatusModal";
import { SubmitResult } from "interfaces/employee/statuses.interface";
import { SectionMessageData } from "interfaces/common/section-message.interface";
import { statusEmptyStateID } from "interfaces/common/status.interface";
import {
  ProfileModalChangeDateWithPolicyAccrual
} from "../../../../../../../interfaces/timeOffPolicy/profileModalChangeDateWithPolicyAccrual.interface";

export function useSetStatusModal(props: SetStatusModalProps) {
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<OptionType | null>(null);
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  const [submitResult, setSubmitResult] = useState<SubmitResult | null>(null);
  const [showToInactiveMessage, setShowToInactiveMessage] = useState(false);
  const [showToActiveWarning, setShowToActiveWarning] = useState(false);

  const handleChange = (date: string) => {
    setSelectedDate(date === "" ? undefined : date);
  };

  const closeModal = () => {
    setShowToInactiveMessage(false);
    setShowToActiveWarning(false);
    setSelectedStatus(null);
    setSelectedDate(undefined);
    props.closeModal();
  };

  const handleStatusChange = (status: OptionType | null) => {
    setShowToInactiveMessage(false);
    if (!status?.isActive && props.currentStatusIsActive) {
      setShowToInactiveMessage(true);
    }
    if (
      !props.currentStatusArchived &&
      props.currentOriginalStatusId !== statusEmptyStateID &&
      status?.isActive &&
      !props.currentStatusIsActive
    ) {
      setShowToActiveWarning(true);
    }
    setSelectedStatus(status);
  };
  const onSuccess = () => {
    props.onStatusUpdate();
    setSubmitResult("success");
    setShowSubmitMessage(true);
    setSelectedStatus(null);
    setSelectedDate(undefined);
    props.closeModal();
    const timeoutId = setTimeout(() => setShowSubmitMessage(false), 5000);
    return () => clearTimeout(timeoutId);
  };

  const onFail = () => {
    setSubmitResult("error");
    setShowSubmitMessage(true);
    const timeoutId = setTimeout(() => setShowSubmitMessage(false), 5000);
    return () => clearTimeout(timeoutId);
  };

  const handleAddStatus = async () => {
    if (selectedStatus && selectedDate) {
      try {
        const response = await statusAPI.setUserStatus(
          props.employeeId,
          selectedStatus.value,
          selectedDate
        );
        if (response.ok) {
          onSuccess();
        } else {
          onFail();
        }
      } catch (error) {
        onFail();
      } finally {
        setShowToInactiveMessage(false);
        setShowToActiveWarning(false);
      }
    }
  };

  const handleEditStatus = async () => {
    if (selectedDate && props.lastStatusId) {
      try {
        const response = await statusAPI.updateUserStatus(
          props.employeeId,
          props.lastStatusId,
          selectedDate
        );
        if (response.ok) {
          const policies = await response.json();
          if (props.setPolicies && Object.keys(policies).length > 0) {
            props.setPolicies(Object.values(policies) as ProfileModalChangeDateWithPolicyAccrual[]);
          }
          onSuccess();
        } else {
          onFail();
        }
      } catch (error) {
        onFail();
      } finally {
        setShowToInactiveMessage(false);
        setShowToActiveWarning(false);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.mode === "add") {
      handleAddStatus();
    } else {
      handleEditStatus();
    }
  };

  type ActionStatus = { [key in SubmitResult]: SectionMessageData };
  const submitMessages: {
    [key: string]: ActionStatus;
  } = {
    add: {
      success: {
        appearance: "success",
        title: `You’ve added the status`,
        text: `If you need to perform additional actions in the employee's card or 
                                      elsewhere, don't forget to do so.`,
      },
      error: {
        appearance: "error",
        title: `Failed to add the status`,
        text: `There was an issue while adding the status. Please try again later or contact support if the issue persists.`,
      },
    },
    edit: {
      success: {
        appearance: "success",
        title: `You’ve changed the date of status`,
        text: ``,
      },
      error: {
        appearance: "error",
        title: `Failed to change the date of status`,
        text: `There was an issue while changing the date of the status. Please try again later or contact support if the issue persists.`,
      },
    },
  };

  return {
    showSubmitMessage,
    selectedStatus,
    showToActiveWarning,
    showToInactiveMessage,
    submitMessages,
    selectedDate,
    setSelectedDate,
    handleSubmit,
    closeModal,
    handleChange,
    handleStatusChange,
    submitResult,
    setPolicies: props.setPolicies,
  };
}
