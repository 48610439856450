import {SerializedStylesType} from 'types'

export const tableRowConfig: SerializedStylesType[] = [
  {
    width: '116px',
    height: '24px',
    marginLeft: 'space.100',
  },
  {
    width: '57px',
    height: '24px',
    marginLeft: 'space.100',
  },
  {
    width: '556px',
    height: '24px',
    marginLeft: 'space.100',
  },
  {
    width: '192px',
    height: '24px',
    marginLeft: 'space.100',
  },
  {
    width: '100px',
    height: '24px',
    marginLeft: 'space.100',
  },
]