import { useState } from "react";
import { adminStatusAPI } from "API/common/status";

interface AddStatusProps {
  name: string;
  onSuccess: () => void;
}

const useAddStatus = () => {
  const [error, setError] = useState<string | null>(null);

  const addStatus = async ({ name, onSuccess }: AddStatusProps) => {
    try {
      const updateStatusResponse = await adminStatusAPI.addStatus(name, false);
      if (!updateStatusResponse.ok) {
        throw new Error(`HTTP error! status: ${updateStatusResponse.status}`);
      }
      onSuccess();
    } catch (error) {
      setError((error as Error).message);
    } finally {
    }
  };

  return { addStatus, error };
};

export default useAddStatus;
