import { useState, useEffect } from "react";

const useShowErrorMessages = (deleteError: any) => {
  const [showErrorMessages, setShowErrorMessages] = useState(false);

  useEffect(() => {
    if (deleteError) {
      if (deleteError.statusCode === 422) {
        setShowErrorMessages(true);
        const timeoutId = setTimeout(() => {
          setShowErrorMessages(false);
        }, 5000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [deleteError]);

  return { showErrorMessages };
};

export default useShowErrorMessages;
