import { useState } from "react";
import { IconButton } from "@atlaskit/button/new";
import { Grid, xcss } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import Popup from "@atlaskit/popup";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { Link } from "react-router-dom";
import styled from "styled-components";

const contentStyles = xcss({
  minWidth: "277px",
  width: "min-content",
  padding: "space.200",
  color: "color.text",
  fontSize: "11px",
});

const StyledLink = styled.span`
  & > a {
    color: ${token("color.text.subtle")};
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
`;

const StyledBtnWrap = styled.div`
  & > button {
    color: ${token("color.icon.accent.gray")};

    &[aria-expanded="true"] {
      background: ${token("color.background.selected")};
      color: ${token("color.icon.selected")};
    }
  }
`;

const TableStatusPopup = ({
  comment,
  fullname,
  status,
  id,
}: {
  comment: string;
  fullname: string;
  status: string;
  id: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-end"
      content={() => (
        <Grid xcss={contentStyles} gap="space.100" templateColumns="70px 1fr">
          <span>
            {capitalizeFirstLetter(
              status.toLowerCase() !== "new"
                ? status.toLowerCase() + " by:"
                : fullname
                  ? "created by:"
                  : ""
            )}{" "}
          </span>
          <StyledLink>
            <Link to={"/employee/" + id}>{fullname}</Link>
          </StyledLink>
          {comment && (
            <>
              <span>Comment:</span>
              <span>{comment}</span>
            </>
          )}
        </Grid>
      )}
      trigger={(triggerProps) => (
        <StyledBtnWrap>
          <IconButton
            {...triggerProps}
            label="Info"
            appearance="subtle"
            spacing="compact"
            isSelected={isOpen}
            icon={EditorPanelIcon}
            onClick={() => setIsOpen(!isOpen)}
          ></IconButton>
        </StyledBtnWrap>
      )}
    />
  );
};

export default TableStatusPopup;
