import {FC} from "react";
import cn from "classnames";
import style from "./BlockInfo.module.scss";

import { SkeletonHeadingItem } from '@atlaskit/menu';

export const BlockInfoSkeletonItem: FC = () => {
    return (
        <tr className={style.rowContent}>
            <td className={cn(style.titleItem, style.skeleton, style.skeletonTitle)}><SkeletonHeadingItem isShimmering /></td>
            <td className={cn(style.contentItem, style.skeleton)}><SkeletonHeadingItem isShimmering /></td>
        </tr>
    );
};