import React from 'react';
import  { PUSH_NOTIFICATIONS } from '../../../../../utils/constants/api';
import ConfirmDeleteModal from '../../../Layer/Modal/ConfirmDeleteModal';
import { PushNotificationFromApi } from '../../../../../shared/PushNotification/data/PushNotificationFromApi';

type DeletePushNotification = {
    opened: boolean,
    onOk: () => void,
    onClose: () => any,
    pushNotification: PushNotificationFromApi
};

function DeletePushNotification(props: DeletePushNotification) {
    function deleteTitle(): void {
        fetch(PUSH_NOTIFICATIONS + '/' + props.pushNotification.id, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    props.onOk();
                } else {
                    response.json().then();
                }
            });
    }

    return (
        <ConfirmDeleteModal
            opened={ props.opened }
            modalTitle='Delete Push Notification'
            cancelText='Cancel'
            deleteText={'<span>&laquo;' + props.pushNotification.value.subject + '&raquo;</span>'}
            okText='Delete'
            onCancel={ props.onClose }
            onOk={ deleteTitle }
        />
    )
}

export default DeletePushNotification;