import { token } from "@atlaskit/tokens";
import styled from "styled-components";

export const AnnouncementsTableStyles = styled.div`
  padding-bottom: 20px;

  table {
    border-spacing: 0 ${token("space.100")};

    thead {
      th {
        height: 30px;
        padding: ${token("space.050")} ${token("space.100")};
        vertical-align: middle;

        &:hover {
          background-color: transparent;
        }

        &:first-of-type {
          padding-left: ${token("space.100")};
        }

        &:last-of-type {
          padding-right: ${token("space.100")};
        }
      }
    }

    tbody {

      tr {
        vertical-align: top;
      }

      td {
        padding: 10px ${token("space.100")};
        color: ${token("color.text.subtlest")};

        &:first-of-type {
          padding-inline-start: ${token("space.100")};
        }
      }
    }
  }
`