import React, {FC} from 'react';
import style from './Auth.module.scss';
import {Outlet} from 'react-router-dom';

export const Auth: FC = () => {
    return (
        <div className={style.bg}>
            <div className={style.bgC}></div>
            <div className={style.content}>
                <div className={style.contentBlock}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};