import React, { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useChangePassword } from '../../../API/auth/useChangePassword';
import { useValidationRules } from '../../../API/auth/useValidationRules';

import { ErrorMessage, HelperMessage } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';

import { resetPasswordValidationRules } from 'utils/constants/resetPasswordValidationRules';
import InfoIcon from '../../../assets/images/resetPassword/info.svg';
import { AuthLayout } from '../AuthLayout';
import { SuccessChangePassword } from './SuccessChangePassword';
import ValidationRules from './ValidationRules/ValidationRules';
import { ErrorChangePassword } from './ErrorChangePassword';
import { Button } from '../../UI/Button';
import { PasswordInput } from '../../UI/PasswordInput';

import styleAuth from '../Auth.module.scss';
import style from './ResetPassword.module.scss';

interface ResetPasswordFormFields {
    newPassword?: string;
    confirmedPassword?: string;
}

interface ResetPasswordProps {
    isResetPassword?: boolean;
}

export const ResetPassword: FC<ResetPasswordProps> = ({ isResetPassword = true }) => {
    const [searchParams] = useSearchParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        getValues,
    } = useForm<ResetPasswordFormFields>({
        mode: 'all',
        defaultValues: {
            newPassword: '',
            confirmedPassword: '',
        },
    });

    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [isBeforeCheck, setIsBeforeCheck] = useState(true);
    const [focusedField, setFocusedField] = useState<string | null>(null);

    const code = searchParams.get('code') || '';
    const { isLoading, isSuccess, isError, changePassword } = useChangePassword(code);
    const { ruleSets, validateFields, isValid } = useValidationRules(resetPasswordValidationRules);

    const handleKeyUp = (fieldName: string, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (fieldName === 'newPassword') {
            validateFields(fieldName, event.currentTarget.value, isBeforeCheck);
        }
        if (event.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    };

    const onSubmit: SubmitHandler<ResetPasswordFormFields> = async (values) => {
        const isValid = ruleSets.every((ruleSet) => {
            const { name, rules } = ruleSet;
            const fieldValue = values[name as keyof ResetPasswordFormFields] || '';
            return rules.every((rule) => rule.status === 'success') && fieldValue;
        });

        const passwordsMatch = values.newPassword === values.confirmedPassword;

        if (isValid && passwordsMatch) {
            changePassword(values.newPassword || '', values.confirmedPassword || '');
        } else {
            if (!passwordsMatch) {
                setError('confirmedPassword', {
                    type: 'manual',
                    message: "Passwords don't match",
                });
            }

            if (!values.newPassword && !values.confirmedPassword) {
                setError('confirmedPassword', {
                    type: 'manual',
                    message: "Please fill out all fields before submitting your password change request",
                });
            }
        }
    };

    const handleBlur = () => {
        const newPassword = getValues('newPassword');
        const confirmedPassword = getValues('confirmedPassword');
    
        if (newPassword !== confirmedPassword) {
            setError('confirmedPassword', {
                type: 'manual',
                message: "Passwords don't match",
            });
        }
    };

    const handleBlurNewPassword = () => {
        const newPassword = getValues('newPassword');

        if(newPassword) {
            setIsBeforeCheck(false);
            validateFields('newPassword', newPassword, false);
        }
    };

    if (isSuccess) {
        return <SuccessChangePassword isResetPassword={isResetPassword} />;
    }

    if (isError) {
        return <ErrorChangePassword isResetPassword={isResetPassword} />;
    }

    return (
        <AuthLayout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h2 className={style.title}>{isResetPassword ? 'Reset Password' : 'Set Password'}</h2>
                    <p className={style.description}>Please create a new password</p>
                </div>
                <div className={style.fieldsWrapper}>
                    <div className={styleAuth.inputContainer}>
                        <PasswordInput
                            {...register('newPassword')}
                            name="newPassword"
                            label="New Password"
                            placeholder="Enter password"
                            isInvalid={!!errors.newPassword?.message || !isValid}
                            onBlur={handleBlurNewPassword}
                            onKeyUp={(event) => handleKeyUp('newPassword', event)}
                            onFocus={() => setFocusedField('newPassword')}
                        />
                        {isCapsLockOn && focusedField === 'newPassword' && (
                            <div className={style.infoWrapper}>
                                <img src={InfoIcon} width={16} height={16} alt="info" className={style.infoImage} />
                                <HelperMessage>Caps Lock is on</HelperMessage>
                            </div>
                        )}
                        <ValidationRules rules={ruleSets.find((ruleSet) => ruleSet.name === 'newPassword')?.rules || []} />
                    </div>

                    <div className={styleAuth.inputContainer}>
                        <PasswordInput
                            {...register('confirmedPassword')}
                            name="confirmedPassword"
                            label="Confirm password"
                            placeholder="Re-enter Password"
                            isInvalid={!!errors.confirmedPassword?.message}
                            onKeyUp={(event) => handleKeyUp('confirmedPassword', event)}
                            onFocus={() => setFocusedField('confirmedPassword')}
                            onBlur={handleBlur}
                        />
                          {isCapsLockOn && focusedField === 'confirmedPassword' && (
                            <div className={style.infoWrapper}>
                                <img src={InfoIcon} width={16} height={16} alt="info" className={style.infoImage} />
                                <HelperMessage>Caps Lock is on</HelperMessage>
                            </div>
                        )}
                        {errors.confirmedPassword && <ErrorMessage>{errors.confirmedPassword.message}</ErrorMessage>}
                    </div>
                </div>

                <div className={styleAuth.sendWrapper}>
                    {isLoading ? (
                        <LoadingButton appearance="primary" isLoading className={styleAuth.sendBtn}>
                            Loading...
                        </LoadingButton>
                    ) : (
                        <Button appearance="primary" type="submit" className={styleAuth.sendBtn}>
                            {isResetPassword ? 'Reset password' : 'Set password'}
                        </Button>
                    )}
                </div>
            </form>
        </AuthLayout>
    );
};
