import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router/createRouter";
import reportWebVitals from "./reportWebVitals";
import MomentTimezone from "moment-timezone";
import { TIMEZONE } from "./utils/constants/projectSettingConstants";
import AppProvider from "@atlaskit/app-provider";
import { worker } from "./mocks/browser";

import "@atlaskit/css-reset";
import "react-toastify/dist/ReactToastify.min.css";
import "material-design-icons/iconfont/material-icons.css";
import "material-symbols/index.css";
import "../src/assets/scss/index.scss";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "1.0"
  ),
  tracePropagationTargets: [
    new RegExp(
      process.env.REACT_APP_TRACE_PROPAGATION_TARGETS ||
        "^https://prod.corehr.io/api"
    ),
  ],
  profilesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_PROFILES_SAMPLE_RATE || "1.0"
  ),
  replaysSessionSampleRate: parseFloat(
    process.env.REACT_APP_REPLAYS_SESSION_SAMPLE_RATE || "0.1"
  ),
  replaysOnErrorSampleRate:
    parseFloat(process.env.REACT_APP_REPLAYS_ON_ERROR_SAMPLE_RATE || "1.0"),

  beforeSend(event, hint) {
    const allowedDomains = (
      process.env.REACT_APP_ALLOWED_DOMAINS || "prod.corehr.io"
    ).split(",");

    if (event.request && event.request.url) {
      const url = new URL(event.request.url);
      if (!allowedDomains.includes(url.hostname)) {
        return null;
      }
    }

    return event;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
MomentTimezone.tz.setDefault(TIMEZONE);
let shouldRender = true;

// Переопределяем fetch для того, чтобы CORS работал корректно
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  const originalFetch = window.fetch;

  window.fetch = function (input, init) {
    init = init || {};

    if (!init.credentials) {
      init.credentials = "include";
    }

    return originalFetch(input, init);
  };
}


if (process.env.REACT_APP_MODE === "be-detached") {
  worker.start();
  root.render(
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
  shouldRender = false;
}

if (shouldRender) {
  root.render(
    // Temporary disabled React.StrictMode due to the issue with ADS components
    // <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<h1>Ooops, something went wrong!</h1>}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </Sentry.ErrorBoundary>
    // </React.StrictMode>
  );
}

reportWebVitals();
