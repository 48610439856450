import { token } from "@atlaskit/tokens";
import styled from "styled-components";
import { xcss } from "@atlaskit/primitives";

export const StyledTitle = styled.h1`
  color: ${token("color.text")};
  font-size: 29px;
  font-weight: 500;
  line-height: 32px;
`;

export const StyledTabsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${token("space.200")};

  button > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 ${token("space.100")};

    .small-icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }
`;

export const StyledTable = styled.div`
  color: ${token("color.text.subtlest")};

  [aria-roledescription*="Sort"] {
    color: ${token("color.text.subtle")};
  }

  table {
    border-spacing: 0 ${token("space.100")};

    [class*="TableBodyCell"] {
      padding: ${token("space.100")};
    }

    td:first-child,
    th:first-child {
      padding-left: ${token("space.100")};
    }

    td:last-child,
    th:last-child {
      padding-right: ${token("space.100")};
    }
  }
`;

interface Amount {
  balance: string;
}

const setBackgroundColor = (balance: string) => {
  switch (balance) {
    case "positive":
      return token("color.background.success");
    case "negative":
      return token("color.background.danger");
    default:
      return token("color.background.neutral");
  }
};

const setTextColor = (balance: string) => {
  switch (balance) {
    case "positive":
      return token("color.text.success");
    case "negative":
      return token("color.text.danger");
    default:
      return token("color.text");
  }
};

export const StyledAmount = styled.span<Amount>`
  display: inline-flex;
  padding: ${token("space.025")} ${token("space.075")};
  align-items: center;
  border-radius: 8px;
  background: ${(props) => setBackgroundColor(props.balance)};
  color: ${(props) => setTextColor(props.balance)};
  text-align: center;
  font-size: 12px;
  line-height: 12px;
`;

export const UserBlock = xcss({
  height: "40px",
  gap: "space.100",
  alignItems: "center",
  color: "color.text.subtlest",
});

export const StyledUserBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;

  & > a {
    color: ${token("color.text")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  & > span {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;
