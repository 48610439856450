export const changeUserPassword = async (resetCode: string, newPassword: string, confirmedPassword: string) => {
    try {
        const response = await fetch(`/api/employees/password?reset_code=${resetCode}`, {
            method: 'PUT',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                newPassword: newPassword,
                confirmedPassword: confirmedPassword
            })
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }
    } catch (error) {
        console.error(error)
        throw new Error();
    }
};