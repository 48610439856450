import {AUTH} from "../../utils/constants/api";

export const signIn = async (email: string, password: string) => {
    try {
        const response = await fetch(AUTH, {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                email: email,
                password: password
            })
        });

        if (!response.ok || response.status !== 200) {
            throw new Error();
        }

        return await response.json();
    } catch (error) {
        alert('Wrong email or password');
        console.error(error)
        throw new Error();
    }
};