import {EMPLOYEES} from "../../utils/constants/api";

export const checkOut = async (employeeId: string) => {
    try {
        const response = await fetch(EMPLOYEES + '/' + employeeId + '/attendance/check-out', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                employeeId: employeeId,
                type: 'check_out',
                actionEmployeeId: employeeId
            })
        });

        if (!response.ok) throw new Error(); //TODO:
    } catch (error) {
        console.error(error);
        throw new Error();
    }
};