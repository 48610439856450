import {TransformJobTitles} from './jobTitles.types';
import {useState} from "react";
import {fetchCurrentJobTitle as fetchCurrentJobTitleAPI, fetchJobTitles as fetchJobTitlesAPI} from './jobTitles';
import {useEvent} from "../../../../../customHooks/useEvent";
import {useQuery} from "../../../../useQuery";

export const useJobTitles = () => {
    const [jobTitles, setJobTitles] = useState<Array<TransformJobTitles>>([]);
    const [getAllJobTitles, isLoading, isSuccess, isError] = useQuery(fetchJobTitlesAPI);

    const [currentJobTitle, setCurrentJobTitle] = useState<TransformJobTitles>();
    const [getCurrentJobTitle, isLoadingCurrent, isSuccessCurrent, isErrorCurrent] = useQuery(fetchCurrentJobTitleAPI);

    const fetchJobTitles = useEvent(async () => {
        return getAllJobTitles()
            .then((response) => {
                setJobTitles(response);
                return response;
            });
    });

    const fetchCurrentJobTitle = useEvent((id: string) => {
        return getCurrentJobTitle(id).then((response) => {
            setCurrentJobTitle(response[0]);
            return response[0];
        });
    });

    return {
        jobTitles: jobTitles,
        fetchJobTitles: fetchJobTitles,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,

        currentJobTitle: currentJobTitle,
        fetchCurrentJobTitle: fetchCurrentJobTitle
    }
}