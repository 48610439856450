import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import Popup from "@atlaskit/popup";
import CalendarIcon from "@atlaskit/icon/glyph/calendar";
import style from "./FilterCalendar.module.scss";
import styleBTN from "../../AttendanceLog/AttendanceLog.module.scss";
import { Button } from "../Button";
import {
  Calendar,
  DayRange,
} from "@hassanmojab/react-modern-calendar-datepicker";

import {
  defaultRangeAttendanceLog,
  intendedRanges,
  localeCalendar,
} from "./FilterCalendar.constants";
import { dayObjToString } from "./FilterCalendar.functions";

const ReloadCalendar: FC<{
  setShowCalendar: Dispatch<SetStateAction<boolean>>;
}> = ({ setShowCalendar }) => {
  useEffect(() => {
    setShowCalendar(true);
  }, []);

  return <div className={style.calendarVoid} />;
};

interface FilterCalendarProps {
  defaultRange: typeof defaultRangeAttendanceLog;
  setDateRange: Dispatch<SetStateAction<DayRange>>;
  onClose?: () => void;
}

export const FilterCalendar: FC<FilterCalendarProps> = ({
  defaultRange,
  setDateRange,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);
  const [rangeName, setRangeName] = useState<string>(defaultRange.name);
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>(
    defaultRange.range
  );

  useEffect(() => {
    setSelectedDayRange(defaultRange.range);
  }, [defaultRange]);

  useEffect(() => {
    if (selectedDayRange.to && selectedDayRange.from) {
      setDateRange(selectedDayRange);
    }
    // if (!selectedDayRange.to) {
    //   setDateRange({
    //     from: selectedDayRange.from,
    //     to: selectedDayRange.from,
    //   });
    //   return;
    // }
  }, [selectedDayRange.to, selectedDayRange.from]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);

        if (!selectedDayRange.to) {
          setDateRange({
            from: selectedDayRange.from,
            to: selectedDayRange.from,
          });
          return;
        }

        setDateRange(selectedDayRange);

        if (onClose) {
          onClose();
        }
      }}
      placement="bottom-start"
      content={() => {
        return (
          <div className={style.calendarWrapper}>
            <div className={style.btnSide}>
              <ul className={style.btnList}>
                {intendedRanges.map(({ range, name }, index) => {
                  return (
                    <li key={index}>
                      <Button
                        appearance="subtle"
                        shouldFitContainer
                        isSelected={rangeName === name}
                        onClick={() => {
                          setSelectedDayRange(range);
                          setRangeName(name);
                          setShowCalendar(false);
                        }}
                      >
                        {name}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
            {showCalendar ? (
              <div>
                <Calendar
                  value={selectedDayRange}
                  locale={localeCalendar}
                  onChange={(dateRange) => {
                    setRangeName("Custom");
                    setSelectedDayRange(dateRange);
                    console.log(dateRange);
                  }}
                />
              </div>
            ) : (
              <ReloadCalendar setShowCalendar={setShowCalendar} />
            )}
          </div>
        );
      }}
      trigger={(triggerProps) => (
        <Button
          {...triggerProps}
          isSelected={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          appearance="subtle"
          iconBefore={<CalendarIcon primaryColor="#44546F" label="Date" />}
          className={styleBTN.dateBtn}
        >
          <div className={styleBTN.dateWrap}>
            <span className={styleBTN.dateType}>{rangeName}</span>
            <span className={styleBTN.dateRange}>
              {selectedDayRange.from && dayObjToString(selectedDayRange.from)} -{" "}
              {selectedDayRange.to && dayObjToString(selectedDayRange.to)}
            </span>
          </div>
        </Button>
      )}
    />
  );
};
