import Button from "@atlaskit/button/new";
import { token } from "@atlaskit/tokens";
import styled from "styled-components";
import { ReactComponent as Stars } from "../../../../assets/images/ProfileIcons/Stars.svg";
import { Box, xcss } from "@atlaskit/primitives";
import { ReactNode, FC, useState, useEffect } from "react";
import Popup from "@atlaskit/popup";
import Tooltip from "@atlaskit/tooltip";
import { INTERVIEWS, EMPLOYEES } from "../../../../utils/constants/api";
import { useEmployee } from "../../../../contexts/EmployeeContext";
import AIModal from "../../../../components/Profile/ShortInformationCard/TimeOffBalance/AIModal/AIModal";
import ModalContentWithAIButton from "./ModalContentWithAIButton";
import ModalContentNoAIButton from "./ModalContentNoAIButton";
import { useSelector, useDispatch,} from 'react-redux'
import {increment} from "../../../../store/rootSlice";
import { RootState} from "../../../../store/store";

const StarButtonWrapper: FC<{
  children: ReactNode;
}> = styled.div`
  margin-right: ${token("space.150")};

  button {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button[aria-expanded="true"] {
    svg path {
      fill: ${token("color.icon.selected")};
    }
  }
  button[aria-expanded="false"] {
    background-color: ${token("color.background.neutral.hovered")};
    svg path {
      fill: ${token("color.icon.accent.purple")};
    }
  }
  button span {
    display: flex;
    justify-content: center;
  }
`;

export type TDirectManager = {
  firstNameEn: string;
  id: string;
  image: string;
  lastNameEn: string;
  nickname: string;
};

export default function AIStatusButton() {
  const rootReducer = useSelector((state:RootState) => state.rootReducer);
  const dispatch = useDispatch();
  const { employee, signIn } = useEmployee();
  const [isOpen, setIsOpen] = useState(false);
  const [interviewsID, setInterviewsID] = useState("");
  const [modalContent, setModalContent] = useState(1);
  const [AIOpen, setAIOpen] = useState(false);
  const [lastInterview, setLastInterview] = useState(null);

  const [directManager, setDirectManager] = useState<TDirectManager | null>(
    null
  );

  useEffect(() => {
    fetch(INTERVIEWS + "/last")
      .then((res) => {
        if (res) {
          return res.json();
        }
      })
      .then((data) => {
        if (data) {
          setLastInterview(data);
        }
      });
  }, [isOpen]);

  useEffect(() => {
    if (employee) {
      fetch(EMPLOYEES + "/" + employee.id + "/interviews/open-request")
        .then((res) => res.json())
        .then((data) => {
          if (typeof data === "string") {
            setInterviewsID(data);
          } else {
            setInterviewsID("");
          }
        });
    }
  }, [modalContent]);

  useEffect(() => {
    if (employee) {
      fetch(EMPLOYEES + "/employee/" + employee.id + "/direct-manager")
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setDirectManager(data);
        });
    }
  }, [employee]);

  return (
    <>
      <Popup
        offset={[-184, 8]}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom-start"
        content={() => (
          <Box
            xcss={xcss({
              padding: "space.300",
            })}
          >
            <Box
              xcss={xcss({
                color: "color.text",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                paddingBottom: "space.300",
              })}
            >
              Personality analysis by AI
            </Box>

            {directManager && !interviewsID && !lastInterview && (
              <ModalContentNoAIButton directManager={directManager} />
            )}

            {lastInterview && (
              <ModalContentWithAIButton
                directManager={directManager}
                employee={employee}
                AIOpen={AIOpen}
                interviewsID={interviewsID}
                setAIOpen={(val: boolean) => {
                  setAIOpen(val);
                  setIsOpen(val);
                }}
                lastInterview={lastInterview}
              />
            )}
          </Box>
        )}
        trigger={(triggerProps) =>
          isOpen ? (
            <StarButtonWrapper>
              <Button
                {...triggerProps}
                appearance="primary"
                isSelected={isOpen}
                onClick={() => setIsOpen(!isOpen)}
              >
                <Stars />
              </Button>
            </StarButtonWrapper>
          ) : (
            <Tooltip position={"bottom"} content="AI personality analysis">
              <StarButtonWrapper>
                <Button
                  {...triggerProps}
                  appearance="primary"
                  isSelected={isOpen}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Stars />
                </Button>
              </StarButtonWrapper>
            </Tooltip>
          )
        }
      />
      
      {employee && (
        <AIModal
          interviesID={interviewsID}
          setContent={setModalContent}
          content={modalContent}
          isOpen={AIOpen}
          setIsOpen={(val) => {
            setAIOpen(val);
            setIsOpen(val);
            dispatch(increment());
          }}
        />
      )}
    </>
  );
}
