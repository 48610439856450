import React, { useState, useEffect, FC } from "react";
import moment from "moment";
import { Popover } from "@headlessui/react";

import CreateRandomPhrase from "./Modal/CreateRandomPhrase";
import EditRandomPhrase from "./Modal/EditRandomPhrase";
import DeleteRandomPhrase from "./Modal/DeleteRandomPhrase";
import Button, { Size, Type } from "../../../Component/Button/Button";

import { MultiSelect, Option } from "react-multi-select-component";
import { getGMTOffsetByTimezoneName } from "../../../Common/Timezones/Services";

import { RandomPhraseFromApi } from "../../../../shared/RandomPhrase/data/RandomPhraseFromApi";
import { LocationFromApi } from "../Locations/Locations";
import { SelectValue } from "../../../../shared/Common/data/SelectValue";

import {
  ROLE_ADMIN,
  ROLE_CONTENT_MANAGER,
} from "../../../../shared/Security/constants/AccessLevels";
import { RANDOM_PHRASES, LOCATIONS } from "../../../../utils/constants/api";

import "./RandomPhrases.scss";

import {
  useEmployee,
  assertEmployee,
} from "../../../../contexts/EmployeeContext";

export const RandomPhrases: FC = () => {
  const { employee } = useEmployee();
  assertEmployee(employee);
  const [locations, setLocations] = useState<LocationFromApi[]>([]);
  const [locationOptions, setLocationOptions] = useState<SelectValue[]>([]);

  const [createRandomPhraseModal, setCreateRandomPhraseModal] =
    useState<boolean>(false);
  const [editRandomPhraseModal, setEditRandomPhraseModal] =
    useState<boolean>(false);
  const [deleteRandomPhraseModal, setDeleteRandomPhraseModal] =
    useState<boolean>(false);
  const [randomPhrases, setRandomPhrases] = useState<RandomPhraseFromApi[]>([]);
  const [randomPhraseToChange, setRandomPhraseToChange] =
    useState<RandomPhraseFromApi | null>(null);
  const [randomPhraseToDelete, deleteRandomPhrase] =
    useState<RandomPhraseFromApi | null>(null);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState<
    SelectValue[]
  >([]);

  const [dailyChecked, setDailyChecked] = useState(true);
  const [specialChecked, setSpecialChecked] = useState(true);

  function handleCheckboxChange(event: {
    target: {
      id: string;
      checked: boolean | ((prevState: boolean) => boolean);
    };
  }) {
    if (event.target.id === "daily-checkbox") {
      setDailyChecked(event.target.checked);
    } else if (event.target.id === "special-checkbox") {
      setSpecialChecked(event.target.checked);
    }
    getRandomPhrases();
  }

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getRandomPhrases();
  }, [selectedLocationOptions, dailyChecked, specialChecked]);

  const [sortByName, setSortByName] = useState<string | null>(null);
  function handleSortClick() {
    if (sortByName === null || sortByName === "desc") {
      setSortByName("asc");
      randomPhrases.sort((a, b) => compareStrings(a, b));
    } else if (sortByName === "asc") {
      setSortByName("desc");
      randomPhrases.sort((a, b) => compareStrings(b, a));
    }
  }

  function filterRandomPhrasesByLocation(
    randomPhrases: RandomPhraseFromApi[],
    selectedLocationOptions: SelectValue[]
  ): RandomPhraseFromApi[] {
    const locationArr = getValuesFromSelectValues(selectedLocationOptions);
    return randomPhrases.filter((randomPhrase) =>
      randomPhrase.locations.some((location) => locationArr.includes(location))
    );
  }

  function getValuesFromSelectValues(selectValues: SelectValue[]): string[] {
    return selectValues.map((selectValue) => selectValue.value);
  }

  function compareStrings(a: RandomPhraseFromApi, b: RandomPhraseFromApi) {
    return a.text.localeCompare(b.text, undefined, { sensitivity: "base" });
  }

  async function getLocations() {
    const response = await fetch(LOCATIONS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    });
    const locations = await response.json();
    setLocations(locations);

    const locationOptions = locations.map((location: { id: string }) => {
      let locationName = "";
      locations.map((locationValue: LocationFromApi) => {
        if (locationValue.id === location.id) {
          locationName =
            locationValue.name +
            " " +
            getGMTOffsetByTimezoneName(locationValue.value.timezone);
        }
      });
      return {
        label: locationName,
        value: location.id,
      };
    });

    setLocationOptions(locationOptions);
    setSelectedLocationOptions(locationOptions);
  }

  function getRandomPhrases() {
    fetch(RANDOM_PHRASES, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((randomPhrases: RandomPhraseFromApi[]) =>
        setRandomPhrases(
          filterRandomPhrasesByLocation(randomPhrases, selectedLocationOptions)
        )
      );
  }

  function showRandomPhrase({ randomPhrase }: { randomPhrase: any }): void {
    fetch(RANDOM_PHRASES + "/" + randomPhrase.id, {
      method: "PUT",
      body: JSON.stringify({
        text: randomPhrase.text,
        locations: randomPhrase.locations,
        visible: !randomPhrase.visible,
      }),
    }).then((response) => {
      if (response.ok) {
        getRandomPhrases();
      } else {
        response.json().then((jsonData) => alert(jsonData.error));
      }
    });
  }

  function editRandomPhraseModalVisibility(
    randomPhrase: RandomPhraseFromApi
  ): void {
    setRandomPhraseToChange(randomPhrase);
    setEditRandomPhraseModal(true);
  }

  function confirmDeleteRandomPhraseModalVisibility(
    randomPhrase: RandomPhraseFromApi
  ): void {
    deleteRandomPhrase(randomPhrase);
    setDeleteRandomPhraseModal(true);
  }

  // Access levels
  const roles = [ROLE_ADMIN, ROLE_CONTENT_MANAGER] as typeof employee.roles;
  const hasRole = roles.some((role) => employee.roles.includes(role));

  return (
    <div className={"admin-panel-board"}>
      <div className="sub-content-block d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Random Phrases</h3>
          </div>
          <div className="sub-header-filter-block align-items-center d-flex">
            <div>
              <p>Locations:</p>
            </div>
            <div className="filter">
              <MultiSelect
                options={locationOptions as Option[]}
                hasSelectAll={true}
                shouldToggleOnHover={false}
                disableSearch={true}
                value={selectedLocationOptions as Option[]}
                disabled={false}
                onChange={setSelectedLocationOptions}
                labelledBy={"All locations"}
                className={"multi-select-with-checkboxes"}
                overrideStrings={{
                  allItemsAreSelected: "All locations",
                  selectSomeItems: "Select locations",
                }}
              />
            </div>
          </div>
          {hasRole ? (
            <div className="add-button-block">
              <Button
                text="<i className='material-icons add-item'>add</i> Add phrase"
                size={Size.Medium}
                type={Type.Accent}
                onClick={() => setCreateRandomPhraseModal(true)}
              />
            </div>
          ) : null}
        </div>
        {randomPhrases.length > 0 && (
          <div className="sub-content-main">
            <table className="random-phrases-table">
              <thead>
                <tr>
                  <th>
                    <div>
                      <span>Random Phrase</span>
                      <i onClick={handleSortClick} className={"material-icons"}>
                        {sortByName === "desc"
                          ? "arrow_drop_up"
                          : "arrow_drop_down"}
                      </i>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {randomPhrases.map((randomPhrase) => {
                  return (
                    <tr key={randomPhrase.id}>
                      <td>{randomPhrase.text}</td>
                      <td>
                        {hasRole && (
                          <>
                            <i
                              className="material-icons"
                              onClick={() =>
                                editRandomPhraseModalVisibility(randomPhrase)
                              }
                            >
                              edit
                            </i>
                            <i
                              className="material-icons"
                              onClick={() =>
                                confirmDeleteRandomPhraseModalVisibility(
                                  randomPhrase
                                )
                              }
                            >
                              delete
                            </i>
                            <i
                              className="material-icons"
                              onClick={() =>
                                showRandomPhrase({ randomPhrase: randomPhrase })
                              }
                            >
                              {randomPhrase.visible
                                ? "visibility"
                                : "visibility_off"}
                            </i>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <CreateRandomPhrase
        locations={locations}
        opened={createRandomPhraseModal}
        onOk={() => {
          getRandomPhrases();
          setCreateRandomPhraseModal(false);
        }}
        onClose={() => setCreateRandomPhraseModal(false)}
      />
      {randomPhraseToDelete ? (
        <DeleteRandomPhrase
          opened={deleteRandomPhraseModal}
          onOk={() => {
            getRandomPhrases();
            setDeleteRandomPhraseModal(false);
            deleteRandomPhrase(null);
          }}
          onClose={() => {
            setDeleteRandomPhraseModal(false);
            deleteRandomPhrase(null);
          }}
          randomPhrase={randomPhraseToDelete}
        />
      ) : null}
      {randomPhraseToChange ? (
        <EditRandomPhrase
          locations={locations}
          opened={editRandomPhraseModal}
          onOk={() => {
            getRandomPhrases();
            setEditRandomPhraseModal(false);
            setRandomPhraseToChange(null);
          }}
          onClose={() => {
            setEditRandomPhraseModal(false);
            setRandomPhraseToChange(null);
          }}
          randomPhrase={randomPhraseToChange}
        />
      ) : null}
    </div>
  );
};
