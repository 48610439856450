import React, { FC } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ModalProvider } from "./application/Common/Context/ModalContext";
import { EmployeeContextProvider } from "./contexts/EmployeeContext";
import { App } from "./application/App";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";

export const ProvidersLoader: FC = () => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string}
    >
      <ModalProvider>
        <EmployeeContextProvider>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </EmployeeContextProvider>
      </ModalProvider>
    </GoogleOAuthProvider>
  );
};
