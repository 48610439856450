import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

export const LocationsListStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  .tag {
    display: inline-flex;
    height: 20px;
    padding: 0px ${token("space.050")};
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: ${token("color.background.neutral")};

    .tag-body {
      color: ${token("color.text")};
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`