import { FC } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { token } from "@atlaskit/tokens";
import { Inline } from "@atlaskit/primitives";
import {
  StyledText,
  StyledExitButton,
  StyledCancelButton,
} from "./StylesModalDeletePolicy";
import type {TimeOffPolicyResponseProps} from "../../../../API/timeOffPolicies/timeOffPolicy.types";
import {TypeIconsCollection} from "../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";

interface ModalDeletePolicyProps {
  policy: TimeOffPolicyResponseProps | null;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalDeletePolicy: FC<ModalDeletePolicyProps> = ({
  policy,
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} width="small" height={"208px"}>
          <ModalHeader>
            <ModalTitle>
              <Inline alignBlock="center" space="space.100">
                <ErrorIcon primaryColor={token("color.icon.danger")} label="" />
                Delete policy
              </Inline>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <StyledText>
              {TypeIconsCollection[policy?.typeIcon as keyof typeof TypeIconsCollection](
                  "large-icon",
                  "withouthover"
              )}
              <p>{policy ? policy.name : ''}</p>
            </StyledText>
          </ModalBody>
          <ModalFooter>
            <StyledCancelButton>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
            </StyledCancelButton>
            <StyledExitButton>
              <Button appearance="danger" onClick={onConfirm}>
                Delete
              </Button>
            </StyledExitButton>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ModalDeletePolicy;
