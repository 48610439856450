import Button from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import ErrorIcon from '@atlaskit/icon/glyph/error'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import Lozenge from '@atlaskit/lozenge';
import { announcementPriorityColorMap, announcementPriorityLabelMap, AnnouncementPriorityEnum } from 'utils/constants/announcements'
import Modal, { ModalFooter, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { LocationsList } from '../locations-list'
import { useDeleteAnnouncement } from './hooks' 
import { IDeleteAnnouncementModalProps } from './types';
import { DeleteAnnouncementModalBodyStyles, DeleteAnnouncementModalHeaderStyles } from './styles'

export const DeleteAnnouncementModal = (props: IDeleteAnnouncementModalProps) => {
  const { onClose, payload, onDelete  } = props

  const { deleteAnnouncement, loading } = useDeleteAnnouncement()

  if (!payload) return null

  const {startDate, endDate, text, priority, locations} = payload

  const handelDelete = async () => {
    try {
      await deleteAnnouncement(payload.id)
      onDelete(payload.id)
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ModalTransition>
      <Modal onClose={onClose} width={448}>
        <DeleteAnnouncementModalHeaderStyles>
          <ErrorIcon primaryColor={token('color.icon.danger')} size="large" label="" />
          <ModalTitle>
            Delete announcement
          </ModalTitle>
        </DeleteAnnouncementModalHeaderStyles>
        <DeleteAnnouncementModalBodyStyles>
          <div className="dates">
            <span className="label">Display dates:</span>
            <span className="value">
              {getFormattedDate(startDate, 'coreHRDateFormat')} - {getFormattedDate(endDate, 'coreHRDateFormat')}
            </span>
          </div>
          <div className='priority'>
            <span className="label">Priority:</span>
            <span className="value">
              <Lozenge appearance={announcementPriorityColorMap[priority as AnnouncementPriorityEnum]}>
                {announcementPriorityLabelMap[priority as AnnouncementPriorityEnum]}
              </Lozenge>
            </span>
          </div>
          <div>
            <span className="label">Announcement: {text}</span>
          </div>
          <div className='locations'>
            <span className="label">Location:</span>
            <LocationsList locations={locations} /> 
          </div>
        </DeleteAnnouncementModalBodyStyles>
        <ModalFooter>
          <Button onClick={onClose} appearance="subtle">
            Cancel
          </Button>
          <Button appearance="danger" onClick={handelDelete} isLoading={loading}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  )
}