import {FC, useEffect, useState} from "react";
import {useProfile} from "../../../context";
import {BlockInfoItem} from "../../../BlockInfo";
import cn from "classnames";
import style from "../../../Profile.module.scss";
import InlineEdit from "@atlaskit/inline-edit";
import {Input} from "../../../../UI/Input";
import {InputText} from "../../../ViewingModes/InputText";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import {Button} from "../../../../UI/Button";
import {useSetField} from "../../../../../API/profile/setField/useSetField";
import {EmergencyContactPhonesProps} from "../../../../../API/profile/emergencyContact/emergencyContact.types";
import {Tooltip} from '../../../../UI/Tooltip';
import {IconButton} from "@atlaskit/button/new";
import StarIcon from "@atlaskit/icon/glyph/star";
import Spinner from "@atlaskit/spinner";
import {ReactComponent as EditorRemoveIconNew} from '../../../../../assets/images/ProfileIcons/trash.svg';
import styleParent from "../../../Profile.module.scss";
import {useEmployee} from "../../../../../contexts/EmployeeContext";
import {formatPhoneNumber} from "../../../../../utils/helpers/EmployeeProfile/formatPhoneNumber";

export interface PhoneNumberProps {
    id: string;
    employeeId: string;
    phones: Array<EmergencyContactPhonesProps>;
    addPhoneAPI: (id: string, data: string) => Promise<any>;
    editPhoneAPI: (idContact: string, id: string, data: string) => Promise<any>;
    removePhoneAPI: (idContact: string, id: string) => void;
}

export const PhoneNumber: FC<PhoneNumberProps> = ({
    phones: phonesProps,
    id: idContact,
    employeeId,
    addPhoneAPI,
    editPhoneAPI,
    removePhoneAPI
}) => {
    const {isAccessEdit, emergencyContact, idOpenProfile, showErrorNotify} = useProfile();
    const [phones, setPhones] = useState(phonesProps);
    const [isMyProfile, setIsMyProfile] = useState<boolean | null>(null);
    const {employee} = useEmployee();

    const [otherErrorStateServ, setOtherErrorStateServ] = useState<{
        clearError?: () => void | undefined;
        error?: string | undefined;
        valueBeforeError: string;
    }[]>([]);

    useEffect(() => {
        setPhones(phonesProps);
    }, [phonesProps]);

    useEffect(() => {
        if (employeeId === employee?.id) {
            setIsMyProfile(true);
        } else {
            setIsMyProfile(false);
        }
    }, [employee]);

    return(
        <BlockInfoItem title="Phone number">
            {phones.length > 0 && phones.map(({phone, id}, index) => {
                const field = index;
                return (
                    <div className={cn(style.otherPhones, style.emergencyPhone)} key={`${id}${index}`}>
                        <div
                            className={cn(style.inputWrapper, (isAccessEdit || isMyProfile) && style.profileInputTextIsEditing)}>
                            <InlineEdit
                                {...(!isAccessEdit && {isEditing: false})}
                                {...(otherErrorStateServ[Number(field)]?.error && {isEditing: true})}
                                defaultValue={otherErrorStateServ[Number(field)]?.error ? otherErrorStateServ[Number(field)]?.valueBeforeError : formatPhoneNumber(phone)}
                                editView={({errorMessage, onChange, ...fieldProps}, ref) => (
                                    <Input
                                        {...fieldProps}
                                        onChange={(event) => {
                                            event.currentTarget.value = formatPhoneNumber(event.currentTarget.value);
                                            onChange(event);
                                        }}
                                        ref={ref}
                                        isInvalid={!!errorMessage || !!otherErrorStateServ[Number(field)]?.error}
                                        autoComplete="off"
                                        autoFocus={!otherErrorStateServ[Number(field)]?.error}
                                        placeholder=""
                                        className={style.profileInput}
                                    />
                                )}
                                readView={() => (<InputText value={formatPhoneNumber(phone)}/>)}
                                onConfirm={(valueInput: string) => {
                                    const newOtherErrorStateServ = [...otherErrorStateServ];
                                    // @ts-ignore
                                    newOtherErrorStateServ[Number(field)]?.clearError && newOtherErrorStateServ[Number(field)].clearError();
                                    newOtherErrorStateServ[Number(field)] = {
                                        clearError: undefined,
                                        error: undefined,
                                        valueBeforeError: ''
                                    };
                                    setOtherErrorStateServ(newOtherErrorStateServ);

                                    const value = valueInput;

                                    if (!value || value === '+' || value === phones[index].phone) {
                                        return;
                                    }

                                    if(!phones[index].id) {
                                        addPhoneAPI(idContact, value.replace(/\s/g, '')).catch((error) => {
                                            const newOtherErrorStateServ = [...otherErrorStateServ];
                                            newOtherErrorStateServ[Number(field)] = {
                                                clearError: showErrorNotify(String(error.message)),
                                                error: String(error.message),
                                                valueBeforeError: value
                                            };
                                            setOtherErrorStateServ(newOtherErrorStateServ);
                                        });
                                    } else {
                                        editPhoneAPI(idContact, id, value.replace(/\s/g, '')).catch((error) => {
                                            const newOtherErrorStateServ = [...otherErrorStateServ];
                                            newOtherErrorStateServ[Number(field)] = {
                                                clearError: showErrorNotify(String(error.message)),
                                                error: String(error.message),
                                                valueBeforeError: value
                                            };
                                            setOtherErrorStateServ(newOtherErrorStateServ);
                                        });
                                    }
                                }}
                            />
                        </div>
                        {(isAccessEdit || isMyProfile) && <div className={style.contactBtnGroup}>
                            <div>
                                <Tooltip position="bottom" delay={0} content="Delete phone number">
                                    {(tooltipProps) => (
                                        <IconButton
                                            {...tooltipProps}
                                            onClick={() => {
                                                const newOtherErrorStateServ = [...otherErrorStateServ];
                                                // @ts-ignore
                                                newOtherErrorStateServ[Number(field)]?.clearError && newOtherErrorStateServ[Number(field)].clearError();
                                                newOtherErrorStateServ[Number(field)] = {
                                                    clearError: undefined,
                                                    error: undefined,
                                                    valueBeforeError: ''
                                                };
                                                setOtherErrorStateServ(newOtherErrorStateServ);
                                                if(id === null) {
                                                    phones.splice(index, 1)
                                                    setPhones([...phones]);
                                                } else {
                                                    removePhoneAPI(idContact, id);
                                                }
                                            }}
                                            appearance="subtle"
                                            shape="default"
                                            // @ts-ignore
                                            icon={EditorRemoveIconNew}
                                            //overlay={isRemoveLoading ? <Spinner size="medium" /> : null}
                                            label="Delete"
                                        />
                                    )}
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                );
            })}
            {(isAccessEdit || isMyProfile) && phones.length < 2 && <div className={cn(phones.length > 0 && style.addContactField)}>
                <Button
                    className={styleParent.btnAdd}
                    iconBefore={<EditorAddIcon primaryColor="#758195" label="Add Phone number"/>}
                    appearance="subtle"
                    spacing="compact"
                    onClick={() => {
                        //@ts-ignore
                        setPhones([...phones, {
                            phone: '',
                            //@ts-ignore
                            id: null
                        }]);
                    }}
                >Add phone number</Button>
            </div>}
        </BlockInfoItem>
    );
};