import {Dispatch, FC, SetStateAction, MouseEvent, useState, forwardRef, useId} from "react";
import style from './PasswordInput.module.scss';
import { Label } from '@atlaskit/form';
import TextField, {TextFieldProps} from '@atlaskit/textfield';
import WatchIcon from '@atlaskit/icon/glyph/watch'
import UnWatchIcon from '@atlaskit/icon/glyph/watch-filled'

interface IconPasswordInputProps {
    isVisiblePassword: boolean;
    setVisiblePassword: Dispatch<SetStateAction<boolean>>
}

const IconPasswordInput: FC<IconPasswordInputProps> = ({isVisiblePassword, setVisiblePassword}) => {
    const onClickEvent = (event: MouseEvent<HTMLDivElement>) => {
        setVisiblePassword(!isVisiblePassword);
    };

    return (
        <div className={style.iconWrapper} onClick={onClickEvent}>
            {isVisiblePassword ? <UnWatchIcon label="UnWatch Password" /> : <WatchIcon label="Watch Password" />}
        </div>
    );
};

interface PasswordInputProps extends TextFieldProps {
    label?: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({label, id, ...props}, ref) => {
    const [isVisiblePassword, setVisiblePassword] = useState<boolean>(false);

    const randID = useId();
    const idProps = id ? id : randID;

    return (
        <div className={style.inputWrapper}>
            {label && (<div className={style.label}>
                <Label htmlFor={idProps}>{label}</Label>
            </div>)}
            <TextField
                {...props}
                ref={ref}
                id={idProps}
                type={isVisiblePassword ? 'text' : 'password'}
                minLength={8}
                maxLength={30}
                className={style.input}
                elemAfterInput={<IconPasswordInput isVisiblePassword={isVisiblePassword} setVisiblePassword={setVisiblePassword} />}
            />
        </div>
    );
});