import {useQuery} from "../../../../useQuery";
import {addContact as addContactAPIs, removeContact as removeContactAPIs} from "./contactInfo";
import {useEvent} from "../../../../../customHooks/useEvent";

export const useContactInfo = (id: string) => {
    const [addContactAPI, isLoading, isSuccess, isError] = useQuery(addContactAPIs);
    const [removeContactAPI, isRemoveLoading, isRemoveSuccess, isRemoveError] = useQuery(removeContactAPIs);

    const addContact = useEvent( async (data: string) => {
        try {
            const res = await addContactAPI(id, data);

            if(res && res.error) {
                throw new Error(res.error);
            }

            return res;
        } catch (error) {
            // @ts-ignore
            throw new Error(error.message);
        }
    });

    const removeContact = useEvent(async (field: string) => {
        try {
            const res = await removeContactAPI(id, field);

            return res;
        } catch (error) {
            throw new Error();
        }
    });

    return {
        addContact: addContact,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
        removeContact: removeContact,
        isRemoveLoading: isRemoveLoading,
        isRemoveSuccess: isRemoveSuccess,
        isRemoveError: isRemoveError
    };
};