import React, { FC } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import { token } from "@atlaskit/tokens";
import { Inline } from "@atlaskit/primitives";
import {
  StyledExitButton,
  StyledText,
} from "./StylesModalPolicyNotification";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import {
    ProfileModalChangeDateWithPolicyAccrual
} from "../../../../interfaces/timeOffPolicy/profileModalChangeDateWithPolicyAccrual.interface";
import {formatDate} from "../../../../utils/helpers/formatDate";
import {TypeIconsCollection} from "../../../../shared/TimeOffPolicy/constants/NewTypeIconsCollection";

interface Policy {
  accrueRuleId: string;
  date: string;
  intervalType: string;
  policyId: string;
  policyName: string;
  yearlyAllocationDateType: string;
}

interface ModalPolicyNotificationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  policies: ProfileModalChangeDateWithPolicyAccrual[];
}

const ModalPolicyNotification: FC<ModalPolicyNotificationProps> = ({
     isOpen,
     onClose,
     onConfirm,
     policies,
    }) => {
    return (
      <ModalTransition>
        {isOpen && (
        <Modal onClose={onClose} width="443px">
          <ModalHeader>
            <ModalTitle>
              <Inline alignBlock="center" space="space.100">
                <WarningIcon
                    label=""
                    primaryColor={token("color.icon.warning")}
                />
                You changed the date
              </Inline>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <StyledText>
              You have updated the reference date used for calculating<br/>
              days according to absence policies or days with special<br/>
              statuses. Adjust the number of days manually if the accrual<br/>
              schedule has changed significantly.<br />
              The list of policies with newly calculated upcoming accrual<br/>
              dates is as follows:

              {policies.map((policy, index) => (
                  <div className={'modal-policy-accrual-notification'} key={index}>
                      <div className={'inner-block'}>
                          {TypeIconsCollection[
                              policy.policyIcon as keyof typeof TypeIconsCollection
                              ]("small-icon", "withouthover")}
                          <strong>{policy.policyName}</strong>
                      </div>
                      <div><strong>{formatDate(new Date(policy.date))}</strong></div>
                  </div>
              ))}
            </StyledText>
          </ModalBody>
            <ModalFooter>
                <StyledExitButton>
                    <Button appearance="primary" onClick={onConfirm}>
                        Got it
                    </Button>
                </StyledExitButton>
            </ModalFooter>
        </Modal>
        )}
      </ModalTransition>
  );
};

export default ModalPolicyNotification;