import { FC, ReactNode } from "react";
import Tooltip, { PositionType } from "@atlaskit/tooltip";

interface LongTextTooltipProps {
  content: string;
  maxContentLength: number;
  children: ReactNode;
  position?: PositionType;
}

export const LongTextTooltip: FC<LongTextTooltipProps> = ({
  content,
  maxContentLength,
  position = "bottom-end",
  children,
}) => {
  return (
    <>
      {content.length > maxContentLength ? (
        <Tooltip content={content} position={position}>
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
