import {useState} from "react";
import {DirectManagerProps} from "./directManager.types";
import {useQuery} from "../../../../useQuery";
import {fetchDirectManagerAPI} from "./directManager";
import {useEvent} from "../../../../../customHooks/useEvent";

export const useDirectManager = (idOpenProfile: string) => {
    const [directManager, setDirectManagers] = useState<Array<DirectManagerProps>>([]);
    const [getAllEmployees, isLoading, isSuccess, isError] = useQuery(fetchDirectManagerAPI);

    const fetchDirectManagers = useEvent(async () => {
        return getAllEmployees(idOpenProfile)
            .then((response) => {
                setDirectManagers(response);
                return response;
            });
    });

    return {
        directManager: directManager,
        fetchDirectManagers: fetchDirectManagers,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError
    };
};