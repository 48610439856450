import { MultiSelect } from 'react-multi-select-component'
import { Box, Inline } from '@atlaskit/primitives'
import Tag from '@atlaskit/tag'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import { Checkbox } from '@atlaskit/checkbox'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { LongTextTooltip } from 'components/UI/LongTextTooltip'
import {
  StyledPolicySelect,
  StyledSelectPlaceholder,
} from './PolicySelectStyles'

interface Option {
  value: any
  label: string
  icon?: string
}

interface IDefaultItemRendererProps {
  checked: boolean
  option: Option
  disabled?: boolean
  onClick: React.ChangeEventHandler<HTMLInputElement>
  onChange: (arg: Option[]) => void
  value: Option[]
}

const ItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
  onChange,
  value,
}: IDefaultItemRendererProps) => {
  return !(option.label === 'Clear Button') ? (
    <LongTextTooltip content={option.label} maxContentLength={20}>
      <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
        <Box>
          <Checkbox
            isChecked={checked}
            onChange={(val) => {
              onClick(val)

              const cssElement = document.querySelector(
                '.select-panel .search input'
              )
              if (cssElement instanceof HTMLElement) {
                cssElement.focus()
              }
            }}
            isDisabled={disabled}
          />
        </Box>
        <Box>
          <div className="item-textline">
            {option.icon
              ? TypeIconsCollection[
                  option.icon as keyof typeof TypeIconsCollection
                ]('small-icon')
              : null}

            <span>{option.label}</span>
          </div>
        </Box>
      </div>
    </LongTextTooltip>
  ) : (
    <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
      <Box
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation()
          onChange([])
        }}
      >
        <Tag removeButtonLabel="Remove" text={String(value.length)} />
      </Box>
    </div>
  )
}

function valueRenderer(
  selectedOptions: Option[],
  placeholder: string,
  onChange: (arg: Option[]) => void
) {
  return selectedOptions.length > 0 ? (
    <StyledSelectPlaceholder>
      <p
        onClick={(e: { stopPropagation: () => void }) => {
          e.stopPropagation()
          onChange([])
        }}
      >
        <Tag removeButtonLabel="Remove" text={String(selectedOptions.length)} />
      </p>

      <Box>
        {selectedOptions[0].label}
        {selectedOptions.length > 1 ? ', ' + selectedOptions[1].label : ''}
        {selectedOptions.length > 2 ? ', ' + selectedOptions[2].label : ''}
        {selectedOptions.length > 3 ? ', ' + selectedOptions[3].label : ''}
      </Box>
    </StyledSelectPlaceholder>
  ) : (
    <>{placeholder}</>
  )
}

interface IMultiSelect {
  dropdownHorizontalDisplacement?: string
  options: Option[]
  value: Option[]
  onChange: (arg: Option[]) => void
  onSelectClick?: () => void
  placeholder: string
  labelledBy: string
  isOpen?: boolean
  hideSearch?: boolean
  [key: string]: any
}

function MultiSelectCustom({
  options,
  value,
  onChange,
  placeholder,
  labelledBy,
  onSelectClick,
  dropdownHorizontalDisplacement,
  isOpen,
  hideSearch,
  ...props
}: IMultiSelect) {
  return (
    <StyledPolicySelect
      onClick={(e) => {
        e.stopPropagation()
      }}
      dropdownHorizontalDisplacement={dropdownHorizontalDisplacement}
    >
      <MultiSelect
        isOpen={isOpen}
        onMenuToggle={() => {
          if (onSelectClick) {
            onSelectClick()
          }
        }}
        disableSearch={hideSearch}
        labelledBy={labelledBy}
        options={options}
        value={value}
        onChange={onChange}
        hasSelectAll={false}
        ArrowRenderer={(arg) => {
          return arg.expanded ? (
            <ChevronDownIcon label="dropdown_arrow" />
          ) : (
            <ChevronDownIcon label="dropdown_arrow" />
          )
        }}
        ItemRenderer={(obj: IDefaultItemRendererProps) => {
          return ItemRenderer({ ...obj, onChange, value })
        }}
        valueRenderer={(selectedOptions) => {
          return valueRenderer(selectedOptions, placeholder, onChange)
        }}
        {...props}
      />
    </StyledPolicySelect>
  )
}

export default MultiSelectCustom
