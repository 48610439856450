import { Stack, Inline, xcss, Box } from "@atlaskit/primitives";
import Avatar from "@atlaskit/avatar";
import Button from "@atlaskit/button/new";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Select, { type ValueType } from "@atlaskit/select";
import Form, { Field, ErrorMessage } from "@atlaskit/form";
import TextArea from "@atlaskit/textarea";
import { TypeIconsCollection } from "shared/TimeOffPolicy/constants/NewTypeIconsCollection";
import Calendar from "@atlaskit/calendar";
import {
  StyledRequestCalendar,
  StyledPolicyItem,
  StyledUserBlockInfo,
  UserBlock,
  StyledRequestModal,
  StyledCalendarInfo,
  StyledRequestModalBody,
} from "./TimeOffModalStyles";
import { SetStateAction, useEffect, useState } from "react";
import {
  CALENDAR,
  EMPLOYEES,
  EMPLOYEE,
  HOLIDAYS,
} from "../../../../utils/constants/api";
import { token } from "@atlaskit/tokens";
import SelectClearIcon from "@atlaskit/icon/glyph/select-clear";
import {
  TSubstitutes,
  TSubstitute,
  TPolicies,
  TPolicy,
  TEmployees,
  TEmployee,
  TPayroll,
} from "../RequestsTypes";

export default function RequestTimeOffModal({
  isModalOpen,
  closeModal,
  isManager,
  isHR,
  viewer,
  subordinates,
  setNotes,
  updateData,
  profile,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  isManager: boolean;
  isHR: boolean;
  viewer: any;
  subordinates: any;
  setNotes: (arg: string[]) => void;
  updateData: () => void;
  profile?: any;
}) {
  const initialUser = profile ? profile : viewer;

  const [employee, setEmployee] = useState<{
    label: any;
    value: string;
    id: string;
  } | null>(
    !isHR && !isManager
      ? {
          label: `${initialUser.firstNameEn} ${initialUser.lastNameEn} ${initialUser.nickname ? "(" + initialUser.nickname + ")" : null}`,
          value: `${initialUser.firstNameEn} ${initialUser.lastNameEn} ${initialUser.nickname ? "(" + initialUser.nickname + ")" : null}`,
          id: initialUser.id,
        }
      : profile
        ? {
            label: `${profile.firstNameEn} ${profile.lastNameEn} ${profile.nickname ? "(" + profile.nickname + ")" : null}`,
            value: `${profile.firstNameEn} ${profile.lastNameEn} ${profile.nickname ? "(" + profile.nickname + ")" : null}`,
            id: profile.id,
          }
        : null
  );
  const [employees, setEmployees] = useState<
    { label: any; value: string; id: string }[]
  >([]);
  const [policy, setPolicy] = useState<TPolicy | null>(null);
  const [policies, setPolicies] = useState<{ label: any; options: any }[]>([]);
  const [substitutes, setSubstitutes] = useState<
    { label: any; value: string }[]
  >([]);
  const [substitute, setSubstitute] = useState<TSubstitute | null>(null);

  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);

  const [payroll, setPayroll] = useState<TPayroll | null>(null);

  const [errors, setErrors] = useState({
    calendar: { dateFrom: false, dateTo: false },
    employee: false,
    policy: false,
    substitute: false,
  });

  const [comment, setComment] = useState("");
  const [holidays, setHolidays] = useState<string[]>([]);

  const [monthYear, setMonthYear] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
  );

  const [sending, setSending] = useState(false);
  const [policyLoading, setPolicyLoading] = useState(false);
  const [employeesLoading, setEmployeesLoading] = useState(false);
  const [substituteLoading, setSubstituteLoading] = useState(false);

  const onSubmit = (val: any) => {
    const errors = {
      calendar: {
        dateFrom: !val.calendar.dateFrom,
        dateTo: !val.calendar.dateTo,
      },
      employee: !val.employee,
      policy: !val.policy,
      substitute: val.policy
        ? val.policy.replacement
          ? !val.substitute
          : false
        : false,
    };
    setErrors(errors);

    if (
      !Object.values(errors).includes(true) &&
      !Object.values(errors.calendar).includes(true)
    ) {
      let formData = new FormData();
      formData.append(
        "request",
        JSON.stringify({
          requester: employee?.id,
          startDate: dateFrom,
          endDate: dateTo,
          substitute: substitute ? substitute?.id : null,
          comment: comment ? comment : null,
          statusChangeBy: viewer.id,
          balance: policy?.id,
          status: "New",
        })
      );
      saveRequest(formData);
    } else {
      console.log("Form error");
    }
  };

  function moveObjectToFirst(
    arr: { [key: string]: any }[],
    property: string,
    valueToMove: string
  ) {
    let index = arr.findIndex((obj) => obj[property] === valueToMove);

    if (index !== -1) {
      let [objectToMove] = arr.splice(index, 1);

      arr.unshift(objectToMove);
    }

    return arr;
  }

  useEffect(() => {
    setEmployeesLoading(true);
    fetch(EMPLOYEES + "/type/active")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        setEmployeesLoading(false);
        if (data) {
          let result = moveObjectToFirst(data as TEmployees[], "id", viewer.id);

          setEmployees(
            result
              .filter(
                !isHR
                  ? (item) => {
                      return (
                        subordinates.find((obj: any) => {
                          return item.id === obj.id;
                        }) || item.id === viewer.id
                      );
                    }
                  : () => true
              )
              .map((item) => {
                return {
                  label: (
                    <Inline xcss={UserBlock}>
                      <Avatar
                        name="username"
                        src={item.avatar}
                        size="small"
                        appearance="circle"
                      />
                      <StyledUserBlockInfo>
                        <p>
                          {item.firstNameEn} {item.lastNameEn}{" "}
                          {item.nickname ? "(" + item.nickname + ")" : null}
                        </p>
                        <span>{item.email}</span>
                      </StyledUserBlockInfo>
                    </Inline>
                  ),
                  value: `${item.firstNameEn} ${item.lastNameEn} ${item.nickname ? "(" + item.nickname + ")" : null}`,
                  id: item.id,
                };
              })
          );
        }
      });
  }, [viewer]);

  useEffect(() => {
    function buildLi(item: TPolicies) {
      return {
        label: (
          <StyledPolicyItem>
            {TypeIconsCollection[
              item.policyIcon as keyof typeof TypeIconsCollection
            ]("small-icon", "withouthover")}
            <p
              style={
                item.quantity === 0 && item.accrualSchedule !== false
                  ? { color: token("color.text.disabled") }
                  : {}
              }
            >
              {item.policyName}
            </p>
            <span>
              {item.accrualSchedule === false
                ? "unlimited"
                : `${item.quantity} day${item.quantity > 1 ? "s" : ""}`}
            </span>
          </StyledPolicyItem>
        ),
        value: item.policyName,
        id: item.policyId,
        replacement: item.isReplacementPersonMandatory,
        quantity: item.quantity,
        minimumLead: item.minimumLeadTimeRequirement,
        maxQuantity: item.maxQuantity,
        minimumDays: item.minimumDaysRequirement,
        maximumDays: item.maximumDaysRequirement,
        accrualSchedule: item.accrualSchedule,
      };
    }

    if (employee) {
      setPolicy(null);
      setPolicyLoading(true);
      fetch(EMPLOYEE + `/` + employee?.id + `/time-off-accrued-days`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setPolicyLoading(false);
          if (data) {
            const paid: TPolicies[] = [];

            const unpaid: TPolicies[] = [];

            (data as TPolicies[]).forEach((item) => {
              if (item.companyPaidRegardlessOfWorkday) {
                paid.push(item);
              } else {
                unpaid.push(item);
              }
            });

            const paidOptions = paid.map(buildLi);

            const unpaidOptions = unpaid.map(buildLi);

            setPolicies([
              {
                label: "Paid",
                options: paidOptions,
              },
              {
                label: "Unpaid",
                options: unpaidOptions,
              },
            ]);
          }
        });

      fetch(EMPLOYEES + "/" + employee.id + "/payroll/last-report")
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data: TPayroll) => {
          if (data) {
            setPayroll(data);
          }
        });
    }
  }, [employee]);

  useEffect(() => {
    if (employee && policy && dateFrom && dateTo) {
      setSubstituteLoading(true);
      fetch(
        EMPLOYEES +
          `/type/active-without-request-for-period?date_start=${dateFrom}&date_end=${dateTo}`
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setSubstituteLoading(false);
          if (data) {
            setSubstitutes(
              (data as TSubstitutes[])
                .filter((item) => item.id !== employee.id)
                .map((item) => {
                  return {
                    label: (
                      <Inline xcss={UserBlock}>
                        <Avatar
                          name="username"
                          src={item.avatar}
                          size="small"
                          appearance="circle"
                        />
                        <StyledUserBlockInfo>
                          <p>
                            {item.firstNameEn} {item.lastNameEn}{" "}
                            {item.nickname ? "(" + item.nickname + ")" : null}
                          </p>
                          <span>{item.email}</span>
                        </StyledUserBlockInfo>
                      </Inline>
                    ),
                    value: `${item.firstNameEn} ${item.lastNameEn} (${item.nickname})`,
                    id: item.id,
                  };
                })
            );
          }
        });
    }
  }, [policy, dateFrom, dateTo]);

  useEffect(() => {
    if (employee) {
      const startDate = new Date(monthYear);
      startDate.setMonth(new Date(monthYear).getMonth());
      const endDate = new Date(monthYear);
      endDate.setMonth(new Date(monthYear).getMonth() + 3);

      const startPeriod = `${startDate.getFullYear()}-${startDate.getMonth()}-${"01"}`;
      const endPeriod = `${endDate.getFullYear()}-${endDate.getMonth()}-${"01"}`;

      fetch(
        HOLIDAYS +
          "/employee/" +
          employee.id +
          "/" +
          startPeriod +
          "/" +
          endPeriod +
          "?withRequests=" +
          true,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((timeOffDates: []) => {
          setHolidays(timeOffDates);
        });
    }
  }, [employee, monthYear]);

  function getDatesBetween(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    let currentDate = start;

    while (currentDate <= end) {
      dateArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray.filter((elm) => !holidays.includes(elm));
  }

  function showRange(startDate: string, endDate: string | null) {
    if (endDate) {
      const from = new Date(startDate);
      const to = new Date(endDate);

      return `${String(from.getDate()).padStart(2, "0")}.${String(from.getMonth() + 1).padStart(2, "0")}.${from.getFullYear()} - ${String(to.getDate()).padStart(2, "0")}.${String(to.getMonth() + 1).padStart(2, "0")}.${to.getFullYear()}`;
    } else {
      const from = new Date(startDate);

      return `${String(from.getDate()).padStart(2, "0")}.${String(from.getMonth() + 1).padStart(2, "0")}.${from.getFullYear()} -`;
    }
  }

  function saveRequest(formData: any): void {
    setSending(true);
    fetch(CALENDAR + "/request", { method: "POST", body: formData })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then((newRequest) => {
        setSending(false);
        if (newRequest !== null) {
          setNotes(["Request created"]);
          closeModal();
          updateData();
        }
      });
  }

  function checkDate() {
    return policy === null
      ? () => true
      : (date: string) => {
          let result = holidays.includes(date);

          if (payroll) {
            result = result || new Date(date) <= new Date(payroll.endDate);
          }

          if (policy.minimumLead) {
            result =
              result ||
              getDatesBetween(new Date().toISOString().split("T")[0], date)
                .length <= policy.minimumLead;
          }

          if (policy && dateFrom && !dateTo) {
            if (policy.accrualSchedule !== false) {
              result =
                result ||
                getDatesBetween(dateFrom, date).length > policy?.quantity ||
                getDatesBetween(date, dateFrom).length > policy?.quantity;
            }
          }

          if (policy && dateFrom && !dateTo) {
            if (policy?.minimumDays) {
              result =
                result ||
                (new Date(date) >= new Date(dateFrom)
                  ? getDatesBetween(dateFrom, date).length < policy?.minimumDays
                  : getDatesBetween(date, dateFrom).length <
                    policy?.minimumDays);
            }

            if (policy?.maximumDays) {
              result =
                result ||
                (new Date(date) >= new Date(dateFrom)
                  ? getDatesBetween(dateFrom, date).length > policy?.maximumDays
                  : getDatesBetween(date, dateFrom).length >
                    policy?.maximumDays);
            }
          }

          return result;
        };
  }

  function onDateSelect(date: { iso: string }) {
    if (dateFrom === null && dateTo === null) {
      setDateFrom(date.iso);
    } else if (dateFrom !== null && dateTo === null) {
      if (date.iso >= dateFrom) {
        setDateTo(date.iso);
      } else {
        if (policy) {
          setDateFrom(date.iso);
          setDateTo(dateFrom);
        }
      }
    } else if (dateFrom !== null && dateTo !== null) {
      setDateFrom(date.iso);
      setDateTo(null);
    } else if (dateFrom === null && dateTo !== null) {
      setDateFrom(date.iso);
    }
  }

  return (
    <>
      <ModalTransition>
        {isModalOpen && (
          <Modal
            onClose={closeModal}
            width={689}
            shouldScrollInViewport
            autoFocus={false}
          >
            <StyledRequestModal>
              <Form onSubmit={onSubmit}>
                {({ formProps }) => (
                  <form id="form-with-id" {...formProps}>
                    <ModalHeader>
                      <ModalTitle>Create a request</ModalTitle>
                    </ModalHeader>

                    <ModalBody>
                      <StyledRequestModalBody>
                        <Stack grow="fill" space="space.150">
                          <Box
                            xcss={xcss({
                              display: isHR || isManager ? "block" : "none",
                            })}
                          >
                            {" "}
                            <Field<ValueType<TEmployee>>
                              name="employee"
                              label="Choose employee"
                              defaultValue={employee}
                            >
                              {({ fieldProps: { id } }) => (
                                <>
                                  <Select<TEmployee>
                                    isLoading={employeesLoading}
                                    onFocus={() => {
                                      setErrors((errors) => {
                                        return { ...errors, employee: false };
                                      });
                                    }}
                                    isDisabled={!!profile}
                                    inputId={id}
                                    options={employees}
                                    maxMenuHeight={296}
                                    placeholder="Employee"
                                    className="request-modal-employee"
                                    value={employee}
                                    onChange={(val) => {
                                      setEmployee(val as TEmployee);
                                    }}
                                  />
                                  {errors.employee && (
                                    <ErrorMessage>
                                      Please, choose the employee
                                    </ErrorMessage>
                                  )}
                                </>
                              )}
                            </Field>
                          </Box>
                          <Field<ValueType<TPolicy>>
                            name="policy"
                            label="Choose policy"
                            defaultValue={policy}
                          >
                            {({ fieldProps: { id } }) => (
                              <>
                                <Select<TPolicy>
                                  isLoading={policyLoading}
                                  onFocus={() => {
                                    setErrors((errors) => {
                                      return { ...errors, policy: false };
                                    });
                                  }}
                                  isOptionDisabled={(option) => {
                                    return (
                                      option.quantity === 0 &&
                                      option.accrualSchedule !== false
                                    );
                                  }}
                                  inputId={id}
                                  options={policies}
                                  maxMenuHeight={204}
                                  placeholder="Policy"
                                  className="request-modal-policy"
                                  {...(employee ? {} : { isDisabled: true })}
                                  onChange={(val) => {
                                    setPolicy(val as TPolicy);
                                  }}
                                />
                                {errors.policy && (
                                  <ErrorMessage>
                                    Please, choose the policy
                                  </ErrorMessage>
                                )}
                              </>
                            )}
                          </Field>
                          <Field<ValueType<TSubstitute>>
                            name="substitute"
                            label={
                              "Select substitute during your absence" +
                              (policy?.replacement ? "" : " (optional)")
                            }
                            defaultValue={substitute}
                          >
                            {({ fieldProps: { id } }) => (
                              <>
                                <Select
                                  isLoading={substituteLoading}
                                  onFocus={() => {
                                    setErrors((errors) => {
                                      return { ...errors, substitute: false };
                                    });
                                  }}
                                  inputId={id}
                                  options={substitutes}
                                  maxMenuHeight={296}
                                  placeholder="Select colleague"
                                  className="request-modal-substitute"
                                  {...(policy && dateFrom && dateTo
                                    ? {}
                                    : { isDisabled: true })}
                                  onChange={(val) => {
                                    setSubstitute(val as TSubstitute);
                                  }}
                                />
                                {errors.substitute && (
                                  <ErrorMessage>
                                    Please, select substitute
                                  </ErrorMessage>
                                )}
                              </>
                            )}
                          </Field>

                          <Field
                            label="Add a comment (optional)"
                            name="request-form-comment"
                            defaultValue={comment}
                          >
                            {({ fieldProps }: any) => (
                              <>
                                <TextArea
                                  {...fieldProps}
                                  onChange={(e) => {
                                    setComment(e.target.value);
                                  }}
                                  placeholder="Add a comment"
                                  maxHeight={86}
                                />
                              </>
                            )}
                          </Field>
                        </Stack>

                        <Stack grow="fill">
                          <StyledRequestCalendar>
                            <Field
                              label="Select the date range"
                              name="calendar"
                              defaultValue={{
                                dateFrom: dateFrom,
                                dateTo: dateTo,
                              }}
                            >
                              {({ fieldProps: { id, ...rest }, error }) => (
                                <>
                                  {errors.calendar ? (
                                    errors.calendar.dateTo ||
                                    errors.calendar.dateFrom ? (
                                      <ErrorMessage>
                                        Please, select a date
                                      </ErrorMessage>
                                    ) : null
                                  ) : null}

                                  <Calendar
                                    previouslySelected={
                                      dateFrom && dateTo
                                        ? getDatesBetween(dateFrom, dateTo)
                                        : dateFrom
                                          ? [dateFrom]
                                          : []
                                    }
                                    weekStartDay={1}
                                    testId={rest.name}
                                    disabledDateFilter={checkDate()}
                                    onChange={(date, e) => {
                                      setMonthYear(date.iso);
                                      setErrors((errors) => {
                                        return {
                                          ...errors,
                                          calendar: {
                                            dateFrom: false,
                                            dateTo: false,
                                          },
                                        };
                                      });
                                    }}
                                    onSelect={(date, e) => {
                                      onDateSelect(date);
                                      setErrors((errors) => {
                                        return {
                                          ...errors,
                                          calendar: {
                                            dateFrom: false,
                                            dateTo: false,
                                          },
                                        };
                                      });
                                    }}
                                    onFocus={() => {}}
                                  />
                                </>
                              )}
                            </Field>
                          </StyledRequestCalendar>

                          <StyledCalendarInfo>
                            <p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {dateFrom ? showRange(dateFrom, dateTo) : null}
                                {dateFrom ? (
                                  <span
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setDateFrom(null);
                                      setDateTo(null);
                                    }}
                                  >
                                    {" "}
                                    <SelectClearIcon
                                      label="clear"
                                      size="small"
                                    />{" "}
                                  </span>
                                ) : null}
                              </div>

                              {dateFrom && dateTo ? (
                                <span>
                                  for {getDatesBetween(dateFrom, dateTo).length}{" "}
                                  day
                                  {getDatesBetween(dateFrom, dateTo).length > 1
                                    ? "s"
                                    : ""}
                                </span>
                              ) : null}
                            </p>

                            <div>
                              <p>
                                Days marked in gray color — non-working and past
                                days, are unavailable.
                              </p>
                            </div>
                          </StyledCalendarInfo>
                        </Stack>
                      </StyledRequestModalBody>
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={closeModal} appearance="subtle">
                        Cancel
                      </Button>
                      <Button
                        isDisabled={sending}
                        isLoading={sending}
                        type="submit"
                        form="form-with-id"
                        appearance="primary"
                      >
                        Send request
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Form>
            </StyledRequestModal>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
