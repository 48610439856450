import React, { useEffect, useState, FC, ReactElement } from "react";
import { xcss, Box } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { TimeOffBalance } from "../../../../../components/Profile/ShortInformationCard/TimeOffBalance/TimeOffBalance";

import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";

const ButtonWrapper: FC<{
  children: ReactElement<any, any>;
  style?: any;
}> = styled.div`
  ${(props) => `
    button {
        background-color: ${token("color.background.accent.blue.subtlest")};
        width:100%;
        border-radius: 3px;
        border: 1px solid ${token("color.border")};
    }
    button:hover{
        background-color: ${token("color.background.accent.blue.subtlest.hovered")};
    }
  `}
`;

const TimeOffBalanceWrapper = styled.div`
  ${(props) => `
  button {
      cursor:default !important;
      width: 91px !important;
  }
`}
`;

export default function RequestTimeOff({
  openModal,
  timeOffBalance,
}: {
  openModal: (arg: boolean) => void;
  timeOffBalance: {
    timeOffPolicyId: string;
    timeOffPolicyName: string;
    quantity: number;
    typeIcon: string;
  }[];
}) {
  return timeOffBalance.length > 0 ? (
    <Box
      xcss={xcss({
        border: "1px solid",
        backgroundColor: "elevation.surface",
        borderColor: "color.border.disabled",
        borderRadius: "border.radius.050",
        padding: "space.200",
        width: "100%",
        minHeight: "138px",
        marginBottom: "space.300",
      })}
    >
      <Box>
        <h2
          style={{
            fontFamily: "inherit",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
          }}
        >
          Time Off Balance:
        </h2>
      </Box>
      <Box
        xcss={xcss({
          marginTop: "space.100",
          display: "flex",
          flexWrap: "wrap",
          rowGap: "space.100",
          columnGap: "space.200",
        })}
      >
        {timeOffBalance.map((value) => (
          <TimeOffBalanceWrapper key={value.timeOffPolicyName}>
            <TimeOffBalance
              type={value.typeIcon}
              title={value.timeOffPolicyName}
              count={value.quantity}
              onClick={() => {}}
              noHover={true}
            />
          </TimeOffBalanceWrapper>
        ))}
      </Box>
      <Box
        xcss={xcss({
          marginTop: "space.200",
        })}
      >
        <ButtonWrapper>
          <Button
            className="requestTimeOff"
            onClick={() => {
              openModal(true);
            }}
          >
            <Box
              xcss={xcss({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "space.100",
              })}
            >
              {" "}
              <Box
                xcss={xcss({
                  marginRight: "space.050",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                })}
              >
                <EditorAddIcon label="EditorAddIcon" />
              </Box>
              Request Time Off
            </Box>
          </Button>
        </ButtonWrapper>
      </Box>
    </Box>
  ) : null;
}
